@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.edi-user-menu,
.edi-user-org-selector-list {
	z-index: 90500 !important;
}

body .#{$main_prefix}-header {
	&-panel {
		& .x-panel-body {
			background: #FFF !important;
		}

		box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

		&-user {
			margin: 3px 0 0 0;
			padding: 10px;
			min-width: 132px;
			background-color: #F5F5F5;
			border-radius: 18px;

			color: #000 !important;

			& .x-btn-inner {
				text-align: left !important;
				line-height: 1.2em;
				font-size: 13px;
				font-weight: 500;
			}
		}

		&-org {
			& .x-form-trigger-wrap {
				margin: -3px 0 0 0;

				& .x-form-text {
					color: #000;
					margin: auto;
					line-height: 1.2em;
					font-size: 13px;
					font-weight: 500;
					background: transparent;
					box-shadow: none;
				}

				& .x-trigger-glyph {
					color: #000;
				}
			}
		}

		&-notifications.x-btn {
			@include roundHover(#1D252C, #1D252C, #F3F4F4, #E8E9E9);
		}

		&-notices.x-btn {
			@include roundHover(#1D252C, #1D252C, #F3F4F4, #E8E9E9);
		}

		&-nav-button.x-btn {
			@include roundHover(#3D7DCA, #3D7DCA, #E2ECF7, #E2ECF7);
			float: left !important;
		}
	}
}

.edi-viewport-header {
	z-index: 90000;
	position: relative !important;
	margin: 0 auto !important;
	border-bottom-left-radius: 80px;
	border-bottom-right-radius: 85px;


	.x-panel-body {
		background: linear-gradient(268.03deg, rgba(255, 255, 255, 0.75) 77.58%, rgba(228, 228, 228, 0.75) 123.22%);
		backdrop-filter: blur(4px);
		display: flex;
		justify-content: center;
		align-items: center;

		a {
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 16px;
			letter-spacing: 0.4px;
			color: #8E9295;
		}
	}
}

.lang-button {
	.ui-button-button::after {
		color: $color-grey-50;
	}

	&.ui-button-menu-active {
		.ui-button-button::after {
			color: $color-dark;
		}
	}

	.ui-button-inner,
	&-menu-item .x-menu-item-link {
		width: 28px;
		height: 16px;
		background-repeat: no-repeat;
	}

	&-menu.ui-menu {
		z-index: 90001 !important;

		.ui-menu-body {
			padding: 4px 0;

			.x-menu-item {
				padding: 8px;

				.x-menu-item-indent-no-separator {
					@extend .caption_01;
					margin-left: 40px;
				}
			}
		}
	}
}

.lang-button,
.lang-button-menu-item {
	&.lang {
		&-RUSSIAN {
			.ui-button-inner,
			.x-menu-item-link {
				background-image: url(./images/flags/flag-RUSSIAN.svg);
			}
		}

		&-ENGLISH {
			.ui-button-inner,
			.x-menu-item-link {
				background-image: url(./images/flags/flag-ENGLISH.svg);
			}
		}

		&-ESTONIAN {
			.ui-button-inner,
			.x-menu-item-link {
				background-image: url(./images/flags/flag-ESTONIAN.svg);
			}
		}

		&-LATVIAN {
			.ui-button-inner,
			.x-menu-item-link {
				background-image: url(./images/flags/flag-LATVIAN.svg);
			}
		}

		&-LITHUANIAN {
			.ui-button-inner,
			.x-menu-item-link {
				background-image: url(./images/flags/flag-LITHUANIAN.svg);
			}
		}
	}
}