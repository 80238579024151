@import '@UIkit/components/fields/Base/Base.scss';
@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-filefield {
	@extend %field;

	.x-form {
		&-trigger {
			&-wrap {
				border-color: transparent !important;
				background-color: rgba(29, 37, 44, 0.05);

				&::before {
					color: $color-blue;
					content: '\e226';
					font-family: 'Material Icons', sans-serif;
					font-size: 24px;
					width: 24px;
					height: 24px;
					line-height: 24px;
					text-align: center;
					margin: 0 0 0 4px;
				}
			}
		}
		&-text {
			cursor: default;
			border: none !important;
			height: auto !important;
			color: $color-blue;
			@extend .body-short_01;

			text-overflow: ellipsis;
		}
	}

	&-hidden {
		display: none !important;
	}
}
