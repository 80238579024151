// @import "@UIkit/styles/global/Colors/Variables";

// $bg-color: $color-white;
// $bg-color-disabled: $color-grey-5;
// $text-color: $color-dark;
// $focus-color: $color-blue;
// $inactive-color: $color-dark;
// $invalid-color: $color-error;
// $border-color-inactive: $color-grey-20;
// $border-color-hover: $color-grey-50;

$border-radius: 4px;
$animation_speed: 200ms;

@import './includes/label';
@import './includes/wrap';
@import './includes/text';

@import './includes/base';
@import './includes/box';
