$label: "x-form-item-label";
$form-text: "x-form-text";

%base-combo {
	position: relative;

	& .#{$label} {
		@include label-inside;
	}

	& .#{$form-text} {
		@include form-text;
	}

	& .x-form-trigger {
		&-wrap {
			@include wrap;

			.x-form-trigger {
				vertical-align: middle;
			}

			& .x-trigger-cell {
				min-width: 20px !important;
				width: 28px !important;
			}
		}
	}

	& .x-form-item-body {
		height: 100% !important;
		padding: 5px 0 0 0;
		width: 100% !important;
	}

	&.had {
		&-not-empty {
			& .#{$label} {
					@include label-top;
			}
		}

		&-focus {
			& .#{$label} {
				@include label-top {
					color: $focus-color !important;
				}
			}

			& .x-form-trigger {
				&-wrap {
					color: $text-color !important;
					border-color: $focus-color !important;
				}
			}
		}

		&-readonly {
			& .#{$form-text} {
				color: $inactive-color !important;
			}
		}

		&-disabled {
			@at-root .had-not-empty#{&} {
				& .#{$label} {
						background: linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;background: -o-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
						background: -moz-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
						background: -webkit-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
						background: -ms-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
				}
			}

			& .x-form-trigger {
				&-wrap {
					border: 1px solid $border-color-inactive !important;
					background: $bg-color-disabled !important;
				}
			}

			& .#{$form-text} {
				opacity: 1 !important;
				color: $inactive-color !important;
			}
		}
	}

	@include invalid;
}