.ui-plugin-combo-items-action {
	display: flex;
	justify-content: start;
	align-items: center;
	column-gap: 16px;

	// &.ui-button {
	// 	display: inline-block !important;
	// }
}
