BODY.edi-viewport {
	.x-window-default {
		box-shadow: 0 4px 8px rgba(30, 54, 182, 0.1) !important;
		border-radius: 10px !important;
	}
	.x-window {
		.x-window-header-default {
			font-size: 13px;
			border-color: #fff;
			background-color: #fff;
			padding: 15px 5px 0 12px;

			.x-window-header-text-container-default, .x-window-header-title.x-window-header-title-default {
				color: black;
				font-family: "Roboto", helvetica, verdana, sans-serif;
				text-transform: none;

				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 24px;
				letter-spacing: 0.4px;
			}
		}

		.x-header {
			.x-tool {
				display: flex;
				justify-content: center;
				align-items: center;

				right: 0 !important;
				left: auto !important;
				border-radius: 100%;
				background-color: transparent;

				&.x-tool-after-title {
					margin: 0 !important;
				}


				&.x-tool-over {
					background-color: #F3F4F4;

					&-img {
						opacity: 1;
					}
				}

				&.x-tool-pressed {
					background-color: #E8E9E9;
				}

				.x-tool-close {
					height: 24px;
					width: 24px;
					&:before {
						font-size: 22px;
						color: black;
						opacity: 0.5;
						content: "\e14c" !important;
					}
				}
			}
		}

		.x-window-text {
			font: normal 13px/17px "Roboto", arial, verdana, sans-serif;
			color: black;
			margin-top: 4px;
			margin-left: 21px;
			margin-right: 21px;
			font-style: normal;
			font-weight: normal;
			font-size: 13px;
			line-height: 15px;
		}

		.x-toolbar-footer {
			margin-bottom: 5px;
		}
	}
}