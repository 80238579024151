@import "@UIkit/styles/global/Colors/Variables";

.ui-tag-text-field {
	background-color: $color-white;

	&-wrap {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;

		width: 100%;
		padding: 1px;
		border: 1px solid $color-grey-20;
		border-radius: 3px;

		transition: all 0.2s ease-out 0s;

		&:hover {
			border: 1px solid $color-grey-50;
		}
	}

	.tags {
		&-wrap {
			display: flex;
			flex-wrap: wrap;
		}
		&-item {
			background-color: $color-dark-opacity-10;
			border-radius: 2px;
			padding: 3px 4px;
			margin: 2px;

			&:hover {
				background-color: $color-dark-opacity-20;
			}

			&-remove {
				width: 10px;
				height: 10px;
				margin-left: 6px;
				color: $color-grey-50;
				line-height: 100%;
				vertical-align: middle;
				cursor: pointer;

				&:hover {
					color: $color-dark;
				}

				&:before {
					font-family: 'Material Icons', sans-serif;
					content: '\e14c';
				}
			}

			&-invalid {
				background-color: $color-error-opacity-10 !important;
			}

			transition: all 0.2s ease-out 0s;
		}
		&-input {
			padding: 5px 5px 4px 5px;
			border: none;
		}
	}
}
