@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

.ui-tooltip {
	background: $color-blue-5;
	border: 1px solid $color-grey-10;
	border-radius: 4px;
	padding: 0;

	&-body {
		@extend .caption_01;
		padding: 2px 4px;
		color: $color-dark;
	}
}
