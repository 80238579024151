$label: "x-form-item-label";
$form-text: "x-form-text";

.#{$prefix}-doublefields {
  & > table:first-child {
    $radius-left: $border-radius 0px 0px $border-radius !important;

    margin-right: -1px;

    & .x-form-trigger {
      &-wrap {
        border-radius: $radius-left;
      }
    }
    & input.#{$form-text} {
      border-radius: $radius-left;
    }
  }
  & > table:last-child {
    $radius-right: 0px $border-radius $border-radius 0px !important;

    & input.#{$form-text} {
      border-radius: $radius-right;
    }
    & .x-form-trigger {
      &-wrap {
        border-radius: $radius-right;
      }
    }
  }

  &:hover {
    & .#{$label}:not(.label-without-hover-effect) {
      @include label-top {
          color: $focus-color !important;
      };
    }

    & input.#{$form-text} {
      color: $text-color !important;
      border-color: $focus-color !important;
    }

    & .x-form-trigger {
      &-wrap {
        color: $text-color !important;
        border-color: $focus-color !important;
      }
    }
  }
}

//Добавил обертку .edi-viewport чтобы перебить классы из ui-core-combofield (ext-74-form-fields.scss)
.edi-viewport {
  .#{$prefix}-doublefields {
    //При наведении на заполненный филд label подсвечивается
    & .had-not-empty:hover {
      .#{$label}.label-without-hover-effect {
        color: $focus-color !important;
      }
    }

    & .ui-core-combofield {
      &.had-invalid {
        .#{$label} {
          color: #E82000 !important;
        }

        .x-form-trigger-wrap {
          border: 1px solid #E82000 !important;
        }
      }
    }
  }
}