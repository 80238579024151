@use '@UIkit/styles/typography/_heading.scss';
@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

//хак с заменой цветов на градиенты нужен, что бы можно было ставить несколько цветов на элемент
//т.к. дизайнеры это закладывают в палитре и используют прозрачные цвета с учетом возможности их комбинирования
$white: linear-gradient($color-white, $color-white);
$light-grey: linear-gradient($color-dark-opacity-5, $color-dark-opacity-5);
$dark-grey: linear-gradient($color-dark-opacity-10, $color-dark-opacity-10);
$light-blue: linear-gradient($color-blue-opacity-5, $color-blue-opacity-5);
$light-yellow: linear-gradient($color-yellow-opacity-10, $color-yellow-opacity-10);
$light-red: linear-gradient($color-error-opacity-10, $color-error-opacity-10);

$this: '.ui-table';

#{$this} {
	position: relative;
	box-shadow: 0 4px 8px rgba(187, 189, 191, 0.25);
	overflow: hidden;

	.x-grid-resize-marker {
		background-color: $color-grey-20;
	}

	//хэдер панели, тот где тайтл и тулзы
	#{$this}-header,
	> .x-panel-header {
		background-color: $color-white;
		border: none;
		padding: 0 8px;

		.x-title-text {
			@extend .heading_01;
		}

		.x-tool {
			background-color: transparent;

			&.x-tool-disabled {
				opacity: 0.5;
			}

			.x-mask {
				background-color: transparent;
			}

			.x-tool-tool-el {
				color: $color-grey-50;
				background-color: transparent;
				font-size: 20px;
				width: 20px;
				height: 20px;
			}

			&.x-tool-over,
			&.x-tool-pressed {
				background-color: transparent;

				.x-tool-tool-el {
					color: $color-dark;
				}
			}
		}
	}

	.x-grid-body {
		position: absolute;
		z-index: 1; //что бы лоадер грида не перекрывал выезжающую панель фильтра
		border: none;

		.x-grid-view,
		.x-tree-view {
			margin-top: -1px !important; //что бы бордер первой строки не добавлялся к бордеру хэдера

			.x-grid-item {
				line-height: 40px;
				height: 40px;
				border-color: $color-grey-20;

				.x-grid-row {
					background: $white;

					&.with-error {
						background: $light-red, $white;
					}

					&.yellow-cell {
						background: $light-yellow, $white;
					}

					&.marked-primary {
						.x-grid-cell:first-child {
							border-left: 4px solid $color-blue;
							padding-left: 8px;
						}
					}

					&.marked-warning {
						.x-grid-cell:first-child {
							border-left: 4px solid $color-yellow;
							padding-left: 8px;
						}
					}

					&.marked-error {
						.x-grid-cell:first-child {
							border-left: 4px solid $color-error;
							padding-left: 8px;
						}
					}

					.x-grid-cell {
						padding: 0 12px;
						vertical-align: middle;
						border: none;
						background-color: transparent;

						.x-grid-cell-inner {
							margin: 0;
							padding: 0;
							@extend .body-short_01;

							color: $color-dark;

							&::before {
								border: none;
							}
						}

						.x-grid-checkcolumn-cell-inner {
							padding: 0;
							width: 24px;
							height: 24px;

							.x-grid-checkcolumn::after {
								content: '\e835';
								font-family: 'Material Icons', sans-serif;
								font-size: 24px;
								color: $color-grey-50;
							}
						}
					}
				}

				&.x-grid-item-selected {
					.x-grid-row {
						background: $light-blue, $white;

						&.with-error {
							background: $light-red, $white;
						}

						&.yellow-cell {
							background: $light-yellow, $white;
						}

						.x-grid-cell-inner {
							&.x-grid-checkcolumn-cell-inner {
								.x-grid-checkcolumn::after {
									content: '\e834';
									color: $color-blue;
								}
							}
						}
					}
				}

				&.x-grid-item-over {
					.x-grid-row {
						background: $light-grey, $white;

						.x-action-col-icon {
							background: $light-grey, $white;

							&:hover {
								background: $dark-grey, $white;
							}
						}

						&.with-error {
							background: $light-grey, $light-red, $white;

							.x-action-col-icon {
								background: $light-grey, $light-red, $white;

								&:hover {
									background: $light-grey, $light-grey, $light-red, $white;
								}
							}
						}

						&.yellow-cell {
							background: $light-grey, $light-yellow, $white;

							.x-action-col-icon {
								background: $light-grey, $light-yellow, $white;

								&:hover {
									background: $light-grey, $light-grey, $light-yellow, $white;
								}
							}
						}
					}
				}

				&.x-grid-item-over.x-grid-item-selected {
					.x-grid-row {
						background: $light-grey, $light-blue, $white;

						.x-action-col-icon {
							background: $light-grey, $light-blue, $white;

							&:hover {
								background: $light-grey, $light-grey, $light-blue, $white;
							}
						}

						&.with-error {
							background: $light-grey, $light-red, $white;

							.x-action-col-icon {
								background: $light-grey, $light-red, $white;

								&:hover {
									background: $light-grey, $light-grey, $light-red, $white;
								}
							}
						}

						&.yellow-cell {
							background: $light-grey, $light-yellow, $white;

							.x-action-col-icon {
								background: $light-grey, $light-yellow, $white;

								&:hover {
									background: $light-grey, $light-grey, $light-yellow, $white;
								}
							}
						}
					}
				}
			}

			//ACTION COLUMN
			.x-grid-item {
				.x-grid-row {
					.x-action-col-cell {
						position: sticky;
						right: 0;
						z-index: 2; // 1 занят обычной ячейкой
						padding: 0;
						margin: 0;
						background-color: transparent;

						.x-grid-cell-inner-action-col {
							text-overflow: clip;

							.x-action-col-icon {
								opacity: 0; //будет отображаться только при наведении на строку
								user-select: none;
								border: none;
								border-radius: 4px;
								outline: none;
								width: 40px;
								height: 40px;
								padding: 10px;
								cursor: pointer;
								color: $color-grey-50;

								&:hover {
									color: $color-dark;
								}

								&.x-item-disabled {
									color: #bbbdbf;
									pointer-events: auto;

									&:hover {
										color: $color-grey-30;
										cursor: not-allowed;
									}
								}
							}
						}
					}
				}

				&.x-grid-item-over {
					.x-grid-row {
						.x-action-col-cell {
							.x-action-col-icon {
								opacity: 1;
								background-color: $color-white;
							}
						}
					}
				}
			}

			.x-grid-empty {
				@extend .body-short_01;
				text-align: left;
				color: $color-grey-40;
				padding: 13px 16px;
				border-bottom: 1px solid $color-grey-20;
			}
		}

		.x-grid-view,
		.x-tree-view {
			.row-editable {
				&-enable {
					& .x-grid-cell {
						&.editable {
							border-bottom: none;

							& .x-grid-cell-inner {
								border: 1px solid $color-grey-30;
								border-radius: 2px;
								padding: 6px;
								margin: 0 10px;

								&-action-col {
									border: none;
									padding: 0;
									margin: 0;
								}
							}
						}
					}
				}
			}
		}

		.x-tree-view {
			.x-grid-item {
				border-bottom: 1px solid $color-grey-20;

				&:last-child {
					border: none;
				}
			}
		}
	}

	#{$this}-with-shadow {
		overflow: visible;

		.x-panel-header {
			padding: 10px 0 16px 0;
		}

		.x-panel-bodyWrap {
			overflow: visible;

			.x-grid-header-ct,
			.x-grid-body {
				box-shadow: 0 4px 8px rgba(187, 189, 191, 0.25);
			}
		}
	}

	&.edi-grid-mark-empty-red {
		.x-grid-body {
			.x-grid-view,
			.x-tree-view {
				.x-grid-empty {
					color: $color-error;
				}
			}
		}
	}

	&.is-edit {
		.editable {
			.x-grid-cell-inner {
				border: 1px solid $color-grey-20;
				box-sizing: border-box;
				border-radius: 3px;
				margin: 0 20px;
				padding: 4px 0 4px 8px;
			}
		}

		.with-error .editable .x-grid-cell-inner,
		.without-error .editable .x-grid-cell-inner {
			border: 1px solid $color-grey-20;
			box-sizing: border-box;
			border-radius: 3px;
			margin: 0 20px;
			padding: 4px 0 4px 8px !important;
		}

		.with-error .editable .x-grid-cell-inner:hover,
		.without-error .editable .x-grid-cell-inner:hover {
			border: 1px solid $color-grey-50;
		}

		.valid-record .editable .x-grid-cell-inner {
			border: none;
			box-sizing: border-box;
			border-radius: 3px;
			margin: 0 20px;
			padding: 4px 0 4px 8px !important;
		}
	}

	.x-editor.x-grid-editor {
		overflow: visible;

		.x-form-error-wrap {
			display: none;
		}

		.x-field {
			.x-form-text {
				position: relative;
				font: normal 13px/15px 'Roboto', arial, verdana, sans-serif;
				padding: 4px 9px 3px 9px;
				border: 2px solid #3d7dca;
				box-sizing: border-box;
				border-radius: 3px;
				height: 33px;
				margin-top: 2px;
				min-width: 150px !important;
			}

			.x-form-trigger-spinner,
			.x-form-arrow-trigger {
				display: none;
			}
		}
	}
}

.tab-grid.edi-grid-with-row-lines,
.modal-grid.edi-grid-with-row-lines {
	.x-panel-header-default {
		border-color: transparent;
	}
}

.tab-grid {
	box-shadow: 0 4px 8px rgba(187, 189, 191, 0.25);
}
