@mixin wrap {
	box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

	width: 100% !important;
	border: 1px solid $border-color-inactive;
	border-radius: $border-radius;

	// -webkit-box-shadow:inset 0px 0px 0px 1px $border-color-inactive !important;
  // -moz-box-shadow:inset 0px 0px 0px 1px $border-color-inactive !important;
  // box-shadow:inset 0px 0px 0px 1px $border-color-inactive !important;
	// outline: 1px solid $border-color-inactive !important;
	// box-shadow: none;

	transition: all $speed ease-out 0s;

	&:hover {
		// -webkit-box-shadow:inset 0px 0px 0px 1px $border-color-hover !important;
		// -moz-box-shadow:inset 0px 0px 0px 1px $border-color-hover !important;
		// box-shadow:inset 0px 0px 0px 1px $border-color-hover !important;
		border: 1px solid $border-color-hover;
	}
}