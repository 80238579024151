.requirements-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.32px;
    color: #1D252C;
}

.requirements-pas {
    display: block;
    font-size: 12px;
    letter-spacing: 0.32px;
    color: #8E9295;
    padding-top: 6px;
    padding-bottom: 0px;

    .x-btn-button {
        height: auto!important;
        .x-btn-inner {
            white-space: pre-wrap!important;
            text-align: left!important;
        }
    }

    &-success {
        & .requirements-pas-glyph {
            color: #00AC2D;
        }

        color: #1D252C;
    }
}

.edi-login-form-password-recovery .edi-login-form__field-label {
    color: green;
    position: absolute;
    top: -10px;
    left: 9px;
    background-color: white;
    padding: 0 3px;
    transition: all 0.2s ease-out 0s;
    z-index: 10;
}

.result-password-recovery {
    background: url('images/enterLockOk.png') no-repeat !important;
    width: 96px !important;
    height: 96px !important;
    margin-left: 100px !important;
    margin-top: 16px !important;
}

.result-error-password-recovery {
    background: url('images/enterLockError.png') no-repeat !important;
    width: 117px !important;
    height: 96px !important;
    margin-left: 85px !important;
    margin-top: 16px !important;
}

.new-password-not-saved {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #1D252C;
    margin-top: 32px;
    margin-bottom: 32px;
    display: block;
    text-align: center;
}

.new-password-saved {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
    color: #1D252C;
    margin-top: 32px;
    margin-bottom: 32px;
    display: block;
    text-align: center;
}