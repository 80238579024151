@use '@UIkit/styles/typography/_text.scss';
@import "@UIkit/styles/global/Colors/Variables.scss";

$this: '.ui-simple-selector';

#{$this} {
	position: relative; //заглушка что бы класс создался

	#{$this}-details-container {
		border-radius: 3px;
		min-height: 32px;

		&#{$this}-read-only {
			#{$this}-details-container-body {
				padding: 0;
				background-color: transparent;
			}
		}

		#{$this}-details-container-body {
			top: 0 !important;
			left: 0 !important;
			width: 100% !important;
			height: 100% !important;
			padding: 8px;
			background-color: $color-dark-opacity-5;

			.edi-company-row {
				white-space: normal;

				.code,
				.row-data {
					@extend .caption_01;
					color: $color-grey-50;
					margin: 2px 0;
					padding: 2px 0;
				}

				.row-data {
					color: $color-dark;
				}
			}

			#{$this}-empty-label {
				@extend .body-short_01;
				color: $color-grey-50;
			}

			#{$this}-dots-button {
				position: absolute;
				z-index: 1;
				width: 32px !important;
				height: 32px !important;
				right: 0 !important;
				left: unset !important;
				top: 0 !important;
				bottom: unset !important;
				border-radius: 4px;

				&:hover {
					background: $color-dark-opacity-10;
				}

				&.ui-button-menu {
					.ui-button-button::before {
						content: '\e5d3';
						font-family: Material Icons, sans-serif;
						color: $color-grey-50;
						transform: translate(30%, 0);
						font-size: 26px;
						margin-top: -7px;
					}

					&:hover {
						.ui-button-button::before {
							color: $color-dark;
						}
					}
				}
			}
		}
	}
}

#{$this}-action-button {
	margin: 0 !important; //нужно что бы кнопка растягивалась на всю ширину меню

	.ui-button-button.ui-button-text {
		width: 100%;
		justify-content: start;

		.ui-button-inner {
			@extend .body-short_01; //так написано в юай-ките для пунктов меню (dropdown)
		}
	}
}

#{$this}-invalid-tooltip-body {
	&.x-tip-body-default.x-tip-body-default {
		color: $color-dark;
		padding: 2px 2px 2px 20px;
		width: unset !important;

		&:before {
			position: absolute;
			left: 0;
			color: $color-error;
			font-family: Material Icons, sans-serif;
			font-size: 17px;
			content: '\e000';
		}
	}
}

#{$this}-required-label,
#{$this}-error-label {
	margin-top: 4px;
	font-size: 12px !important;
	line-height: 16px !important;
	letter-spacing: 0.32px !important;
	color: $color-grey-50;
}

#{$this}-details-with-errors {
	position: relative; //заглушка что бы класс создался

	#{$this}-details-container {
		background-color: $color-error-opacity-10;
	}

	#{$this}-required-label,
	#{$this}-error-label {
		color: $color-error;
	}
}

#{$this}-modal {
	position: absolute; //заглушка что бы класс создался
}
