@import "@UIkit/styles/global/Colors/Variables";

.edi-viewport .ui-tree-panel {
	&.x-tree-arrows {
		.ui-tree-panel-header-treecolumn {
			.x-column-header-text {
				padding-left: 36px;
			}
		}

		.x-grid-cell-inner-treecolumn {
			overflow: visible;

			.x-tree-icon,
			.x-tree-icon-leaf {
				display: none;
			}

			.x-tree-expander {
				background: url(./images/arrow_side.png) no-repeat center;
				width: 20px;
				height: 20px;

				&::before {
					content: '';
				}
			}

			.x-tree-expander:hover {
				background-color: $color-blue-10;
				border-radius: 4px;
			}

			.x-tree-icon,
			.x-tree-elbow-img,
			.x-tree-checkbox {
				margin-top: 0;
				margin-bottom: -9px;
			}

			.x-tree-node-text {
				padding-left: 11px;
			}
		}

		.x-grid-tree-node-expanded {
			.x-grid-cell-inner-treecolumn {
				.x-tree-expander {
					background: url(./images/arrow_down.png) no-repeat center;
				}
			}
		}
	}

	.edi-linked-current-element {
		background-color: $color-blue-10 !important;

		.x-action-col-icon {
			background-color: $color-blue-10 !important;
		}
	}
}
