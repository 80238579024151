@mixin form-text {
	display: block;
	padding: 10px 12px 9px 12px !important;
	margin: 0px !important;

	box-shadow: none !important;
	outline: 0 !important;

	height: auto !important;
	line-height: 20px !important;

	font-size: $input-size !important;
	color: $text-color !important;
	letter-spacing: .25px !important;
	border-radius: $border-radius;

	@content;
}