@mixin label-inside {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	max-width: calc(100% - 22px);

	background: transparent !important;
	position: absolute;
	top: 8px;
	left: 13px;
	font-size: $input-size;
	min-height: 1.5rem;
	letter-spacing: .4px;
	color: $inactive-color;
	transition: all $speed ease-out 0s;
	z-index: 1 !important;
	cursor: text !important;

	.x-form-item-label-inner {
		padding: 0 !important;
	}

	@content;
}

@mixin label-top {
	max-width: calc(100% - 18px);
	top: -4px !important;
	left: 9px !important;
	background: $bg-color !important;
	padding: 0 4px !important;
	line-height: 1.5rem !important;
	font-size: $label-size !important;
	min-height: 1.5rem;
	z-index: 1 !important;
	cursor: default !important;

	.x-form-item-label-inner {
		padding: 0 !important;
	}

	@content;
}