@import '@UIkit/components/fields/Base/Base.scss';
@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-textareafield {
	@extend %field;

	#{$label} {
		&-inner {
			background: linear-gradient(transparent 9px, $color-white 9px) !important;
		}
	}

	.x-form {
		&-text {
			@extend .body-short_01;
			color: $color-dark;
			min-height: auto !important;
		}
	}

	&.x-has-min-height {
		height: auto !important;
	}
}
