@import '@UIkit/styles/global/Colors/Variables.scss';

@mixin wrap {
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;

	overflow: hidden;
	display: flex;
	justify-content: start;
	align-items: center;

	width: 100%;
	border: 1px solid $color-grey-30;
	border-radius: $border-radius;
	background-color: $color-white;

	transition: all $animation_speed ease-out 0s;

	@content;
}
