@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

.ui-menu {
	border: none;
	box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);

	.ui-menu-body {
		background: $color-white;
		border-radius: 4px;
		overflow: hidden;
		padding: 16px 0;

		.x-menu-item {
			padding: 11px 16px;
			border: none;

			&-text {
				@extend .body-short_01;
				color: $color-dark;
			}

			&-focus,
			&-active {
				color: $color-dark;
				background-color: $color-grey-5;
			}

			&.x-menu-item-disabled {
				opacity: 1;
				background-color: transparent;
				cursor: default;

				.x-menu-item-text,
				.x-menu-item-icon {
					color: $color-grey-30;
				}
			}
		}
	}
}
