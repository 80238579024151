$color-dark: var(--color-dark);
$color-grey-50: var(--color-grey-50);
$color-grey-40: var(--color-grey-40);
$color-grey-30: var(--color-grey-30);
$color-grey-20: var(--color-grey-20);
$color-grey-10: var(--color-grey-10);
$color-grey-5: var(--color-grey-5);
$color-grey-3: var(--color-grey-3);
$color-white: var(--color-white);
$color-white-opacity-50: var(--color-white-opacity-50);
$color-white-opacity-15: var(--color-white-opacity-15);
$color-white-opacity-10: var(--color-white-opacity-10);
$color-dark-opacity-20: var(--color-dark-opacity-20);
$color-dark-opacity-10: var(--color-dark-opacity-10);
$color-dark-opacity-5: var(--color-dark-opacity-5);
$color-success: var(--color-success);
$color-success-opacity-10: var(--color-success-opacity-10);
$color-error: var(--color-error);
$color-error-opacity-10: var(--color-error-opacity-10);
$color-error-opacity-5: var(--color-error-opacity-5);
$color-yellow: var(--color-yellow);
$color-yellow-opacity-10: var(--color-yellow-opacity-10);
$color-background: var(--color-background);
// Project palette
// Coreplat, Factorplat, Ediweb, LinkServer
$color-blue: var(--color-blue);
$color-blue-90: var(--color-blue-90);
$color-blue-80: var(--color-blue-80);
$color-blue-50: var(--color-blue-50);
$color-blue-15: var(--color-blue-15);
$color-blue-10: var(--color-blue-10);
$color-blue-5: var(--color-blue-5);
$color-blue-opacity-15: var(--color-blue-opacity-15);
$color-blue-opacity-10: var(--color-blue-opacity-10);
$color-blue-opacity-5: var(--color-blue-opacity-5);
// Dropcat, Prodcat
$color-dc-dark: var(--color-dc-dark);
$color-dc-modile: var(--color-dc-modile);
$color-teal: var(--color-teal);
$color-teal-90: var(--color-teal-90);
$color-teal-80: var(--color-teal-80);
$color-teal-15: var(--color-teal-16);
