@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

$baseCls: '.ui-boundlist';

.ui-boundlist {
	border-radius: 4px;
	box-shadow: 0px 4px 24px rgba(29, 37, 44, 0.1);

	&-list {
		&-ct {
			background-color: $color-white;
			overflow: hidden;
			padding: 0;
		}
		&-plain {
			list-style: none;
			padding: 0;
			margin: 0;

			&:has(li) {
				padding: 16px 0;
			}
		}
	}

	&-item {
		&-wrap {
			padding: 11px 16px;
			border: none;

			cursor: pointer;
		}

		&-inner {
			width: 100%;
			display: block;
			color: $color-dark;
			@extend .body-short_01;
		}

		&-over {
			& > #{$baseCls}-item-wrap {
				background-color: $color-grey-5;
			}

			&#{$baseCls}-selected {
				& > #{$baseCls}-item-wrap {
					background: linear-gradient(0deg, $color-dark-opacity-5, $color-dark-opacity-5), $color-blue-15;
				}
			}
		}
	}
	&-selected {
		& > #{$baseCls}-item-wrap {
			background-color: $color-blue-opacity-10;
		}
	}
}
