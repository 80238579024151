@use '@UIkit/styles/typography/_text.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

$baseCls: '.ui-button';

.ui-button {
	position: relative;
	display: block;
	text-decoration: none;

	// --- Базовые стили кнопки ---
	// Стиль кнопки
	&-button {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		column-gap: 8px;
		cursor: pointer;

		transition: all 200ms ease-out 0s;
	}

	// Стиль текста
	&-inner {
		@extend .body-short_02;
		color: inherit;
		white-space: nowrap;
	}

	// Стиль иконки
	&-glyph {
		width: 24px;
		height: 24px;
		line-height: 24px;
		font-size: 24px;
		color: inherit;

		transition: all 200ms ease-out 0s;
	}

	&-icononly {
		#{$baseCls}-inner {
			display: none;
		}
	}

	&-textonly {
		#{$baseCls}-icon-el {
			display: none;
		}
	}
}

// --- Типы кнопок ---
// --- По умолчанию стандартная ссылка ---
.ui-button-default {
	@extend .ui-button;

	#{$baseCls}-button {
		height: 40px;
		border-radius: 4px;
		color: $color-blue;
	}

	#{$baseCls}-text {
		padding-inline: 16px;
	}
	#{$baseCls}-icon {
		&-left {
			padding-left: 12px;
		}
		&-right {
			padding-right: 12px;
		}
	}

	#{$baseCls}-icononly {
		padding: 8px;
	}

	// состояния кнопки
	&#{$baseCls}-disabled {
		opacity: 0.3;
	}
	&#{$baseCls}-over {
		#{$baseCls}-button {
			color: $color-blue-90;
		}
	}
	&#{$baseCls}-pressed {
		#{$baseCls}-button {
			color: $color-blue-80;
		}
	}
}

// Тип кнопки PRIMARY
.ui-button-primary {
	#{$baseCls}-button {
		height: 40px;
		background-color: $color-blue;
		color: $color-white;
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			background-color: $color-blue-90;
			color: $color-white;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			background-color: $color-blue-80;
			color: $color-white;
		}
	}

	&#{$baseCls}-menu {
		#{$baseCls}-button {
			padding-right: 49px;

			&::before {
				content: '';
				display: block;
				width: 1px;
				height: 32px;
				position: absolute;
				top: 50%;
				right: 40px;
				transform: translate(0, -50%);
				background-color: $color-blue-80;
			}
		}

		&-active {
			#{$baseCls}-button {
				&::before {
					background-color: $color-blue-10;
					opacity: 0.3;
				}
			}
		}
	}
}

// Тип кнопки SECONDARY
.ui-button-secondary {
	#{$baseCls}-button {
		color: $color-blue;
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			color: $color-blue;
			background-color: $color-blue-opacity-10;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			color: $color-blue;
			background-color: $color-blue-opacity-15;
		}
	}
}

.ui-button-outline {
	#{$baseCls}-button {
		outline: 1px solid $color-blue;
		outline-offset: -1px;
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			outline: none;
			background-color: $color-blue-90;
			color: $color-white;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			outline: none;
			background-color: $color-blue-80;
			color: $color-white;
		}
	}
}

.ui-button-light {
	#{$baseCls}-button {
		color: $color-dark;

		&::after,
		#{$baseCls}-glyph {
			color: $color-grey-50;
		}
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			background-color: $color-grey-5;
			color: $color-dark;

			&::after,
			#{$baseCls}-glyph {
				color: $color-dark;
			}
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			background-color: $color-grey-10;
			color: $color-dark;

			&::after,
			#{$baseCls}-glyph {
				color: $color-dark;
			}
		}
	}
}

.ui-button-white {
	#{$baseCls}-button {
		color: $color-white;

		#{$baseCls}-glyph {
			color: $color-white;
		}
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			background-color: $color-white-opacity-10;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			background-color: $color-white-opacity-15;
		}
	}
}

.ui-button-dark {
	#{$baseCls}-button {
		outline: 1px solid $color-white;
		outline-offset: -1px;
		color: $color-white;
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			outline: none;
			background-color: $color-white;
			color: $color-dark;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			outline: none;
			background-color: $color-grey-5;
			color: $color-dark;
		}
	}
}

.ui-button-link {
	#{$baseCls}-button {
		padding: 0;
		height: auto;

		#{$baseCls}-inner {
			@extend .caption_01;
		}
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			color: $color-blue-80;
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			color: $color-blue;
		}
	}
}

.ui-button-icon {
	#{$baseCls}-button {
		width: 24px;
		height: 24px;

		#{$baseCls}-glyph {
			color: $color-grey-50;
		}
	}

	&#{$baseCls}-over {
		#{$baseCls}-button {
			#{$baseCls}-glyph {
				color: $color-dark;
			}
		}
	}
	&#{$baseCls}-pressed,
	&#{$baseCls}-menu-active {
		#{$baseCls}-button {
			#{$baseCls}-glyph {
				color: $color-dark;
			}
		}
	}
}

// --- Типы кнопок ---

// --- Модификаторы ---
.ui-button-menu {
	#{$baseCls}-button {
		padding-right: 40px;

		&::after {
			position: absolute;
			top: 50%;
			right: 8px;
			width: 24px;
			height: 24px;
			transform: translate(0, -50%);
			line-height: 24px;
			text-align: center;
			content: '\e5c5';
			font-family: 'Material Icons', sans-serif;
			font-size: 24px;
			font-style: normal;
			font-weight: normal;
			text-decoration: inherit;
		}
	}

	&-active {
		#{$baseCls}-button {
			&::after {
				content: '\e5c7';
			}
		}
	}
}

.ui-button-stretch {
	width: 100%;
}

.ui-button-rounded {
	#{$baseCls}-button {
		border-radius: 20px;
	}
}

.ui-button-small {
	#{$baseCls}-button {
		height: 32px;
	}

	#{$baseCls}-icononly {
		padding: 4px;
	}
}

//кнопка сама является элементом меню
.ui-button-menu-item {
	margin: 0;

	.ui-button-inner {
		@extend .body-short_01;
	}

	.ui-button-button {
		justify-content: space-between;
	}
}

.ui-button-withoutArrow {
	#{$baseCls}-button {
		padding: 10px 16px;

		&::after {
			content: none;
		}
	}
}

//это замена для edi-actions-button
.ui-button-action {
	float: right;
}
