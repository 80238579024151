$prefix: "ui-core";

$bg-color: #FFF;
$bg-color-disabled: #F3F4F4;
$text-color: #1D252C;
$focus-color: #3D7DCA;
$inactive-color: #8E9295;
$invalid-color: #E82000;

$label-size: 12px;
$input-size: 14px;

$border-radius: 3px;
$border-color-inactive: #D2D3D5;
$border-color-hover: #8E9295;

$speed: .2s;

@import './includes/label';
@import './includes/text';
@import './includes/wrap';

@import './errors';

@import './types/base-text';
@import './types/base-combo';

@import './types/text';
@import './types/number';

@import './types/combo';
@import './types/multi';
@import './types/date';

@import './types/double';