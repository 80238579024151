@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

@mixin input {
	.x-form {
		&-cb {
			@content;
		}
	}
}

@mixin checked {
	&.x-form-cb {
		&-checked {
			@include input {
				@content;
			}
		}
	}
}

%box {
	margin: 0;
	line-height: initial;

	.x-form {
		&-cb {
			position: relative;
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 20px;
			height: 20px;
			overflow: visible;

			&-input {
				cursor: pointer !important;
			}

			&-label {
				@extend .body-short_01;

				line-height: 20px;
				color: $color-dark;
				cursor: pointer;

				margin: 0;
				padding: 0;

				&-before {
					padding-right: 12px;
				}

				&-after {
					padding-left: 12px;
				}
			}

			&-wrap {
				display: flex;
				justify-content: start;
				align-items: center;
				height: auto !important;

				&-inner {
					display: inherit;
				}
			}
		}
	}

	&.ui-field {
		&-readonly,
		&-disabled {
			& .x-form-cb {
				opacity: 0.3 !important;
			}
		}
	}
}
