@use '@UIkit/styles/typography/heading';
@import "@UIkit/styles/global/Colors/Variables";

$this: '.ui-fieldset';
#{$this} {
	position: relative;
	border: none;
	border-radius: 8px;
	padding: 24px;
	background-color: $color-blue-opacity-5;

	legend {
		position: absolute;
	}

	&-header {
		display: flex;
		position: relative;
		color: $color-dark;

		&-text {
			@extend .heading_02;
			color: inherit;
		}
	}

	&-body {
		margin-top: 50px;
	}

	&.fieldset-without-header {
		#{$this}-body {
			margin-top: 0;
		}
	}

	&-toggle-btn {
		margin: 0 0 0 8px;
	}

	&-collapsed {
		padding-bottom: 50px;

		#{$this}-toggle-btn {
			transform: rotate(180deg);
		}

		#{$this}-body {
			display: none;
		}
	}

	//это исправляет цвет панелек внутри филдсета (например twoColumnsLayout)
	//что бы через них просвечивал фон филдсета
	.x-panel-body.x-panel-body-default {
		background-color: transparent;
	}
}
