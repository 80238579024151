@use '@UIkit/styles/typography/_text.scss';
@use '@UIkit/styles/typography/_heading.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

$width: 18px;
$height: 18px;

$this: '.ui-badge';

#{$this} {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;

	&-default {
		background: $color-error;
		border-radius: 4px;
		color: $color-white;
	}

	&-body {
		min-width: $width;
		@extend .caption_02;
		color: inherit;
		white-space: nowrap;
		text-align: center;
		padding: 0 4px;
	}

	&-fixed {
		position: absolute;
		margin: 0;
		z-index: 99;
	}
	&-align {
		&-tl {
			top: 0;
			left: 0;
			transform: translate(-50%, -50%);
		}
		&-t {
			top: 0;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&-tr {
			top: 0;
			right: 0;
			transform: translate(50%, -50%);
		}
		&-trc {
			top: 50%;
			left: 50%;
			transform: translate(0, -100%);
		}
		&-bl {
			bottom: 0;
			left: 0;
			transform: translate(-50%, 50%);
		}
		&-b {
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 50%);
		}
		&-br {
			bottom: 0;
			right: 0;
			transform: translate(50%, 50%);
		}
		&-l {
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%);
		}
		&-c {
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		&-r {
			top: 50%;
			right: 0;
			transform: translate(50%, -50%);
		}
	}

	&-middle {
		$width: 24px;
		$height: 24px;

		#{$this}-body {
			min-width: $width;
			@extend .body-short_02;
		}

		&#{$this}-rounded {
			border-radius: calc($width / 2);
		}
	}
	&-big {
		$width: 36px;
		$height: 36px;

		#{$this}-body {
			min-width: $width;
			@extend .heading_02;
		}

		&#{$this}-rounded {
			border-radius: calc($width / 2);
		}
	}
	&-rounded {
		border-radius: calc($width / 2);
	}

	// Presets
	&-button-counter {
		border-radius: 16px;
		height: 16px;

		#{$this}-body {
			min-width: 16px;
			line-height: 16px;
			background-color: $color-error;
			color: $color-white
		}
	}

	&-tab-counter {
		border-radius: 4px;
		height: 18px;

		#{$this}-body {
			line-height: 18px;
			background-color: $color-blue-10;
			color: $color-blue;
		}
	}

	&-primary {
		background: $color-blue;
		color: $color-white;
	}

	&-success {
		background: $color-success;
		color: $color-white;
	}

	&-danger {
		background: $color-error;
		color: $color-white;
	}

	&-warning {
		background: $color-yellow;
		color: $color-white;
	}

	&-dark {
		background: $color-dark;
		color: $color-white;
	}

	&-info {
		background: $color-blue-10;
		color: $color-blue;
	}
}
