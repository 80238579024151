@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-button-container {
  padding: 16px 24px;
  box-shadow: 0 -8px 8px $color-dark-opacity-5;

  .ui-button {
	margin-right: 24px;
	&:last-child {
	  margin-right: 0;
	}
  }
}