@import '@UIkit/components/fields/Base/Base.scss';

.ui-datefield {
	@extend %field;

	&-trigger {
		&::before {
			content: '\e916';
			font-family: 'Material Icons', sans-serif;
		}
	}
}
