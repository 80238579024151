@import "@UIkit/styles/global/Colors/Variables";

$this: '.ui-tool';

#{$this} {
	background-color: transparent;
	border-radius: 3px;
	padding: 2px;
	margin-left: 5px;

	&.x-tool-disabled {
		opacity: 0.5;
	}

	.x-mask {
		background-color: transparent;
	}

	#{$this}-tool-el {
		color: $color-dark;
		background-color: transparent;
		font-size: 24px;
		line-height: 24px;
		width: 24px;
		height: 24px;
	}

	&.x-tool-over,
	&.x-tool-pressed {
		background-color: $color-grey-5;
	}
}

#{$this}-primary {
	background-color: $color-blue;

	#{$this}-tool-el {
		color: $color-white;
	}

	&.x-tool-over,
	&.x-tool-pressed {
		background-color: $color-blue-90;
	}
}

#{$this}-secondary {
	background-color: transparent;

	#{$this}-tool-el {
		color: $color-blue;
	}

	&.x-tool-over,
	&.x-tool-pressed {
		background-color: $color-blue-opacity-10;
	}
}

#{$this}-light {
	background-color: transparent;

	#{$this}-tool-el {
		color: $color-dark
	}

	&.x-tool-over,
	&.x-tool-pressed {
		background-color: $color-grey-5;
	}
}
