@import "@UIkit/styles/global/Colors/Variables.scss";

.ui-toolbar {
  background-color: $color-white;

  //Нужно для отступов ui-toolbar сверху и снизу если указаны items
  .x-box-target > * {
    margin-top: 4px;
    margin-bottom: 4px;
  }
}