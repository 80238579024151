@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

@mixin label-inside {
	max-width: 100%;
	display: block;
	min-height: 18px;
	line-height: 18px;

	background: transparent;
	position: absolute;
	top: 15px;
	padding: 0 10px 0 0;
	transition: all $animation_speed ease-out 0s;
	cursor: text;
	z-index: 1;

	color: $color-grey-50;

	@content;

	&-inner {
		display: block;
		margin-left: 10px;
		padding: 0 !important;
	}
	&-text {
		display: block;
		transition: all $animation_speed ease-out 0s;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		color: inherit;
		@extend .body-short_01;
	}
}

@mixin label-top {
	padding: 0 6px 0 0 !important;
	top: -1px;
	cursor: default;
	z-index: 99;

	&-inner {
		margin-left: 6px;
		background: linear-gradient(transparent 9px, $color-white 9px 10px, transparent 8px);
		padding: 0 3px !important;
	}
	&-text {
		font-size: 12px;
	}
}
