@use '@UIkit/styles/typography/text';
@import '@UIkit/components/buttons/Buttons.scss';
@import '@UIkit/styles/global/Colors/Variables';

.ui-datepicker {
	$this: &;

	position: relative;
	box-shadow: 0 4px 24px $color-dark-opacity-20;
	background-color: $color-white;
	border-radius: 4px;

	&-wrap {
		padding: 24px;
	}

	&-header {
		display: flex;
		justify-content: space-between;
		margin: 0 0 12px 0;
	}
	&-arrow {
		width: 32px;
		height: 32px;
		cursor: pointer;

		font: 16px/24px 'Font Awesome 5 Free';
		color: $color-grey-50;

		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-prev {
		&::before {
			content: '\f053';
		}
	}
	&-next {
		&::before {
			content: '\f054';
		}
	}
	&-month {
		.x-btn {
			background-color: transparent !important;

			&-over {
			}
			&-wrap {
				padding: 0 !important;

				&::after {
					content: '' !important;
				}
			}
			&-button {
			}
			&-inner {
				@extend .body-short_01;
				color: $color-dark;
			}
		}
	}

	&-inner {
		border-collapse: collapse;
		border: 0;

		thead {
			display: block;
			margin: 0 0 12px 0;

			tr {
				display: flex;
				justify-content: space-around;
				align-items: center;
			}
		}
		tbody {
			display: block;

			tr {
				position: relative;
				display: flex;
				gap: 4px;
				margin-bottom: 4px;

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	&-column {
		&-header {
			@extend .caption_01;
			color: $color-grey-50;
		}
	}
	&-cell {
		border-radius: 50%;
		width: 32px;
		height: 32px;

		cursor: pointer;
		transition: all 200ms ease-out 0s;

		display: inline-table;

		&:hover {
			&:not(#{$this}-disabled, #{$this}-today, #{$this}-selected) {
				background-color: $color-dark-opacity-5;
				color: inherit;
			}
		}
	}
	&-date {
		@extend .body-short_03;
		line-height: 32px;
		text-align: center;
	}
	&-today {
		background-color: transparent;
		color: $color-blue;
	}
	&-selected {
		background-color: $color-blue;
		color: $color-white;
	}
	&-prevday {
		&:not(#{$this}-disabled) {
			color: $color-grey-20;
		}
	}
	&-disabled {
		cursor: default;
		opacity: 0.3;
	}

	&-footer {
		padding: 16px 8px;
	}
}
