@use '@UIkit/styles/typography/_text.scss';
@import "@UIkit/styles/global/Colors/Variables.scss";

$settingsButton: '.ui-grid-settings-button';
$settingsPanel: '.ui-grid-settings-panel';
$toolbarForButton: '.ui-grid-settings-toolbar';
$additionalGridCls: '.ui-grid-with-settings-toolbar';

#{$settingsButton} {
	.ui-button-button:hover {
		background-color: transparent;
	}

	.ui-button-button:active {
		background-color: $color-grey-10;
	}
}

#{$toolbarForButton} {
	padding: 0;
	overflow: visible;
	position: absolute;
	z-index: 5;

	.x-box-inner {
		position: absolute;
		overflow: visible;
	}

	.x-box-target {
		overflow: visible;
		margin-left: -40px;
		top: 0;
	}
}

#{$settingsPanel} {
	background-color: $color-white;
	border-radius: 3px;
	box-shadow: 0 4px 16px rgba(187, 189, 191, 0.5);

	.x-panel-body {
		padding: 16px 16px 0 16px;
		border: none;
	}

	#{$settingsPanel}-toolbar {
		padding: 8px !important;
		border-top: 1px solid $color-grey-20 !important;

		.x-btn-inner {
			@extend .body-short_01;
			padding: 0;
			color: $color-dark;
		}
	}

	#{$settingsPanel}-checkboxgroup {
		.ui-checkboxfield {
			.x-form-cb {
				width: 20px !important;
			}

			.x-form-cb-label {
				white-space: nowrap;
			}
		}
	}
}
