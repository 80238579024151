@import './scss/fields/base';

.x-panel.document-filter {
  padding: 16px 0 0 0 !important;
  background-color: #fff !important;
}

.x-panel-body.document-filter-body {
  &::-webkit-scrollbar {
    max-width: 10px;
    max-height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border: solid 3px transparent;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px #d2d3d5;
    border-radius: 5px;
    border: solid 3px transparent;
  }
}