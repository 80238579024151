
.x-resizable-handle-south-over,
.x-resizable-handle-north-over,
.x-resizable-pinned > .x-resizable-handle-south,
.x-resizable-pinned > .x-resizable-handle-north {
    font: 16px/10px 'Font Awesome 5 Free';
}

.x-resizable-handle-south-over:before,
.x-resizable-handle-north-over:before,
.x-resizable-pinned > .x-resizable-handle-south:before,
.x-resizable-pinned > .x-resizable-handle-north:before {
    content: '\f141';
}

.x-resizable-handle-southeast-over,
.x-resizable-pinned > .x-resizable-handle-southeast {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-southeast-over:before,
.x-resizable-pinned > .x-resizable-handle-southeast:before {
    content: "\e60c";
}

.x-resizable-handle-northwest-over,
.x-resizable-pinned > .x-resizable-handle-northwest {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-northwest-over:before,
.x-resizable-pinned > .x-resizable-handle-northwest:before {
    content: "\e609";
}

.x-resizable-handle-northeast-over,
.x-resizable-pinned > .x-resizable-handle-northeast {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-northeast-over:before,
.x-resizable-pinned > .x-resizable-handle-northeast:before {
    content: "\e60a";
}

.x-resizable-handle-southwest-over,
.x-resizable-pinned > .x-resizable-handle-southwest {
    font: 16px/1 ExtJS;
}

.x-resizable-handle-southwest-over:before,
.x-resizable-pinned > .x-resizable-handle-southwest:before {
    content: "\e60b";
}

.x-slider:before {
    border: 1px solid #d0d0d0;
    content: '';
    position: absolute;
    border-radius: 2px;
    background-color: #eee;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-slider-thumb {
    background-color: #f6f6f6;
    border: 1px solid #d0d0d0;
    border-radius: 2px;
}

.x-slider-thumb:before {
    color: #d0d0d0;
}

.x-slider-thumb-over {
    background-color: #fcfcfc;
    border-color: #c8cdd2;
}

.x-slider-thumb-over:before {
    color: #d0d0d0;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb {
    background-color: #f6f6f6;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb:before {
    color: #a6c7e5;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb-over {
    background-color: #fcfcfc;
}

.x-slider-thumb-drag {
    background-color: #f1f1f1;
    border-color: #c0c8cf;
}

.x-slider-thumb-drag:before {
    color: #d0d0d0;
}

.x-keyboard-mode .x-slider-focus .x-slider-thumb-drag {
    background-color: #f1f1f1;
}

.x-slider-horz {
    padding-left: 10px;
    background: no-repeat 0 -20px;
    margin: 6px 0 6px;
}

.x-slider-horz .x-slider-end {
    padding-right: 10px;
    background: no-repeat right -40px;
}

.x-slider-horz .x-slider-inner {
    height: 20px;
}

.x-slider-horz:before {
    height: 10px;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -5px;
}

.x-slider-horz .x-slider-thumb {
    width: 20px;
    height: 20px;
    margin-left: -10px;
    font: 12px/1 ExtJS;
}

.x-slider-horz .x-slider-thumb:before {
    content: "\e612";
}

.x-rtl.x-slider-horz {
    padding-left: 0;
    padding-right: 10px;
}

.x-rtl.x-slider-horz .x-slider-end {
    padding-right: 0;
    padding-left: 10px;
}

.x-rtl.x-slider-horz .x-slider-thumb {
    margin-right: -10px;
}

.x-slider-ct-vert {
    /* Vertical styles */
    height: 100%;
}

.x-slider-vert {
    padding-top: 10px;
    height: 100%;
}

.x-slider-vert > .x-slider-end {
    height: 100%;
}

.x-slider-vert > .x-slider-end > .x-slider-inner {
    height: 100%;
}

.x-slider-vert:before {
    width: 10px;
    height: 100%;
    left: 10px;
    top: 0;
    margin-left: -5px;
}

.x-slider-vert .x-slider-end {
    padding-bottom: 10px;
    background: no-repeat -20px bottom;
    width: 20px;
}

.x-slider-vert .x-slider-inner {
    width: 20px;
}

.x-slider-vert .x-slider-thumb {
    width: 20px;
    height: 20px;
    margin-bottom: -10px;
    font: 12px/1 ExtJS;
}

.x-slider-vert .x-slider-thumb:before {
    content: "\e605";
}

.x-slider-default-cell > .x-grid-cell-inner,
.x-sliderwidget-default-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-tab-default-top {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 10px 8px 10px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-default-top-mc {
    background-color: transparent;
}

.x-nbr .x-tab-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-top-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-10-8-10;
}

.x-tab-default-top-tl {
    background-position: 0 0;
}

.x-tab-default-top-tr {
    background-position: right 0;
}

.x-tab-default-top-bl {
    background-position: 0 0;
}

.x-tab-default-top-br {
    background-position: right 0;
}

.x-tab-default-top-ml {
    background-position: 0 top;
}

.x-tab-default-top-mr {
    background-position: right top;
}

.x-tab-default-top-tc {
    background-position: 0 0;
}

.x-tab-default-top-bc {
    background-position: 0 0;
}

.x-tab-default-top-tr,
.x-tab-default-top-br,
.x-tab-default-top-mr {
    padding-right: 0;
}

.x-tab-default-top-tl,
.x-tab-default-top-bl,
.x-tab-default-top-ml {
    padding-left: 0;
}

.x-tab-default-top-tc {
    height: 0;
}

.x-tab-default-top-bc {
    height: 0;
}

.x-tab-default-top-mc {
    padding: 8px 10px 8px 10px;
}

.x-tab-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 8px 10px 8px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-default-bottom-mc {
    background-color: transparent;
}

.x-nbr .x-tab-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-bottom-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-10-8-10;
}

.x-tab-default-bottom-tl {
    background-position: 0 0;
}

.x-tab-default-bottom-tr {
    background-position: right 0;
}

.x-tab-default-bottom-bl {
    background-position: 0 0;
}

.x-tab-default-bottom-br {
    background-position: right 0;
}

.x-tab-default-bottom-ml {
    background-position: 0 bottom;
}

.x-tab-default-bottom-mr {
    background-position: right bottom;
}

.x-tab-default-bottom-tc {
    background-position: 0 0;
}

.x-tab-default-bottom-bc {
    background-position: 0 0;
}

.x-tab-default-bottom-tr,
.x-tab-default-bottom-br,
.x-tab-default-bottom-mr {
    padding-right: 0;
}

.x-tab-default-bottom-tl,
.x-tab-default-bottom-bl,
.x-tab-default-bottom-ml {
    padding-left: 0;
}

.x-tab-default-bottom-tc {
    height: 0;
}

.x-tab-default-bottom-bc {
    height: 0;
}

.x-tab-default-bottom-mc {
    padding: 8px 10px 8px 10px;
}

.x-tab-default-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 8px 10px 8px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-default-left-mc {
    background-color: transparent;
}

.x-nbr .x-tab-default-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-left-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-10-8-10;
}

.x-tab-default-left-tl {
    background-position: 0 0;
}

.x-tab-default-left-tr {
    background-position: right 0;
}

.x-tab-default-left-bl {
    background-position: 0 0;
}

.x-tab-default-left-br {
    background-position: right 0;
}

.x-tab-default-left-ml {
    background-position: 0 top;
}

.x-tab-default-left-mr {
    background-position: right top;
}

.x-tab-default-left-tc {
    background-position: 0 0;
}

.x-tab-default-left-bc {
    background-position: 0 0;
}

.x-tab-default-left-tr,
.x-tab-default-left-br,
.x-tab-default-left-mr {
    padding-right: 0;
}

.x-tab-default-left-tl,
.x-tab-default-left-bl,
.x-tab-default-left-ml {
    padding-left: 0;
}

.x-tab-default-left-tc {
    height: 0;
}

.x-tab-default-left-bc {
    height: 0;
}

.x-tab-default-left-mc {
    padding: 8px 10px 8px 10px;
}

.x-tab-default-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 8px 10px 8px 10px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-default-right-mc {
    background-color: transparent;
}

.x-nbr .x-tab-default-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-default-right-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-8-10-8-10;
}

.x-tab-default-right-tl {
    background-position: 0 0;
}

.x-tab-default-right-tr {
    background-position: right 0;
}

.x-tab-default-right-bl {
    background-position: 0 0;
}

.x-tab-default-right-br {
    background-position: right 0;
}

.x-tab-default-right-ml {
    background-position: 0 top;
}

.x-tab-default-right-mr {
    background-position: right top;
}

.x-tab-default-right-tc {
    background-position: 0 0;
}

.x-tab-default-right-bc {
    background-position: 0 0;
}

.x-tab-default-right-tr,
.x-tab-default-right-br,
.x-tab-default-right-mr {
    padding-right: 0;
}

.x-tab-default-right-tl,
.x-tab-default-right-bl,
.x-tab-default-right-ml {
    padding-left: 0;
}

.x-tab-default-right-tc {
    height: 0;
}

.x-tab-default-right-bc {
    height: 0;
}

.x-tab-default-right-mc {
    padding: 8px 10px 8px 10px;
}

.x-tab-default {
    border-color: transparent;
    cursor: pointer;
}

.x-tab-default-top {
    margin: 0 4px 0 0;
}

.x-tab-default-top.x-rtl {
    margin: 0 0 0 4px;
}

.x-tab-default-top.x-tab-rotate-left {
    margin: 0 0 0 4px;
}

.x-tab-default-top.x-tab-rotate-left.x-rtl {
    margin: 0 4px 0 0;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-top.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-right {
    margin: 0 0 4px 0;
}

.x-tab-default-right.x-rtl {
    margin: 0 0 4px 0;
}

.x-tab-default-right.x-tab-rotate-right {
    margin: 4px 0 0 0;
}

.x-tab-default-right.x-tab-rotate-right.x-rtl {
    margin: 4px 0 0 0;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-right.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-bottom {
    margin: 0 4px 0 0;
}

.x-tab-default-bottom.x-rtl {
    margin: 0 0 0 4px;
}

.x-tab-default-bottom.x-tab-rotate-left {
    margin: 0 0 0 4px;
}

.x-tab-default-bottom.x-tab-rotate-left.x-rtl {
    margin: 0 4px 0 0;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-bottom.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-default-left {
    margin: 0 0 4px 0;
}

.x-tab-default-left.x-rtl {
    margin: 0 0 4px 0;
}

.x-tab-default-left.x-tab-rotate-right {
    margin: 4px 0 0 0;
}

.x-tab-default-left.x-tab-rotate-right.x-rtl {
    margin: 4px 0 0 0;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-default-left.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-button-default {
    height: 20px;
}

.x-tab-inner-default {
    font: 400 13px/20px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #f0f0f0;
    max-width: 100%;
}

.x-tab-bar-plain .x-tab-inner-default {
    color: #606060;
}

.x-tab-icon-right > .x-tab-inner-default,
.x-tab-icon-left > .x-tab-inner-default {
    max-width: calc(100% - 20px);
}

.x-tab-icon-el-default {
    min-height: 20px;
    background-position: center center;
    font-size: 20px;
    line-height: 20px;
    color: #f0f0f0;
}

.x-tab-icon-left > .x-tab-icon-el-default,
.x-tab-icon-right > .x-tab-icon-el-default {
    width: 20px;
}

.x-tab-icon-top > .x-tab-icon-el-default,
.x-tab-icon-bottom > .x-tab-icon-el-default {
    min-width: 20px;
}

.x-tab-bar-plain .x-tab-icon-el-default {
    color: #606060;
}

.x-tab-icon-el-default.x-tab-glyph {
    opacity: 0.7;
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-default {
    margin-right: 6px;
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-default.x-rtl {
    margin-right: 0;
    margin-left: 6px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-default {
    margin-left: 6px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-default.x-rtl {
    margin-left: 0;
    margin-right: 6px;
}

.x-tab-text.x-tab-icon-top > .x-tab-icon-el-default {
    margin-bottom: 6px;
}

.x-tab-text.x-tab-icon-bottom > .x-tab-icon-el-default {
    margin-top: 6px;
}

.x-keyboard-mode .x-tab-focus.x-tab-default {
    border-color: transparent;
    background-color: transparent;
    outline: 1px solid #5fa2dd;
    outline-offset: -3px;
}

.x-ie .x-keyboard-mode .x-tab-focus.x-tab-default,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-default,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-default {
    outline: none;
}

.x-ie .x-keyboard-mode .x-tab-focus.x-tab-default:after,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-default:after,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-default:after {
    position: absolute;
    content: ' ';
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-default .x-tab-inner-default {
    color: #606060;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-default .x-tab-icon-el {
    color: #606060;
}

.x-tab-over.x-tab-default {
    border-color: #000;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.08);
}

.x-ie8 .x-tab-over.x-tab-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#14000000, endColorstr=#14000000)";
    zoom: 1;
}

.x-ie8 .x-tab-over.x-tab-default.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-ie8 .x-tab-over.x-tab-default.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-bar-plain .x-tab-over.x-tab-default .x-tab-inner-default {
    color: #606060;
}

.x-tab-bar-plain .x-tab-over.x-tab-default .x-tab-icon-el {
    color: #606060;
}

.x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-default {
    border-color: #000;
    background-image: none;
    background-color: rgba(0, 0, 0, 0.08);
}

.x-ie8 .x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#14000000, endColorstr=#14000000)";
    zoom: 1;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-default .x-tab-inner-default {
    color: #606060;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-default .x-tab-icon-el {
    color: #606060;
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-default.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-default.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab.x-tab-active.x-tab-default {
    border-color: #fff;
    background-color: #fff;
}

.x-tab.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #4c82b1;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #404040;
}

.x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #4c82b1;
}

.x-ie8 .x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #82a7c8;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #404040;
}

.x-keyboard-mode .x-tab-focus.x-tab-active.x-tab-default {
    border-color: #fff;
    background-color: #fff;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-default .x-tab-inner-default {
    color: #404040;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-default .x-tab-icon-el {
    color: #404040;
}

.x-tab.x-tab-disabled.x-tab-default {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-inner-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default .x-tab-inner-default {
    color: #606060;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el-default {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el {
    color: #f0f0f0;
    opacity: 0.3;
    filter: none;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-default .x-tab-icon-el {
    color: #606060;
}

.x-nbr .x-tab-default {
    background-image: none;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-default-top-tl,*/
/*.x-tab-over .x-tab-default-top-bl,*/
/*.x-tab-over .x-tab-default-top-tr,*/
/*.x-tab-over .x-tab-default-top-br,*/
/*.x-tab-over .x-tab-default-top-tc,*/
/*.x-tab-over .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-default-top-ml,*/
/*.x-tab-over .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-over-sides.gif);*/
/*}*/

.x-tab-over .x-tab-default-top-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-top-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-default-top-tl,*/
/*.x-tab-focus .x-tab-default-top-bl,*/
/*.x-tab-focus .x-tab-default-top-tr,*/
/*.x-tab-focus .x-tab-default-top-br,*/
/*.x-tab-focus .x-tab-default-top-tc,*/
/*.x-tab-focus .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-default-top-ml,*/
/*.x-tab-focus .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-default-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-top-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-br,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-focus-over-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-over .x-tab-default-top-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-top-focus-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-default-top-tl,*/
/*.x-tab.x-tab-active .x-tab-default-top-bl,*/
/*.x-tab.x-tab-active .x-tab-default-top-tr,*/
/*.x-tab.x-tab-active .x-tab-default-top-br,*/
/*.x-tab.x-tab-active .x-tab-default-top-tc,*/
/*.x-tab.x-tab-active .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-default-top-ml,*/
/*.x-tab.x-tab-active .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-active-sides.gif);*/
/*}*/

.x-tab.x-tab-active .x-tab-default-top-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-top-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-br,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-focus-active-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-active .x-tab-default-top-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-top-focus-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-top-tl,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-bl,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-tr,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-br,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-tc,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-bc {*/
/*    background-image: url(images/tab/tab-default-top-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-top-ml,*/
/*.x-tab.x-tab-disabled .x-tab-default-top-mr {*/
/*    background-image: url(images/tab/tab-default-top-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-top-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-default-right-tl,*/
/*.x-tab-over .x-tab-default-right-bl,*/
/*.x-tab-over .x-tab-default-right-tr,*/
/*.x-tab-over .x-tab-default-right-br,*/
/*.x-tab-over .x-tab-default-right-tc,*/
/*.x-tab-over .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-default-right-ml,*/
/*.x-tab-over .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-over-sides.gif);*/
/*}*/

.x-tab-over .x-tab-default-right-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-right-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-default-right-tl,*/
/*.x-tab-focus .x-tab-default-right-bl,*/
/*.x-tab-focus .x-tab-default-right-tr,*/
/*.x-tab-focus .x-tab-default-right-br,*/
/*.x-tab-focus .x-tab-default-right-tc,*/
/*.x-tab-focus .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-default-right-ml,*/
/*.x-tab-focus .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-default-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-right-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-br,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-focus-over-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-over .x-tab-default-right-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-right-focus-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-default-right-tl,*/
/*.x-tab.x-tab-active .x-tab-default-right-bl,*/
/*.x-tab.x-tab-active .x-tab-default-right-tr,*/
/*.x-tab.x-tab-active .x-tab-default-right-br,*/
/*.x-tab.x-tab-active .x-tab-default-right-tc,*/
/*.x-tab.x-tab-active .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-default-right-ml,*/
/*.x-tab.x-tab-active .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-active-sides.gif);*/
/*}*/

.x-tab.x-tab-active .x-tab-default-right-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-right-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-br,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-focus-active-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-active .x-tab-default-right-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-right-focus-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-right-tl,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-bl,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-tr,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-br,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-tc,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-bc {*/
/*    background-image: url(images/tab/tab-default-right-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-right-ml,*/
/*.x-tab.x-tab-disabled .x-tab-default-right-mr {*/
/*    background-image: url(images/tab/tab-default-right-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-right-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-default-bottom-tl,*/
/*.x-tab-over .x-tab-default-bottom-bl,*/
/*.x-tab-over .x-tab-default-bottom-tr,*/
/*.x-tab-over .x-tab-default-bottom-br,*/
/*.x-tab-over .x-tab-default-bottom-tc,*/
/*.x-tab-over .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-default-bottom-ml,*/
/*.x-tab-over .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-over-sides.gif);*/
/*}*/

.x-tab-over .x-tab-default-bottom-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-bottom-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-default-bottom-tl,*/
/*.x-tab-focus .x-tab-default-bottom-bl,*/
/*.x-tab-focus .x-tab-default-bottom-tr,*/
/*.x-tab-focus .x-tab-default-bottom-br,*/
/*.x-tab-focus .x-tab-default-bottom-tc,*/
/*.x-tab-focus .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-default-bottom-ml,*/
/*.x-tab-focus .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-default-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-bottom-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-br,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-over-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-over .x-tab-default-bottom-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-bottom-focus-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-default-bottom-tl,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-bl,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-tr,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-br,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-tc,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-default-bottom-ml,*/
/*.x-tab.x-tab-active .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-active-sides.gif);*/
/*}*/

.x-tab.x-tab-active .x-tab-default-bottom-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-bottom-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-br,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-focus-active-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-active .x-tab-default-bottom-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-bottom-focus-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-tl,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-bl,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-tr,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-br,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-tc,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-bc {*/
/*    background-image: url(images/tab/tab-default-bottom-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-ml,*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-mr {*/
/*    background-image: url(images/tab/tab-default-bottom-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-bottom-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-default-left-tl,*/
/*.x-tab-over .x-tab-default-left-bl,*/
/*.x-tab-over .x-tab-default-left-tr,*/
/*.x-tab-over .x-tab-default-left-br,*/
/*.x-tab-over .x-tab-default-left-tc,*/
/*.x-tab-over .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-default-left-ml,*/
/*.x-tab-over .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-over-sides.gif);*/
/*}*/

.x-tab-over .x-tab-default-left-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-left-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-default-left-tl,*/
/*.x-tab-focus .x-tab-default-left-bl,*/
/*.x-tab-focus .x-tab-default-left-tr,*/
/*.x-tab-focus .x-tab-default-left-br,*/
/*.x-tab-focus .x-tab-default-left-tc,*/
/*.x-tab-focus .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-default-left-ml,*/
/*.x-tab-focus .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-default-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-left-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-br,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-focus-over-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-over .x-tab-default-left-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-left-focus-over-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-default-left-tl,*/
/*.x-tab.x-tab-active .x-tab-default-left-bl,*/
/*.x-tab.x-tab-active .x-tab-default-left-tr,*/
/*.x-tab.x-tab-active .x-tab-default-left-br,*/
/*.x-tab.x-tab-active .x-tab-default-left-tc,*/
/*.x-tab.x-tab-active .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-default-left-ml,*/
/*.x-tab.x-tab-active .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-active-sides.gif);*/
/*}*/

.x-tab.x-tab-active .x-tab-default-left-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-left-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-br,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-focus-active-sides.gif);*/
/*}*/

.x-tab-focus.x-tab-active .x-tab-default-left-mc {
    background-repeat: repeat-x;
    background-image: url(images/tab/tab-default-left-focus-active-fbg.gif);
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-left-tl,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-bl,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-tr,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-br,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-tc,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-bc {*/
/*    background-image: url(images/tab/tab-default-left-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-left-ml,*/
/*.x-tab.x-tab-disabled .x-tab-default-left-mr {*/
/*    background-image: url(images/tab/tab-default-left-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-default-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-default-left-disabled-fbg.gif);*/
/*}*/

.x-tab-default-tl,
.x-tab-default-bl,
.x-tab-default-tr,
.x-tab-default-br {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
}

.x-tab-default .x-tab-close-btn {
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font: 12px/1 'Font Awesome 5 Free';
    color: #fff;
}

.x-tab-default .x-tab-close-btn:before {
    content: '\f00d';
}

.x-tab-bar-plain .x-tab-default .x-tab-close-btn {
    color: #919191;
}

.x-tab-default .x-tab-close-btn-over {
    background-position: -16px 0;
}

.x-tab-default .x-tab-close-btn-pressed {
    background-position: -32px 0;
}

.x-keyboard-mode .x-tab-default.x-tab-focus .x-tab-close-btn {
    color: #fff;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-default.x-tab-focus .x-tab-close-btn {
    color: #919191;
}

.x-tab-default.x-tab-over .x-tab-close-btn {
    color: #fff;
}

.x-tab-bar-plain .x-tab-default.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-keyboard-mode .x-tab-default.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: #fff;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-default.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-tab-default.x-tab-active .x-tab-close-btn {
    color: #919191;
}

.x-keyboard-mode .x-tab-default.x-tab-focus.x-tab-active .x-tab-close-btn {
    color: #919191;
}

.x-tab-default.x-tab-disabled .x-tab-close-btn {
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
    background-position: 0 0;
}

.x-tab-bar-plain .x-tab-default.x-tab-disabled .x-tab-close-btn {
    color: #919191;
}

.x-rtl.x-tab-default .x-tab-close-btn {
    right: auto;
    left: 0;
}

.x-tab-closable.x-tab-default .x-tab-button {
    padding-right: 16px;
}

.x-rtl.x-tab-closable.x-tab-default .x-tab-button {
    padding-right: 0px;
    padding-left: 16px;
}

.x-tab-bar-default {
    background-color: #5fa2dd;
}

.x-tab-bar-default-top > .x-tab-bar-body-default {
    padding: 8px 4px 0 8px;
}

.x-tab-bar-default-bottom > .x-tab-bar-body-default {
    padding: 0 4px 8px 8px;
}

.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 4px 0 8px 8px;
}

.x-rtl.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 4px 8px 8px 0;
}

.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 8px 8px 4px 0;
}

.x-rtl.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 8px 0 4px 8px;
}

.x-tab-bar-plain.x-tab-bar-default-top > .x-tab-bar-body-default {
    padding: 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-default-bottom > .x-tab-bar-body-default {
    padding: 0 0 8px 0;
}

.x-tab-bar-plain.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 0 0 0 8px;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-default-left > .x-tab-bar-body-default {
    padding: 0 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 0 8px 0 0;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-default-right > .x-tab-bar-body-default {
    padding: 0 0 0 8px;
}

.x-tab-bar-plain.x-tab-bar-default-horizontal {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 0;
    border-right-width: 0;
}

.x-tab-bar-plain.x-tab-bar-default-vertical {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-width: 0;
    border-bottom-width: 0;
}

.x-tab-bar-horizontal > .x-tab-bar-body-default {
    min-height: 44px;
}

.x-ie8m .x-tab-bar-horizontal > .x-tab-bar-body-default {
    min-height: 36px;
}

.x-tab-bar-vertical > .x-tab-bar-body-default {
    min-width: 44px;
}

.x-ie8m .x-tab-bar-vertical > .x-tab-bar-body-default {
    min-width: 36px;
}

.x-tab-bar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-tab-bar-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-tab-bar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-box-scroller-tab-bar-default {
    cursor: pointer;
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-tab-bar-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-tab-bar-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-tab-bar-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-tab-bar-default.x-box-scroller-left,
.x-box-scroller-tab-bar-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-tab-bar-default.x-box-scroller-left {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-tab-bar-default.x-box-scroller-right {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-right:before {
    content: '\f054';
}

.x-box-scroller-tab-bar-default.x-box-scroller-top,
.x-box-scroller-tab-bar-default.x-box-scroller-bottom {
    height: 24px;
    width: 24px;
    left: 50%;
    margin-left: -12px;
}

.x-box-scroller-tab-bar-default.x-box-scroller-top {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-tab-bar-default.x-box-scroller-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-tab-bar-more-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-tab-bar-more-icon:before {
    content: '\f0c9';
}

.x-tab-bar-plain.x-tab-bar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-tab-bar-plain.x-tab-bar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-tab-bar-plain .x-box-scroller-tab-bar-default {
    color: #919191;
}

.x-tab-bar-default-top .x-box-scroller-tab-bar-default {
    margin-top: -8px;
}

.x-tab-bar-default-right .x-box-scroller-tab-bar-default {
    margin-left: -16px;
}

.x-tab-bar-default-bottom .x-box-scroller-tab-bar-default {
    margin-top: -16px;
}

.x-tab-bar-default-left .x-box-scroller-tab-bar-default {
    margin-left: -8px;
}

.x-box-scroller-tab-bar-default {
    background-color: #5fa2dd;
}

.x-box-scroller-tab-bar-default .x-ie8 .x-box-scroller-plain {
    background-color: #fff;
}

.x-breadcrumb-btn-default {
    margin: 0 0 0 0px;
}

.x-breadcrumb-icon-folder-default {
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-breadcrumb-icon-folder-default:before {
    content: '\f07b';
}

.x-btn-menu-active .x-breadcrumb-icon-folder-default {
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-btn-menu-active .x-breadcrumb-icon-folder-default:before {
    content: '\f07c';
}

.x-breadcrumb-icon-leaf-default {
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-breadcrumb-icon-leaf-default:before {
    content: '\f15b';
}

.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    width: 20px;
    background-image: url(images/breadcrumb/default-arrow.png);
}

.x-rtl.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(images/breadcrumb/default-arrow-rtl.png);
}

.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(images/breadcrumb/default-arrow-open.png);
}

.x-rtl.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-arrow:after {
    background-image: url(images/breadcrumb/default-arrow-open-rtl.png);
}

.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    width: 20px;
    content: '\f105';
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
    background: none !important;
}

.x-rtl.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    content: '\f104';
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-btn-menu-active.x-breadcrumb-btn .x-btn-wrap-plain-toolbar-small.x-btn-split:after {
    content: '\f107';
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
    background: none !important;
}

.x-breadcrumb-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 32px;
}

.x-breadcrumb-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 32px;
}

.x-breadcrumb-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 24px;
}

.x-box-scroller-breadcrumb-default {
    cursor: pointer;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-left,
.x-box-scroller-breadcrumb-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-breadcrumb-default.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    background-image: url(images/breadcrumb/default-scroll-left.png);
}

.x-box-scroller-breadcrumb-default.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    background-image: url(images/breadcrumb/default-scroll-right.png);
}

.x-tree-expander {
    cursor: pointer;
}

.x-tree-arrows .x-tree-expander {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-arrows .x-tree-expander:before {
    content: '\f0da';
}

.x-tree-arrows .x-tree-expander.x-rtl {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
}

.x-tree-arrows .x-tree-expander.x-rtl:before {
    content: '\f0d9';
}

.x-tree-arrows .x-grid-tree-node-expanded .x-tree-expander {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-arrows .x-grid-tree-node-expanded .x-tree-expander:before {
    content: '\f0d7';
}

.x-tree-elbow-plus,
.x-tree-elbow-end-plus {
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-elbow-plus:before,
.x-tree-elbow-end-plus:before {
    content: '\f0fe';
}

.x-grid-tree-node-expanded .x-tree-elbow-plus,
.x-grid-tree-node-expanded .x-tree-elbow-end-plus {
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-grid-tree-node-expanded .x-tree-elbow-plus:before,
.x-grid-tree-node-expanded .x-tree-elbow-end-plus:before {
    content: '\f146';
}

.x-tree-icon {
    width: 16px;
    height: 32px;
    line-height: 32px;
    color: #5fa2dd;
    font-size: 16px;
}

.x-tree-elbow-img {
    width: 18px;
    height: 32px;
    line-height: 32px;
    margin-right: 4px;
}

.x-rtl.x-tree-elbow-img {
    margin-right: 0;
    margin-left: 4px;
}

.x-tree-icon,
.x-tree-elbow-img,
.x-tree-checkbox {
    margin-top: -7px;
    margin-bottom: -6px;
}

.x-tree-icon-leaf {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-icon-leaf:before {
    content: '\f15b';
}

.x-tree-icon-parent {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-icon-parent:before {
    content: '\f07b';
}

.x-tree-icon-parent-expanded {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
    color: #5fa2dd;
}

.x-tree-icon-parent-expanded:before {
    content: '\f07c';
}

.x-tree-icon-custom {
    background-image: none;
}

.x-tree-icon-custom:before {
    content: '';
}

.x-tree-icon-custom.x-rtl {
    background-image: none;
}

.x-tree-checkbox {
    margin-right: 6px;
    top: 7px;
    width: 18px;
    height: 18px;
    font: 18px/1 ExtJS;
    color: #5fa2dd;
}

.x-tree-checkbox:before {
    content: "\e614";
}

.x-rtl.x-tree-checkbox {
    margin-right: 0;
    margin-left: 6px;
}

.x-tree-checkbox-checked {
    font: 18px/1 ExtJS;
}

.x-tree-checkbox-checked:before {
    content: "\e613";
}

.x-grid-tree-loading .x-tree-icon {
    background-image: url(images/tree/loading.gif);
}

.x-grid-tree-loading .x-rtl.x-tree-icon {
    background-image: url(images/tree/loading.gif);
}

.x-tree-node-text {
    padding-left: 6px;
}

.x-rtl.x-tree-node-text {
    padding-left: 0;
    padding-right: 6px;
}

.x-grid-cell-inner-treecolumn {
    padding: 7px 10px 6px 6px;
}

.x-tree-drop-ok-append .x-dd-drop-icon,
.x-tree-drop-ok-above .x-dd-drop-icon,
.x-tree-drop-ok-below .x-dd-drop-icon,
.x-tree-drop-ok-between .x-dd-drop-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-tree-drop-ok-append .x-dd-drop-icon:before,
.x-tree-drop-ok-above .x-dd-drop-icon:before,
.x-tree-drop-ok-below .x-dd-drop-icon:before,
.x-tree-drop-ok-between .x-dd-drop-icon:before {
    content: '\f055';
}

.x-tree-ddindicator {
    height: 1px;
    border-width: 1px 0px 0px;
    border-style: dotted;
    border-color: green;
}

.x-multiselector-remove {
    font-size: 110%;
    color: #d0d0d0;
    cursor: pointer;
}

.x-multiselector-remove .x-grid-cell-inner {
    padding: 7px 4px 6px 4px;
}

.x-grid-item-over .x-multiselector-remove {
    color: red;
}

.x-multiselector-search-input .x-form-text::-ms-clear {
    display: none;
}

.x-toast-icon-information {
    background-image: url(images/window/toast/icon16_info.png);
}

.x-toast-icon-error {
    background-image: url(images/window/toast/icon16_error.png);
}

.x-toast-window .x-window-body {
    /* Using standard theme */
    padding: 15px 5px 15px 5px;
}

.x-toast-light .x-window-header {
    /* Custom styling */
    background-color: #7fb5e4;
}

.x-toast-light .x-tool-img {
    background-color: #7fb5e4;
}

.x-toast-light {
    background-image: url(images/window/toast/fader.png);
}

.x-toast-light .x-window-body {
    padding: 15px 5px 20px 5px;
    background-color: transparent;
    border: 0px solid white;
}

.x-btn-plain-toolbar-small {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 7px 7px 7px 7px;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-small-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-7-7-7-7;
}

.x-btn-plain-toolbar-small-tl {
    background-position: 0 -2px;
}

.x-btn-plain-toolbar-small-tr {
    background-position: right -3px;
}

.x-btn-plain-toolbar-small-bl {
    background-position: 0 -4px;
}

.x-btn-plain-toolbar-small-br {
    background-position: right -5px;
}

.x-btn-plain-toolbar-small-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-small-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-small-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-small-bc {
    background-position: 0 -1px;
}

.x-btn-plain-toolbar-small-tr,
.x-btn-plain-toolbar-small-br,
.x-btn-plain-toolbar-small-mr {
    padding-right: 1px;
}

.x-btn-plain-toolbar-small-tl,
.x-btn-plain-toolbar-small-bl,
.x-btn-plain-toolbar-small-ml {
    padding-left: 1px;
}

.x-btn-plain-toolbar-small-tc {
    height: 1px;
}

.x-btn-plain-toolbar-small-bc {
    height: 1px;
}

.x-btn-plain-toolbar-small-mc {
    padding: 7px 7px 7px 7px;
}

.x-btn-plain-toolbar-small {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-plain-toolbar-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-plain-toolbar-small {
    font: 400 12px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-plain-toolbar-small {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-small {
    font-size: 16px;
    height: 16px;
    color: #919191;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-small,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-small.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-small,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-small {
    padding-bottom: 7px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-arrow-right:after {
    width: 18px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: '\f0d7';
    font: 16px/12px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button {
    padding-right: 7px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 7px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-right:after {
    width: 20px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 7px;
}

.x-btn-wrap-plain-toolbar-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 7px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom {
    margin-bottom: -7px;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-small.x-btn-split-bottom:after {
    height: 20px;
    content: '\f0d7';
    font: 16px/20px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-small {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-plain-toolbar-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 27px;
    pointer-events: none;
}

.x-btn-plain-toolbar-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-plain-toolbar-small {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-small {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-small,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-small {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-small {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-small {
    background-image: none;
    background-color: transparent;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2966 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-tl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-bl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-tr,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-br,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-tc,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-small-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2974 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-ml,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-small-focus-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-small-tl,
.x-btn-over .x-btn-plain-toolbar-small-bl,
.x-btn-over .x-btn-plain-toolbar-small-tr,
.x-btn-over .x-btn-plain-toolbar-small-br,
.x-btn-over .x-btn-plain-toolbar-small-tc,
.x-btn-over .x-btn-plain-toolbar-small-bc {
    background-image: url(images/btn/btn-plain-toolbar-small-over-corners.gif);
}

.x-btn-over .x-btn-plain-toolbar-small-ml,
.x-btn-over .x-btn-plain-toolbar-small-mr {
    background-image: url(images/btn/btn-plain-toolbar-small-over-sides.gif);
}

.x-btn-over .x-btn-plain-toolbar-small-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-bc {
    background-image: url(images/btn/btn-plain-toolbar-small-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-mr {
    background-image: url(images/btn/btn-plain-toolbar-small-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-small-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-tl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-bl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-tr,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-br,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-tc,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-bc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-tl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-bl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-tr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-br,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-tc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-bc {
    background-image: url(images/btn/btn-plain-toolbar-small-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-ml,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-mr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-ml,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-mr {
    background-image: url(images/btn/btn-plain-toolbar-small-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-small-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-small-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-bc {
    background-image: url(images/btn/btn-plain-toolbar-small-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-mr {
    background-image: url(images/btn/btn-plain-toolbar-small-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-small-mc {
    background-color: #e2e2e2;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3095 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-tl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-bl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-tr,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-br,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-tc,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-small-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3103 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-ml,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-small-disabled-sides.gif);*/
/*}*/

.x-btn.x-btn-disabled .x-btn-plain-toolbar-small-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-small {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first .x-btn-plain-toolbar-small-mc {
    padding-right: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-middle .x-btn-plain-toolbar-small-mc {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-last .x-btn-plain-toolbar-small-mc {
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first .x-btn-plain-toolbar-small-mc {
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-middle .x-btn-plain-toolbar-small-mc {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-last .x-btn-plain-toolbar-small-mc {
    padding-top: 7px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-small.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-small.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-plain-toolbar-small-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-button-plain-toolbar-small-cell > .x-grid-cell-inner >.x-btn-plain-toolbar-small {
    vertical-align: top;
}

.x-btn-plain-toolbar-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 8px 8px 8px;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-medium-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-8-8-8-8;
}

.x-btn-plain-toolbar-medium-tl {
    background-position: 0 -2px;
}

.x-btn-plain-toolbar-medium-tr {
    background-position: right -3px;
}

.x-btn-plain-toolbar-medium-bl {
    background-position: 0 -4px;
}

.x-btn-plain-toolbar-medium-br {
    background-position: right -5px;
}

.x-btn-plain-toolbar-medium-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-medium-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-medium-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-medium-bc {
    background-position: 0 -1px;
}

.x-btn-plain-toolbar-medium-tr,
.x-btn-plain-toolbar-medium-br,
.x-btn-plain-toolbar-medium-mr {
    padding-right: 1px;
}

.x-btn-plain-toolbar-medium-tl,
.x-btn-plain-toolbar-medium-bl,
.x-btn-plain-toolbar-medium-ml {
    padding-left: 1px;
}

.x-btn-plain-toolbar-medium-tc {
    height: 1px;
}

.x-btn-plain-toolbar-medium-bc {
    height: 1px;
}

.x-btn-plain-toolbar-medium-mc {
    padding: 8px 8px 8px 8px;
}

.x-btn-plain-toolbar-medium {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-plain-toolbar-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-plain-toolbar-medium {
    font: 400 14px/18px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-plain-toolbar-medium {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-medium {
    font-size: 20px;
    height: 20px;
    color: #919191;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-medium,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-medium.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-medium.x-rtl {
    margin-right: 0;
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium.x-rtl {
    margin-left: 0;
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-medium,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-arrow-right:after {
    width: 20px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: '\f0d7';
    font: 20px/14px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button {
    padding-right: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-right:after {
    width: 26px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-medium.x-btn-split-bottom:after {
    height: 24px;
    content: '\f0d7';
    font: 20px/24px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-medium {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-plain-toolbar-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 34px;
    pointer-events: none;
}

.x-btn-plain-toolbar-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-medium.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-plain-toolbar-medium {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-medium {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-medium,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-medium {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-medium {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-medium {
    background-image: none;
    background-color: transparent;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2966 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-tl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-bl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-tr,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-br,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-tc,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-medium-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2974 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-ml,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-medium-focus-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-medium-tl,
.x-btn-over .x-btn-plain-toolbar-medium-bl,
.x-btn-over .x-btn-plain-toolbar-medium-tr,
.x-btn-over .x-btn-plain-toolbar-medium-br,
.x-btn-over .x-btn-plain-toolbar-medium-tc,
.x-btn-over .x-btn-plain-toolbar-medium-bc {
    background-image: url(images/btn/btn-plain-toolbar-medium-over-corners.gif);
}

.x-btn-over .x-btn-plain-toolbar-medium-ml,
.x-btn-over .x-btn-plain-toolbar-medium-mr {
    background-image: url(images/btn/btn-plain-toolbar-medium-over-sides.gif);
}

.x-btn-over .x-btn-plain-toolbar-medium-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-bc {
    background-image: url(images/btn/btn-plain-toolbar-medium-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-mr {
    background-image: url(images/btn/btn-plain-toolbar-medium-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-medium-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-tl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-bl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-tr,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-br,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-tc,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-bc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-tl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-bl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-tr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-br,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-tc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-bc {
    background-image: url(images/btn/btn-plain-toolbar-medium-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-ml,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-mr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-ml,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-mr {
    background-image: url(images/btn/btn-plain-toolbar-medium-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-medium-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-medium-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-bc {
    background-image: url(images/btn/btn-plain-toolbar-medium-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-mr {
    background-image: url(images/btn/btn-plain-toolbar-medium-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-medium-mc {
    background-color: #e2e2e2;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3095 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-tl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-bl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-tr,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-br,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-tc,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-medium-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3103 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-ml,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-medium-disabled-sides.gif);*/
/*}*/

.x-btn.x-btn-disabled .x-btn-plain-toolbar-medium-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first .x-btn-plain-toolbar-medium-mc {
    padding-right: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-middle .x-btn-plain-toolbar-medium-mc {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-last .x-btn-plain-toolbar-medium-mc {
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first .x-btn-plain-toolbar-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-middle .x-btn-plain-toolbar-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-last .x-btn-plain-toolbar-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-medium.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-medium.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-plain-toolbar-medium-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-plain-toolbar-medium-cell > .x-grid-cell-inner >.x-btn-plain-toolbar-medium {
    vertical-align: top;
}

.x-btn-plain-toolbar-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 9px 9px 9px 9px;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
}

.x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-plain-toolbar-large-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-9-9-9-9;
}

.x-btn-plain-toolbar-large-tl {
    background-position: 0 -2px;
}

.x-btn-plain-toolbar-large-tr {
    background-position: right -3px;
}

.x-btn-plain-toolbar-large-bl {
    background-position: 0 -4px;
}

.x-btn-plain-toolbar-large-br {
    background-position: right -5px;
}

.x-btn-plain-toolbar-large-ml {
    background-position: 0 top;
}

.x-btn-plain-toolbar-large-mr {
    background-position: right top;
}

.x-btn-plain-toolbar-large-tc {
    background-position: 0 0;
}

.x-btn-plain-toolbar-large-bc {
    background-position: 0 -1px;
}

.x-btn-plain-toolbar-large-tr,
.x-btn-plain-toolbar-large-br,
.x-btn-plain-toolbar-large-mr {
    padding-right: 1px;
}

.x-btn-plain-toolbar-large-tl,
.x-btn-plain-toolbar-large-bl,
.x-btn-plain-toolbar-large-ml {
    padding-left: 1px;
}

.x-btn-plain-toolbar-large-tc {
    height: 1px;
}

.x-btn-plain-toolbar-large-bc {
    height: 1px;
}

.x-btn-plain-toolbar-large-mc {
    padding: 9px 9px 9px 9px;
}

.x-btn-plain-toolbar-large {
    border-color: transparent;
}

.x-btn-button-plain-toolbar-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-plain-toolbar-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-plain-toolbar-large {
    font: 400 16px/20px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-plain-toolbar-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-plain-toolbar-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-plain-toolbar-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-plain-toolbar-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-plain-toolbar-large {
    max-width: none;
}

.x-btn-icon-el-plain-toolbar-large {
    font-size: 24px;
    height: 24px;
    color: #919191;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large,
.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-large,
.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-plain-toolbar-large.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-plain-toolbar-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-plain-toolbar-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-plain-toolbar-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-plain-toolbar-large,
.x-btn-split-bottom > .x-btn-button-plain-toolbar-large {
    padding-bottom: 9px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-arrow-right:after {
    width: 22px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: '\f0d7';
    font: 24px/16px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button {
    padding-right: 9px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 9px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-right:after {
    width: 32px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 9px;
}

.x-btn-wrap-plain-toolbar-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 9px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom {
    margin-bottom: -9px;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-plain-toolbar-large.x-btn-split-bottom:after {
    height: 28px;
    content: '\f0d7';
    font: 24px/28px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-plain-toolbar-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-plain-toolbar-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-plain-toolbar-large {
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-plain-toolbar-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 41px;
    pointer-events: none;
}

.x-btn-plain-toolbar-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-plain-toolbar-large.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-plain-toolbar-large {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-plain-toolbar-large {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-plain-toolbar-large,
.x-btn.x-btn-pressed.x-btn-plain-toolbar-large {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-large {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-plain-toolbar-large {
    background-image: none;
    background-color: transparent;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2966 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-tl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-bl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-tr,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-br,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-tc,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-large-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2974 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-ml,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-large-focus-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus .x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-btn-over .x-btn-plain-toolbar-large-tl,
.x-btn-over .x-btn-plain-toolbar-large-bl,
.x-btn-over .x-btn-plain-toolbar-large-tr,
.x-btn-over .x-btn-plain-toolbar-large-br,
.x-btn-over .x-btn-plain-toolbar-large-tc,
.x-btn-over .x-btn-plain-toolbar-large-bc {
    background-image: url(images/btn/btn-plain-toolbar-large-over-corners.gif);
}

.x-btn-over .x-btn-plain-toolbar-large-ml,
.x-btn-over .x-btn-plain-toolbar-large-mr {
    background-image: url(images/btn/btn-plain-toolbar-large-over-sides.gif);
}

.x-btn-over .x-btn-plain-toolbar-large-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-bc {
    background-image: url(images/btn/btn-plain-toolbar-large-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-mr {
    background-image: url(images/btn/btn-plain-toolbar-large-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-plain-toolbar-large-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-tl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-bl,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-tr,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-br,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-tc,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-bc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-tl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-bl,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-tr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-br,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-tc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-bc {
    background-image: url(images/btn/btn-plain-toolbar-large-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-ml,
.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-mr,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-ml,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-mr {
    background-image: url(images/btn/btn-plain-toolbar-large-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-plain-toolbar-large-mc,
.x-btn.x-btn-pressed .x-btn-plain-toolbar-large-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-bc {
    background-image: url(images/btn/btn-plain-toolbar-large-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-mr {
    background-image: url(images/btn/btn-plain-toolbar-large-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-plain-toolbar-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-plain-toolbar-large-mc {
    background-color: #e2e2e2;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3095 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-tl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-bl,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-tr,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-br,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-tc,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-bc {*/
/*    background-image: url(images/btn/btn-plain-toolbar-large-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3103 *!*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-ml,*/
/*.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-mr {*/
/*    background-image: url(images/btn/btn-plain-toolbar-large-disabled-sides.gif);*/
/*}*/

.x-btn.x-btn-disabled .x-btn-plain-toolbar-large-mc {
    background-color: transparent;
}

.x-nbr .x-btn-plain-toolbar-large {
    background-image: none;
}

.x-btn-disabled.x-btn-plain-toolbar-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first .x-btn-plain-toolbar-large-mc {
    padding-right: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-middle .x-btn-plain-toolbar-large-mc {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-last .x-btn-plain-toolbar-large-mc {
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first .x-btn-plain-toolbar-large-mc {
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-middle .x-btn-plain-toolbar-large-mc {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-last .x-btn-plain-toolbar-large-mc {
    padding-top: 9px !important;
}

.x-nbr .x-segmented-button-item.x-btn-plain-toolbar-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-plain-toolbar-large.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-plain-toolbar-large.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-plain-toolbar-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-plain-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-plain-toolbar-large-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-plain-toolbar-large-cell > .x-grid-cell-inner >.x-btn-plain-toolbar-large {
    vertical-align: top;
}

.x-btn-plain-toolbar-small-disabled .x-btn-icon-el,
.x-btn-plain-toolbar-medium-disabled .x-btn-icon-el,
.x-btn-plain-toolbar-large-disabled .x-btn-icon-el {
    background-color: #fff;
}

.x-ie8 .x-btn-plain-toolbar-small-disabled .x-btn-icon-el,
.x-ie8 .x-btn-plain-toolbar-medium-disabled .x-btn-icon-el,
.x-ie8 .x-btn-plain-toolbar-large-disabled .x-btn-icon-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-panel-light {
    border-color: #d0d0d0;
    padding: 0;
}

.x-panel-light.x-masked {
    border-color: #e8e8e8;
}

.x-panel-header-light {
    font-size: 16px;
    border: 1px solid #d0d0d0;
}

.x-panel-header-light .x-tool-tool-el {
    color: #919191;
}

.x-panel-header-light .x-tool-tool-el {
    background-color: #fff;
}

.x-panel-header-light-horizontal {
    padding: 11px 15px 12px;
}

.x-panel-header-light-horizontal .x-panel-header-light-tab-bar {
    margin-top: -11px;
    margin-bottom: -12px;
}

.x-panel-header-light-horizontal.x-header-noborder {
    padding: 12px 16px 12px 16px;
}

.x-panel-header-light-horizontal.x-header-noborder .x-panel-header-light-tab-bar {
    margin-top: -12px;
    margin-bottom: -12px;
}

.x-panel-header-light-vertical {
    padding: 15px 11px 15px 12px;
}

.x-panel-header-light-vertical .x-panel-header-light-tab-bar {
    margin-right: -11px;
    margin-left: -12px;
}

.x-panel-header-light-vertical.x-header-noborder {
    padding: 16px 12px 16px 12px;
}

.x-panel-header-light-vertical.x-header-noborder .x-panel-header-light-tab-bar {
    margin-right: -12px;
    margin-left: -12px;
}

.x-rtl.x-panel-header-light-vertical {
    padding: 15px 12px 15px 11px;
}

.x-rtl.x-panel-header-light-vertical .x-panel-header-light-tab-bar {
    margin-left: -11px;
    margin-right: -12px;
}

.x-rtl.x-panel-header-light-vertical.x-header-noborder {
    padding: 16px 12px 16px 12px;
}

.x-rtl.x-panel-header-light-vertical.x-header-noborder .x-panel-header-light-tab-bar {
    margin-left: -12px;
    margin-right: -12px;
}

.x-panel-header-title-light {
    color: #606060;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 20px;
}

.x-keyboard-mode .x-panel-header-title-light.x-title-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-light.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-light.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-panel-header-title-light > .x-title-text-light {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-light > .x-title-icon-wrap-light > .x-title-icon-light {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #606060;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-light > .x-title-icon-wrap-light > .x-title-icon-light.x-title-glyph {
    color: #606060;
}

.x-panel-body-light {
    background: #fff;
    border-color: #d0d0d0;
    color: #404040;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-light {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-vertical {
    background-image: none;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-vertical {
    background-image: none;
    background-color: #fff;
}

.x-panel .x-panel-header-light-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-light-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-light-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-light-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-light .x-tool-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-light .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-light .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-light .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-light .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-light .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-light .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-rtl.x-panel-header-light-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-light-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-light-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-light-outer-border-l {
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-b {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-bl {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-r {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-outer-border-rl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-rb {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-rbl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-t {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
}

.x-panel-light-outer-border-tl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-tb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-tbl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-tr {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
}

.x-panel-light-outer-border-trl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 1px !important;
}

.x-panel-light-outer-border-trb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 1px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 1px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 1px !important;
}

.x-panel-light-outer-border-trbl {
    border-color: #d0d0d0 !important;
    border-width: 1px !important;
}

.x-panel-light-framed {
    border-color: #d0d0d0;
    padding: 0;
}

.x-panel-light-framed.x-masked {
    border-color: #e8e8e8;
}

.x-panel-header-light-framed {
    font-size: 16px;
    border: 2px solid #d0d0d0;
}

.x-panel-header-light-framed .x-tool-tool-el {
    color: #919191;
}

.x-panel-header-light-framed .x-tool-tool-el {
    background-color: #fff;
}

.x-panel-header-light-framed-horizontal {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-light-framed-horizontal .x-panel-header-light-framed-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-panel-header-light-framed-horizontal.x-header-noborder {
    padding: 12px 16px 10px 16px;
}

.x-panel-header-light-framed-horizontal.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-top: -12px;
    margin-bottom: -10px;
}

.x-panel-header-light-framed-vertical {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-light-framed-vertical .x-panel-header-light-framed-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-panel-header-light-framed-vertical.x-header-noborder {
    padding: 16px 12px 16px 10px;
}

.x-panel-header-light-framed-vertical.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-right: -12px;
    margin-left: -10px;
}

.x-rtl.x-panel-header-light-framed-vertical {
    padding: 14px 10px 14px 10px;
}

.x-rtl.x-panel-header-light-framed-vertical .x-panel-header-light-framed-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-rtl.x-panel-header-light-framed-vertical.x-header-noborder {
    padding: 16px 10px 16px 12px;
}

.x-rtl.x-panel-header-light-framed-vertical.x-header-noborder .x-panel-header-light-framed-tab-bar {
    margin-left: -12px;
    margin-right: -10px;
}

.x-panel-header-title-light-framed {
    color: #606060;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 20px;
}

.x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-light-framed.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-panel-header-title-light-framed > .x-title-text-light-framed {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed > .x-title-icon-light-framed {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #606060;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-light-framed > .x-title-icon-wrap-light-framed > .x-title-icon-light-framed.x-title-glyph {
    color: #606060;
}

.x-panel-body-light-framed {
    background: #fff;
    border-color: #d0d0d0;
    color: #404040;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-light-framed {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-light-framed-mc {
    background-color: #fff;
}

.x-nbr .x-panel-light-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-light-framed-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-0px-0px-0px-0px;
}

.x-panel-light-framed-tl {
    background-position: 0 -4px;
}

.x-panel-light-framed-tr {
    background-position: right -6px;
}

.x-panel-light-framed-bl {
    background-position: 0 -8px;
}

.x-panel-light-framed-br {
    background-position: right -10px;
}

.x-panel-light-framed-ml {
    background-position: 0 top;
}

.x-panel-light-framed-mr {
    background-position: right top;
}

.x-panel-light-framed-tc {
    background-position: 0 0;
}

.x-panel-light-framed-bc {
    background-position: 0 -2px;
}

.x-panel-light-framed-tr,
.x-panel-light-framed-br,
.x-panel-light-framed-mr {
    padding-right: 2px;
}

.x-panel-light-framed-tl,
.x-panel-light-framed-bl,
.x-panel-light-framed-ml {
    padding-left: 2px;
}

.x-panel-light-framed-tc {
    height: 2px;
}

.x-panel-light-framed-bc {
    height: 2px;
}

.x-panel-light-framed-tl,
.x-panel-light-framed-bl,
.x-panel-light-framed-tr,
.x-panel-light-framed-br,
.x-panel-light-framed-tc,
.x-panel-light-framed-bc,
.x-panel-light-framed-ml,
.x-panel-light-framed-mr {
    background-image: url(images/panel/panel-light-framed-corners.gif);
}

.x-panel-light-framed-ml,
.x-panel-light-framed-mr {
    background-image: url(images/panel/panel-light-framed-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-light-framed-mc {
    padding: 0px 0px 0px 0px;
}

.x-panel-header-light-framed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-top-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-top-frameInfo {
    font-family: dh-2-2-0-2-2-2-0-2-10-14-10-14;
}

.x-panel-header-light-framed-top-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-top-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-top-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-top-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-top-ml {
    background-position: 0 top;
}

.x-panel-header-light-framed-top-mr {
    background-position: right top;
}

.x-panel-header-light-framed-top-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-top-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-top-tr,
.x-panel-header-light-framed-top-br,
.x-panel-header-light-framed-top-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-top-tl,
.x-panel-header-light-framed-top-bl,
.x-panel-header-light-framed-top-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-top-tc {
    height: 2px;
}

.x-panel-header-light-framed-top-bc {
    height: 0;
}

.x-panel-header-light-framed-top-tl,
.x-panel-header-light-framed-top-bl,
.x-panel-header-light-framed-top-tr,
.x-panel-header-light-framed-top-br,
.x-panel-header-light-framed-top-tc,
.x-panel-header-light-framed-top-bc,
.x-panel-header-light-framed-top-ml,
.x-panel-header-light-framed-top-mr {
    background-image: url(images/panel-header/panel-header-light-framed-top-corners.gif);
}

.x-panel-header-light-framed-top-ml,
.x-panel-header-light-framed-top-mr {
    background-image: url(images/panel-header/panel-header-light-framed-top-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-light-framed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 2px 2px 0;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-right {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-right-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-right-frameInfo {
    font-family: dh-2-2-2-0-2-2-2-0-14-10-14-10;
}

.x-panel-header-light-framed-right-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-right-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-right-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-right-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-right-ml {
    background-position: right 0;
}

.x-panel-header-light-framed-right-mr {
    background-position: right 0;
}

.x-panel-header-light-framed-right-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-right-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-right-tr,
.x-panel-header-light-framed-right-br,
.x-panel-header-light-framed-right-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-right-tl,
.x-panel-header-light-framed-right-bl,
.x-panel-header-light-framed-right-ml {
    padding-left: 0;
}

.x-panel-header-light-framed-right-tc {
    height: 2px;
}

.x-panel-header-light-framed-right-bc {
    height: 2px;
}

.x-panel-header-light-framed-right-tl,
.x-panel-header-light-framed-right-bl,
.x-panel-header-light-framed-right-tr,
.x-panel-header-light-framed-right-br,
.x-panel-header-light-framed-right-tc,
.x-panel-header-light-framed-right-bc,
.x-panel-header-light-framed-right-ml,
.x-panel-header-light-framed-right-mr {
    background-image: url(images/panel-header/panel-header-light-framed-right-corners.gif);
}

.x-rtl.x-panel-header-light-framed-right-tl,
.x-rtl.x-panel-header-light-framed-right-ml,
.x-rtl.x-panel-header-light-framed-right-bl,
.x-rtl.x-panel-header-light-framed-right-tr,
.x-rtl.x-panel-header-light-framed-right-mr,
.x-rtl.x-panel-header-light-framed-right-br {
    background-image: url(images/panel-header/panel-header-light-framed-right-corners-rtl.gif);
}

.x-panel-header-light-framed-right-ml,
.x-panel-header-light-framed-right-mr {
    background-image: url(images/panel-header/panel-header-light-framed-right-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-light-framed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-bottom-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-bottom-frameInfo {
    font-family: dh-0-2-2-2-0-2-2-2-10-14-10-14;
}

.x-panel-header-light-framed-bottom-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-bottom-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-bottom-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-bottom-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-light-framed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-light-framed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-bottom-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-bottom-tr,
.x-panel-header-light-framed-bottom-br,
.x-panel-header-light-framed-bottom-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-bottom-tl,
.x-panel-header-light-framed-bottom-bl,
.x-panel-header-light-framed-bottom-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-bottom-tc {
    height: 0;
}

.x-panel-header-light-framed-bottom-bc {
    height: 2px;
}

.x-panel-header-light-framed-bottom-tl,
.x-panel-header-light-framed-bottom-bl,
.x-panel-header-light-framed-bottom-tr,
.x-panel-header-light-framed-bottom-br,
.x-panel-header-light-framed-bottom-tc,
.x-panel-header-light-framed-bottom-bc,
.x-panel-header-light-framed-bottom-ml,
.x-panel-header-light-framed-bottom-mr {
    background-image: url(images/panel-header/panel-header-light-framed-bottom-corners.gif);
}

.x-panel-header-light-framed-bottom-ml,
.x-panel-header-light-framed-bottom-mr {
    background-image: url(images/panel-header/panel-header-light-framed-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-light-framed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 0 2px 2px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-left {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-left-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-left-frameInfo {
    font-family: dh-2-0-2-2-2-0-2-2-14-10-14-10;
}

.x-panel-header-light-framed-left-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-left-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-left-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-left-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-left-ml {
    background-position: left 0;
}

.x-panel-header-light-framed-left-mr {
    background-position: left 0;
}

.x-panel-header-light-framed-left-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-left-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-left-tr,
.x-panel-header-light-framed-left-br,
.x-panel-header-light-framed-left-mr {
    padding-right: 0;
}

.x-panel-header-light-framed-left-tl,
.x-panel-header-light-framed-left-bl,
.x-panel-header-light-framed-left-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-left-tc {
    height: 2px;
}

.x-panel-header-light-framed-left-bc {
    height: 2px;
}

.x-panel-header-light-framed-left-tl,
.x-panel-header-light-framed-left-bl,
.x-panel-header-light-framed-left-tr,
.x-panel-header-light-framed-left-br,
.x-panel-header-light-framed-left-tc,
.x-panel-header-light-framed-left-bc,
.x-panel-header-light-framed-left-ml,
.x-panel-header-light-framed-left-mr {
    background-image: url(images/panel-header/panel-header-light-framed-left-corners.gif);
}

.x-rtl.x-panel-header-light-framed-left-tl,
.x-rtl.x-panel-header-light-framed-left-ml,
.x-rtl.x-panel-header-light-framed-left-bl,
.x-rtl.x-panel-header-light-framed-left-tr,
.x-rtl.x-panel-header-light-framed-left-mr,
.x-rtl.x-panel-header-light-framed-left-br {
    background-image: url(images/panel-header/panel-header-light-framed-left-corners-rtl.gif);
}

.x-panel-header-light-framed-left-ml,
.x-panel-header-light-framed-left-mr {
    background-image: url(images/panel-header/panel-header-light-framed-left-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-light-framed-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-top-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-top-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-panel-header-light-framed-collapsed-top-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-top-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-collapsed-top-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-collapsed-top-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-collapsed-top-ml {
    background-position: 0 top;
}

.x-panel-header-light-framed-collapsed-top-mr {
    background-position: right top;
}

.x-panel-header-light-framed-collapsed-top-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-top-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-top-tr,
.x-panel-header-light-framed-collapsed-top-br,
.x-panel-header-light-framed-collapsed-top-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-collapsed-top-tl,
.x-panel-header-light-framed-collapsed-top-bl,
.x-panel-header-light-framed-collapsed-top-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-collapsed-top-tc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-top-bc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-top-tl,
.x-panel-header-light-framed-collapsed-top-bl,
.x-panel-header-light-framed-collapsed-top-tr,
.x-panel-header-light-framed-collapsed-top-br,
.x-panel-header-light-framed-collapsed-top-tc,
.x-panel-header-light-framed-collapsed-top-bc,
.x-panel-header-light-framed-collapsed-top-ml,
.x-panel-header-light-framed-collapsed-top-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-top-corners.gif);
}

.x-panel-header-light-framed-collapsed-top-ml,
.x-panel-header-light-framed-collapsed-top-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-top-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-collapsed-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-light-framed-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-collapsed-right {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-right-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-right-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-panel-header-light-framed-collapsed-right-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-right-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-collapsed-right-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-collapsed-right-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-collapsed-right-ml {
    background-position: right 0;
}

.x-panel-header-light-framed-collapsed-right-mr {
    background-position: right 0;
}

.x-panel-header-light-framed-collapsed-right-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-right-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-right-tr,
.x-panel-header-light-framed-collapsed-right-br,
.x-panel-header-light-framed-collapsed-right-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-collapsed-right-tl,
.x-panel-header-light-framed-collapsed-right-bl,
.x-panel-header-light-framed-collapsed-right-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-collapsed-right-tc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-right-bc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-right-tl,
.x-panel-header-light-framed-collapsed-right-bl,
.x-panel-header-light-framed-collapsed-right-tr,
.x-panel-header-light-framed-collapsed-right-br,
.x-panel-header-light-framed-collapsed-right-tc,
.x-panel-header-light-framed-collapsed-right-bc,
.x-panel-header-light-framed-collapsed-right-ml,
.x-panel-header-light-framed-collapsed-right-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-right-corners.gif);
}

.x-rtl.x-panel-header-light-framed-collapsed-right-tl,
.x-rtl.x-panel-header-light-framed-collapsed-right-ml,
.x-rtl.x-panel-header-light-framed-collapsed-right-bl,
.x-rtl.x-panel-header-light-framed-collapsed-right-tr,
.x-rtl.x-panel-header-light-framed-collapsed-right-mr,
.x-rtl.x-panel-header-light-framed-collapsed-right-br {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-right-corners-rtl.gif);
}

.x-panel-header-light-framed-collapsed-right-ml,
.x-panel-header-light-framed-collapsed-right-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-right-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-collapsed-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-light-framed-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-bottom-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-bottom-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-panel-header-light-framed-collapsed-bottom-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-bottom-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-collapsed-bottom-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-collapsed-bottom-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-collapsed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-light-framed-collapsed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-light-framed-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-bottom-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-bottom-tr,
.x-panel-header-light-framed-collapsed-bottom-br,
.x-panel-header-light-framed-collapsed-bottom-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-collapsed-bottom-tl,
.x-panel-header-light-framed-collapsed-bottom-bl,
.x-panel-header-light-framed-collapsed-bottom-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-collapsed-bottom-tc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-bottom-bc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-bottom-tl,
.x-panel-header-light-framed-collapsed-bottom-bl,
.x-panel-header-light-framed-collapsed-bottom-tr,
.x-panel-header-light-framed-collapsed-bottom-br,
.x-panel-header-light-framed-collapsed-bottom-tc,
.x-panel-header-light-framed-collapsed-bottom-bc,
.x-panel-header-light-framed-collapsed-bottom-ml,
.x-panel-header-light-framed-collapsed-bottom-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-bottom-corners.gif);
}

.x-panel-header-light-framed-collapsed-bottom-ml,
.x-panel-header-light-framed-collapsed-bottom-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-collapsed-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-light-framed-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-rtl.x-panel-header-light-framed-collapsed-left {
    background-image: none;
    background-color: #fff;
}

.x-panel-header-light-framed-collapsed-left-mc {
    background-color: #fff;
}

.x-nbr .x-panel-header-light-framed-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-light-framed-collapsed-left-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-panel-header-light-framed-collapsed-left-tl {
    background-position: 0 -4px;
}

.x-panel-header-light-framed-collapsed-left-tr {
    background-position: right -6px;
}

.x-panel-header-light-framed-collapsed-left-bl {
    background-position: 0 -8px;
}

.x-panel-header-light-framed-collapsed-left-br {
    background-position: right -10px;
}

.x-panel-header-light-framed-collapsed-left-ml {
    background-position: left 0;
}

.x-panel-header-light-framed-collapsed-left-mr {
    background-position: left 0;
}

.x-panel-header-light-framed-collapsed-left-tc {
    background-position: 0 0;
}

.x-panel-header-light-framed-collapsed-left-bc {
    background-position: 0 -2px;
}

.x-panel-header-light-framed-collapsed-left-tr,
.x-panel-header-light-framed-collapsed-left-br,
.x-panel-header-light-framed-collapsed-left-mr {
    padding-right: 2px;
}

.x-panel-header-light-framed-collapsed-left-tl,
.x-panel-header-light-framed-collapsed-left-bl,
.x-panel-header-light-framed-collapsed-left-ml {
    padding-left: 2px;
}

.x-panel-header-light-framed-collapsed-left-tc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-left-bc {
    height: 2px;
}

.x-panel-header-light-framed-collapsed-left-tl,
.x-panel-header-light-framed-collapsed-left-bl,
.x-panel-header-light-framed-collapsed-left-tr,
.x-panel-header-light-framed-collapsed-left-br,
.x-panel-header-light-framed-collapsed-left-tc,
.x-panel-header-light-framed-collapsed-left-bc,
.x-panel-header-light-framed-collapsed-left-ml,
.x-panel-header-light-framed-collapsed-left-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-left-corners.gif);
}

.x-rtl.x-panel-header-light-framed-collapsed-left-tl,
.x-rtl.x-panel-header-light-framed-collapsed-left-ml,
.x-rtl.x-panel-header-light-framed-collapsed-left-bl,
.x-rtl.x-panel-header-light-framed-collapsed-left-tr,
.x-rtl.x-panel-header-light-framed-collapsed-left-mr,
.x-rtl.x-panel-header-light-framed-collapsed-left-br {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-left-corners-rtl.gif);
}

.x-panel-header-light-framed-collapsed-left-ml,
.x-panel-header-light-framed-collapsed-left-mr {
    background-image: url(images/panel-header/panel-header-light-framed-collapsed-left-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-light-framed-collapsed-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel .x-panel-header-light-framed-top {
    border-bottom-width: 2px !important;
}

.x-panel .x-panel-header-light-framed-right {
    border-left-width: 2px !important;
}

.x-panel .x-panel-header-light-framed-bottom {
    border-top-width: 2px !important;
}

.x-panel .x-panel-header-light-framed-left {
    border-right-width: 2px !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-top {
    border-bottom-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-right {
    border-left-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-bottom {
    border-top-width: 0 !important;
}

.x-nbr .x-panel-header-light-framed-collapsed-left {
    border-right-width: 0 !important;
}

.x-panel-header-light-framed-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-framed-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-framed-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-light-framed-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-light-framed-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-framed-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-light-framed-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-light-framed-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-light-framed .x-tool-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-light-framed .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-rtl.x-panel-header-light-framed-collapsed-border-right {
    border-right-width: 2px !important;
}

.x-rtl.x-panel-header-light-framed-collapsed-border-left {
    border-left-width: 2px !important;
}

.x-panel-light-framed-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-light-framed-outer-border-l {
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-b {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
}

.x-panel-light-framed-outer-border-bl {
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-r {
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
}

.x-panel-light-framed-outer-border-rl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-rb {
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
}

.x-panel-light-framed-outer-border-rbl {
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-t {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
}

.x-panel-light-framed-outer-border-tl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-tb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
}

.x-panel-light-framed-outer-border-tbl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-tr {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
}

.x-panel-light-framed-outer-border-trl {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
    border-left-color: #d0d0d0 !important;
    border-left-width: 2px !important;
}

.x-panel-light-framed-outer-border-trb {
    border-top-color: #d0d0d0 !important;
    border-top-width: 2px !important;
    border-right-color: #d0d0d0 !important;
    border-right-width: 2px !important;
    border-bottom-color: #d0d0d0 !important;
    border-bottom-width: 2px !important;
}

.x-panel-light-framed-outer-border-trbl {
    border-color: #d0d0d0 !important;
    border-width: 2px !important;
}

.x-form-file-wrap .x-form-trigger-wrap {
    border: 0;
}

.x-form-file-wrap .x-form-trigger-wrap .x-form-text {
    border: 1px solid;
    border-color: #d0d0d0;
    height: 32px;
}

.x-form-file-wrap .x-form-trigger-wrap .x-form-text.x-form-invalid-field {
    border-color: #cf4c35;
}

.x-html-editor-container {
    border: 1px solid;
    border-color: #d0d0d0;
}

.x-grid-header-ct {
    border: 1px solid #d0d0d0;
}

.x-column-header-trigger {
    background-color: #eaeff4;
    border-left: 1px solid #d0d0d0;
}

.x-rtl.x-column-header-trigger {
    border-right: 1px solid #d0d0d0;
    border-left: 0;
}

.x-column-header-last {
    border-right-width: 0;
}

.x-column-header-last .x-column-header-over .x-column-header-trigger {
    border-right: 1px solid #d0d0d0;
}

.x-rtl.x-column-header-last {
    border-left-width: 0;
}

.x-rtl.x-column-header-last .x-column-header-over .x-column-header-trigger {
    border-left: 1px solid #d0d0d0;
}

.x-group-header {
    border-right-width: 0;
}

.x-group-header:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #d0d0d0;
    position: absolute;
    right: 0;
    top: 0;
}

.x-rtl.x-group-header {
    border-left-width: 0;
}

.x-rtl.x-group-header:before {
    content: '';
    width: 1px;
    height: 100%;
    background: #d0d0d0;
    position: absolute;
    left: 0;
    top: 0;
}

.x-rtl.x-group-header:after {
    width: 0;
}

.x-resizable-handle {
    background-repeat: no-repeat;
}

.x-resizable-handle-east-over,
.x-resizable-handle-west-over {
    background-position: center;
}

.x-resizable-handle-south-over,
.x-resizable-handle-north-over {
    background-position: center;
}

.x-resizable-handle-southeast-over {
    background-position: -2px -2px;
}

.x-resizable-handle-northwest-over {
    background-position: 2px 2px;
}

.x-resizable-handle-northeast-over {
    background-position: -2px 2px;
}

.x-resizable-handle-southwest-over {
    background-position: 2px -2px;
}

.x-resizable-pinned .x-resizable-handle-east,
.x-resizable-pinned .x-resizable-handle-west {
    background-position: center;
}

.x-resizable-pinned .x-resizable-handle-south,
.x-resizable-pinned .x-resizable-handle-north {
    background-position: center;
}

.x-resizable-pinned .x-resizable-handle-southeast {
    background-position: -2px -2px;
}

.x-resizable-pinned .x-resizable-handle-northwest {
    background-position: 2px 2px;
}

.x-resizable-pinned .x-resizable-handle-northeast {
    background-position: -2px 2px;
}

.x-resizable-pinned .x-resizable-handle-southwest {
    background-position: 2px -2px;
}

.fa-spin {
    animation: fa-spin 2s infinite linear;
}

.fa-pulse {
    animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {

    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.fa-border {
    border: solid 0.08em #eee;
    border-radius: 0.1em;
    padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
    float: left;
}

.fa-pull-right {
    float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
    margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
    margin-left: 0.3em;
}

.fa,
.fas,
.far,
.fal,
.fab {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
}

.fa-fw {
    text-align: center;
    width: 1.25em;
}

.fa-500px:before {
    content: '\f26e' !important;
}

.fa-accessible-icon:before {
    content: '\f368' !important;
}

.fa-accusoft:before {
    content: '\f369' !important;
}

.fa-acquisitions-incorporated:before {
    content: '\f6af' !important;
}

.fa-ad:before {
    content: '\f641' !important;
}

.fa-address-book:before {
    content: '\f2b9' !important;
}

.fa-address-card:before {
    content: '\f2bb' !important;
}

.fa-adjust:before {
    content: '\f042' !important;
}

.fa-adn:before {
    content: '\f170' !important;
}

.fa-adobe:before {
    content: '\f778' !important;
}

.fa-adversal:before {
    content: '\f36a' !important;
}

.fa-affiliatetheme:before {
    content: '\f36b' !important;
}

.fa-air-freshener:before {
    content: '\f5d0' !important;
}

.fa-algolia:before {
    content: '\f36c' !important;
}

.fa-align-center:before {
    content: '\f037' !important;
}

.fa-align-justify:before {
    content: '\f039' !important;
}

.fa-align-left:before {
    content: '\f036' !important;
}

.fa-align-right:before {
    content: '\f038' !important;
}

.fa-alipay:before {
    content: '\f642' !important;
}

.fa-allergies:before {
    content: '\f461' !important;
}

.fa-amazon:before {
    content: '\f270' !important;
}

.fa-amazon-pay:before {
    content: '\f42c' !important;
}

.fa-ambulance:before {
    content: '\f0f9' !important;
}

.fa-american-sign-language-interpreting:before {
    content: '\f2a3' !important;
}

.fa-amilia:before {
    content: '\f36d' !important;
}

.fa-anchor:before {
    content: '\f13d' !important;
}

.fa-android:before {
    content: '\f17b' !important;
}

.fa-angellist:before {
    content: '\f209' !important;
}

.fa-angle-double-down:before {
    content: '\f103' !important;
}

.fa-angle-double-left:before {
    content: '\f100' !important;
}

.fa-angle-double-right:before {
    content: '\f101' !important;
}

.fa-angle-double-up:before {
    content: '\f102' !important;
}

.fa-angle-down:before {
    content: '\f107' !important;
}

.fa-angle-left:before {
    content: '\f104' !important;
}

.fa-angle-right:before {
    content: '\f105' !important;
}

.fa-angle-up:before {
    content: '\f106' !important;
}

.fa-angry:before {
    content: '\f556' !important;
}

.fa-angrycreative:before {
    content: '\f36e' !important;
}

.fa-angular:before {
    content: '\f420' !important;
}

.fa-ankh:before {
    content: '\f644' !important;
}

.fa-app-store:before {
    content: '\f36f' !important;
}

.fa-app-store-ios:before {
    content: '\f370' !important;
}

.fa-apper:before {
    content: '\f371' !important;
}

.fa-apple:before {
    content: '\f179' !important;
}

.fa-apple-alt:before {
    content: '\f5d1' !important;
}

.fa-apple-pay:before {
    content: '\f415' !important;
}

.fa-archive:before {
    content: '\f187' !important;
}

.fa-archway:before {
    content: '\f557' !important;
}

.fa-arrow-alt-circle-down:before {
    content: '\f358' !important;
}

.fa-arrow-alt-circle-left:before {
    content: '\f359' !important;
}

.fa-arrow-alt-circle-right:before {
    content: '\f35a' !important;
}

.fa-arrow-alt-circle-up:before {
    content: '\f35b' !important;
}

.fa-arrow-circle-down:before {
    content: '\f0ab' !important;
}

.fa-arrow-circle-left:before {
    content: '\f0a8' !important;
}

.fa-arrow-circle-right:before {
    content: '\f0a9' !important;
}

.fa-arrow-circle-up:before {
    content: '\f0aa' !important;
}

.fa-arrow-down:before {
    content: '\f063' !important;
}

.fa-arrow-left:before {
    content: '\f060' !important;
}

.fa-arrow-right:before {
    content: '\f061' !important;
}

.fa-arrow-up:before {
    content: '\f062' !important;
}

.fa-arrows-alt:before {
    content: '\f31e' !important;
}

.fa-arrows-alt-h:before {
    content: '\f337' !important;
}

.fa-arrows-alt-v:before {
    content: '\f338' !important;
}

.fa-artstation:before {
    content: '\f77a' !important;
}

.fa-assistive-listening-systems:before {
    content: '\f2a2' !important;
}

.fa-asterisk:before {
    content: '\f069' !important;
}

.fa-asymmetrik:before {
    content: '\f372' !important;
}

.fa-at:before {
    content: '\f1fa' !important;
}

.fa-atlas:before {
    content: '\f558' !important;
}

.fa-atlassian:before {
    content: '\f77b' !important;
}

.fa-atom:before {
    content: '\f5d2' !important;
}

.fa-audible:before {
    content: '\f373' !important;
}

.fa-audio-description:before {
    content: '\f29e' !important;
}

.fa-autoprefixer:before {
    content: '\f41c' !important;
}

.fa-avianex:before {
    content: '\f374' !important;
}

.fa-aviato:before {
    content: '\f421' !important;
}

.fa-award:before {
    content: '\f559' !important;
}

.fa-aws:before {
    content: '\f375' !important;
}

.fa-baby:before {
    content: '\f77c' !important;
}

.fa-baby-carriage:before {
    content: '\f77d' !important;
}

.fa-backspace:before {
    content: '\f55a' !important;
}

.fa-backward:before {
    content: '\f04a' !important;
}

.fa-balance-scale:before {
    content: '\f24e' !important;
}

.fa-ban:before {
    content: '\f05e' !important;
}

.fa-band-aid:before {
    content: '\f462' !important;
}

.fa-bandcamp:before {
    content: '\f2d5' !important;
}

.fa-barcode:before {
    content: '\f02a' !important;
}

.fa-bars:before {
    content: '\f0c9' !important;
}

.fa-baseball-ball:before {
    content: '\f433' !important;
}

.fa-basketball-ball:before {
    content: '\f434' !important;
}

.fa-bath:before {
    content: '\f2cd' !important;
}

.fa-battery-empty:before {
    content: '\f244' !important;
}

.fa-battery-full:before {
    content: '\f240' !important;
}

.fa-battery-half:before {
    content: '\f242' !important;
}

.fa-battery-quarter:before {
    content: '\f243' !important;
}

.fa-battery-three-quarters:before {
    content: '\f241' !important;
}

.fa-bed:before {
    content: '\f236' !important;
}

.fa-beer:before {
    content: '\f0fc' !important;
}

.fa-behance:before {
    content: '\f1b4' !important;
}

.fa-behance-square:before {
    content: '\f1b5' !important;
}

.fa-bell:before {
    content: '\f0f3' !important;
}

.fa-bell-slash:before {
    content: '\f1f6' !important;
}

.fa-bezier-curve:before {
    content: '\f55b' !important;
}

.fa-bible:before {
    content: '\f647' !important;
}

.fa-bicycle:before {
    content: '\f206' !important;
}

.fa-bimobject:before {
    content: '\f378' !important;
}

.fa-binoculars:before {
    content: '\f1e5' !important;
}

.fa-biohazard:before {
    content: '\f780' !important;
}

.fa-birthday-cake:before {
    content: '\f1fd' !important;
}

.fa-bitbucket:before {
    content: '\f171' !important;
}

.fa-bitcoin:before {
    content: '\f15a' !important;
}

.fa-bity:before {
    content: '\f37a' !important;
}

.fa-black-tie:before {
    content: '\f27e' !important;
}

.fa-blackberry:before {
    content: '\f37b' !important;
}

.fa-blender:before {
    content: '\f517' !important;
}

.fa-blender-phone:before {
    content: '\f6b6' !important;
}

.fa-blind:before {
    content: '\f29d' !important;
}

.fa-blog:before {
    content: '\f781' !important;
}

.fa-blogger:before {
    content: '\f37c' !important;
}

.fa-blogger-b:before {
    content: '\f37d' !important;
}

.fa-bluetooth:before {
    content: '\f293' !important;
}

.fa-bluetooth-b:before {
    content: '\f294' !important;
}

.fa-bold:before {
    content: '\f032' !important;
}

.fa-bolt:before {
    content: '\f0e7' !important;
}

.fa-bomb:before {
    content: '\f1e2' !important;
}

.fa-bone:before {
    content: '\f5d7' !important;
}

.fa-bong:before {
    content: '\f55c' !important;
}

.fa-book:before {
    content: '\f02d' !important;
}

.fa-book-dead:before {
    content: '\f6b7' !important;
}

.fa-book-open:before {
    content: '\f518' !important;
}

.fa-book-reader:before {
    content: '\f5da' !important;
}

.fa-bookmark:before {
    content: '\f02e' !important;
}

.fa-bowling-ball:before {
    content: '\f436' !important;
}

.fa-box:before {
    content: '\f466' !important;
}

.fa-box-open:before {
    content: '\f49e' !important;
}

.fa-boxes:before {
    content: '\f468' !important;
}

.fa-braille:before {
    content: '\f2a1' !important;
}

.fa-brain:before {
    content: '\f5dc' !important;
}

.fa-briefcase:before {
    content: '\f0b1' !important;
}

.fa-briefcase-medical:before {
    content: '\f469' !important;
}

.fa-broadcast-tower:before {
    content: '\f519' !important;
}

.fa-broom:before {
    content: '\f51a' !important;
}

.fa-brush:before {
    content: '\f55d' !important;
}

.fa-btc:before {
    content: '\f15a' !important;
}

.fa-bug:before {
    content: '\f188' !important;
}

.fa-building:before {
    content: '\f1ad' !important;
}

.fa-bullhorn:before {
    content: '\f0a1' !important;
}

.fa-bullseye:before {
    content: '\f140' !important;
}

.fa-burn:before {
    content: '\f46a' !important;
}

.fa-buromobelexperte:before {
    content: '\f37f' !important;
}

.fa-bus:before {
    content: '\f207' !important;
}

.fa-bus-alt:before {
    content: '\f55e' !important;
}

.fa-business-time:before {
    content: '\f64a' !important;
}

.fa-buysellads:before {
    content: '\f20d' !important;
}

.fa-calculator:before {
    content: '\f1ec' !important;
}

.fa-calendar:before {
    content: '\f073' !important;
}

.fa-calendar-alt:before {
    content: '\f073' !important;
}

.fa-calendar-check:before {
    content: '\f274' !important;
}

.fa-calendar-day:before {
    content: '\f783' !important;
}

.fa-calendar-minus:before {
    content: '\f272' !important;
}

.fa-calendar-plus:before {
    content: '\f271' !important;
}

.fa-calendar-times:before {
    content: '\f273' !important;
}

.fa-calendar-week:before {
    content: '\f784' !important;
}

.fa-camera:before {
    content: '\f030' !important;
}

.fa-camera-retro:before {
    content: '\f083' !important;
}

.fa-campground:before {
    content: '\f6bb' !important;
}

.fa-canadian-maple-leaf:before {
    content: '\f785' !important;
}

.fa-candy-cane:before {
    content: '\f786' !important;
}

.fa-cannabis:before {
    content: '\f55f' !important;
}

.fa-capsules:before {
    content: '\f46b' !important;
}

.fa-car:before {
    content: '\f1b9' !important;
}

.fa-car-alt:before {
    content: '\f5de' !important;
}

.fa-car-battery:before {
    content: '\f5df' !important;
}

.fa-car-crash:before {
    content: '\f5e1' !important;
}

.fa-car-side:before {
    content: '\f5e4' !important;
}

.fa-caret-down:before {
    content: '\f0d7' !important;
}

.fa-caret-left:before {
    content: '\f0d9' !important;
}

.fa-caret-right:before {
    content: '\f0da' !important;
}

.fa-caret-square-down:before {
    content: '\f150' !important;
}

.fa-caret-square-left:before {
    content: '\f191' !important;
}

.fa-caret-square-right:before {
    content: '\f152' !important;
}

.fa-caret-square-up:before {
    content: '\f151' !important;
}

.fa-caret-up:before {
    content: '\f0d8' !important;
}

.fa-carrot:before {
    content: '\f787' !important;
}

.fa-cart-arrow-down:before {
    content: '\f218' !important;
}

.fa-cart-plus:before {
    content: '\f217' !important;
}

.fa-cash-register:before {
    content: '\f788' !important;
}

.fa-cat:before {
    content: '\f6be' !important;
}

.fa-cc-amazon-pay:before {
    content: '\f42d' !important;
}

.fa-cc-amex:before {
    content: '\f1f3' !important;
}

.fa-cc-apple-pay:before {
    content: '\f416' !important;
}

.fa-cc-diners-club:before {
    content: '\f24c' !important;
}

.fa-cc-discover:before {
    content: '\f1f2' !important;
}

.fa-cc-jcb:before {
    content: '\f24b' !important;
}

.fa-cc-mastercard:before {
    content: '\f1f1' !important;
}

.fa-cc-paypal:before {
    content: '\f1f4' !important;
}

.fa-cc-stripe:before {
    content: '\f1f5' !important;
}

.fa-cc-visa:before {
    content: '\f1f0' !important;
}

.fa-centercode:before {
    content: '\f380' !important;
}

.fa-centos:before {
    content: '\f789' !important;
}

.fa-certificate:before {
    content: '\f0a3' !important;
}

.fa-chair:before {
    content: '\f6c0' !important;
}

.fa-chalkboard:before {
    content: '\f51b' !important;
}

.fa-chalkboard-teacher:before {
    content: '\f51c' !important;
}

.fa-charging-station:before {
    content: '\f5e7' !important;
}

.fa-chart-area:before {
    content: '\f1fe' !important;
}

.fa-chart-bar:before {
    content: '\f080' !important;
}

.fa-chart-line:before {
    content: '\f201' !important;
}

.fa-chart-pie:before {
    content: '\f200' !important;
}

.fa-check:before {
    content: '\f00c' !important;
}

.fa-check-circle:before {
    content: '\f058' !important;
}

.fa-check-double:before {
    content: '\f560' !important;
}

.fa-check-square:before {
    content: '\f14a' !important;
}

.fa-chess:before {
    content: '\f439' !important;
}

.fa-chess-bishop:before {
    content: '\f43a' !important;
}

.fa-chess-board:before {
    content: '\f43c' !important;
}

.fa-chess-king:before {
    content: '\f43f' !important;
}

.fa-chess-knight:before {
    content: '\f441' !important;
}

.fa-chess-pawn:before {
    content: '\f443' !important;
}

.fa-chess-queen:before {
    content: '\f445' !important;
}

.fa-chess-rook:before {
    content: '\f447' !important;
}

.fa-chevron-circle-down:before {
    content: '\f13a' !important;
}

.fa-chevron-circle-left:before {
    content: '\f137' !important;
}

.fa-chevron-circle-right:before {
    content: '\f138' !important;
}

.fa-chevron-circle-up:before {
    content: '\f139' !important;
}

.fa-chevron-down:before {
    content: '\f078' !important;
}

.fa-chevron-left:before {
    content: '\f053' !important;
}

.fa-chevron-right:before {
    content: '\f054' !important;
}

.fa-chevron-up:before {
    content: '\f077' !important;
}

.fa-child:before {
    content: '\f1ae' !important;
}

.fa-chrome:before {
    content: '\f268' !important;
}

.fa-church:before {
    content: '\f51d' !important;
}

.fa-circle:before {
    content: '\f111' !important;
}

.fa-circle-notch:before {
    content: '\f1ce' !important;
}

.fa-city:before {
    content: '\f64f' !important;
}

.fa-clipboard:before {
    content: '\f328' !important;
}

.fa-clipboard-check:before {
    content: '\f46c' !important;
}

.fa-clipboard-list:before {
    content: '\f46d' !important;
}

.fa-clock:before {
    content: '\f017' !important;
}

.fa-clone:before {
    content: '\f24d' !important;
}

.fa-closed-captioning:before {
    content: '\f20a' !important;
}

.fa-cloud:before {
    content: '\f0c2' !important;
}

.fa-cloud-download-alt:before {
    content: '\f381' !important;
}

.fa-cloud-meatball:before {
    content: '\f73b' !important;
}

.fa-cloud-moon:before {
    content: '\f6c3' !important;
}

.fa-cloud-moon-rain:before {
    content: '\f73c' !important;
}

.fa-cloud-rain:before {
    content: '\f73d' !important;
}

.fa-cloud-showers-heavy:before {
    content: '\f740' !important;
}

.fa-cloud-sun:before {
    content: '\f6c4' !important;
}

.fa-cloud-sun-rain:before {
    content: '\f743' !important;
}

.fa-cloud-upload-alt:before {
    content: '\f382' !important;
}

.fa-cloudscale:before {
    content: '\f383' !important;
}

.fa-cloudsmith:before {
    content: '\f384' !important;
}

.fa-cloudversify:before {
    content: '\f385' !important;
}

.fa-cocktail:before {
    content: '\f561' !important;
}

.fa-code:before {
    content: '\f121' !important;
}

.fa-code-branch:before {
    content: '\f126' !important;
}

.fa-codepen:before {
    content: '\f1cb' !important;
}

.fa-codiepie:before {
    content: '\f284' !important;
}

.fa-coffee:before {
    content: '\f0f4' !important;
}

.fa-cog:before {
    content: '\f013' !important;
}

.fa-cogs:before {
    content: '\f085' !important;
}

.fa-coins:before {
    content: '\f51e' !important;
}

.fa-columns:before {
    content: '\f0db' !important;
}

.fa-comment:before {
    content: '\f075' !important;
}

.fa-comment-alt:before {
    content: '\f27a' !important;
}

.fa-comment-dollar:before {
    content: '\f651' !important;
}

.fa-comment-dots:before {
    content: '\f4ad' !important;
}

.fa-comment-slash:before {
    content: '\f4b3' !important;
}

.fa-comments:before {
    content: '\f086' !important;
}

.fa-comments-dollar:before {
    content: '\f653' !important;
}

.fa-compact-disc:before {
    content: '\f51f' !important;
}

.fa-compass:before {
    content: '\f14e' !important;
}

.fa-compress:before {
    content: '\f066' !important;
}

.fa-compress-arrows-alt:before {
    content: '\f78c' !important;
}

.fa-concierge-bell:before {
    content: '\f562' !important;
}

.fa-confluence:before {
    content: '\f78d' !important;
}

.fa-connectdevelop:before {
    content: '\f20e' !important;
}

.fa-contao:before {
    content: '\f26d' !important;
}

.fa-cookie:before {
    content: '\f563' !important;
}

.fa-cookie-bite:before {
    content: '\f564' !important;
}

.fa-copy:before {
    content: '\f0c5' !important;
}

.fa-copyright:before {
    content: '\f1f9' !important;
}

.fa-couch:before {
    content: '\f4b8' !important;
}

.fa-cpanel:before {
    content: '\f388' !important;
}

.fa-creative-commons:before {
    content: '\f25e' !important;
}

.fa-creative-commons-by:before {
    content: '\f4e7' !important;
}

.fa-creative-commons-nc:before {
    content: '\f4e8' !important;
}

.fa-creative-commons-nc-eu:before {
    content: '\f4e9' !important;
}

.fa-creative-commons-nc-jp:before {
    content: '\f4ea' !important;
}

.fa-creative-commons-nd:before {
    content: '\f4eb' !important;
}

.fa-creative-commons-pd:before {
    content: '\f4ec' !important;
}

.fa-creative-commons-pd-alt:before {
    content: '\f4ed' !important;
}

.fa-creative-commons-remix:before {
    content: '\f4ee' !important;
}

.fa-creative-commons-sa:before {
    content: '\f4ef' !important;
}

.fa-creative-commons-sampling:before {
    content: '\f4f0' !important;
}

.fa-creative-commons-sampling-plus:before {
    content: '\f4f1' !important;
}

.fa-creative-commons-share:before {
    content: '\f4f2' !important;
}

.fa-creative-commons-zero:before {
    content: '\f4f3' !important;
}

.fa-credit-card:before {
    content: '\f09d' !important;
}

.fa-critical-role:before {
    content: '\f6c9' !important;
}

.fa-crop:before {
    content: '\f125' !important;
}

.fa-crop-alt:before {
    content: '\f565' !important;
}

.fa-cross:before {
    content: '\f654' !important;
}

.fa-crosshairs:before {
    content: '\f05b' !important;
}

.fa-crow:before {
    content: '\f520' !important;
}

.fa-crown:before {
    content: '\f521' !important;
}

.fa-css3:before {
    content: '\f13c' !important;
}

.fa-css3-alt:before {
    content: '\f38b' !important;
}

.fa-cube:before {
    content: '\f1b2' !important;
}

.fa-cubes:before {
    content: '\f1b3' !important;
}

.fa-cut:before {
    content: '\f0c4' !important;
}

.fa-cuttlefish:before {
    content: '\f38c' !important;
}

.fa-d-and-d:before {
    content: '\f38d' !important;
}

.fa-d-and-d-beyond:before {
    content: '\f6ca' !important;
}

.fa-dashcube:before {
    content: '\f210' !important;
}

.fa-database:before {
    content: '\f1c0' !important;
}

.fa-deaf:before {
    content: '\f2a4' !important;
}

.fa-delicious:before {
    content: '\f1a5' !important;
}

.fa-democrat:before {
    content: '\f747' !important;
}

.fa-deploydog:before {
    content: '\f38e' !important;
}

.fa-deskpro:before {
    content: '\f38f' !important;
}

.fa-desktop:before {
    content: '\f108' !important;
}

.fa-dev:before {
    content: '\f6cc' !important;
}

.fa-deviantart:before {
    content: '\f1bd' !important;
}

.fa-dharmachakra:before {
    content: '\f655' !important;
}

.fa-dhl:before {
    content: '\f790' !important;
}

.fa-diagnoses:before {
    content: '\f470' !important;
}

.fa-diaspora:before {
    content: '\f791' !important;
}

.fa-dice:before {
    content: '\f522' !important;
}

.fa-dice-d20:before {
    content: '\f6cf' !important;
}

.fa-dice-d6:before {
    content: '\f6d1' !important;
}

.fa-dice-five:before {
    content: '\f523' !important;
}

.fa-dice-four:before {
    content: '\f524' !important;
}

.fa-dice-one:before {
    content: '\f525' !important;
}

.fa-dice-six:before {
    content: '\f526' !important;
}

.fa-dice-three:before {
    content: '\f527' !important;
}

.fa-dice-two:before {
    content: '\f528' !important;
}

.fa-digg:before {
    content: '\f1a6' !important;
}

.fa-digital-ocean:before {
    content: '\f391' !important;
}

.fa-digital-tachograph:before {
    content: '\f566' !important;
}

.fa-directions:before {
    content: '\f5eb' !important;
}

.fa-discord:before {
    content: '\f392' !important;
}

.fa-discourse:before {
    content: '\f393' !important;
}

.fa-divide:before {
    content: '\f529' !important;
}

.fa-dizzy:before {
    content: '\f567' !important;
}

.fa-dna:before {
    content: '\f471' !important;
}

.fa-dochub:before {
    content: '\f394' !important;
}

.fa-docker:before {
    content: '\f395' !important;
}

.fa-dog:before {
    content: '\f6d3' !important;
}

.fa-dollar-sign:before {
    content: '\f155' !important;
}

.fa-dolly:before {
    content: '\f472' !important;
}

.fa-dolly-flatbed:before {
    content: '\f474' !important;
}

.fa-donate:before {
    content: '\f4b9' !important;
}

.fa-door-closed:before {
    content: '\f52a' !important;
}

.fa-door-open:before {
    content: '\f52b' !important;
}

.fa-dot-circle:before {
    content: '\f192' !important;
}

.fa-dove:before {
    content: '\f4ba' !important;
}

.fa-download:before {
    content: '\f019' !important;
}

.fa-draft2digital:before {
    content: '\f396' !important;
}

.fa-drafting-compass:before {
    content: '\f568' !important;
}

.fa-dragon:before {
    content: '\f6d5' !important;
}

.fa-draw-polygon:before {
    content: '\f5ee' !important;
}

.fa-dribbble:before {
    content: '\f17d' !important;
}

.fa-dribbble-square:before {
    content: '\f397' !important;
}

.fa-dropbox:before {
    content: '\f16b' !important;
}

.fa-drum:before {
    content: '\f569' !important;
}

.fa-drum-steelpan:before {
    content: '\f56a' !important;
}

.fa-drumstick-bite:before {
    content: '\f6d7' !important;
}

.fa-drupal:before {
    content: '\f1a9' !important;
}

.fa-dumbbell:before {
    content: '\f44b' !important;
}

.fa-dumpster:before {
    content: '\f793' !important;
}

.fa-dumpster-fire:before {
    content: '\f794' !important;
}

.fa-dungeon:before {
    content: '\f6d9' !important;
}

.fa-dyalog:before {
    content: '\f399' !important;
}

.fa-earlybirds:before {
    content: '\f39a' !important;
}

.fa-ebay:before {
    content: '\f4f4' !important;
}

.fa-edge:before {
    content: '\f282' !important;
}

.fa-edit:before {
    content: '\f044' !important;
}

.fa-eject:before {
    content: '\f052' !important;
}

.fa-elementor:before {
    content: '\f430' !important;
}

.fa-ellipsis-h:before {
    content: '\f141' !important;
}

.fa-ellipsis-v:before {
    content: '\f142' !important;
}

.fa-ello:before {
    content: '\f5f1' !important;
}

.fa-ember:before {
    content: '\f423' !important;
}

.fa-empire:before {
    content: '\f1d1' !important;
}

.fa-envelope:before {
    content: '\f0e0' !important;
}

.fa-envelope-open:before {
    content: '\f2b6' !important;
}

.fa-envelope-open-text:before {
    content: '\f658' !important;
}

.fa-envelope-square:before {
    content: '\f199' !important;
}

.fa-envira:before {
    content: '\f299' !important;
}

.fa-equals:before {
    content: '\f52c' !important;
}

.fa-eraser:before {
    content: '\f12d' !important;
}

.fa-erlang:before {
    content: '\f39d' !important;
}

.fa-ethereum:before {
    content: '\f42e' !important;
}

.fa-ethernet:before {
    content: '\f796' !important;
}

.fa-etsy:before {
    content: '\f2d7' !important;
}

.fa-euro-sign:before {
    content: '\f153' !important;
}

.fa-exchange-alt:before {
    content: '\f362' !important;
}

.fa-exclamation:before {
    content: '\f12a' !important;
}

.fa-exclamation-circle:before {
    content: '\f06a' !important;
}

.fa-exclamation-triangle:before {
    content: '\f071' !important;
}

.fa-expand:before {
    content: '\f065' !important;
}

.fa-expand-arrows-alt:before {
    content: '\f31e' !important;
}

.fa-expeditedssl:before {
    content: '\f23e' !important;
}

.fa-external-link-alt:before {
    content: '\f35d' !important;
}

.fa-external-link-square-alt:before {
    content: '\f360' !important;
}

.fa-eye:before {
    content: '\f06e' !important;
}

.fa-eye-dropper:before {
    content: '\f1fb' !important;
}

.fa-eye-slash:before {
    content: '\f070' !important;
}

.fa-facebook:before {
    content: '\f39e' !important;
}

.fa-facebook-f:before {
    content: '\f39e' !important;
}

.fa-facebook-messenger:before {
    content: '\f39f' !important;
}

.fa-facebook-square:before {
    content: '\f082' !important;
}

.fa-fantasy-flight-games:before {
    content: '\f6dc' !important;
}

.fa-fast-backward:before {
    content: '\f049' !important;
}

.fa-fast-forward:before {
    content: '\f050' !important;
}

.fa-fax:before {
    content: '\f1ac' !important;
}

.fa-feather:before {
    content: '\f52d' !important;
}

.fa-feather-alt:before {
    content: '\f56b' !important;
}

.fa-fedex:before {
    content: '\f797' !important;
}

.fa-fedora:before {
    content: '\f798' !important;
}

.fa-female:before {
    content: '\f182' !important;
}

.fa-fighter-jet:before {
    content: '\f0fb' !important;
}

.fa-figma:before {
    content: '\f799' !important;
}

.fa-file:before {
    content: '\f15b' !important;
}

.fa-file-alt:before {
    content: '\f15c' !important;
}

.fa-file-archive:before {
    content: '\f1c6' !important;
}

.fa-file-audio:before {
    content: '\f1c7' !important;
}

.fa-file-code:before {
    content: '\f1c9' !important;
}

.fa-file-contract:before {
    content: '\f56c' !important;
}

.fa-file-csv:before {
    content: '\f6dd' !important;
}

.fa-file-download:before {
    content: '\f56d' !important;
}

.fa-file-excel:before {
    content: '\f1c3' !important;
}

.fa-file-export:before {
    content: '\f56e' !important;
}

.fa-file-image:before {
    content: '\f1c5' !important;
}

.fa-file-import:before {
    content: '\f56f' !important;
}

.fa-file-invoice:before {
    content: '\f570' !important;
}

.fa-file-invoice-dollar:before {
    content: '\f571' !important;
}

.fa-file-medical:before {
    content: '\f477' !important;
}

.fa-file-medical-alt:before {
    content: '\f478' !important;
}

.fa-file-pdf:before {
    content: '\f1c1' !important;
}

.fa-file-powerpoint:before {
    content: '\f1c4' !important;
}

.fa-file-prescription:before {
    content: '\f572' !important;
}

.fa-file-signature:before {
    content: '\f573' !important;
}

.fa-file-upload:before {
    content: '\f574' !important;
}

.fa-file-video:before {
    content: '\f1c8' !important;
}

.fa-file-word:before {
    content: '\f1c2' !important;
}

.fa-fill:before {
    content: '\f575' !important;
}

.fa-fill-drip:before {
    content: '\f576' !important;
}

.fa-film:before {
    content: '\f008' !important;
}

.fa-filter:before {
    content: '\f0b0' !important;
}

.fa-fingerprint:before {
    content: '\f577' !important;
}

.fa-fire:before {
    content: '\f06d' !important;
}

.fa-fire-alt:before {
    content: '\f7e4' !important;
}

.fa-fire-extinguisher:before {
    content: '\f134' !important;
}

.fa-firefox:before {
    content: '\f269' !important;
}

.fa-first-aid:before {
    content: '\f479' !important;
}

.fa-first-order:before {
    content: '\f2b0' !important;
}

.fa-first-order-alt:before {
    content: '\f50a' !important;
}

.fa-firstdraft:before {
    content: '\f3a1' !important;
}

.fa-fish:before {
    content: '\f578' !important;
}

.fa-fist-raised:before {
    content: '\f6de' !important;
}

.fa-flag:before {
    content: '\f024' !important;
}

.fa-flag-checkered:before {
    content: '\f11e' !important;
}

.fa-flag-usa:before {
    content: '\f74d' !important;
}

.fa-flask:before {
    content: '\f0c3' !important;
}

.fa-flickr:before {
    content: '\f16e' !important;
}

.fa-flipboard:before {
    content: '\f44d' !important;
}

.fa-flushed:before {
    content: '\f579' !important;
}

.fa-fly:before {
    content: '\f417' !important;
}

.fa-folder:before {
    content: '\f07b' !important;
}

.fa-folder-minus:before {
    content: '\f65d' !important;
}

.fa-folder-open:before {
    content: '\f07c' !important;
}

.fa-folder-plus:before {
    content: '\f65e' !important;
}

.fa-font:before {
    content: '\f031' !important;
}

.fa-font-awesome:before {
    content: '\f2b4' !important;
}

.fa-font-awesome-alt:before {
    content: '\f35c' !important;
}

.fa-font-awesome-flag:before {
    content: '\f425' !important;
}

.fa-font-awesome-logo-full:before {
    content: '\f4e6' !important;
}

.fa-fonticons:before {
    content: '\f280' !important;
}

.fa-fonticons-fi:before {
    content: '\f3a2' !important;
}

.fa-football-ball:before {
    content: '\f44e' !important;
}

.fa-fort-awesome:before {
    content: '\f286' !important;
}

.fa-fort-awesome-alt:before {
    content: '\f3a3' !important;
}

.fa-forumbee:before {
    content: '\f211' !important;
}

.fa-forward:before {
    content: '\f04e' !important;
}

.fa-foursquare:before {
    content: '\f180' !important;
}

.fa-free-code-camp:before {
    content: '\f2c5' !important;
}

.fa-freebsd:before {
    content: '\f3a4' !important;
}

.fa-frog:before {
    content: '\f52e' !important;
}

.fa-frown:before {
    content: '\f119' !important;
}

.fa-frown-open:before {
    content: '\f57a' !important;
}

.fa-fulcrum:before {
    content: '\f50b' !important;
}

.fa-funnel-dollar:before {
    content: '\f662' !important;
}

.fa-futbol:before {
    content: '\f1e3' !important;
}

.fa-galactic-republic:before {
    content: '\f50c' !important;
}

.fa-galactic-senate:before {
    content: '\f50d' !important;
}

.fa-gamepad:before {
    content: '\f11b' !important;
}

.fa-gas-pump:before {
    content: '\f52f' !important;
}

.fa-gavel:before {
    content: '\f0e3' !important;
}

.fa-gem:before {
    content: '\f3a5' !important;
}

.fa-genderless:before {
    content: '\f22d' !important;
}

.fa-get-pocket:before {
    content: '\f265' !important;
}

.fa-gg:before {
    content: '\f260' !important;
}

.fa-gg-circle:before {
    content: '\f261' !important;
}

.fa-ghost:before {
    content: '\f6e2' !important;
}

.fa-gift:before {
    content: '\f06b' !important;
}

.fa-gifts:before {
    content: '\f79c' !important;
}

.fa-git:before {
    content: '\f1d3' !important;
}

.fa-git-square:before {
    content: '\f1d2' !important;
}

.fa-github:before {
    content: '\f09b' !important;
}

.fa-github-alt:before {
    content: '\f113' !important;
}

.fa-github-square:before {
    content: '\f092' !important;
}

.fa-gitkraken:before {
    content: '\f3a6' !important;
}

.fa-gitlab:before {
    content: '\f296' !important;
}

.fa-gitter:before {
    content: '\f426' !important;
}

.fa-glass-cheers:before {
    content: '\f79f' !important;
}

.fa-glass-martini:before {
    content: '\f000' !important;
}

.fa-glass-martini-alt:before {
    content: '\f57b' !important;
}

.fa-glass-whiskey:before {
    content: '\f7a0' !important;
}

.fa-glasses:before {
    content: '\f530' !important;
}

.fa-glide:before {
    content: '\f2a5' !important;
}

.fa-glide-g:before {
    content: '\f2a6' !important;
}

.fa-globe:before {
    content: '\f0ac' !important;
}

.fa-globe-africa:before {
    content: '\f57c' !important;
}

.fa-globe-americas:before {
    content: '\f57d' !important;
}

.fa-globe-asia:before {
    content: '\f57e' !important;
}

.fa-globe-europe:before {
    content: '\f7a2' !important;
}

.fa-gofore:before {
    content: '\f3a7' !important;
}

.fa-golf-ball:before {
    content: '\f450' !important;
}

.fa-goodreads:before {
    content: '\f3a8' !important;
}

.fa-goodreads-g:before {
    content: '\f3a9' !important;
}

.fa-google:before {
    content: '\f1a0' !important;
}

.fa-google-drive:before {
    content: '\f3aa' !important;
}

.fa-google-play:before {
    content: '\f3ab' !important;
}

.fa-google-plus:before {
    content: '\f0d5' !important;
}

.fa-google-plus-g:before {
    content: '\f0d5' !important;
}

.fa-google-plus-square:before {
    content: '\f0d4' !important;
}

.fa-google-wallet:before {
    content: '\f1ee' !important;
}

.fa-gopuram:before {
    content: '\f664' !important;
}

.fa-graduation-cap:before {
    content: '\f19d' !important;
}

.fa-gratipay:before {
    content: '\f184' !important;
}

.fa-grav:before {
    content: '\f2d6' !important;
}

.fa-greater-than:before {
    content: '\f531' !important;
}

.fa-greater-than-equal:before {
    content: '\f532' !important;
}

.fa-grimace:before {
    content: '\f57f' !important;
}

.fa-grin:before {
    content: '\f580' !important;
}

.fa-grin-alt:before {
    content: '\f581' !important;
}

.fa-grin-beam:before {
    content: '\f582' !important;
}

.fa-grin-beam-sweat:before {
    content: '\f583' !important;
}

.fa-grin-hearts:before {
    content: '\f584' !important;
}

.fa-grin-squint:before {
    content: '\f585' !important;
}

.fa-grin-squint-tears:before {
    content: '\f586' !important;
}

.fa-grin-stars:before {
    content: '\f587' !important;
}

.fa-grin-tears:before {
    content: '\f588' !important;
}

.fa-grin-tongue:before {
    content: '\f589' !important;
}

.fa-grin-tongue-squint:before {
    content: '\f58a' !important;
}

.fa-grin-tongue-wink:before {
    content: '\f58b' !important;
}

.fa-grin-wink:before {
    content: '\f58c' !important;
}

.fa-grip-horizontal:before {
    content: '\f58d' !important;
}

.fa-grip-lines:before {
    content: '\f7a4' !important;
}

.fa-grip-lines-vertical:before {
    content: '\f7a5' !important;
}

.fa-grip-vertical:before {
    content: '\f58e' !important;
}

.fa-gripfire:before {
    content: '\f3ac' !important;
}

.fa-grunt:before {
    content: '\f3ad' !important;
}

.fa-guitar:before {
    content: '\f7a6' !important;
}

.fa-gulp:before {
    content: '\f3ae' !important;
}

.fa-h-square:before {
    content: '\f0fd' !important;
}

.fa-hacker-news:before {
    content: '\f1d4' !important;
}

.fa-hacker-news-square:before {
    content: '\f3af' !important;
}

.fa-hackerrank:before {
    content: '\f5f7' !important;
}

.fa-hammer:before {
    content: '\f6e3' !important;
}

.fa-hamsa:before {
    content: '\f665' !important;
}

.fa-hand-holding:before {
    content: '\f4bd' !important;
}

.fa-hand-holding-heart:before {
    content: '\f4be' !important;
}

.fa-hand-holding-usd:before {
    content: '\f4c0' !important;
}

.fa-hand-lizard:before {
    content: '\f258' !important;
}

.fa-hand-paper:before {
    content: '\f256' !important;
}

.fa-hand-peace:before {
    content: '\f25b' !important;
}

.fa-hand-point-down:before {
    content: '\f0a7' !important;
}

.fa-hand-point-left:before {
    content: '\f0a5' !important;
}

.fa-hand-point-right:before {
    content: '\f0a4' !important;
}

.fa-hand-point-up:before {
    content: '\f0a6' !important;
}

.fa-hand-pointer:before {
    content: '\f25a' !important;
}

.fa-hand-rock:before {
    content: '\f255' !important;
}

.fa-hand-scissors:before {
    content: '\f257' !important;
}

.fa-hand-spock:before {
    content: '\f259' !important;
}

.fa-hands:before {
    content: '\f4c2' !important;
}

.fa-hands-helping:before {
    content: '\f4c4' !important;
}

.fa-handshake:before {
    content: '\f2b5' !important;
}

.fa-hanukiah:before {
    content: '\f6e6' !important;
}

.fa-hashtag:before {
    content: '\f292' !important;
}

.fa-hat-wizard:before {
    content: '\f6e8' !important;
}

.fa-haykal:before {
    content: '\f666' !important;
}

.fa-hdd:before {
    content: '\f0a0' !important;
}

.fa-heading:before {
    content: '\f1dc' !important;
}

.fa-headphones:before {
    content: '\f025' !important;
}

.fa-headphones-alt:before {
    content: '\f58f' !important;
}

.fa-headset:before {
    content: '\f590' !important;
}

.fa-heart:before {
    content: '\f004' !important;
}

.fa-heart-broken:before {
    content: '\f7a9' !important;
}

.fa-heartbeat:before {
    content: '\f21e' !important;
}

.fa-helicopter:before {
    content: '\f533' !important;
}

.fa-highlighter:before {
    content: '\f591' !important;
}

.fa-hiking:before {
    content: '\f6ec' !important;
}

.fa-hippo:before {
    content: '\f6ed' !important;
}

.fa-hips:before {
    content: '\f452' !important;
}

.fa-hire-a-helper:before {
    content: '\f3b0' !important;
}

.fa-history:before {
    content: '\f1da' !important;
}

.fa-hockey-puck:before {
    content: '\f453' !important;
}

.fa-holly-berry:before {
    content: '\f7aa' !important;
}

.fa-home:before {
    content: '\f015' !important;
}

.fa-hooli:before {
    content: '\f427' !important;
}

.fa-hornbill:before {
    content: '\f592' !important;
}

.fa-horse:before {
    content: '\f6f0' !important;
}

.fa-horse-head:before {
    content: '\f7ab' !important;
}

.fa-hospital:before {
    content: '\f0f8' !important;
}

.fa-hospital-alt:before {
    content: '\f47d' !important;
}

.fa-hospital-symbol:before {
    content: '\f47e' !important;
}

.fa-hot-tub:before {
    content: '\f593' !important;
}

.fa-hotel:before {
    content: '\f236' !important;
}

.fa-hotjar:before {
    content: '\f3b1' !important;
}

.fa-hourglass:before {
    content: '\f254' !important;
}

.fa-hourglass-end:before {
    content: '\f253' !important;
}

.fa-hourglass-half:before {
    content: '\f252' !important;
}

.fa-hourglass-start:before {
    content: '\f251' !important;
}

.fa-house-damage:before {
    content: '\f6f1' !important;
}

.fa-houzz:before {
    content: '\f27c' !important;
}

.fa-hryvnia:before {
    content: '\f6f2' !important;
}

.fa-html5:before {
    content: '\f13b' !important;
}

.fa-hubspot:before {
    content: '\f3b2' !important;
}

.fa-i-cursor:before {
    content: '\f246' !important;
}

.fa-icicles:before {
    content: '\f7ad' !important;
}

.fa-id-badge:before {
    content: '\f2c1' !important;
}

.fa-id-card:before {
    content: '\f2c2' !important;
}

.fa-id-card-alt:before {
    content: '\f47f' !important;
}

.fa-igloo:before {
    content: '\f7ae' !important;
}

.fa-image:before {
    content: '\f03e' !important;
}

.fa-images:before {
    content: '\f302' !important;
}

.fa-imdb:before {
    content: '\f2d8' !important;
}

.fa-inbox:before {
    content: '\f01c' !important;
}

.fa-indent:before {
    content: '\f03c' !important;
}

.fa-industry:before {
    content: '\f275' !important;
}

.fa-infinity:before {
    content: '\f534' !important;
}

.fa-info:before {
    content: '\f129' !important;
}

.fa-info-circle:before {
    content: '\f05a' !important;
}

.fa-instagram:before {
    content: '\f16d' !important;
}

.fa-intercom:before {
    content: '\f7af' !important;
}

.fa-internet-explorer:before {
    content: '\f26b' !important;
}

.fa-invision:before {
    content: '\f7b0' !important;
}

.fa-ioxhost:before {
    content: '\f208' !important;
}

.fa-italic:before {
    content: '\f033' !important;
}

.fa-itunes:before {
    content: '\f3b4' !important;
}

.fa-itunes-note:before {
    content: '\f3b5' !important;
}

.fa-java:before {
    content: '\f4e4' !important;
}

.fa-jedi:before {
    content: '\f669' !important;
}

.fa-jedi-order:before {
    content: '\f50e' !important;
}

.fa-jenkins:before {
    content: '\f3b6' !important;
}

.fa-jira:before {
    content: '\f7b1' !important;
}

.fa-joget:before {
    content: '\f3b7' !important;
}

.fa-joint:before {
    content: '\f595' !important;
}

.fa-joomla:before {
    content: '\f1aa' !important;
}

.fa-journal-whills:before {
    content: '\f66a' !important;
}

.fa-js:before {
    content: '\f3b8' !important;
}

.fa-js-square:before {
    content: '\f3b9' !important;
}

.fa-jsfiddle:before {
    content: '\f1cc' !important;
}

.fa-kaaba:before {
    content: '\f66b' !important;
}

.fa-kaggle:before {
    content: '\f5fa' !important;
}

.fa-key:before {
    content: '\f084' !important;
}

.fa-keybase:before {
    content: '\f4f5' !important;
}

.fa-keyboard:before {
    content: '\f11c' !important;
}

.fa-keycdn:before {
    content: '\f3ba' !important;
}

.fa-khanda:before {
    content: '\f66d' !important;
}

.fa-kickstarter:before {
    content: '\f3bb' !important;
}

.fa-kickstarter-k:before {
    content: '\f3bc' !important;
}

.fa-kiss:before {
    content: '\f596' !important;
}

.fa-kiss-beam:before {
    content: '\f597' !important;
}

.fa-kiss-wink-heart:before {
    content: '\f598' !important;
}

.fa-kiwi-bird:before {
    content: '\f535' !important;
}

.fa-korvue:before {
    content: '\f42f' !important;
}

.fa-landmark:before {
    content: '\f66f' !important;
}

.fa-language:before {
    content: '\f1ab' !important;
}

.fa-laptop:before {
    content: '\f109' !important;
}

.fa-laptop-code:before {
    content: '\f5fc' !important;
}

.fa-laravel:before {
    content: '\f3bd' !important;
}

.fa-lastfm:before {
    content: '\f202' !important;
}

.fa-lastfm-square:before {
    content: '\f203' !important;
}

.fa-laugh:before {
    content: '\f599' !important;
}

.fa-laugh-beam:before {
    content: '\f59a' !important;
}

.fa-laugh-squint:before {
    content: '\f59b' !important;
}

.fa-laugh-wink:before {
    content: '\f59c' !important;
}

.fa-layer-group:before {
    content: '\f5fd' !important;
}

.fa-leaf:before {
    content: '\f06c' !important;
}

.fa-leanpub:before {
    content: '\f212' !important;
}

.fa-lemon:before {
    content: '\f094' !important;
}

.fa-less:before {
    content: '\f41d' !important;
}

.fa-less-than:before {
    content: '\f536' !important;
}

.fa-less-than-equal:before {
    content: '\f537' !important;
}

.fa-level-down-alt:before {
    content: '\f3be' !important;
}

.fa-level-up-alt:before {
    content: '\f3bf' !important;
}

.fa-life-ring:before {
    content: '\f1cd' !important;
}

.fa-lightbulb:before {
    content: '\f0eb' !important;
}

.fa-line:before {
    content: '\f3c0' !important;
}

.fa-link:before {
    content: '\f0c1' !important;
}

.fa-linkedin:before {
    content: '\f0e1' !important;
}

.fa-linkedin-in:before {
    content: '\f0e1' !important;
}

.fa-linode:before {
    content: '\f2b8' !important;
}

.fa-linux:before {
    content: '\f17c' !important;
}

.fa-lira-sign:before {
    content: '\f195' !important;
}

.fa-list:before {
    content: '\f03a' !important;
}

.fa-list-alt:before {
    content: '\f022' !important;
}

.fa-list-ol:before {
    content: '\f0cb' !important;
}

.fa-list-ul:before {
    content: '\f0ca' !important;
}

.fa-location-arrow:before {
    content: '\f124' !important;
}

.fa-lock:before {
    content: '\f023' !important;
}

.fa-lock-open:before {
    content: '\f3c1' !important;
}

.fa-long-arrow-alt-down:before {
    content: '\f309' !important;
}

.fa-long-arrow-alt-left:before {
    content: '\f30a' !important;
}

.fa-long-arrow-alt-right:before {
    content: '\f30b' !important;
}

.fa-long-arrow-alt-up:before {
    content: '\f30c' !important;
}

.fa-low-vision:before {
    content: '\f2a8' !important;
}

.fa-luggage-cart:before {
    content: '\f59d' !important;
}

.fa-lyft:before {
    content: '\f3c3' !important;
}

.fa-magento:before {
    content: '\f3c4' !important;
}

.fa-magic:before {
    content: '\f0d0' !important;
}

.fa-magnet:before {
    content: '\f076' !important;
}

.fa-mail-bulk:before {
    content: '\f674' !important;
}

.fa-mailchimp:before {
    content: '\f59e' !important;
}

.fa-male:before {
    content: '\f183' !important;
}

.fa-mandalorian:before {
    content: '\f50f' !important;
}

.fa-map:before {
    content: '\f279' !important;
}

.fa-map-marked:before {
    content: '\f59f' !important;
}

.fa-map-marked-alt:before {
    content: '\f5a0' !important;
}

.fa-map-marker:before {
    content: '\f3c5' !important;
}

.fa-map-marker-alt:before {
    content: '\f3c5' !important;
}

.fa-map-pin:before {
    content: '\f276' !important;
}

.fa-map-signs:before {
    content: '\f277' !important;
}

.fa-markdown:before {
    content: '\f60f' !important;
}

.fa-marker:before {
    content: '\f5a1' !important;
}

.fa-mars:before {
    content: '\f222' !important;
}

.fa-mars-double:before {
    content: '\f227' !important;
}

.fa-mars-stroke:before {
    content: '\f229' !important;
}

.fa-mars-stroke-h:before {
    content: '\f22b' !important;
}

.fa-mars-stroke-v:before {
    content: '\f22a' !important;
}

.fa-mask:before {
    content: '\f6fa' !important;
}

.fa-mastodon:before {
    content: '\f4f6' !important;
}

.fa-maxcdn:before {
    content: '\f136' !important;
}

.fa-medal:before {
    content: '\f5a2' !important;
}

.fa-medapps:before {
    content: '\f3c6' !important;
}

.fa-medium:before {
    content: '\f23a' !important;
}

.fa-medium-m:before {
    content: '\f3c7' !important;
}

.fa-medkit:before {
    content: '\f0fa' !important;
}

.fa-medrt:before {
    content: '\f3c8' !important;
}

.fa-meetup:before {
    content: '\f2e0' !important;
}

.fa-megaport:before {
    content: '\f5a3' !important;
}

.fa-meh:before {
    content: '\f11a' !important;
}

.fa-meh-blank:before {
    content: '\f5a4' !important;
}

.fa-meh-rolling-eyes:before {
    content: '\f5a5' !important;
}

.fa-memory:before {
    content: '\f538' !important;
}

.fa-mendeley:before {
    content: '\f7b3' !important;
}

.fa-menorah:before {
    content: '\f676' !important;
}

.fa-mercury:before {
    content: '\f223' !important;
}

.fa-meteor:before {
    content: '\f753' !important;
}

.fa-microchip:before {
    content: '\f2db' !important;
}

.fa-microphone:before {
    content: '\f130' !important;
}

.fa-microphone-alt:before {
    content: '\f3c9' !important;
}

.fa-microphone-alt-slash:before {
    content: '\f539' !important;
}

.fa-microphone-slash:before {
    content: '\f131' !important;
}

.fa-microscope:before {
    content: '\f610' !important;
}

.fa-microsoft:before {
    content: '\f3ca' !important;
}

.fa-minus:before {
    content: '\f068' !important;
}

.fa-minus-circle:before {
    content: '\f056' !important;
}

.fa-minus-square:before {
    content: '\f146' !important;
}

.fa-mitten:before {
    content: '\f7b5' !important;
}

.fa-mix:before {
    content: '\f3cb' !important;
}

.fa-mixcloud:before {
    content: '\f289' !important;
}

.fa-mizuni:before {
    content: '\f3cc' !important;
}

.fa-mobile:before {
    content: '\f3cd' !important;
}

.fa-mobile-alt:before {
    content: '\f3cd' !important;
}

.fa-modx:before {
    content: '\f285' !important;
}

.fa-monero:before {
    content: '\f3d0' !important;
}

.fa-money-bill:before {
    content: '\f0d6' !important;
}

.fa-money-bill-alt:before {
    content: '\f3d1' !important;
}

.fa-money-bill-wave:before {
    content: '\f53a' !important;
}

.fa-money-bill-wave-alt:before {
    content: '\f53b' !important;
}

.fa-money-check:before {
    content: '\f53c' !important;
}

.fa-money-check-alt:before {
    content: '\f53d' !important;
}

.fa-monument:before {
    content: '\f5a6' !important;
}

.fa-moon:before {
    content: '\f186' !important;
}

.fa-mortar-pestle:before {
    content: '\f5a7' !important;
}

.fa-mosque:before {
    content: '\f678' !important;
}

.fa-motorcycle:before {
    content: '\f21c' !important;
}

.fa-mountain:before {
    content: '\f6fc' !important;
}

.fa-mouse-pointer:before {
    content: '\f245' !important;
}

.fa-mug-hot:before {
    content: '\f7b6' !important;
}

.fa-music:before {
    content: '\f001' !important;
}

.fa-napster:before {
    content: '\f3d2' !important;
}

.fa-neos:before {
    content: '\f612' !important;
}

.fa-network-wired:before {
    content: '\f6ff' !important;
}

.fa-neuter:before {
    content: '\f22c' !important;
}

.fa-newspaper:before {
    content: '\f1ea' !important;
}

.fa-nimblr:before {
    content: '\f5a8' !important;
}

.fa-nintendo-switch:before {
    content: '\f418' !important;
}

.fa-node:before {
    content: '\f419' !important;
}

.fa-node-js:before {
    content: '\f3d3' !important;
}

.fa-not-equal:before {
    content: '\f53e' !important;
}

.fa-notes-medical:before {
    content: '\f481' !important;
}

.fa-npm:before {
    content: '\f3d4' !important;
}

.fa-ns8:before {
    content: '\f3d5' !important;
}

.fa-nutritionix:before {
    content: '\f3d6' !important;
}

.fa-object-group:before {
    content: '\f247' !important;
}

.fa-object-ungroup:before {
    content: '\f248' !important;
}

.fa-odnoklassniki:before {
    content: '\f263' !important;
}

.fa-odnoklassniki-square:before {
    content: '\f264' !important;
}

.fa-oil-can:before {
    content: '\f613' !important;
}

.fa-old-republic:before {
    content: '\f510' !important;
}

.fa-om:before {
    content: '\f679' !important;
}

.fa-opencart:before {
    content: '\f23d' !important;
}

.fa-openid:before {
    content: '\f19b' !important;
}

.fa-opera:before {
    content: '\f26a' !important;
}

.fa-optin-monster:before {
    content: '\f23c' !important;
}

.fa-osi:before {
    content: '\f41a' !important;
}

.fa-otter:before {
    content: '\f700' !important;
}

.fa-outdent:before {
    content: '\f03b' !important;
}

.fa-page4:before {
    content: '\f3d7' !important;
}

.fa-pagelines:before {
    content: '\f18c' !important;
}

.fa-paint-brush:before {
    content: '\f1fc' !important;
}

.fa-paint-roller:before {
    content: '\f5aa' !important;
}

.fa-palette:before {
    content: '\f53f' !important;
}

.fa-palfed:before {
    content: '\f3d8' !important;
}

.fa-pallet:before {
    content: '\f482' !important;
}

.fa-paper-plane:before {
    content: '\f1d8' !important;
}

.fa-paperclip:before {
    content: '\f0c6' !important;
}

.fa-parachute-box:before {
    content: '\f4cd' !important;
}

.fa-paragraph:before {
    content: '\f1dd' !important;
}

.fa-parking:before {
    content: '\f540' !important;
}

.fa-passport:before {
    content: '\f5ab' !important;
}

.fa-pastafarianism:before {
    content: '\f67b' !important;
}

.fa-paste:before {
    content: '\f328' !important;
}

.fa-patreon:before {
    content: '\f3d9' !important;
}

.fa-pause:before {
    content: '\f04c' !important;
}

.fa-pause-circle:before {
    content: '\f28b' !important;
}

.fa-paw:before {
    content: '\f1b0' !important;
}

.fa-paypal:before {
    content: '\f1ed' !important;
}

.fa-peace:before {
    content: '\f67c' !important;
}

.fa-pen:before {
    content: '\f304' !important;
}

.fa-pen-alt:before {
    content: '\f305' !important;
}

.fa-pen-fancy:before {
    content: '\f5ac' !important;
}

.fa-pen-nib:before {
    content: '\f5ad' !important;
}

.fa-pen-square:before {
    content: '\f14b' !important;
}

.fa-pencil-alt:before {
    content: '\f303' !important;
}

.fa-pencil-ruler:before {
    content: '\f5ae' !important;
}

.fa-penny-arcade:before {
    content: '\f704' !important;
}

.fa-people-carry:before {
    content: '\f4ce' !important;
}

.fa-percent:before {
    content: '\f295' !important;
}

.fa-percentage:before {
    content: '\f541' !important;
}

.fa-periscope:before {
    content: '\f3da' !important;
}

.fa-person-booth:before {
    content: '\f756' !important;
}

.fa-phabricator:before {
    content: '\f3db' !important;
}

.fa-phoenix-framework:before {
    content: '\f3dc' !important;
}

.fa-phoenix-squadron:before {
    content: '\f511' !important;
}

.fa-phone:before {
    content: '\f095' !important;
}

.fa-phone-slash:before {
    content: '\f3dd' !important;
}

.fa-phone-square:before {
    content: '\f098' !important;
}

.fa-phone-volume:before {
    content: '\f2a0' !important;
}

.fa-php:before {
    content: '\f457' !important;
}

.fa-pied-piper:before {
    content: '\f2ae' !important;
}

.fa-pied-piper-alt:before {
    content: '\f1a8' !important;
}

.fa-pied-piper-hat:before {
    content: '\f4e5' !important;
}

.fa-pied-piper-pp:before {
    content: '\f1a7' !important;
}

.fa-piggy-bank:before {
    content: '\f4d3' !important;
}

.fa-pills:before {
    content: '\f484' !important;
}

.fa-pinterest:before {
    content: '\f0d2' !important;
}

.fa-pinterest-p:before {
    content: '\f231' !important;
}

.fa-pinterest-square:before {
    content: '\f0d3' !important;
}

.fa-place-of-worship:before {
    content: '\f67f' !important;
}

.fa-plane:before {
    content: '\f072' !important;
}

.fa-plane-arrival:before {
    content: '\f5af' !important;
}

.fa-plane-departure:before {
    content: '\f5b0' !important;
}

.fa-play:before {
    content: '\f04b' !important;
}

.fa-play-circle:before {
    content: '\f144' !important;
}

.fa-playstation:before {
    content: '\f3df' !important;
}

.fa-plug:before {
    content: '\f1e6' !important;
}

.fa-plus:before {
    content: '\f067' !important;
}

.fa-plus-circle:before {
    content: '\f055' !important;
}

.fa-plus-square:before {
    content: '\f0fe' !important;
}

.fa-podcast:before {
    content: '\f2ce' !important;
}

.fa-poll:before {
    content: '\f681' !important;
}

.fa-poll-h:before {
    content: '\f682' !important;
}

.fa-poo:before {
    content: '\f2fe' !important;
}

.fa-poo-storm:before {
    content: '\f75a' !important;
}

.fa-poop:before {
    content: '\f619' !important;
}

.fa-portrait:before {
    content: '\f3e0' !important;
}

.fa-pound-sign:before {
    content: '\f154' !important;
}

.fa-power-off:before {
    content: '\f011' !important;
}

.fa-pray:before {
    content: '\f683' !important;
}

.fa-praying-hands:before {
    content: '\f684' !important;
}

.fa-prescription:before {
    content: '\f5b1' !important;
}

.fa-prescription-bottle:before {
    content: '\f485' !important;
}

.fa-prescription-bottle-alt:before {
    content: '\f486' !important;
}

.fa-print:before {
    content: '\f02f' !important;
}

.fa-procedures:before {
    content: '\f487' !important;
}

.fa-product-hunt:before {
    content: '\f288' !important;
}

.fa-project-diagram:before {
    content: '\f542' !important;
}

.fa-pushed:before {
    content: '\f3e1' !important;
}

.fa-puzzle-piece:before {
    content: '\f12e' !important;
}

.fa-python:before {
    content: '\f3e2' !important;
}

.fa-qq:before {
    content: '\f1d6' !important;
}

.fa-qrcode:before {
    content: '\f029' !important;
}

.fa-question:before {
    content: '\f128' !important;
}

.fa-question-circle:before {
    content: '\f059' !important;
}

.fa-quidditch:before {
    content: '\f458' !important;
}

.fa-quinscape:before {
    content: '\f459' !important;
}

.fa-quora:before {
    content: '\f2c4' !important;
}

.fa-quote-left:before {
    content: '\f10d' !important;
}

.fa-quote-right:before {
    content: '\f10e' !important;
}

.fa-quran:before {
    content: '\f687' !important;
}

.fa-r-project:before {
    content: '\f4f7' !important;
}

.fa-radiation:before {
    content: '\f7b9' !important;
}

.fa-radiation-alt:before {
    content: '\f7ba' !important;
}

.fa-rainbow:before {
    content: '\f75b' !important;
}

.fa-random:before {
    content: '\f074' !important;
}

.fa-raspberry-pi:before {
    content: '\f7bb' !important;
}

.fa-ravelry:before {
    content: '\f2d9' !important;
}

.fa-react:before {
    content: '\f41b' !important;
}

.fa-reacteurope:before {
    content: '\f75d' !important;
}

.fa-readme:before {
    content: '\f4d5' !important;
}

.fa-rebel:before {
    content: '\f1d0' !important;
}

.fa-receipt:before {
    content: '\f543' !important;
}

.fa-recycle:before {
    content: '\f1b8' !important;
}

.fa-red-river:before {
    content: '\f3e3' !important;
}

.fa-reddit:before {
    content: '\f1a1' !important;
}

.fa-reddit-alien:before {
    content: '\f281' !important;
}

.fa-reddit-square:before {
    content: '\f1a2' !important;
}

.fa-redhat:before {
    content: '\f7bc' !important;
}

.fa-redo:before {
    content: '\f01e' !important;
}

.fa-redo-alt:before {
    content: '\f2f9' !important;
}

.fa-registered:before {
    content: '\f25d' !important;
}

.fa-renren:before {
    content: '\f18b' !important;
}

.fa-reply:before {
    content: '\f3e5' !important;
}

.fa-reply-all:before {
    content: '\f122' !important;
}

.fa-replyd:before {
    content: '\f3e6' !important;
}

.fa-republican:before {
    content: '\f75e' !important;
}

.fa-researchgate:before {
    content: '\f4f8' !important;
}

.fa-resolving:before {
    content: '\f3e7' !important;
}

.fa-restroom:before {
    content: '\f7bd' !important;
}

.fa-retweet:before {
    content: '\f079' !important;
}

.fa-rev:before {
    content: '\f5b2' !important;
}

.fa-ribbon:before {
    content: '\f4d6' !important;
}

.fa-ring:before {
    content: '\f70b' !important;
}

.fa-road:before {
    content: '\f018' !important;
}

.fa-robot:before {
    content: '\f544' !important;
}

.fa-rocket:before {
    content: '\f135' !important;
}

.fa-rocketchat:before {
    content: '\f3e8' !important;
}

.fa-rockrms:before {
    content: '\f3e9' !important;
}

.fa-route:before {
    content: '\f4d7' !important;
}

.fa-rss:before {
    content: '\f09e' !important;
}

.fa-rss-square:before {
    content: '\f143' !important;
}

.fa-ruble-sign:before {
    content: '\f158' !important;
}

.fa-ruler:before {
    content: '\f545' !important;
}

.fa-ruler-combined:before {
    content: '\f546' !important;
}

.fa-ruler-horizontal:before {
    content: '\f547' !important;
}

.fa-ruler-vertical:before {
    content: '\f548' !important;
}

.fa-running:before {
    content: '\f70c' !important;
}

.fa-rupee-sign:before {
    content: '\f156' !important;
}

.fa-sad-cry:before {
    content: '\f5b3' !important;
}

.fa-sad-tear:before {
    content: '\f5b4' !important;
}

.fa-safari:before {
    content: '\f267' !important;
}

.fa-sass:before {
    content: '\f41e' !important;
}

.fa-satellite:before {
    content: '\f7bf' !important;
}

.fa-satellite-dish:before {
    content: '\f7c0' !important;
}

.fa-save:before {
    content: '\f0c7' !important;
}

.fa-schlix:before {
    content: '\f3ea' !important;
}

.fa-school:before {
    content: '\f549' !important;
}

.fa-screwdriver:before {
    content: '\f54a' !important;
}

.fa-scribd:before {
    content: '\f28a' !important;
}

.fa-scroll:before {
    content: '\f70e' !important;
}

.fa-sd-card:before {
    content: '\f7c2' !important;
}

.fa-search:before {
    content: '\f002' !important;
}

.fa-search-dollar:before {
    content: '\f688' !important;
}

.fa-search-location:before {
    content: '\f689' !important;
}

.fa-search-minus:before {
    content: '\f010' !important;
}

.fa-search-plus:before {
    content: '\f00e' !important;
}

.fa-searchengin:before {
    content: '\f3eb' !important;
}

.fa-seedling:before {
    content: '\f4d8' !important;
}

.fa-sellcast:before {
    content: '\f2da' !important;
}

.fa-sellsy:before {
    content: '\f213' !important;
}

.fa-server:before {
    content: '\f233' !important;
}

.fa-servicestack:before {
    content: '\f3ec' !important;
}

.fa-shapes:before {
    content: '\f61f' !important;
}

.fa-share:before {
    content: '\f064' !important;
}

.fa-share-alt:before {
    content: '\f1e0' !important;
}

.fa-share-alt-square:before {
    content: '\f1e1' !important;
}

.fa-share-square:before {
    content: '\f14d' !important;
}

.fa-shekel-sign:before {
    content: '\f20b' !important;
}

.fa-shield-alt:before {
    content: '\f3ed' !important;
}

.fa-ship:before {
    content: '\f21a' !important;
}

.fa-shipping-fast:before {
    content: '\f48b' !important;
}

.fa-shirtsinbulk:before {
    content: '\f214' !important;
}

.fa-shoe-prints:before {
    content: '\f54b' !important;
}

.fa-shopping-bag:before {
    content: '\f290' !important;
}

.fa-shopping-basket:before {
    content: '\f291' !important;
}

.fa-shopping-cart:before {
    content: '\f07a' !important;
}

.fa-shopware:before {
    content: '\f5b5' !important;
}

.fa-shower:before {
    content: '\f2cc' !important;
}

.fa-shuttle-van:before {
    content: '\f5b6' !important;
}

.fa-sign:before {
    content: '\f4d9' !important;
}

.fa-sign-in-alt:before {
    content: '\f2f6' !important;
}

.fa-sign-language:before {
    content: '\f2a7' !important;
}

.fa-sign-out-alt:before {
    content: '\f2f5' !important;
}

.fa-signal:before {
    content: '\f012' !important;
}

.fa-signature:before {
    content: '\f5b7' !important;
}

.fa-sim-card:before {
    content: '\f7c4' !important;
}

.fa-simplybuilt:before {
    content: '\f215' !important;
}

.fa-sistrix:before {
    content: '\f3ee' !important;
}

.fa-sitemap:before {
    content: '\f0e8' !important;
}

.fa-sith:before {
    content: '\f512' !important;
}

.fa-skating:before {
    content: '\f7c5' !important;
}

.fa-sketch:before {
    content: '\f7c6' !important;
}

.fa-skiing:before {
    content: '\f7c9' !important;
}

.fa-skiing-nordic:before {
    content: '\f7ca' !important;
}

.fa-skull:before {
    content: '\f54c' !important;
}

.fa-skull-crossbones:before {
    content: '\f714' !important;
}

.fa-skyatlas:before {
    content: '\f216' !important;
}

.fa-skype:before {
    content: '\f17e' !important;
}

.fa-slack:before {
    content: '\f198' !important;
}

.fa-slack-hash:before {
    content: '\f3ef' !important;
}

.fa-slash:before {
    content: '\f715' !important;
}

.fa-sleigh:before {
    content: '\f7cc' !important;
}

.fa-sliders-h:before {
    content: '\f1de' !important;
}

.fa-slideshare:before {
    content: '\f1e7' !important;
}

.fa-smile:before {
    content: '\f118' !important;
}

.fa-smile-beam:before {
    content: '\f5b8' !important;
}

.fa-smile-wink:before {
    content: '\f4da' !important;
}

.fa-smog:before {
    content: '\f75f' !important;
}

.fa-smoking:before {
    content: '\f48d' !important;
}

.fa-smoking-ban:before {
    content: '\f54d' !important;
}

.fa-sms:before {
    content: '\f7cd' !important;
}

.fa-snapchat:before {
    content: '\f2ab' !important;
}

.fa-snapchat-ghost:before {
    content: '\f2ac' !important;
}

.fa-snapchat-square:before {
    content: '\f2ad' !important;
}

.fa-snowboarding:before {
    content: '\f7ce' !important;
}

.fa-snowflake:before {
    content: '\f2dc' !important;
}

.fa-snowman:before {
    content: '\f7d0' !important;
}

.fa-snowplow:before {
    content: '\f7d2' !important;
}

.fa-socks:before {
    content: '\f696' !important;
}

.fa-solar-panel:before {
    content: '\f5ba' !important;
}

.fa-sort:before {
    content: '\f0dc' !important;
}

.fa-sort-alpha-down:before {
    content: '\f15d' !important;
}

.fa-sort-alpha-up:before {
    content: '\f15e' !important;
}

.fa-sort-amount-down:before {
    content: '\f160' !important;
}

.fa-sort-amount-up:before {
    content: '\f161' !important;
}

.fa-sort-down:before {
    content: '\f0dd' !important;
}

.fa-sort-numeric-down:before {
    content: '\f162' !important;
}

.fa-sort-numeric-up:before {
    content: '\f163' !important;
}

.fa-sort-up:before {
    content: '\f0de' !important;
}

.fa-soundcloud:before {
    content: '\f1be' !important;
}

.fa-sourcetree:before {
    content: '\f7d3' !important;
}

.fa-spa:before {
    content: '\f5bb' !important;
}

.fa-space-shuttle:before {
    content: '\f197' !important;
}

.fa-speakap:before {
    content: '\f3f3' !important;
}

.fa-spider:before {
    content: '\f717' !important;
}

.fa-spinner:before {
    content: '\f110' !important;
}

.fa-splotch:before {
    content: '\f5bc' !important;
}

.fa-spotify:before {
    content: '\f1bc' !important;
}

.fa-spray-can:before {
    content: '\f5bd' !important;
}

.fa-square:before {
    content: '\f0c8' !important;
}

.fa-square-full:before {
    content: '\f45c' !important;
}

.fa-square-root-alt:before {
    content: '\f698' !important;
}

.fa-squarespace:before {
    content: '\f5be' !important;
}

.fa-stack-exchange:before {
    content: '\f18d' !important;
}

.fa-stack-overflow:before {
    content: '\f16c' !important;
}

.fa-stamp:before {
    content: '\f5bf' !important;
}

.fa-star:before {
    content: '\f005' !important;
}

.fa-star-and-crescent:before {
    content: '\f699' !important;
}

.fa-star-half:before {
    content: '\f089' !important;
}

.fa-star-half-alt:before {
    content: '\f5c0' !important;
}

.fa-star-of-david:before {
    content: '\f69a' !important;
}

.fa-star-of-life:before {
    content: '\f621' !important;
}

.fa-staylinked:before {
    content: '\f3f5' !important;
}

.fa-steam:before {
    content: '\f1b6' !important;
}

.fa-steam-square:before {
    content: '\f1b7' !important;
}

.fa-steam-symbol:before {
    content: '\f3f6' !important;
}

.fa-step-backward:before {
    content: '\f048' !important;
}

.fa-step-forward:before {
    content: '\f051' !important;
}

.fa-stethoscope:before {
    content: '\f0f1' !important;
}

.fa-sticker-mule:before {
    content: '\f3f7' !important;
}

.fa-sticky-note:before {
    content: '\f249' !important;
}

.fa-stop:before {
    content: '\f04d' !important;
}

.fa-stop-circle:before {
    content: '\f28d' !important;
}

.fa-stopwatch:before {
    content: '\f2f2' !important;
}

.fa-store:before {
    content: '\f54e' !important;
}

.fa-store-alt:before {
    content: '\f54f' !important;
}

.fa-strava:before {
    content: '\f428' !important;
}

.fa-stream:before {
    content: '\f550' !important;
}

.fa-street-view:before {
    content: '\f21d' !important;
}

.fa-strikethrough:before {
    content: '\f0cc' !important;
}

.fa-stripe:before {
    content: '\f429' !important;
}

.fa-stripe-s:before {
    content: '\f42a' !important;
}

.fa-stroopwafel:before {
    content: '\f551' !important;
}

.fa-studiovinari:before {
    content: '\f3f8' !important;
}

.fa-stumbleupon:before {
    content: '\f1a4' !important;
}

.fa-stumbleupon-circle:before {
    content: '\f1a3' !important;
}

.fa-subscript:before {
    content: '\f12c' !important;
}

.fa-subway:before {
    content: '\f239' !important;
}

.fa-suitcase:before {
    content: '\f0f2' !important;
}

.fa-suitcase-rolling:before {
    content: '\f5c1' !important;
}

.fa-sun:before {
    content: '\f185' !important;
}

.fa-superpowers:before {
    content: '\f2dd' !important;
}

.fa-superscript:before {
    content: '\f12b' !important;
}

.fa-supple:before {
    content: '\f3f9' !important;
}

.fa-surprise:before {
    content: '\f5c2' !important;
}

.fa-suse:before {
    content: '\f7d6' !important;
}

.fa-swatchbook:before {
    content: '\f5c3' !important;
}

.fa-swimmer:before {
    content: '\f5c4' !important;
}

.fa-swimming-pool:before {
    content: '\f5c5' !important;
}

.fa-synagogue:before {
    content: '\f69b' !important;
}

.fa-sync:before {
    content: '\f021' !important;
}

.fa-sync-alt:before {
    content: '\f2f1' !important;
}

.fa-syringe:before {
    content: '\f48e' !important;
}

.fa-table:before {
    content: '\f0ce' !important;
}

.fa-table-tennis:before {
    content: '\f45d' !important;
}

.fa-tablet:before {
    content: '\f3fa' !important;
}

.fa-tablet-alt:before {
    content: '\f3fa' !important;
}

.fa-tablets:before {
    content: '\f490' !important;
}

.fa-tachometer-alt:before {
    content: '\f3fd' !important;
}

.fa-tag:before {
    content: '\f02b' !important;
}

.fa-tags:before {
    content: '\f02c' !important;
}

.fa-tape:before {
    content: '\f4db' !important;
}

.fa-tasks:before {
    content: '\f0ae' !important;
}

.fa-taxi:before {
    content: '\f1ba' !important;
}

.fa-teamspeak:before {
    content: '\f4f9' !important;
}

.fa-teeth:before {
    content: '\f62e' !important;
}

.fa-teeth-open:before {
    content: '\f62f' !important;
}

.fa-telegram:before {
    content: '\f2c6' !important;
}

.fa-telegram-plane:before {
    content: '\f3fe' !important;
}

.fa-temperature-high:before {
    content: '\f769' !important;
}

.fa-temperature-low:before {
    content: '\f76b' !important;
}

.fa-tencent-weibo:before {
    content: '\f1d5' !important;
}

.fa-tenge:before {
    content: '\f7d7' !important;
}

.fa-terminal:before {
    content: '\f120' !important;
}

.fa-text-height:before {
    content: '\f034' !important;
}

.fa-text-width:before {
    content: '\f035' !important;
}

.fa-th:before {
    content: '\f00a' !important;
}

.fa-th-large:before {
    content: '\f009' !important;
}

.fa-th-list:before {
    content: '\f00b' !important;
}

.fa-the-red-yeti:before {
    content: '\f69d' !important;
}

.fa-theater-masks:before {
    content: '\f630' !important;
}

.fa-themeco:before {
    content: '\f5c6' !important;
}

.fa-themeisle:before {
    content: '\f2b2' !important;
}

.fa-thermometer:before {
    content: '\f2c7' !important;
}

.fa-thermometer-empty:before {
    content: '\f2cb' !important;
}

.fa-thermometer-full:before {
    content: '\f2c7' !important;
}

.fa-thermometer-half:before {
    content: '\f2c9' !important;
}

.fa-thermometer-quarter:before {
    content: '\f2ca' !important;
}

.fa-thermometer-three-quarters:before {
    content: '\f2c8' !important;
}

.fa-think-peaks:before {
    content: '\f731' !important;
}

.fa-thumbs-down:before {
    content: '\f165' !important;
}

.fa-thumbs-up:before {
    content: '\f164' !important;
}

.fa-thumbtack:before {
    content: '\f08d' !important;
}

.fa-ticket-alt:before {
    content: '\f3ff' !important;
}

.fa-times:before {
    content: '\f00d' !important;
}

.fa-times-circle:before {
    content: '\f057' !important;
}

.fa-tint:before {
    content: '\f043' !important;
}

.fa-tint-slash:before {
    content: '\f5c7' !important;
}

.fa-tired:before {
    content: '\f5c8' !important;
}

.fa-toggle-off:before {
    content: '\f204' !important;
}

.fa-toggle-on:before {
    content: '\f205' !important;
}

.fa-toilet:before {
    content: '\f7d8' !important;
}

.fa-toilet-paper:before {
    content: '\f71e' !important;
}

.fa-toolbox:before {
    content: '\f552' !important;
}

.fa-tools:before {
    content: '\f7d9' !important;
}

.fa-tooth:before {
    content: '\f5c9' !important;
}

.fa-torah:before {
    content: '\f6a0' !important;
}

.fa-torii-gate:before {
    content: '\f6a1' !important;
}

.fa-tractor:before {
    content: '\f722' !important;
}

.fa-trade-federation:before {
    content: '\f513' !important;
}

.fa-trademark:before {
    content: '\f25c' !important;
}

.fa-traffic-light:before {
    content: '\f637' !important;
}

.fa-train:before {
    content: '\f238' !important;
}

.fa-tram:before {
    content: '\f7da' !important;
}

.fa-transgender:before {
    content: '\f224' !important;
}

.fa-transgender-alt:before {
    content: '\f225' !important;
}

.fa-trash:before {
    content: '\f2ed' !important;
}

.fa-trash-alt:before {
    content: '\f2ed' !important;
}

.fa-tree:before {
    content: '\f1bb' !important;
}

.fa-trello:before {
    content: '\f181' !important;
}

.fa-tripadvisor:before {
    content: '\f262' !important;
}

.fa-trophy:before {
    content: '\f091' !important;
}

.fa-truck:before {
    content: '\f0d1' !important;
}

.fa-truck-loading:before {
    content: '\f4de' !important;
}

.fa-truck-monster:before {
    content: '\f63b' !important;
}

.fa-truck-moving:before {
    content: '\f4df' !important;
}

.fa-truck-pickup:before {
    content: '\f63c' !important;
}

.fa-tshirt:before {
    content: '\f553' !important;
}

.fa-tty:before {
    content: '\f1e4' !important;
}

.fa-tumblr:before {
    content: '\f173' !important;
}

.fa-tumblr-square:before {
    content: '\f174' !important;
}

.fa-tv:before {
    content: '\f26c' !important;
}

.fa-twitch:before {
    content: '\f1e8' !important;
}

.fa-twitter:before {
    content: '\f099' !important;
}

.fa-twitter-square:before {
    content: '\f081' !important;
}

.fa-typo3:before {
    content: '\f42b' !important;
}

.fa-uber:before {
    content: '\f402' !important;
}

.fa-ubuntu:before {
    content: '\f7df' !important;
}

.fa-uikit:before {
    content: '\f403' !important;
}

.fa-umbrella:before {
    content: '\f0e9' !important;
}

.fa-umbrella-beach:before {
    content: '\f5ca' !important;
}

.fa-underline:before {
    content: '\f0cd' !important;
}

.fa-undo:before {
    content: '\f0e2' !important;
}

.fa-undo-alt:before {
    content: '\f2ea' !important;
}

.fa-uniregistry:before {
    content: '\f404' !important;
}

.fa-universal-access:before {
    content: '\f29a' !important;
}

.fa-university:before {
    content: '\f19c' !important;
}

.fa-unlink:before {
    content: '\f127' !important;
}

.fa-unlock:before {
    content: '\f09c' !important;
}

.fa-unlock-alt:before {
    content: '\f13e' !important;
}

.fa-untappd:before {
    content: '\f405' !important;
}

.fa-upload:before {
    content: '\f093' !important;
}

.fa-ups:before {
    content: '\f7e0' !important;
}

.fa-usb:before {
    content: '\f287' !important;
}

.fa-user:before {
    content: '\f007' !important;
}

.fa-user-alt:before {
    content: '\f406' !important;
}

.fa-user-alt-slash:before {
    content: '\f4fa' !important;
}

.fa-user-astronaut:before {
    content: '\f4fb' !important;
}

.fa-user-check:before {
    content: '\f4fc' !important;
}

.fa-user-circle:before {
    content: '\f2bd' !important;
}

.fa-user-clock:before {
    content: '\f4fd' !important;
}

.fa-user-cog:before {
    content: '\f4fe' !important;
}

.fa-user-edit:before {
    content: '\f4ff' !important;
}

.fa-user-friends:before {
    content: '\f500' !important;
}

.fa-user-graduate:before {
    content: '\f501' !important;
}

.fa-user-injured:before {
    content: '\f728' !important;
}

.fa-user-lock:before {
    content: '\f502' !important;
}

.fa-user-md:before {
    content: '\f0f0' !important;
}

.fa-user-minus:before {
    content: '\f503' !important;
}

.fa-user-ninja:before {
    content: '\f504' !important;
}

.fa-user-plus:before {
    content: '\f234' !important;
}

.fa-user-secret:before {
    content: '\f21b' !important;
}

.fa-user-shield:before {
    content: '\f505' !important;
}

.fa-user-slash:before {
    content: '\f506' !important;
}

.fa-user-tag:before {
    content: '\f507' !important;
}

.fa-user-tie:before {
    content: '\f508' !important;
}

.fa-user-times:before {
    content: '\f235' !important;
}

.fa-users:before {
    content: '\f0c0' !important;
}

.fa-users-cog:before {
    content: '\f509' !important;
}

.fa-usps:before {
    content: '\f7e1' !important;
}

.fa-ussunnah:before {
    content: '\f407' !important;
}

.fa-utensil-spoon:before {
    content: '\f2e5' !important;
}

.fa-utensils:before {
    content: '\f2e7' !important;
}

.fa-vaadin:before {
    content: '\f408' !important;
}

.fa-vector-square:before {
    content: '\f5cb' !important;
}

.fa-venus:before {
    content: '\f221' !important;
}

.fa-venus-double:before {
    content: '\f226' !important;
}

.fa-venus-mars:before {
    content: '\f228' !important;
}

.fa-viacoin:before {
    content: '\f237' !important;
}

.fa-viadeo:before {
    content: '\f2a9' !important;
}

.fa-viadeo-square:before {
    content: '\f2aa' !important;
}

.fa-vial:before {
    content: '\f492' !important;
}

.fa-vials:before {
    content: '\f493' !important;
}

.fa-viber:before {
    content: '\f409' !important;
}

.fa-video:before {
    content: '\f03d' !important;
}

.fa-video-slash:before {
    content: '\f4e2' !important;
}

.fa-vihara:before {
    content: '\f6a7' !important;
}

.fa-vimeo:before {
    content: '\f27d' !important;
}

.fa-vimeo-square:before {
    content: '\f194' !important;
}

.fa-vimeo-v:before {
    content: '\f27d' !important;
}

.fa-vine:before {
    content: '\f1ca' !important;
}

.fa-vk:before {
    content: '\f189' !important;
}

.fa-vnv:before {
    content: '\f40b' !important;
}

.fa-volleyball-ball:before {
    content: '\f45f' !important;
}

.fa-volume-down:before {
    content: '\f027' !important;
}

.fa-volume-mute:before {
    content: '\f6a9' !important;
}

.fa-volume-off:before {
    content: '\f026' !important;
}

.fa-volume-up:before {
    content: '\f028' !important;
}

.fa-vote-yea:before {
    content: '\f772' !important;
}

.fa-vr-cardboard:before {
    content: '\f729' !important;
}

.fa-vuejs:before {
    content: '\f41f' !important;
}

.fa-walking:before {
    content: '\f554' !important;
}

.fa-wallet:before {
    content: '\f555' !important;
}

.fa-warehouse:before {
    content: '\f494' !important;
}

.fa-water:before {
    content: '\f773' !important;
}

.fa-weebly:before {
    content: '\f5cc' !important;
}

.fa-weibo:before {
    content: '\f18a' !important;
}

.fa-weight:before {
    content: '\f496' !important;
}

.fa-weight-hanging:before {
    content: '\f5cd' !important;
}

.fa-weixin:before {
    content: '\f1d7' !important;
}

.fa-whatsapp:before {
    content: '\f232' !important;
}

.fa-whatsapp-square:before {
    content: '\f40c' !important;
}

.fa-wheelchair:before {
    content: '\f193' !important;
}

.fa-whmcs:before {
    content: '\f40d' !important;
}

.fa-wifi:before {
    content: '\f1eb' !important;
}

.fa-wikipedia-w:before {
    content: '\f266' !important;
}

.fa-wind:before {
    content: '\f72e' !important;
}

.fa-window-close:before {
    content: '\f410' !important;
}

.fa-window-maximize:before {
    content: '\f2d0' !important;
}

.fa-window-minimize:before {
    content: '\f2d1' !important;
}

.fa-window-restore:before {
    content: '\f2d2' !important;
}

.fa-windows:before {
    content: '\f17a' !important;
}

.fa-wine-bottle:before {
    content: '\f72f' !important;
}

.fa-wine-glass:before {
    content: '\f4e3' !important;
}

.fa-wine-glass-alt:before {
    content: '\f5ce' !important;
}

.fa-wix:before {
    content: '\f5cf' !important;
}

.fa-wizards-of-the-coast:before {
    content: '\f730' !important;
}

.fa-wolf-pack-battalion:before {
    content: '\f514' !important;
}

.fa-won-sign:before {
    content: '\f159' !important;
}

.fa-wordpress:before {
    content: '\f19a' !important;
}

.fa-wordpress-simple:before {
    content: '\f411' !important;
}

.fa-wpbeginner:before {
    content: '\f297' !important;
}

.fa-wpexplorer:before {
    content: '\f2de' !important;
}

.fa-wpforms:before {
    content: '\f298' !important;
}

.fa-wpressr:before {
    content: '\f3e4' !important;
}

.fa-wrench:before {
    content: '\f0ad' !important;
}

.fa-x-ray:before {
    content: '\f497' !important;
}

.fa-xbox:before {
    content: '\f412' !important;
}

.fa-xing:before {
    content: '\f168' !important;
}

.fa-xing-square:before {
    content: '\f169' !important;
}

.fa-y-combinator:before {
    content: '\f23b' !important;
}

.fa-yahoo:before {
    content: '\f19e' !important;
}

.fa-yandex:before {
    content: '\f413' !important;
}

.fa-yandex-international:before {
    content: '\f414' !important;
}

.fa-yarn:before {
    content: '\f7e3' !important;
}

.fa-yelp:before {
    content: '\f1e9' !important;
}

.fa-yen-sign:before {
    content: '\f157' !important;
}

.fa-yin-yang:before {
    content: '\f6ad' !important;
}

.fa-yoast:before {
    content: '\f2b1' !important;
}

.fa-youtube:before {
    content: '\f167' !important;
}

.fa-youtube-square:before {
    content: '\f167' !important;
}

.fa-zhihu:before {
    content: '\f63f' !important;
}

.fa-uploadFiles:before {
    content: '\f382' !important;
}

.fa-remove:before {
    content: '\f2ed' !important;
}

.fa-insertAll:before {
    content: '\f093' !important;
}

.fa-cancel:before {
    content: '\f00d' !important;
}

.fa-minimize:before {
    content: '\f068' !important;
}

.fa-autoplay:before {
    content: '\f2f9' !important;
}

.fa-lg {
    font-size: 1.33333em;
    line-height: 0.75em;
    vertical-align: -0.0667em;
}

.fa-xs {
    font-size: 0.75em;
}

.fa-sm {
    font-size: 0.875em;
}

.fa-1x {
    font-size: 1em;
}

.fa-2x {
    font-size: 2em;
}

.fa-3x {
    font-size: 3em;
}

.fa-4x {
    font-size: 4em;
}

.fa-5x {
    font-size: 5em;
}

.fa-6x {
    font-size: 6em;
}

.fa-7x {
    font-size: 7em;
}

.fa-8x {
    font-size: 8em;
}

.fa-9x {
    font-size: 9em;
}

.fa-10x {
    font-size: 10em;
}

.fa-ul {
    list-style-type: none;
    margin-left: 2.5em;
    padding-left: 0;
}

.fa-ul > li {
    position: relative;
}

.fa-li {
    left: -2em;
    position: absolute;
    text-align: center;
    width: 2em;
    line-height: inherit;
}

.fa-rotate-90 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
    transform: rotate(90deg);
}

.fa-rotate-180 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
    transform: rotate(180deg);
}

.fa-rotate-270 {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
    transform: rotate(270deg);
}

.fa-flip-horizontal {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
    transform: scale(-1, 1);
}

.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    transform: scale(1, -1);
}

.fa-flip-horizontal.fa-flip-vertical {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
    transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical {
    filter: none;
}

.sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.fa-stack {
    display: inline-block;
    height: 2em;
    line-height: 2em;
    position: relative;
    vertical-align: middle;
    width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
}

.fa-stack-1x {
    line-height: inherit;
}

.fa-stack-2x {
    font-size: 2em;
}

.fa-inverse {
    color: #fff;
}

@font-face {
    font-family: 'Font Awesome 5 Brands';
    font-style: normal;
    font-weight: normal;
    src: url('font-awesome/fonts/fa-brands-400.eot');
    src: url('font-awesome/fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('font-awesome/fonts/fa-brands-400.woff2') format('woff2'), url('font-awesome/fonts/fa-brands-400.woff') format('woff'), url('font-awesome/fonts/fa-brands-400.ttf') format('truetype'), url('font-awesome/fonts/fa-brands-400.svg#fontawesome') format('svg');
}

.fab {
    font-family: 'Font Awesome 5 Brands';
}

@font-face {
    font-family: 'Font Awesome 5 Free Regular';
    font-style: normal;
    font-weight: 400;
    src: url('font-awesome/fonts/fa-regular-400.eot');
    src: url('font-awesome/fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('font-awesome/fonts/fa-regular-400.woff2') format('woff2'), url('font-awesome/fonts/fa-regular-400.woff') format('woff'), url('font-awesome/fonts/fa-regular-400.ttf') format('truetype'), url('font-awesome/fonts/fa-regular-400.svg#fontawesome') format('svg');
}

.far {
    font-family: 'Font Awesome 5 Free Regular';
    font-weight: 400;
}

@font-face {
    font-family: 'Font Awesome 5 Free';
    font-style: normal;
    font-weight: 900;
    src: url('font-awesome/fonts/fa-solid-900.eot');
    src: url('font-awesome/fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('font-awesome/fonts/fa-solid-900.woff2') format('woff2'), url('font-awesome/fonts/fa-solid-900.woff') format('woff'), url('font-awesome/fonts/fa-solid-900.ttf') format('truetype'), url('font-awesome/fonts/fa-solid-900.svg#fontawesome') format('svg');
}

.fa,
.fas {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.x-fa:before,
.x-fas:before {
    font-family: 'Font Awesome 5 Free' !important;
}

.x-far:before {
    font-family: 'Font Awesome 5 Free Regular' !important;
}

.x-fab:before {
    font-family: 'Font Awesome 5 Brands' !important;
}

@font-face {
    font-family: 'ExtJS';
    src: url('font-ext/fonts/ExtJS.eot?-tqegh9');
    src: url('font-ext/fonts/ExtJS.eot?#iefix-tqegh9') format('embedded-opentype'), url('font-ext/fonts/ExtJS.ttf?-tqegh9') format('truetype'), url('font-ext/fonts/ExtJS.woff?-tqegh9') format('woff'), url('font-ext/fonts/ExtJS.svg?-tqegh9#ExtJS') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ext {
    font-family: ExtJS;
}

.ext-sencha:before {
    content: "\e61e";
}

.ext-checkbox-checked:before {
    content: "\e613";
}

.ext-checkbox-unchecked:before {
    content: "\e614";
}

.ext-chevron-left:before {
    content: "\e615";
}

.ext-chevron-right:before {
    content: "\e616";
}

.ext-double-chevron-left:before {
    content: "\e617";
}

.ext-double-chevron-right:before {
    content: "\e618";
}

.ext-sort-down:before {
    content: "\e619";
}

.ext-sort-up:before {
    content: "\e61a";
}

.ext-spinner-down:before {
    content: "\e61b";
}

.ext-spinner-up:before {
    content: "\e61c";
}

.ext-square-edit:before {
    content: "\e61d";
}

.ext-equals-vertical:before {
    content: "\e612";
}

.ext-expand:before {
    content: "\e600";
}

.ext-columns:before {
    content: "\e601";
}

.ext-dirty:before {
    content: "\e602";
}

.ext-dirty-rtl:before {
    content: "\e603";
}

.ext-edit-html:before {
    content: "\e604";
}

.ext-equals:before {
    content: "\e605";
}

.ext-collapse:before {
    content: "\e606";
}

.ext-group-by:before {
    content: "\e607";
}

.ext-minimize:before {
    content: "\e608";
}

.ext-nw-handle:before {
    content: "\e609";
}

.ext-ne-handle:before {
    content: "\e60a";
}

.ext-sw-handle:before {
    content: "\e60b";
}

.ext-se-handle:before {
    content: "\e60c";
}

.ext-text-background-color:before {
    content: "\e60d";
}

.ext-text-color:before {
    content: "\e60e";
}

.ext-text-decrease:before {
    content: "\e60f";
}

.ext-text-increase:before {
    content: "\e610";
}

.ext-unpin:before {
    content: "\e611";
}

/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-Light"), url('fonts/OpenSans-Light.ttf');*/
/*    font-weight: 300;*/
/*    font-style: normal;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:10 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-LightItalic"), url('fonts/OpenSans-LightItalic.ttf');*/
/*    font-weight: 300;*/
/*    font-style: italic;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:17 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-Regular"), url('fonts/OpenSans-Regular.ttf');*/
/*    font-weight: 400;*/
/*    font-style: normal;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:24 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-Italic"), url('fonts/OpenSans-Italic.ttf');*/
/*    font-weight: 400;*/
/*    font-style: italic;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:31 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-Semibold"), url('fonts/OpenSans-Semibold.ttf');*/
/*    font-weight: 600;*/
/*    font-style: normal;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:38 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-SemiboldItalic"), url('fonts/OpenSans-SemiboldItalic.ttf');*/
/*    font-weight: 600;*/
/*    font-style: italic;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:45 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-Bold"), url('fonts/OpenSans-Bold.ttf');*/
/*    font-weight: 700;*/
/*    font-style: normal;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:52 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-BoldItalic"), url('fonts/OpenSans-BoldItalic.ttf');*/
/*    font-weight: 700;*/
/*    font-style: italic;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:59 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-ExtraBold"), url('fonts/OpenSans-ExtraBold.ttf');*/
/*    font-weight: 800;*/
/*    font-style: normal;*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-triton/sass/src/Component.scss:66 *!*/
/*@font-face {*/
/*    font-family: 'Open Sans';*/
/*    src: local("OpenSans-ExtraBoldItalic"), url('fonts/OpenSans-ExtraBoldItalic.ttf');*/
/*    font-weight: 800;*/
/*    font-style: italic;*/
/*}*/

.x-fieldset {
    border-top: 1px solid #bbb;
    background: #f6f6f6;
    position: static;
}

.x-fieldset .x-fieldset-body {
    position: relative;
}

.x-fieldset.x-fieldset-collapsed {
    background: none;
}

.x-menu-item-active .x-menu-item-text {
    font-weight: 400;
}

.x-tab-close-btn:before {
    display: block;
    width: 16px;
    line-height: 16px;
    text-align: center;
}

.x-breadcrumb-btn > .x-btn-arrow-right:after {
    content: '';
}

.x-toast {
    -webkit-box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
    -moz-box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 2px;
}

.x-tab-navigation-top {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 24px 24px 24px 24px;
    border-width: 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-navigation-top-mc {
    background-color: transparent;
}

.x-nbr .x-tab-navigation-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-navigation-top-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-24-24-24-24;
}

.x-tab-navigation-top-tl {
    background-position: 0 0;
}

.x-tab-navigation-top-tr {
    background-position: right 0;
}

.x-tab-navigation-top-bl {
    background-position: 0 0;
}

.x-tab-navigation-top-br {
    background-position: right 0;
}

.x-tab-navigation-top-ml {
    background-position: 0 top;
}

.x-tab-navigation-top-mr {
    background-position: right top;
}

.x-tab-navigation-top-tc {
    background-position: 0 0;
}

.x-tab-navigation-top-bc {
    background-position: 0 0;
}

.x-tab-navigation-top-tr,
.x-tab-navigation-top-br,
.x-tab-navigation-top-mr {
    padding-right: 0;
}

.x-tab-navigation-top-tl,
.x-tab-navigation-top-bl,
.x-tab-navigation-top-ml {
    padding-left: 0;
}

.x-tab-navigation-top-tc {
    height: 0;
}

.x-tab-navigation-top-bc {
    height: 0;
}

.x-tab-navigation-top-mc {
    padding: 24px 24px 24px 24px;
}

.x-tab-navigation-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 24px 24px 24px 24px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-navigation-bottom-mc {
    background-color: transparent;
}

.x-nbr .x-tab-navigation-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-navigation-bottom-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-24-24-24-24;
}

.x-tab-navigation-bottom-tl {
    background-position: 0 0;
}

.x-tab-navigation-bottom-tr {
    background-position: right 0;
}

.x-tab-navigation-bottom-bl {
    background-position: 0 0;
}

.x-tab-navigation-bottom-br {
    background-position: right 0;
}

.x-tab-navigation-bottom-ml {
    background-position: 0 bottom;
}

.x-tab-navigation-bottom-mr {
    background-position: right bottom;
}

.x-tab-navigation-bottom-tc {
    background-position: 0 0;
}

.x-tab-navigation-bottom-bc {
    background-position: 0 0;
}

.x-tab-navigation-bottom-tr,
.x-tab-navigation-bottom-br,
.x-tab-navigation-bottom-mr {
    padding-right: 0;
}

.x-tab-navigation-bottom-tl,
.x-tab-navigation-bottom-bl,
.x-tab-navigation-bottom-ml {
    padding-left: 0;
}

.x-tab-navigation-bottom-tc {
    height: 0;
}

.x-tab-navigation-bottom-bc {
    height: 0;
}

.x-tab-navigation-bottom-mc {
    padding: 24px 24px 24px 24px;
}

.x-tab-navigation-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 24px 24px 24px 24px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-navigation-left-mc {
    background-color: transparent;
}

.x-nbr .x-tab-navigation-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-navigation-left-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-24-24-24-24;
}

.x-tab-navigation-left-tl {
    background-position: 0 0;
}

.x-tab-navigation-left-tr {
    background-position: right 0;
}

.x-tab-navigation-left-bl {
    background-position: 0 0;
}

.x-tab-navigation-left-br {
    background-position: right 0;
}

.x-tab-navigation-left-ml {
    background-position: 0 top;
}

.x-tab-navigation-left-mr {
    background-position: right top;
}

.x-tab-navigation-left-tc {
    background-position: 0 0;
}

.x-tab-navigation-left-bc {
    background-position: 0 0;
}

.x-tab-navigation-left-tr,
.x-tab-navigation-left-br,
.x-tab-navigation-left-mr {
    padding-right: 0;
}

.x-tab-navigation-left-tl,
.x-tab-navigation-left-bl,
.x-tab-navigation-left-ml {
    padding-left: 0;
}

.x-tab-navigation-left-tc {
    height: 0;
}

.x-tab-navigation-left-bc {
    height: 0;
}

.x-tab-navigation-left-mc {
    padding: 24px 24px 24px 24px;
}

.x-tab-navigation-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 24px 24px 24px 24px;
    border-width: 0 0 0 0;
    border-style: solid;
    background-color: transparent;
}

.x-tab-navigation-right-mc {
    background-color: transparent;
}

.x-nbr .x-tab-navigation-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tab-navigation-right-frameInfo {
    font-family: th-0-0-0-0-0-0-0-0-24-24-24-24;
}

.x-tab-navigation-right-tl {
    background-position: 0 0;
}

.x-tab-navigation-right-tr {
    background-position: right 0;
}

.x-tab-navigation-right-bl {
    background-position: 0 0;
}

.x-tab-navigation-right-br {
    background-position: right 0;
}

.x-tab-navigation-right-ml {
    background-position: 0 top;
}

.x-tab-navigation-right-mr {
    background-position: right top;
}

.x-tab-navigation-right-tc {
    background-position: 0 0;
}

.x-tab-navigation-right-bc {
    background-position: 0 0;
}

.x-tab-navigation-right-tr,
.x-tab-navigation-right-br,
.x-tab-navigation-right-mr {
    padding-right: 0;
}

.x-tab-navigation-right-tl,
.x-tab-navigation-right-bl,
.x-tab-navigation-right-ml {
    padding-left: 0;
}

.x-tab-navigation-right-tc {
    height: 0;
}

.x-tab-navigation-right-bc {
    height: 0;
}

.x-tab-navigation-right-mc {
    padding: 24px 24px 24px 24px;
}

.x-tab-navigation {
    border-color: transparent;
    cursor: pointer;
}

.x-tab-navigation-top {
    margin: 0;
}

.x-tab-navigation-top.x-rtl {
    margin: 0 0 0 0;
}

.x-tab-navigation-top.x-tab-rotate-left {
    margin: 0 0 0 0;
}

.x-tab-navigation-top.x-tab-rotate-left.x-rtl {
    margin: 0;
}

.x-keyboard-mode .x-tab-navigation-top.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-top.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-top.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-navigation-right {
    margin: 0 0 0 0;
}

.x-tab-navigation-right.x-rtl {
    margin: 0 0 0 0;
}

.x-tab-navigation-right.x-tab-rotate-right {
    margin: 0 0 0 0;
}

.x-tab-navigation-right.x-tab-rotate-right.x-rtl {
    margin: 0 0 0 0;
}

.x-keyboard-mode .x-tab-navigation-right.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-right.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-right.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-navigation-bottom {
    margin: 0 0 0 0;
}

.x-tab-navigation-bottom.x-rtl {
    margin: 0 0 0 0;
}

.x-tab-navigation-bottom.x-tab-rotate-left {
    margin: 0 0 0 0;
}

.x-tab-navigation-bottom.x-tab-rotate-left.x-rtl {
    margin: 0 0 0 0;
}

.x-keyboard-mode .x-tab-navigation-bottom.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-bottom.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-bottom.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-navigation-left {
    margin: 0 0 0 0;
}

.x-tab-navigation-left.x-rtl {
    margin: 0 0 0 0;
}

.x-tab-navigation-left.x-tab-rotate-right {
    margin: 0 0 0 0;
}

.x-tab-navigation-left.x-tab-rotate-right.x-rtl {
    margin: 0 0 0 0;
}

.x-keyboard-mode .x-tab-navigation-left.x-tab-focus {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-left.x-tab-focus.x-tab-over {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-keyboard-mode .x-tab-navigation-left.x-tab-focus.x-tab-active {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.x-tab-button-navigation {
    height: 24px;
}

.x-tab-inner-navigation {
    font: bold 15px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #acacac;
    max-width: 100%;
}

.x-tab-icon-right > .x-tab-inner-navigation,
.x-tab-icon-left > .x-tab-inner-navigation {
    max-width: calc(100% - 24px);
}

.x-tab-icon-el-navigation {
    min-height: 24px;
    background-position: center center;
    font-size: 24px;
    line-height: 24px;
    color: #acacac;
}

.x-tab-icon-left > .x-tab-icon-el-navigation,
.x-tab-icon-right > .x-tab-icon-el-navigation {
    width: 24px;
}

.x-tab-icon-top > .x-tab-icon-el-navigation,
.x-tab-icon-bottom > .x-tab-icon-el-navigation {
    min-width: 24px;
}

.x-tab-bar-plain .x-tab-icon-el-navigation {
    color: #606060;
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-navigation {
    margin-right: 5px;
}

.x-tab-text.x-tab-icon-left > .x-tab-icon-el-navigation.x-rtl {
    margin-right: 0;
    margin-left: 5px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-navigation {
    margin-left: 5px;
}

.x-tab-text.x-tab-icon-right > .x-tab-icon-el-navigation.x-rtl {
    margin-left: 0;
    margin-right: 5px;
}

.x-tab-text.x-tab-icon-top > .x-tab-icon-el-navigation {
    margin-bottom: 5px;
}

.x-tab-text.x-tab-icon-bottom > .x-tab-icon-el-navigation {
    margin-top: 5px;
}

.x-keyboard-mode .x-tab-focus.x-tab-navigation {
    border-color: transparent;
    background-color: transparent;
    outline: 1px solid #5fa2dd;
    outline-offset: -3px;
}

.x-ie .x-keyboard-mode .x-tab-focus.x-tab-navigation,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-navigation,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-navigation {
    outline: none;
}

.x-ie .x-keyboard-mode .x-tab-focus.x-tab-navigation:after,
.x-ie10p .x-keyboard-mode .x-tab-focus.x-tab-navigation:after,
.x-edge .x-keyboard-mode .x-tab-focus.x-tab-navigation:after {
    position: absolute;
    content: ' ';
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-navigation .x-tab-inner-navigation {
    color: #606060;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-navigation .x-tab-icon-el {
    color: #606060;
}

.x-tab-over.x-tab-navigation {
    border-color: #000;
    background-image: none;
    background-color: rgba(80, 80, 80, 0.08);
}

.x-ie8 .x-tab-over.x-tab-navigation {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#14505050, endColorstr=#14505050)";
    zoom: 1;
}

.x-ie8 .x-tab-over.x-tab-navigation.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-ie8 .x-tab-over.x-tab-navigation.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-over.x-tab-navigation .x-tab-inner-navigation {
    color: #c4c4c4;
}

.x-tab-bar-plain .x-tab-over.x-tab-navigation .x-tab-inner-navigation {
    color: #606060;
}

.x-tab-over.x-tab-navigation .x-tab-icon-el {
    color: #c4c4c4;
}

.x-ie8m .x-tab-over.x-tab-navigation .x-tab-icon-el {
    color: #c4c4c4;
}

.x-tab-bar-plain .x-tab-over.x-tab-navigation .x-tab-icon-el {
    color: #606060;
}

.x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-navigation {
    border-color: #000;
    background-image: none;
    background-color: rgba(80, 80, 80, 0.08);
}

.x-ie8 .x-keyboard-mode .x-tab-focus.x-tab-over.x-tab-navigation {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#14505050, endColorstr=#14505050)";
    zoom: 1;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-navigation .x-tab-inner-navigation {
    color: #606060;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-over.x-tab-navigation .x-tab-icon-el {
    color: #606060;
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-navigation.x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-keyboard-mode.x-ie8 .x-tab-focus.x-tab-over.x-tab-navigation.x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab.x-tab-active.x-tab-navigation {
    border-color: #fff;
    background-color: #303030;
}

.x-tab.x-tab-active.x-tab-navigation .x-tab-inner-navigation {
    color: #fff;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-navigation .x-tab-inner-navigation {
    color: #404040;
}

.x-tab.x-tab-active.x-tab-navigation .x-tab-icon-el {
    color: #fff;
}

.x-ie8 .x-tab.x-tab-active.x-tab-navigation .x-tab-icon-el {
    color: #fff;
}

.x-tab-bar-plain .x-tab.x-tab-active.x-tab-navigation .x-tab-icon-el {
    color: #404040;
}

.x-keyboard-mode .x-tab-focus.x-tab-active.x-tab-navigation {
    border-color: #fff;
    background-color: #303030;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-navigation .x-tab-inner-navigation {
    color: #404040;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-focus.x-tab-active.x-tab-navigation .x-tab-icon-el {
    color: #404040;
}

.x-tab.x-tab-disabled.x-tab-navigation {
    border-color: transparent;
    background-color: transparent;
    cursor: default;
}

.x-tab.x-tab-disabled.x-tab-navigation .x-tab-inner-navigation {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-navigation .x-tab-inner-navigation {
    color: #606060;
}

.x-tab.x-tab-disabled.x-tab-navigation .x-tab-icon-el-navigation {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-tab.x-tab-disabled.x-tab-navigation .x-tab-icon-el {
    color: #acacac;
    opacity: 0.3;
    filter: none;
}

.x-tab-bar-plain .x-tab.x-tab-disabled.x-tab-navigation .x-tab-icon-el {
    color: #606060;
}

.x-nbr .x-tab-navigation {
    background-image: none;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-navigation-top-tl,*/
/*.x-tab-over .x-tab-navigation-top-bl,*/
/*.x-tab-over .x-tab-navigation-top-tr,*/
/*.x-tab-over .x-tab-navigation-top-br,*/
/*.x-tab-over .x-tab-navigation-top-tc,*/
/*.x-tab-over .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-navigation-top-ml,*/
/*.x-tab-over .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-over .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-navigation-top-tl,*/
/*.x-tab-focus .x-tab-navigation-top-bl,*/
/*.x-tab-focus .x-tab-navigation-top-tr,*/
/*.x-tab-focus .x-tab-navigation-top-br,*/
/*.x-tab-focus .x-tab-navigation-top-tc,*/
/*.x-tab-focus .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-navigation-top-ml,*/
/*.x-tab-focus .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-br,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-focus-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-top-tl,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-bl,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-tr,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-br,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-tc,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-top-ml,*/
/*.x-tab.x-tab-active .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-br,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-focus-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-focus-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-tl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-bl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-tr,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-br,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-tc,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-bc {*/
/*    background-image: url(images/tab/tab-navigation-top-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-ml,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-mr {*/
/*    background-image: url(images/tab/tab-navigation-top-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-top-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-top-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-navigation-right-tl,*/
/*.x-tab-over .x-tab-navigation-right-bl,*/
/*.x-tab-over .x-tab-navigation-right-tr,*/
/*.x-tab-over .x-tab-navigation-right-br,*/
/*.x-tab-over .x-tab-navigation-right-tc,*/
/*.x-tab-over .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-navigation-right-ml,*/
/*.x-tab-over .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-over .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-navigation-right-tl,*/
/*.x-tab-focus .x-tab-navigation-right-bl,*/
/*.x-tab-focus .x-tab-navigation-right-tr,*/
/*.x-tab-focus .x-tab-navigation-right-br,*/
/*.x-tab-focus .x-tab-navigation-right-tc,*/
/*.x-tab-focus .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-navigation-right-ml,*/
/*.x-tab-focus .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-br,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-focus-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-right-tl,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-bl,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-tr,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-br,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-tc,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-right-ml,*/
/*.x-tab.x-tab-active .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-br,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-focus-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-focus-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-tl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-bl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-tr,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-br,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-tc,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-bc {*/
/*    background-image: url(images/tab/tab-navigation-right-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-ml,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-mr {*/
/*    background-image: url(images/tab/tab-navigation-right-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-right-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-right-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-navigation-bottom-tl,*/
/*.x-tab-over .x-tab-navigation-bottom-bl,*/
/*.x-tab-over .x-tab-navigation-bottom-tr,*/
/*.x-tab-over .x-tab-navigation-bottom-br,*/
/*.x-tab-over .x-tab-navigation-bottom-tc,*/
/*.x-tab-over .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-navigation-bottom-ml,*/
/*.x-tab-over .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-over .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-navigation-bottom-tl,*/
/*.x-tab-focus .x-tab-navigation-bottom-bl,*/
/*.x-tab-focus .x-tab-navigation-bottom-tr,*/
/*.x-tab-focus .x-tab-navigation-bottom-br,*/
/*.x-tab-focus .x-tab-navigation-bottom-tc,*/
/*.x-tab-focus .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-navigation-bottom-ml,*/
/*.x-tab-focus .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-br,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-tl,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-bl,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-tr,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-br,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-tc,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-ml,*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-br,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-focus-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-tl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-bl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-tr,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-br,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-tc,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-bc {*/
/*    background-image: url(images/tab/tab-navigation-bottom-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-ml,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-mr {*/
/*    background-image: url(images/tab/tab-navigation-bottom-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-bottom-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-bottom-disabled-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-over .x-tab-navigation-left-tl,*/
/*.x-tab-over .x-tab-navigation-left-bl,*/
/*.x-tab-over .x-tab-navigation-left-tr,*/
/*.x-tab-over .x-tab-navigation-left-br,*/
/*.x-tab-over .x-tab-navigation-left-tc,*/
/*.x-tab-over .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-over .x-tab-navigation-left-ml,*/
/*.x-tab-over .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-over .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus .x-tab-navigation-left-tl,*/
/*.x-tab-focus .x-tab-navigation-left-bl,*/
/*.x-tab-focus .x-tab-navigation-left-tr,*/
/*.x-tab-focus .x-tab-navigation-left-br,*/
/*.x-tab-focus .x-tab-navigation-left-tc,*/
/*.x-tab-focus .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus .x-tab-navigation-left-ml,*/
/*.x-tab-focus .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-focus-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-tl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-bl,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-tr,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-br,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-tc,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-ml,*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-over-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-over .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-focus-over-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-left-tl,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-bl,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-tr,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-br,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-tc,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-left-ml,*/
/*.x-tab.x-tab-active .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-active .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-tl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-bl,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-tr,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-br,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-tc,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-active-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-ml,*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-focus-active-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab-focus.x-tab-active .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-focus-active-fbg.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4065 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-tl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-bl,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-tr,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-br,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-tc,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-bc {*/
/*    background-image: url(images/tab/tab-navigation-left-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4074 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-ml,*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-mr {*/
/*    background-image: url(images/tab/tab-navigation-left-disabled-sides.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/tab/Tab.scss:4079 *!*/
/*.x-tab.x-tab-disabled .x-tab-navigation-left-mc {*/
/*    background-repeat: repeat-x;*/
/*    background-image: url(images/tab/tab-navigation-left-disabled-fbg.gif);*/
/*}*/

.x-tab-navigation-tl,
.x-tab-navigation-bl,
.x-tab-navigation-tr,
.x-tab-navigation-br {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF)";
}

.x-tab-navigation .x-tab-close-btn {
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    font: 12px/1 'Font Awesome 5 Free';
    color: #fff;
}

.x-tab-navigation .x-tab-close-btn:before {
    content: '\f00d';
}

.x-tab-bar-plain .x-tab-navigation .x-tab-close-btn {
    color: #919191;
}

.x-tab-navigation .x-tab-close-btn-over {
    background-position: -16px 0;
}

.x-tab-navigation .x-tab-close-btn-pressed {
    background-position: -32px 0;
}

.x-keyboard-mode .x-tab-navigation.x-tab-focus .x-tab-close-btn {
    color: #fff;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-navigation.x-tab-focus .x-tab-close-btn {
    color: #919191;
}

.x-tab-navigation.x-tab-over .x-tab-close-btn {
    color: #fff;
}

.x-tab-bar-plain .x-tab-navigation.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-keyboard-mode .x-tab-navigation.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: #fff;
}

.x-keyboard-mode .x-tab-bar-plain .x-tab-navigation.x-tab-focus.x-tab-over .x-tab-close-btn {
    color: #919191;
}

.x-tab-navigation.x-tab-active .x-tab-close-btn {
    color: #919191;
}

.x-keyboard-mode .x-tab-navigation.x-tab-focus.x-tab-active .x-tab-close-btn {
    color: #919191;
}

.x-tab-navigation.x-tab-disabled .x-tab-close-btn {
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
    background-position: 0 0;
}

.x-tab-bar-plain .x-tab-navigation.x-tab-disabled .x-tab-close-btn {
    color: #919191;
}

.x-rtl.x-tab-navigation .x-tab-close-btn {
    right: auto;
    left: 0;
}

.x-tab-closable.x-tab-navigation .x-tab-button {
    padding-right: 16px;
}

.x-rtl.x-tab-closable.x-tab-navigation .x-tab-button {
    padding-right: 0px;
    padding-left: 16px;
}

.x-tab-bar-navigation {
    background-color: #404040;
}

.x-tab-bar-navigation-top > .x-tab-bar-body-navigation {
    padding: 0;
}

.x-tab-bar-navigation-bottom > .x-tab-bar-body-navigation {
    padding: 0 0 0 0;
}

.x-tab-bar-navigation-left > .x-tab-bar-body-navigation {
    padding: 0 0 0 0;
}

.x-rtl.x-tab-bar-navigation-left > .x-tab-bar-body-navigation {
    padding: 0 0 0 0;
}

.x-tab-bar-navigation-right > .x-tab-bar-body-navigation {
    padding: 0 0 0 0;
}

.x-rtl.x-tab-bar-navigation-right > .x-tab-bar-body-navigation {
    padding: 0 0 0 0;
}

.x-tab-bar-plain.x-tab-bar-navigation-top > .x-tab-bar-body-navigation {
    padding: 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-navigation-bottom > .x-tab-bar-body-navigation {
    padding: 0 0 8px 0;
}

.x-tab-bar-plain.x-tab-bar-navigation-left > .x-tab-bar-body-navigation {
    padding: 0 0 0 8px;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-navigation-left > .x-tab-bar-body-navigation {
    padding: 0 8px 0 0;
}

.x-tab-bar-plain.x-tab-bar-navigation-right > .x-tab-bar-body-navigation {
    padding: 0 8px 0 0;
}

.x-rtl.x-tab-bar-plain.x-tab-bar-navigation-right > .x-tab-bar-body-navigation {
    padding: 0 0 0 8px;
}

.x-tab-bar-plain.x-tab-bar-navigation-horizontal {
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-width: 0;
    border-right-width: 0;
}

.x-tab-bar-plain.x-tab-bar-navigation-vertical {
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-width: 0;
    border-bottom-width: 0;
}

.x-tab-bar-horizontal > .x-tab-bar-body-navigation {
    min-height: 72px;
}

.x-ie8m .x-tab-bar-horizontal > .x-tab-bar-body-navigation {
    min-height: 72px;
}

.x-tab-bar-vertical > .x-tab-bar-body-navigation {
    min-width: 72px;
}

.x-ie8m .x-tab-bar-vertical > .x-tab-bar-body-navigation {
    min-width: 72px;
}

.x-tab-bar-navigation-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-tab-bar-navigation-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 24px;
}

.x-tab-bar-navigation-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 24px;
}

.x-box-scroller-tab-bar-navigation {
    cursor: pointer;
    color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-left,
.x-box-scroller-tab-bar-navigation.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-left {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-right {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-right:before {
    content: '\f054';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-top,
.x-box-scroller-tab-bar-navigation.x-box-scroller-bottom {
    height: 24px;
    width: 24px;
    left: 50%;
    margin-left: -12px;
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-top {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-bottom {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-tab-bar-navigation.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-tab-bar-more-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-tab-bar-more-icon:before {
    content: '\f0c9';
}

.x-tab-bar-plain.x-tab-bar-navigation-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-tab-bar-plain.x-tab-bar-navigation-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 16px;
}

.x-tab-bar-plain .x-box-scroller-tab-bar-navigation {
    color: #919191;
}

.x-tab-bar-navigation-top .x-box-scroller-tab-bar-navigation {
    margin-top: -12px;
}

.x-tab-bar-navigation-right .x-box-scroller-tab-bar-navigation {
    margin-left: -12px;
}

.x-tab-bar-navigation-bottom .x-box-scroller-tab-bar-navigation {
    margin-top: -12px;
}

.x-tab-bar-navigation-left .x-box-scroller-tab-bar-navigation {
    margin-left: -12px;
}

.x-box-scroller-tab-bar-navigation {
    background-color: #404040;
}

.x-box-scroller-tab-bar-navigation .x-ie8 .x-box-scroller-plain {
    background-color: #fff;
}

.x-panel-navigation {
    border-color: #5fa2dd;
    padding: 0;
}

.x-panel-navigation.x-masked {
    border-color: #afd1ee;
}

.x-panel-header-navigation {
    font-size: 20px;
    border: 1px solid #5fa2dd;
}

.x-panel-header-navigation .x-tool-tool-el {
    background-color: #5fa2dd;
}

.x-panel-header-navigation-horizontal {
    padding: 0;
}

.x-panel-header-navigation-horizontal .x-panel-header-navigation-tab-bar {
    margin-top: 0;
    margin-bottom: 0;
}

.x-panel-header-navigation-vertical {
    padding: 0 0 0 0;
}

.x-panel-header-navigation-vertical .x-panel-header-navigation-tab-bar {
    margin-right: 0;
    margin-left: 0;
}

.x-rtl.x-panel-header-navigation-vertical {
    padding: 0 0 0 0;
}

.x-rtl.x-panel-header-navigation-vertical .x-panel-header-navigation-tab-bar {
    margin-left: 0;
    margin-right: 0;
}

.x-panel-header-title-navigation {
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 24px;
    margin: 36px;
}

.x-keyboard-mode .x-panel-header-title-navigation.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-navigation.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-navigation > .x-title-text-navigation {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-top {
    height: 39px;
    padding-bottom: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-right {
    width: 39px;
    padding-left: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-bottom {
    height: 39px;
    padding-top: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-left {
    width: 39px;
    padding-right: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 15px;
}

.x-panel-header-title-navigation > .x-title-icon-wrap-navigation > .x-title-icon-navigation {
    width: 24px;
    height: 24px;
    font-size: 24px;
    color: #fff;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-navigation > .x-title-icon-wrap-navigation > .x-title-icon-navigation.x-title-glyph {
    color: #fff;
}

.x-panel-body-navigation {
    background: #fff;
    border-color: #d0d0d0;
    color: #404040;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-navigation {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-navigation-vertical {
    background-image: none;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-navigation-vertical {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel .x-panel-header-navigation-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-navigation-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-navigation-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-navigation-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-navigation-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-navigation-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-navigation-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-navigation-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-navigation-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-navigation-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-navigation-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-navigation-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-navigation .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-navigation .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-navigation .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-navigation .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-navigation .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-navigation .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-navigation .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-navigation-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-navigation-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-navigation-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-navigation-outer-border-l {
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-b {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-navigation-outer-border-bl {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-r {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-navigation-outer-border-rl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-rb {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-navigation-outer-border-rbl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-t {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
}

.x-panel-navigation-outer-border-tl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-tb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-navigation-outer-border-tbl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-tr {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-navigation-outer-border-trl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-navigation-outer-border-trb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-navigation-outer-border-trbl {
    border-color: #5fa2dd !important;
    border-width: 1px !important;
}

.x-title-icon-navigation {
    font-family: 'Font Awesome 5 Free';
    color: #fff;
    font-size: 24px;
    line-height: 24px;
}

.x-tab-icon-el-navigation {
    font-family: 'Font Awesome 5 Free';
    color: #acacac;
}

.x-tab-over .x-tab-icon-el-navigation {
    color: #c4c4c4;
}

.x-tab-active .x-tab-icon-el-navigation {
    color: #fff;
}

.x-panel-header-title-navigation > .x-title-text-navigation:after {
    top: 30px;
    right: -24px;
}