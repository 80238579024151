@use '@UIkit/styles/typography/_text.scss';
@import "@UIkit/styles/global/Colors/Variables.scss";


.ui-table {
	//строка заголовков колонок
	.x-grid-header-ct {
		display: block;
		border: none;
		border-bottom: 1px solid $color-grey-20 !important;

		$columnHeader: '.x-column-header';

		#{$columnHeader} {
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 40px;
			border: none;
			padding: 0;

			&:not(#{$columnHeader}-checkbox):not(#{$columnHeader}-action) {
				//невидимая граница нужна что бы не прыгала внутрення ширина колонки и текст внутри вместе с ней
				border-right: 1px solid transparent;
			}

			&:not(#{$columnHeader}-checkbox):not(#{$columnHeader}-action):hover {
				border-right: 1px solid $color-grey-20;
			}

			&.x-group-header {
				.x-group-sub-header {
					border-top: 1px solid silver;
				}

				&:after {
					width: 0;
				}
			}

			&-inner {
				margin: 0 12px;
				padding: 0;
				background-color: transparent;

				&::after {
					border: none !important;
				}
			}

			&-text {
				@extend .body-short_02;
				color: $color-dark;
				margin: 0;

				&-inner {
					display: flex;
					justify-content: start;
					align-items: center;
					overflow: hidden;
					text-overflow: ellipsis;

					&:after {
						display: none;
					}

					span:first-child {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			&-align-left {
				#{$columnHeader}-text {
					&-inner {
						justify-content: start;
					}
				}
			}

			&-align-center {
				#{$columnHeader}-text {
					&-inner {
						justify-content: center;
					}
				}
			}

			&-align-right {
				#{$columnHeader}-text {
					&-inner {
						justify-content: end;
					}
				}
			}

			&-sort-ASC {
				.x-column-header-text-sortEl {
					position: relative;
					width: 20px;
					margin-left: 4px;

					&::before {
						font-family: 'EdiSoftIcons', sans-serif;
						font-size: 20px;
						content: '\e504';
					}
				}
			}

			&-sort-DESC {
				.x-column-header-text-sortEl {
					position: relative;
					width: 20px;
					margin-left: 4px;

					&::before {
						font-family: 'EdiSoftIcons', sans-serif;
						font-size: 20px;
						content: '\e503';
					}
				}
			}

			&-checkbox {
				display: inline-flex;
				justify-content: center;
				align-items: center;

				.x-column-header-inner {
					display: inline-flex;
					width: 24px;
					height: 24px;
					margin: 0 0 0 2px;

					.x-column-header-checkbox {
						&::after {
							content: '\e835';
							font-family: 'Material Icons', sans-serif;
							font-size: 24px;
							color: $color-grey-50;
						}
					}
				}
			}
		}

		.x-column-header-trigger {
			display: none !important;
		}

		.x-grid-hd-checker-on {
			.x-column-header-inner {
				.x-column-header-checkbox {
					&::after {
						content: '\e834';
						color: $color-blue;
					}
				}
			}
		}
	}
}
