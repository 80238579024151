$label: "x-form-item-label";
$form-text: "x-form-text";

%base-text {
	position: relative;

	& .#{$label} {
		@include label-inside;
	}

	& input.#{$form-text} {
		@include form-text;
		@include wrap;
	}
	& textarea.#{$form-text} {
		@include form-text {
            height: 100% !important;
        };
		@include wrap;
	}

	.x-form-trigger-wrap {
		border: 1px solid #d2d3d5;

		.x-form-trigger {
			vertical-align: middle;
		}
	}

    & .x-form-item-body {
        height: 100% !important;
        padding: 5px 0 0 0;
        width: 100% !important;
    }

    &.had {
        &-not-empty {
            & .#{$label} {
                @include label-top;
            }
        }
        &-focus {
            & .#{$label} {
                @include label-top;
            }
            & .#{$form-text} {
				color: $text-color !important;
                border-color: $focus-color !important;
			}
		}
        &-invalid {
			& .#{$label} {
				color: $invalid-color !important;
			}
			& .#{$form-text} {
				border: 1px solid $invalid-color !important;
			}
		}
        &-readonly {
			& .#{$form-text} {
				color: $inactive-color !important;
			}
		}
        &-disabled {
            @at-root .had-not-empty#{&} {
                & .#{$label} {
                    background: linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;background: -o-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
                    background: -moz-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
                    background: -webkit-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
                    background: -ms-linear-gradient($bg-color 50%, $bg-color-disabled 50%) !important;
                }
            }
            & .#{$form-text} {
                opacity: 1 !important;
                border: 1px solid $border-color-inactive !important;
                color: $inactive-color !important;
                background: $bg-color-disabled !important;
            }
        }
    }

    @include invalid;
}