@import '@UIkit/components/buttons/Buttons.scss';
@import "@UIkit/styles/global/Colors/Variables";

.ui-monthpicker {
	border: 0;
	padding: 24px;
	width: 300px;
	background-color: $color-white;

	&-months,
	&-years {
		position: relative;
		width: auto;
		border: none;
		float: none;

		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	&-years {
		border-top: 1px solid $color-grey-20;
		margin: 8px 0 0 0;
		padding: 8px 0 0 0;
	}

	&-yearnav {
		&-button {
			position: absolute;
			display: block;
			width: 32px;
			height: 32px;
			cursor: pointer;

			font: 16px/24px 'Font Awesome 5 Free';
			color: $color-grey-50;

			display: flex;
			justify-content: center;
			align-items: center;
		}
		&-prev {
			left: -24px;

			&::before {
				content: '\f053';
			}
		}
		&-next {
			right: -24px;

			&::before {
				content: '\f054';
			}
		}
	}

	&-item {
		// width: 84px;
		// display: flex;
		// justify-content: center;
		// align-items: center;
		margin: 2px;

		&-inner {
			display: block;
			width: 78px;
			@extend .body-short_01;
			color: $color-dark;

			text-align: center;

			padding: 8px 0;
			border-radius: 4px;
			cursor: pointer;

			transition: all 200ms ease-out 0s;

			&:hover {
				background-color: $color-dark-opacity-5;
				color: inherit;
			}
		}
	}
	&-selected {
		background-color: $color-blue !important;
		color: $color-white !important;
	}

	&-buttons {
		padding: 24px 0 0 0;
		display: flex;
		justify-content: center;
		column-gap: 16px;
	}
}
