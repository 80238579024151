@import '@UIkit/components/fields/Base/Base.scss';
@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-comboboxfield {
	$this: &;

	@extend %field;

	&-trigger {
		&::before {
			font-family: "Material Icons", sans-serif;
			content: "\e5c5";
		}
	}

	&-picker {
		&-open {
			#{$this}-trigger {
				&::before {
					content: "\e5c7";
				}
			}
		}
	}
}
