@use '@UIkit/styles/typography/_heading.scss';
@import "@UIkit/styles/global/Colors/Variables.scss";

$this: '.ui-modal';
#{$this} {
	box-shadow: 0 8px 48px rgba(29, 37, 44, 0.16);
	border-radius: 8px;
	background-color: $color-white;
	position: relative;

	&-handle {
		opacity: 0 !important;
	}

	#{$this}-header {
		padding: 8px 8px 8px 24px;
		width: auto !important;
		position: relative !important;

		&-title {
			@extend .heading_02;
		}

		.ui-tool,
		.x-tool {
			display: inline-block;
			width: 40px;
			height: 40px;
			margin-left: 8px;
			border-radius: 100%;
			padding: 0;
			overflow: hidden;

			&-ui-tool-el,
			&-tool-el {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				font-family: 'Material Icons', sans-serif;
				font-size: 20px;
				line-height: 20px;
				transition: all 200ms ease-out 0s;
				color: $color-grey-50;

				&::before {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					font-family: 'Material Icons', sans-serif;
					font-size: 20px;
					line-height: 20px;
					transition: all 200ms ease-out 0s;
					border-radius: 100%;
					color: $color-grey-50;
				}

				&:hover {
					background: $color-grey-5;
					color: $color-dark;

					&::before {
						background: $color-grey-5;
						color: $color-dark;
					}
				}

				&:active {
					background: $color-grey-10;
					color: $color-dark;

					&::before {
						background: $color-grey-10;
						color: $color-dark;
					}
				}
			}

			&-close {
				&::before {
					content: '\e5cd';
				}
			}

			&-print {
				&::before {
					content: '\e2c4';
				}
			}
		}
	}

	#{$this}-body {
		margin: 0;
		padding: 0;
	}

	.x-toolbar-footer.x-toolbar {
		margin: 0;
		padding: 16px 24px 16px 24px;

		.x-toolbar-item {
			margin-right: 16px;
		}
	}

	&#{$this}-default {
		#{$this}-header {
			box-shadow: none;
		}

		#{$this}-body {
		}

		.x-toolbar-footer.x-toolbar {
			box-shadow: none;
		}
	}

	&#{$this}-with-shadows {
		#{$this}-header {
			box-shadow: 0 2px 12px rgba(29, 37, 44, 0.08);
		}

		#{$this}-body {
		}

		.x-toolbar-footer.x-toolbar {
			box-shadow: 0px -8px 8px rgba(29, 37, 44, 0.04);
		}
	}
}
