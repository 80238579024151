.#{$prefix}-numberfield {
  @extend %base-text;

    &.had {
        &-disabled {
            & .x-form-trigger {
              &-wrap {
                border: 1px solid $border-color-inactive !important;
                background: $bg-color-disabled !important;
              }
            }
        }
    }
}