@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

$label: '.x-form-item-label';
$wrap: '.x-form-trigger-wrap';
$input: '.x-form-text';
$trigger: '.x-form-trigger-default';

%field {
	display: block;
	position: relative;
	margin: 0;
	height: auto;
	padding: 8px 0 0 0;

	#{$label} {
		@include label-inside;
	}

	#{$wrap} {
		@include wrap {
			&:hover {
				border-color: $color-grey-50;
			}
		}
	}

	#{$input} {
		&-wrap {
			width: 100%;
			height: 100%;
			padding: 0;
		}

		width: 100%;
		display: block;
		padding: 6px 8px;
		margin: 0;
		background: transparent;
		box-shadow: none;
		outline: 0;

		@extend .body-short_01;
		color: $color-dark;
	}

	#{$trigger} {
		font: initial;
		position: relative;
		background: transparent;
		min-width: 24px;
		max-width: 24px;
		width: 24px;
		height: 24px;
		line-height: 24px;
		font-family: 'Font Awesome 5 Free';
		font-size: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-right: 4px;

		color: $color-grey-50;

		// &::before {
		// 	font-size: 18px;
		// 	height: auto;
		// }
	}

	.x-form {
		&-item {
			&-body {
				display: initial;
				width: initial;
				min-width: initial;
				min-height: initial;
				height: initial;
				min-height: initial;
			}
		}
		&-trigger {
			&-glyph {
				&::before {
					content: none;
				}
			}

			&-over {
				color: $color-grey-50;
			}
		}
	}

	&.ui-field {
		$this: &;

		&-focus,
		&-filled {
			#{$label} {
				@include label-top;
			}
		}

		&-focus {
			#{$label} {
				color: $color-blue;
			}
			#{$wrap} {
				border-color: $color-blue;
			}
		}

		&-invalid {
			#{$label} {
				color: $color-error;
			}
			#{$wrap} {
				border-color: $color-error;
			}
		}

		&-readonly,
		&-disabled {
			opacity: 1 !important;

			#{$label} {
				color: $color-grey-30;
			}
			#{$wrap} {
				background-color: $color-dark-opacity-5;
			}
			#{$trigger} {
				color: $color-grey-30;
			}

			&#{$this}-filled {
				#{$label} {
					color: $color-grey-50;
				}
			}
		}

		&-disabled {
			&#{$this}-filled {
				#{$input} {
					color: $color-grey-50;
				}
			}
		}

		&-big {
			#{$label} {
				top: 18px;
			}
			#{$input} {
				padding: 11px 8px;
			}
			#{$trigger} {
				margin-right: 8px;
			}

			&#{$this}-focus,
			&#{$this}-filled {
				#{$label} {
					@include label-top;
				}
			}
		}
	}
}
