// Global variables
$global: "edi";
// Variables project's
$project: "ediweb";
$prefix: #{$global}-#{$project};
$main_prefix: #{$global}-main;
.initial-loader{
    filter:                progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
    opacity:               0.5;
    width:                 100vw;
    height:                100vh;
    background-image:      url(images/loading.gif);
    background-color:      #ffffff;
    background-size:       100px;
    background-repeat:     no-repeat;
    background-position:   center center;
    overflow:              hidden;
}

@import './scss/variables';
// @import './scss/plugins';

@import './scss/buttons';
@import './scss/header';
@import './scss/login';
@import './scss/dialog-window';
// @import './scss/tabbar';
// @import './scss/fields/base';
@import './scss/sert-upload';
//@import './scss/plugins/bottom_errors';
@import './scss/password_recovery';
@import './scss/confirmation';

BODY.edi-viewport {
    // .requirements-pas{
    //     font: normal 13px "Roboto", arial, verdana, sans-serif;
    //     color: rgba(0, 0, 0, 0.5);
    //     cursor: default;
    // }
    // .requirements-pas .x-btn-inner{
    //     font: normal 13px "Roboto", arial, verdana, sans-serif;
    //     color: rgba(0, 0, 0, 0.5);
    //     text-transform: none;
    //     display: inline;
    // }
    // .edi-label-button.requirements-pas:hover{
    //     cursor: default;
    // }
    // .edi-label-button.requirements-pas:hover .x-btn-inner{
    //     color: rgba(0, 0, 0, 0.5);
    // }
    // .requirements-pas-true .x-btn-glyph{
    //     color: #009900;
    // }

    .requirements-pas {
        @import "@UIkit/styles/typography/index";

        &-button {
            display: flex;
            justify-content: start;
            align-items: center;
            column-gap: 8px;
        }
        &-inner {
            @include typography__caption_01;
            color: rgba(0, 0, 0, 0.5);
        }
        &-glyph {
            font-size: 20px;
        }

        &-success {
            .requirements-pas-inner {
                color: #1D252C;
            }
            .requirements-pas-glyph {
                color: #009900;
            }
        }
    }

    .edi-ediweb-submit-button {
        .x-btn-wrap {
            text-transform: none!important;
            top: -3px!important;
        }
    }

    .edi-main-panel {
        .x-panel-body-default {
            background: none;
        }
    }

    .edi-ediweb-prewrap-tabs {
        margin-top: 28px;
        //height: 28px;

        .edi-ediweb-tab-bar {
            top: 30px;
            .x-tab-bar-body.x-tab-bar-body-default {
                background-color: transparent;
            }
        }

        .x-horizontal-box-overflow-body {
            height: 40px!important;
        }

        //.edi-login-form__field {
        //    margin-top: 32px;
        //}

        .x-tab {
            //margin-left: 20px!important;
            //width: 120px!important;
            padding: 0 14px!important;
            white-space: pre-wrap!important;
            //top: 2px!important;
            text-align: -webkit-center!important;
            .x-tab-inner {
                white-space: pre-wrap!important;
            }

            .x-tab-wrap {
                height: 28px;
                display: table-cell!important;
                vertical-align: middle!important;
            }

            &.edi-login-method-tab {
                position: relative !important;
                left: unset !important;
                z-index: unset !important;
                height: 28px;
                top: 0px !important;
            }
        }
    }

    .edi-modal-certificate-selector {
        z-index: 99145!important;
    }

    // a {
    //     color: #377ec6;
    // }

    .edi-ediweb-bottom-button {
        position: relative;
        bottom: 20px;
    }

    .edi-ediweb-back-btn:hover {
        background: white;
    }

    .x-message-box {
        z-index: 90001!important;
    }

    .ediweb-file-name-text-field .x-form-item-input-row .x-form-item-body .x-form-field {
        border: none;
        background: transparent !important;
        box-shadow: none!important;
        text-overflow: ellipsis;
        padding-left: 0px !important;
        padding-right: 32px !important;
        font-size: 14px !important;
        line-height: 18px !important;
        letter-spacing: 0.16px !important;
    }

    .ediweb-file-name-text-field .x-form-item-body {
        border: none;
        color: #3d7dca !important;
        background: transparent !important;
        padding: 0px 0 0 20px !important;
    }

    .edi-modal-certificate-selector {
        z-index: 99145!important;
    }

    .edi-grid-right-bar-for-config .edi-grid-settings-button {
        top: 10px!important;
        padding: 0;
    }

    .edi-grid-pagingbar-documents-per-page{
        top: -8px!important;
    }
    .sso-sign-sert-combolist-item {
        padding: 8px 16px !important;
    }

    .sso-sign-sert-combo-item {
        b {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.16px;
            margin: 4px 0px;
        }
        p {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.32px;
            margin: 4px 0px;
            color: #8E9295;
        }
    }

    .sso-main-panel > .x-panel-header-default {
        background-color: transparent;
    }

    &.edi-sso-viewport-runtime {
        background-image: none!important;
        background-color: transparent!important;
    }

    .x-form {
        &-invalid {
            .x-form-item-label-top {
                color: #E82000 !important;
            }
        }
    }

    .edi-sso-something-wrong-image {
        width: 300px;
        height: 300px;
        background-image: url(./images/something-wrong.gif);
        background-size: cover;
        background-position: 50% 50%;
    }

    &.edi-login-form-displayed.edi-viewport-background-ediweb{
        background-image: url(./images/back_ediweb.jpg);
    }

    .edi-sso-poa-banner {
        margin-bottom: 32px;

        a {
            display: block;
            background-image: url(./images/poa_banner.png);
            background-size: contain;
            background-repeat: no-repeat;
            width: 640px;
            height: 104px;
        }
    }
}