
.x-size-monitored {
    /**
 * @class Ext.util.SizeMonitor
 */
    position: relative;
}

.x-size-monitors {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
}

.x-size-monitors > * {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.x-size-monitors.scroll > *.shrink::after {
    content: '';
    display: block;
    width: 200%;
    height: 200%;
    min-width: 1px;
    min-height: 1px;
}

.x-size-monitors.scroll > *.expand::after {
    content: '';
    display: block;
    width: 100000px;
    height: 100000px;
}

.x-size-monitors.overflowchanged > *.shrink > * {
    width: 100%;
    height: 100%;
}

.x-size-monitors.overflowchanged > *.expand > * {
    width: 200%;
    height: 200%;
}

.x-size-change-detector {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.x-size-change-detector > * {
    visibility: hidden;
}

.x-size-change-detector-shrink > * {
    width: 200%;
    height: 200%;
}

.x-size-change-detector-expand > * {
    width: 100000px;
    height: 100000px;
}

@-webkit-keyframes x-paint-monitor-helper {

    from {
        /**
 * @class Ext.util.PaintMonitor
 */
        /**
 * @class Ext.util.PaintMonitor
 */
        zoom: 1;
    }

    to {
        zoom: 1;
    }
}

@keyframes x-paint-monitor-helper {

    from {
        zoom: 1;
    }

    to {
        zoom: 1;
    }
}

.x-paint-monitored {
    position: relative;
}

.x-safari .x-paint-monitor.cssanimation {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}

.x-paint-monitor {
    width: 0 !important;
    height: 0 !important;
    visibility: hidden;
}

.x-paint-monitor.cssanimation {
    -webkit-animation-duration: 0.0001ms;
    -webkit-animation-name: x-paint-monitor-helper;
    animation-duration: 0.0001ms;
    animation-name: x-paint-monitor-helper;
}

.x-paint-monitor.overflowchange {
    overflow: hidden;
}

.x-paint-monitor.overflowchange::after {
    content: '';
    display: block;
    width: 1px !important;
    height: 1px !important;
}

.x-unselectable {
    user-select: none;
    -o-user-select: none;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    cursor: default;
}

.x-hidden-clip {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
}

.x-hidden-display {
    display: none !important;
}

.x-hidden-offsets,
.x-hidden {
    display: block !important;
    visibility: hidden !important;
    position: absolute !important;
    top: -10000px !important;
}

.x-hidden-opacity {
    opacity: 0 !important;
    pointer-events: none;
}

.x-hidden-visibility {
    visibility: hidden !important;
}

.x-pressed .x-ripple-transition,
.x-pressing .x-ripple-transition {
    transition: background-color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.x-pressed .x-ripple-transition .x-icon-el,
.x-pressed .x-ripple-transition .x-arrow-el,
.x-pressing .x-ripple-transition .x-icon-el,
.x-pressing .x-ripple-transition .x-arrow-el {
    transition: background-color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1), color 0.15s 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.x-rippling {
    position: relative;
}

.x-rippling.x-component-confirm .x-ripple {
    background-color: green;
}

.x-rippling.x-component-decline .x-ripple {
    background-color: red;
}

.x-rippling.x-rippling-unbound {
    overflow: visible !important;
}

.x-rippling.x-rippling-unbound .x-ripple-container {
    overflow: visible;
    position: absolute;
}

.x-safari .x-rippling:not(.x-rippling-unbound) .x-ripple-container {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
}

.x-ripple-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
}

.x-ripple-wrapper {
    z-index: 1000;
}

.x-button-fab .x-ripple-container,
.x-button-round .x-ripple-container {
    border-radius: 10000px;
}

.x-splitButton-fab .x-ripple-container,
.x-splitButton-round .x-ripple-container {
    border-radius: 10000px;
}

.x-ripple {
    position: absolute;
    border-radius: 50%;
    background-color: white;
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.4;
    pointer-events: none;
}

.x-rippling.x-rippling-unbound .x-ripple {
    -webkit-animation: x-ripple-unbound-animation 0.3s ease-out forwards;
    animation: x-ripple-unbound-animation 0.3s ease-out forwards;
}

.x-rippling:not(.x-rippling-unbound) .x-ripple {
    -webkit-animation: x-ripple-animation 0.35s ease-out forwards;
    animation: x-ripple-animation 0.35s ease-out forwards;
}

@-webkit-keyframes x-ripple-animation {

    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }

    75% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0.2;
    }

    100% {
        -webkit-transform: scale(2.35, 2.35);
        -moz-transform: scale(2.35, 2.35);
        -ms-transform: scale(2.35, 2.35);
        -o-transform: scale(2.35, 2.35);
        transform: scale(2.35, 2.35);
        opacity: 0;
    }
}

@keyframes x-ripple-animation {

    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }

    75% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0.2;
    }

    100% {
        -webkit-transform: scale(2.35, 2.35);
        -moz-transform: scale(2.35, 2.35);
        -ms-transform: scale(2.35, 2.35);
        -o-transform: scale(2.35, 2.35);
        transform: scale(2.35, 2.35);
        opacity: 0;
    }
}

@-webkit-keyframes x-ripple-unbound-animation {

    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }

    75% {
        -webkit-transform: scale(1.75, 1.75);
        -moz-transform: scale(1.75, 1.75);
        -ms-transform: scale(1.75, 1.75);
        -o-transform: scale(1.75, 1.75);
        transform: scale(1.75, 1.75);
        opacity: 0.2;
    }

    100% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0;
    }
}

@keyframes x-ripple-unbound-animation {

    0% {
        -webkit-transform: scale(0, 0);
        -moz-transform: scale(0, 0);
        -ms-transform: scale(0, 0);
        -o-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.4;
    }

    75% {
        -webkit-transform: scale(1.75, 1.75);
        -moz-transform: scale(1.75, 1.75);
        -ms-transform: scale(1.75, 1.75);
        -o-transform: scale(1.75, 1.75);
        transform: scale(1.75, 1.75);
        opacity: 0.2;
    }

    100% {
        -webkit-transform: scale(2, 2);
        -moz-transform: scale(2, 2);
        -ms-transform: scale(2, 2);
        -o-transform: scale(2, 2);
        transform: scale(2, 2);
        opacity: 0;
    }
}

.x-progress {
    overflow: hidden;
    position: relative;
}

.x-progress-bar {
    height: 100%;
    width: 0;
}

.x-progress-text {
    overflow: hidden;
}

.x-progress-bar {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-touch-action-pan-y {
    overflow-x: hidden !important;
}

.x-touch-action-pan-x {
    overflow-y: hidden !important;
}

.x-drag-body {
    cursor: default;
}

.x-drag-body * {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.x-drag-dragging {
    z-index: 1000000!important;
    pointer-events: none;
}

.x-treelist {
    background-color: #fff;
    background-position: 16px 0%;
    overflow: hidden;
    padding: 0 0 0 0;
}

.x-treelist-container,
.x-treelist-root-container {
    width: 100%;
}

.x-treelist-toolstrip {
    display: none;
}

.x-treelist-micro > .x-treelist-toolstrip {
    display: inline-block;
}

.x-treelist-micro > .x-treelist-root-container {
    display: none;
}

.x-treelist-item,
.x-treelist-container,
.x-treelist-root-container {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    margin: 0;
}

.x-treelist-item-tool,
.x-treelist-row,
.x-treelist-item-wrap {
    position: relative;
}

.x-treelist-item-icon,
.x-treelist-item-expander {
    display: none;
    position: absolute;
    top: 0;
}

.x-treelist-item-expander {
    right: 0;
    cursor: pointer;
}

.x-treelist-expander-only .x-treelist-item-expandable > * > .x-treelist-item-wrap > * {
    cursor: pointer;
}

.x-treelist-item-text {
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
}

.x-treelist-item-collapsed > .x-treelist-container {
    display: none;
}

.x-treelist-item-expandable > * > * > .x-treelist-item-expander,
.x-treelist-item-icon {
    display: block;
}

.x-treelist-item-floated > * > * > .x-treelist-item-expander,
.x-treelist-item-floated > * > * > .x-treelist-item-icon {
    display: none;
}

.x-treelist-expander-first .x-treelist-item-expander {
    left: 0;
    right: auto;
}

.x-treelist-toolstrip {
    background-color: #f8f8f8;
}

.x-treelist-item-selected > .x-treelist-row {
    background-color: #ffefbb;
}

.x-treelist-item-selected > .x-treelist-row-over {
    background-color: #ffefbb;
}

.x-treelist-item-tool {
    padding-left: 6px;
    padding-right: 10px;
}

.x-treelist-item-icon:before,
.x-treelist-item-tool:before,
.x-treelist-item-expander {
    line-height: 32px;
}

.x-treelist-item-icon,
.x-treelist-item-tool,
.x-treelist-item-expander {
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.x-treelist-item-loading .x-treelist-item-icon {
    background-image: url(images/tree/loading.gif);
    color: transparent;
}

.x-treelist-item-icon,
.x-treelist-item-tool {
    color: #5fa2dd;
    font-size: 16px;
    width: 16px;
}

.x-treelist-item-tool {
    width: 32px;
}

.x-treelist-item-expander {
    color: #5fa2dd;
    font-size: 16px;
    width: 18px;
}

.x-treelist-item-expander:after {
    content: '\f0da';
    font: 16px/32px 'Font Awesome 5 Free';
}

.x-treelist-item-expanded > * > * > .x-treelist-item-expander:after {
    content: '\f0d7';
    font: 16px/32px 'Font Awesome 5 Free';
}

.x-treelist-item-text {
    color: #404040;
    margin-left: 22px;
    margin-right: 18px;
    font-size: 13px;
    line-height: 32px;
    text-overflow: ellipsis;
}

.x-treelist-row {
    padding-left: 6px;
    padding-right: 10px;
}

.x-treelist-item-floated .x-treelist-container {
    width: auto;
}

.x-treelist-item-floated > .x-treelist-row {
    background-color: #f8f8f8;
}

.x-treelist-item-floated > .x-treelist-container {
    margin-left: -6px;
}

.x-big .x-treelist-item-floated > .x-treelist-container {
    margin-left: -6px;
}

.x-treelist-item-floated > * > * > .x-treelist-item-text {
    margin-left: 0;
}

.x-treelist-item-floated > * .x-treelist-row {
    cursor: pointer;
    padding-left: 0;
}

.x-treelist-item-floated .x-treelist-row:before {
    width: 0;
}

.x-treelist-item-floated > .x-treelist-row-over {
    background-color: #f8f8f8;
}

.x-treelist-item-floated > .x-treelist-row-over > * > .x-treelist-item-text {
    color: #404040;
}

.x-treelist-item-expanded > .x-treelist-item-expander:after {
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
}

.x-treelist-item-collapsed > * > .x-treelist-item-expander:after {
    content: '\f0da';
    font: 16px/1 'Font Awesome 5 Free';
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-icon {
    color: #5fa2dd;
    transition: color 0.5s;
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-text {
    color: #404040;
    transition: color 0.5s;
}

.x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-expander {
    color: #5fa2dd;
    transition: color 0.5s;
}

.x-treelist-row-over {
    background-color: #eaeff4;
}

.x-treelist-row-over > * > .x-treelist-item-icon {
    color: #5fa2dd;
    transition: color 0.5s;
}

.x-treelist-row-over > * > .x-treelist-item-text {
    color: #404040;
    transition: color 0.5s;
}

.x-treelist-row-over > * > .x-treelist-item-expander {
    color: #5fa2dd;
    transition: color 0.5s;
}

.x-treelist-expander-first .x-treelist-item-icon {
    left: 18px;
}

.x-treelist-expander-first .x-treelist-item-text {
    margin-left: 40px;
    margin-right: 0;
}

.x-treelist-expander-first .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 21px;
}

.x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 3px;
}

.x-treelist-nav {
    background-color: #32404e;
    background-position: 24px 0%;
    padding: 0 0 0 0;
}

.x-treelist-nav .x-treelist-toolstrip {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-selected.x-treelist-item-tool {
    color: #f0f0f0;
    background-color: #5fa2dd;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row {
    background-color: #3f505f;
}

.x-treelist-nav .x-treelist-item-tool {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-item-tool-floated:after {
    height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 4px;
    background-color: #5fa2dd;
}

.x-treelist-nav .x-treelist-item-icon:before,
.x-treelist-nav .x-treelist-item-tool:before,
.x-treelist-nav .x-treelist-item-expander {
    line-height: 44px;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool,
.x-treelist-nav .x-treelist-item-expander {
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
}

.x-treelist-nav .x-treelist-item-loading .x-treelist-item-icon {
    background-image: url(images/tree/loading.gif);
    color: transparent;
}

.x-treelist-nav .x-treelist-item-icon,
.x-treelist-nav .x-treelist-item-tool {
    color: #adb3b8;
    font-size: 18px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-tool {
    width: 44px;
}

.x-treelist-nav .x-treelist-item-expander {
    color: #adb3b8;
    font-size: 16px;
    width: 24px;
}

.x-treelist-nav .x-treelist-item-text {
    color: #adb3b8;
    margin-left: 30px;
    margin-right: 24px;
    font-size: 16px;
    line-height: 44px;
}

.x-treelist-nav .x-treelist-row {
    padding-left: 10px;
    padding-right: 10px;
}

.x-treelist-nav .x-treelist-row-over:before,
.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    content: " ";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 6px;
    height: 100%;
}

.x-treelist-nav .x-treelist-row-over:before {
    background-color: #89bae6;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row-over:before {
    background-color: #89bae6;
}

.x-treelist-nav .x-treelist-item-selected > .x-treelist-row:before {
    background-color: #5fa2dd;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-container {
    width: auto;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-big .x-treelist-nav .x-treelist-item-floated > .x-treelist-container {
    margin-left: -10px;
}

.x-treelist-nav .x-treelist-item-floated > * > * > .x-treelist-item-text {
    margin-left: 0;
}

.x-treelist-nav .x-treelist-item-floated > * .x-treelist-row {
    cursor: pointer;
    padding-left: 0;
}

.x-treelist-nav .x-treelist-item-floated .x-treelist-row:before {
    width: 0;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over {
    background-color: #32404e;
}

.x-treelist-nav .x-treelist-item-floated > .x-treelist-row-over > * > .x-treelist-item-text {
    color: #adb3b8;
}

.x-treelist-nav .x-treelist-item-expanded {
    background-color: #2c3845;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-highlight-path .x-treelist-item-over > * > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over {
    background-color: #4f606f;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-icon {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-text {
    color: #fff;
}

.x-treelist-nav .x-treelist-row-over > * > .x-treelist-item-expander {
    color: #fff;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-icon {
    left: 24px;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-text {
    margin-left: 54px;
    margin-right: 0;
}

.x-treelist-nav .x-treelist-expander-first .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 27px;
}

.x-treelist-nav .x-treelist-item-hide-icon > * > * > .x-treelist-item-text {
    margin-left: 3px;
}

.x-scroller {
    -webkit-overflow-scrolling: touch;
    overflow-anchor: none;
}

.x-scroller-spacer {
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    pointer-events: none;
}

.x-scroller-snappable {
    -ms-scroll-snap-type: mandatory;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;
}

.x-no-scrollbars {
    -ms-overflow-style: none;
}

.x-no-scrollbars::-webkit-scrollbar {
    width: 0;
}

.x-body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@-ms-viewport {
    width: device-width;
}

img {
    border: 0;
}

.x-border-box,
.x-border-box * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-rtl {
    direction: rtl;
}

.x-ltr {
    direction: ltr;
}

.x-clear {
    overflow: hidden;
    clear: both;
    font-size: 0;
    line-height: 0;
    display: table;
}

.x-layer {
    position: absolute !important;
    top: 0;
    left: 0;
    overflow: hidden;
}

.x-fixed-layer {
    position: fixed !important;
    overflow: hidden;
}

.x-shim {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-hidden-clip {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
}

.x-masked-relative {
    position: relative;
}

.x-ie-shadow {
    background-color: #777;
    position: absolute;
    overflow: hidden;
}

.x-selectable {
    cursor: auto;
    -moz-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
    -o-user-select: text;
}

.x-list-plain {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.x-table-plain {
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 1em;
}

.x-frame-tl,
.x-frame-tr,
.x-frame-tc,
.x-frame-bl,
.x-frame-br,
.x-frame-bc {
    overflow: hidden;
    background-repeat: no-repeat;
}

.x-frame-tc,
.x-frame-bc {
    background-repeat: repeat-x;
}

td.x-frame-tl,
td.x-frame-tr,
td.x-frame-bl,
td.x-frame-br {
    width: 1px;
}

.x-frame-mc {
    background-repeat: repeat-x;
    overflow: hidden;
}

.x-proxy-el {
    position: absolute;
    background: #b4b4b4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-css-shadow {
    position: absolute;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
}

.x-item-disabled,
.x-item-disabled * {
    cursor: default;
    pointer-events: none;
}

.x-component,
.x-container {
    position: relative;
}

:focus {
    outline: none;
}

.x-body .x-sync-repaint:before,
.x-body .x-sync-repaint:after {
    content: none !important;
}

.x-position-relative {
    position: relative !important;
}

.x-tab-guard {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.x-box-item {
    position: absolute !important;
    left: 0;
    top: 0;
}

.x-rtl > .x-box-item {
    right: 0;
    left: auto;
}

.x-autocontainer-outerCt {
    display: table;
}

.x-mobile-safari .x-autocontainer-outerCt {
    transform: translateZ(0);
}

.x-clipped .x-autocontainer-outerCt {
    transform: initial;
}

.x-autocontainer-innerCt {
    display: table-cell;
    height: 100%;
    vertical-align: top;
}

.x-mask {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*
     * IE and FF will add an outline to focused elements,
     * which we don't want when using our own focus treatment
     */
    outline: none !important;
}

.x-ie8 .x-mask {
    /*
 * IE8 will treat partially transparent divs as invalid click targets,
 * allowing mouse events to reach elements beneath the mask. Placing
 * a 1x1 transparent gif as the link el background will cure this.
 */
    background-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
}

.x-mask-fixed {
    position: fixed;
}

.x-mask-msg {
    position: absolute;
}

.x-progress {
    border-style: solid;
}

.x-btn {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}

.x-btn > .x-frame {
    height: 100%;
    width: 100%;
}

.x-btn-wrap {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.x-btn-wrap.x-btn-arrow-bottom,
.x-btn-wrap.x-btn-split-bottom {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
}

.x-ie9m .x-btn-wrap {
    display: table;
    border-spacing: 0;
}

.x-btn-button {
    white-space: nowrap;
    line-height: 0;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
}

.x-btn-button.x-btn-icon-top,
.x-btn-button.x-btn-icon-bottom {
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie10p .x-btn-button.x-btn-icon-top,
.x-ie10p .x-btn-button.x-btn-icon-bottom {
    overflow: hidden;
}

.x-ie9m .x-btn-button {
    display: table-cell;
    vertical-align: middle;
}

.x-btn-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-flex-negative: 1;
    display: block;
}

.x-ie9m .x-btn-inner {
    display: inline-block;
    vertical-align: middle;
}

.x-btn-icon.x-btn-no-text > .x-btn-inner {
    display: none;
}

.x-btn-icon-el {
    display: none;
    vertical-align: middle;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
    flex-shrink: 0;
}

.x-btn-icon > .x-btn-icon-el {
    display: block;
}

.x-ie9m .x-btn-icon-left > .x-btn-icon-el,
.x-ie9m .x-btn-icon-right > .x-btn-icon-el {
    display: inline-block;
}

.x-btn-button-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie9m .x-btn-button-center {
    text-align: center;
}

.x-btn-button-center.x-btn-icon-top,
.x-btn-button-center.x-btn-icon-bottom {
    text-align: center;
}

.x-btn-button-left {
    text-align: left;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.x-rtl.x-btn-button-left {
    text-align: right;
}

.x-btn-button-right {
    text-align: right;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.x-btn-tooltip {
    display: none;
    position: absolute;
    pointer-events: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.x-btn-disabled .x-btn-tooltip {
    display: block;
}

.x-rtl.x-btn-button-right {
    text-align: left;
}

.x-btn-arrow:after,
.x-btn-split:after {
    display: block;
    background-repeat: no-repeat;
    content: '';
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    text-align: center;
}

.x-btn-arrow-right:after,
.x-btn-split-right:after {
    background-position: right center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.x-ie9m .x-btn-arrow-right:after,
.x-ie9m .x-btn-split-right:after {
    display: table-cell;
    vertical-align: middle;
}

.x-rtl.x-btn-arrow-right:after,
.x-rtl.x-btn-split-right:after {
    background-position: left center;
}

.x-btn-arrow-bottom:after,
.x-btn-split-bottom:after {
    background-position: center bottom;
    content: '\00a0';
    line-height: 0;
}

.x-ie9m .x-btn-arrow-bottom:after,
.x-ie9m .x-btn-split-bottom:after {
    display: table-row;
}

.x-btn-split-right > .x-btn-button:after {
    position: absolute;
    display: block;
    top: -100%;
    right: 0;
    height: 300%;
    content: '';
}

.x-rtl.x-btn-split-right > .x-btn-button:after {
    left: 0;
    right: auto;
}

.x-btn-split-bottom > .x-btn-button:after {
    position: absolute;
    display: block;
    bottom: 0;
    left: -100%;
    font-size: 0;
    width: 300%;
    content: '';
}

.x-btn-mc {
    overflow: visible;
}

.x-segmented-button {
    display: table;
    table-layout: fixed;
}

.x-segmented-button-item {
    display: table-cell;
    vertical-align: top;
}

.x-segmented-button-item > .x-frame {
    width: 100%;
    height: 100%;
}

.x-segmented-button-item .x-btn-mc {
    width: 100%;
}

.x-segmented-button-item-horizontal {
    display: table-cell;
    height: 100%;
}

.x-segmented-button-item-horizontal.x-segmented-button-first {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-tr,
.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-mr,
.x-segmented-button-item-horizontal.x-segmented-button-first .x-btn-br {
    display: none;
}

.x-segmented-button-item-horizontal.x-segmented-button-middle {
    border-radius: 0;
    border-left: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-tl,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-tr,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-ml,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-mr,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-bl,
.x-segmented-button-item-horizontal.x-segmented-button-middle .x-btn-br {
    display: none;
}

.x-segmented-button-item-horizontal.x-segmented-button-last {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-tl,
.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-ml,
.x-segmented-button-item-horizontal.x-segmented-button-last .x-btn-bl {
    display: none;
}

.x-segmented-button-row {
    display: table-row;
}

.x-segmented-button-item-vertical.x-segmented-button-first {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-bl,
.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-bc,
.x-segmented-button-item-vertical.x-segmented-button-first .x-btn-br {
    display: none;
}

.x-segmented-button-item-vertical.x-segmented-button-middle {
    border-radius: 0;
    border-top: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tl,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tc,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-tr,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-bl,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-bc,
.x-segmented-button-item-vertical.x-segmented-button-middle .x-btn-br {
    display: none;
}

.x-segmented-button-item-vertical.x-segmented-button-last {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tl,
.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tc,
.x-segmented-button-item-vertical.x-segmented-button-last .x-btn-tr {
    display: none;
}

.x-title-icon {
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    line-height: 1;
    text-align: center;
}

.x-title {
    display: table;
    table-layout: fixed;
}

.x-rtl.x-title {
    -o-text-overflow: clip;
    text-overflow: clip;
}

.x-title-text {
    display: table-cell;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.x-title-align-left {
    text-align: left;
}

.x-title-align-left.x-rtl {
    text-align: right;
}

.x-title-align-center {
    text-align: center;
}

.x-title-align-right {
    text-align: right;
}

.x-title-align-right.x-rtl {
    text-align: left;
}

.x-title-rotate-right {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-title-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-title-rotate-right.x-rtl {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-title-rotate-right.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-title-rotate-left {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-title-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-title-rotate-left.x-rtl {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-title-rotate-left.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-left > .x-title-item {
    vertical-align: bottom;
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-horizontal.x-header .x-title-rotate-right.x-title-align-right > .x-title-item {
    vertical-align: top;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-left > .x-title-item {
    vertical-align: top;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-horizontal.x-header .x-title-rotate-left.x-title-align-right > .x-title-item {
    vertical-align: bottom;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-left > .x-title-item {
    vertical-align: top;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-center > .x-title-item {
    vertical-align: middle;
}

.x-vertical.x-header .x-title-rotate-none.x-title-align-right > .x-title-item {
    vertical-align: bottom;
}

.x-title-icon-wrap {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    line-height: 0;
}

.x-title-icon-wrap.x-title-icon-top,
.x-title-icon-wrap.x-title-icon-bottom {
    display: table-row;
}

.x-title-icon {
    display: inline-block;
    vertical-align: middle;
    background-position: center;
    background-repeat: no-repeat;
}

.x-tool {
    font-size: 16px;
    line-height: 1;
}

.x-header > .x-box-inner,
.x-panel-header-mc > .x-box-inner,
.x-window-header-mc > .x-box-inner {
    overflow: visible;
}

.x-splitter {
    font-size: 1px;
}

.x-splitter-horizontal {
    cursor: e-resize;
    cursor: row-resize;
}

.x-splitter-vertical {
    cursor: e-resize;
    cursor: col-resize;
}

.x-splitter-collapsed,
.x-splitter-horizontal-noresize,
.x-splitter-vertical-noresize {
    cursor: default;
}

.x-splitter-active {
    z-index: 4;
}

.x-collapse-el {
    position: absolute;
    background-repeat: no-repeat;
}

.x-splitter-focus {
    z-index: 4;
}

.x-box-layout-ct {
    overflow: hidden;
}

.x-box-target {
    position: absolute;
    width: 20000px;
    top: 0;
    left: 0;
    min-height: 1px;
}

.x-rtl.x-box-target {
    left: auto;
    right: 0;
}

.x-box-inner {
    overflow: hidden;
    position: relative;
    left: 0;
    top: 0;
}

.x-box-scroller {
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    line-height: 0;
    font-size: 0;
    text-align: center;
}

.x-box-scroller-top {
    top: 0;
}

.x-box-scroller-right {
    right: 0;
}

.x-box-scroller-bottom {
    bottom: 0;
}

.x-box-scroller-left {
    left: 0;
}

.x-box-menu-body-horizontal {
    float: left;
}

.x-box-menu-after {
    position: relative;
    float: left;
}

.x-box-scroller-body-vertical,
.x-box-scroller-body-horizontal {
    -ms-overflow-style: none;
}

.x-box-scroller-body-vertical .x-scroller,
.x-box-scroller-body-horizontal .x-scroller {
    -ms-overflow-style: auto;
}

.x-box-scroller-body-vertical :not(.x-scroller)::-webkit-scrollbar,
.x-box-scroller-body-horizontal :not(.x-scroller)::-webkit-scrollbar {
    display: none;
}

.x-toolbar-text {
    white-space: nowrap;
}

.x-toolbar-separator {
    display: block;
    font-size: 1px;
    overflow: hidden;
    cursor: default;
    border: 0;
    width: 0;
    height: 0;
    line-height: 0px;
}

.x-toolbar-scroller {
    padding-left: 0;
}

.x-toolbar-plain {
    border: 0;
}

.x-dd-drag-proxy,
.x-dd-drag-current {
    z-index: 1000000!important;
    pointer-events: none;
}

.x-dd-drag-proxy {
    display: table;
}

.x-dd-drag-repair .x-dd-drag-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-dd-drag-repair .x-dd-drop-icon {
    display: none;
}

.x-dd-drag-ghost,
.x-dd-drop-icon {
    display: table-cell;
    vertical-align: middle;
}

.x-dd-drag-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    opacity: 0.85;
    white-space: nowrap;
}

.x-dd-drop-icon {
    height: 26px;
    width: 26px;
    background-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
}

.x-rtl .x-dd-drag-ghost {
    padding-left: 5px;
    padding-right: 20px;
}

.x-rtl .x-dd-drop-icon {
    left: auto;
    right: 3px;
}

.x-docked {
    position: absolute !important;
    z-index: 2;
}

.x-docked-vertical {
    position: static;
}

.x-docked-top {
    border-bottom-width: 0 !important;
}

.x-docked-bottom {
    border-top-width: 0 !important;
}

.x-docked-left {
    border-right-width: 0 !important;
}

.x-docked-right {
    border-left-width: 0 !important;
}

.x-docked-noborder-top {
    border-top-width: 0 !important;
}

.x-docked-noborder-right {
    border-right-width: 0 !important;
}

.x-docked-noborder-bottom {
    border-bottom-width: 0 !important;
}

.x-docked-noborder-left {
    border-left-width: 0 !important;
}

.x-noborder-l {
    border-left-width: 0 !important;
}

.x-noborder-b {
    border-bottom-width: 0 !important;
}

.x-noborder-bl {
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-r {
    border-right-width: 0 !important;
}

.x-noborder-rl {
    border-right-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-rb {
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-rbl {
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-t {
    border-top-width: 0 !important;
}

.x-noborder-tl {
    border-top-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-tb {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-tbl {
    border-top-width: 0 !important;
    border-bottom-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-tr {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
}

.x-noborder-trl {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-left-width: 0 !important;
}

.x-noborder-trb {
    border-top-width: 0 !important;
    border-right-width: 0 !important;
    border-bottom-width: 0 !important;
}

.x-noborder-trbl {
    border-width: 0 !important;
}

.x-panel,
.x-plain {
    overflow: hidden;
    position: relative;
}

.x-panel {
    outline: none;
}

td.x-frame-mc {
    vertical-align: top;
}

.x-panel-bodyWrap {
    overflow: hidden;
    position: static;
    height: 100%;
    width: 100%;
}

.x-panel-body {
    overflow: hidden;
    position: relative;
}

.x-panel-header-plain,
.x-panel-body-plain {
    border: 0;
    padding: 0;
}

.x-panel-collapsed-mini {
    visibility: hidden;
}

.x-viewport > .x-body.x-panel > .x-panel-bodyWrap {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.x-table-layout {
    font-size: 1em;
}

.x-btn-group {
    position: relative;
    overflow: hidden;
}

.x-btn-group-body {
    position: relative;
}

.x-btn-group-body .x-table-layout-cell {
    vertical-align: top;
}

.x-btn-group-body .x-table-layout-cell .x-btn {
    width: 100%;
}

.x-viewport,
.x-viewport > .x-body {
    margin: 0;
    padding: 0;
    border: 0 none;
}

.x-viewport {
    width: 100%;
    height: 100%;
    position: static;
}

.x-viewport > .x-body {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
}

@media print {

    .x-viewport > .x-body {
        position: static;
    }
}

.x-column {
    float: left;
}

.x-rtl > .x-column {
    float: right;
}

.x-resizable-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 200000;
    background-color: #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-window {
    outline: none;
    overflow: hidden;
}

.x-window .x-window-wrap {
    position: relative;
}

.x-window-body {
    position: relative;
    overflow: hidden;
}

.x-form-item {
    display: table;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: separate;
}

.x-form-item-label {
    overflow: hidden;
}

.x-form-item.x-form-item-no-label > .x-form-item-label {
    display: none;
}

.x-form-item-label,
.x-form-item-body {
    display: table-cell;
}

.x-form-item-body {
    vertical-align: middle;
    height: 100%;
}

.x-form-item-label-inner {
    display: inline-block;
}

.x-form-item-label-top {
    display: table-row;
    height: 1px;
}

.x-form-item-label-top > .x-form-item-label-inner {
    display: table-cell;
}

.x-form-item-label-top-side-error:after {
    display: table-cell;
    content: '';
}

.x-form-item-label-right {
    text-align: right;
}

.x-form-item-label-right.x-rtl {
    text-align: left;
}

.x-form-error-wrap-side {
    display: table-cell;
    vertical-align: middle;
}

.x-form-error-wrap-under {
    display: table-row;
    height: 1px;
}

.x-form-error-wrap-under > .x-form-error-msg {
    display: table-cell;
}

.x-form-error-wrap-under-side-label:before {
    display: table-cell;
    content: '';
    pointer-events: none;
}

.x-form-invalid-icon {
    overflow: hidden;
}

.x-form-invalid-icon ul {
    display: none;
}

.x-form-trigger-wrap {
    display: table;
    width: 100%;
}

.x-form-text-heighted .x-form-trigger-wrap {
    height: 100%;
}

.x-gecko .x-form-trigger-wrap {
    display: -moz-inline-box;
    display: inline-flex;
    vertical-align: top;
}

.x-form-type-text.x-has-min-height {
    height: 1px;
}

.x-form-type-text.x-has-min-height .x-form-text-field-body,
.x-form-type-text.x-has-min-height .x-form-trigger-wrap,
.x-form-type-text.x-has-min-height .x-form-text-wrap {
    min-height: inherit;
}

.x-ie8 .x-form-type-text.x-has-min-height .x-form-text-field-body,
.x-ie8 .x-form-type-text.x-has-min-height .x-form-trigger-wrap,
.x-ie8 .x-form-type-text.x-has-min-height .x-form-text-wrap {
    min-height: inherit;
}

.x-ie8 .x-form-type-text.x-has-min-height .x-form-text {
    min-height: inherit;
}

.x-form-type-text.x-has-min-height .x-form-text {
    height: 100%;
}

.x-form-text-wrap {
    display: table-cell;
    overflow: hidden;
    height: 100%;
    position: relative;
}

.x-gecko .x-form-text-wrap {
    display: block;
    -moz-box-flex: 1;
    flex: 1;
}

.x-form-text-wrap .x-placeholder-label {
    position: absolute;
    top: 0;
    left: 0;
    cursor: text;
    white-space: nowrap;
    overflow: hidden;
}

.x-form-item-body.x-form-text-grow {
    min-width: inherit;
    max-width: inherit;
}

.x-form-text {
    border: 0;
    margin: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    display: block;
    background: repeat-x 0 0;
    width: 100%;
    height: 100%;
}

.x-form-trigger {
    display: table-cell;
    vertical-align: top;
    cursor: pointer;
    overflow: hidden;
    background-repeat: no-repeat;
    text-align: center;
    line-height: 0;
    white-space: nowrap;
}

.x-item-disabled .x-form-trigger {
    cursor: default;
}

.x-form-trigger.x-form-trigger-cmp {
    background: none;
    border: 0;
}

.x-form-trigger.x-form-trigger-cmp.x-rtl {
    background: none;
    border: 0;
}

.x-gecko .x-form-trigger {
    display: block;
}

.x-form-textarea-body.x-form-text-grow > .x-form-trigger-wrap {
    height: 100%;
}

.x-form-textarea {
    overflow: auto;
    resize: none;
}

div.x-form-text-grow .x-form-textarea {
    min-height: inherit;
}

.x-message-box .x-form-display-field {
    height: auto;
}

.x-safari .x-form-fieldcontainer {
    table-layout: auto;
}

.x-ie8 .x-form-fieldcontainer > .x-form-item-body.x-field-container-body-vertical {
    display: table-row;
}

.x-form-cb-wrap {
    vertical-align: top;
}

.x-form-cb-wrap-inner {
    position: relative;
}

.x-form-cb {
    position: absolute;
    left: 0;
    right: auto;
    vertical-align: top;
    overflow: hidden;
    padding: 0;
    border: 0;
}

.x-form-cb::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.x-form-cb-input {
    position: absolute;
    margin: 0;
    padding: 0;
    border: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-rtl.x-form-cb {
    right: 0;
    left: auto;
}

.x-form-cb-after {
    /* allow for the component to be positioned after the label */
    left: auto;
    right: 0;
}

.x-rtl.x-form-cb-after {
    left: 0;
    right: auto;
}

.x-form-cb-label {
    /* some browsers like IE 10 need a block element to be able to measure
the height of a multi-line element */
    display: inline-block;
}

.x-form-cb-label.x-form-cb-no-box-label {
    display: none;
}

.x-fieldset {
    display: block;
    /* preserve margins in IE */
    position: relative;
    overflow: hidden;
}

.x-safari:not(.x-safari10m) .x-fieldset {
    overflow: visible;
}

.x-fieldset-header {
    overflow: hidden;
}

.x-fieldset-header .x-form-item,
.x-fieldset-header .x-tool {
    float: left;
}

.x-fieldset-header .x-fieldset-header-text {
    float: left;
}

.x-fieldset-header .x-form-cb-wrap {
    font-size: 0;
    line-height: 0;
    min-height: 0;
    height: auto;
}

.x-fieldset-header .x-form-cb {
    margin: 0;
    position: static;
    display: inherit;
}

.x-fieldset-body {
    overflow: hidden;
}

.x-fieldset-collapsed {
    padding-bottom: 0 !important;
}

.x-fieldset-collapsed > .x-fieldset-body {
    display: none;
}

.x-fieldset-header-text-collapsible {
    cursor: pointer;
}

.x-rtl.x-fieldset-header .x-form-item,
.x-rtl.x-fieldset-header .x-tool {
    float: right;
}

.x-rtl.x-fieldset-header .x-fieldset-header-text {
    float: right;
}

.x-keyboard-mode .x-view-item-focused {
    outline: 1px dashed #5fa2dd !important;
    outline-offset: -1px;
}

.x-ie9 .x-boundlist-list-ct {
    min-height: 0%;
}

.x-datepicker {
    position: relative;
}

.x-datepicker .x-monthpicker {
    left: 0;
    top: 0;
    display: block;
}

.x-datepicker .x-monthpicker-months,
.x-datepicker .x-monthpicker-years {
    height: 100%;
}

.x-datepicker-inner {
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
}

.x-datepicker-cell {
    padding: 0;
}

.x-datepicker-header {
    position: relative;
}

.x-datepicker-arrow {
    position: absolute;
    outline: none;
    font-size: 0;
    display: table;
}

.x-datepicker-arrow:before {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.x-datepicker-column-header {
    padding: 0;
}

.x-datepicker-date {
    display: block;
    text-decoration: none;
}

.x-monthpicker {
    display: table;
}

.x-monthpicker-body {
    height: 100%;
    position: relative;
}

.x-monthpicker-months,
.x-monthpicker-years {
    float: left;
}

.x-monthpicker-item {
    float: left;
}

.x-monthpicker-item-inner {
    display: block;
    text-decoration: none;
}

.x-monthpicker-yearnav-button-ct {
    float: left;
    text-align: center;
}

.x-monthpicker-yearnav-button {
    display: inline-block;
    outline: none;
    font-size: 0;
}

.x-monthpicker-buttons {
    width: 100%;
}

.x-datepicker .x-monthpicker-buttons {
    position: absolute;
    bottom: 0;
}

.x-datepicker-month .x-btn-split > .x-btn-button:after {
    content: none;
}

.x-form-display-field-body {
    vertical-align: top;
}

.x-form-display-field-body .x-form-display-field {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is dangerous in WebKit as it breaks things at random points */
    word-break: break-all;
    /* Use this non-standard instead */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.x-form-trigger.x-form-trigger-cmp:before {
    content: none;
}

.x-form-file-btn {
    overflow: hidden;
    position: relative;
}

.x-form-file-input {
    border: 0;
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-indent: -10000px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    /* Yes, there's actually a good reason for this...
      * If the configured buttonText is set to something longer than the default,
      * then it will quickly exceed the width of the hidden file input's "Browse..."
      * button, so part of the custom button's clickable area will be covered by
      * the hidden file input's text box instead. This results in a text-selection
      * mouse cursor over that part of the button, at least in Firefox, which is
      * confusing to a user. Giving the hidden file input a huge font-size makes
      * the native button part very large so it will cover the whole clickable area.
      */
    font-size: 1000px;
}

.x-rtl.x-form-file-input {
    right: auto;
    left: 0;
}

.x-form-item-hidden {
    margin: 0;
}

.x-tip {
    position: absolute;
    overflow: visible;
}

.x-tip-body {
    overflow: hidden;
    position: relative;
}

.x-tip-anchor {
    position: absolute;
    border-style: solid;
    height: 0;
    width: 0;
}

.x-color-picker-item {
    float: left;
    text-decoration: none;
}

.x-color-picker-item-inner {
    display: block;
    font-size: 1px;
}

.x-html-editor-tb .x-toolbar {
    position: static !important;
}

.x-htmleditor-iframe,
.x-htmleditor-textarea {
    display: block;
    overflow: auto;
    width: 100%;
    height: 100%;
    border: 0;
}

.x-tagfield-body {
    vertical-align: top;
}

.x-tagfield {
    height: auto!important;
    /* The wrap has to accommodate the list, so override the .x-form-text height rule */
    cursor: text;
    overflow-y: auto;
}

.x-tagfield .x-tagfield-list {
    padding: 0;
    margin: 0;
}

.x-tagfield-list.x-tagfield-singleselect {
    white-space: nowrap;
    overflow: hidden;
}

.x-tagfield-input,
.x-tagfield-item {
    vertical-align: top;
    display: inline-block;
    position: relative;
}

.x-tagfield-input-field {
    font: inherit;
    border: 0;
    margin: 0;
    background: none;
    width: 100%;
}

.x-tagfield-input-field-nogrow {
    min-width: 3px;
    width: 3px;
    max-width: 130px;
    padding: 0;
}

.x-field.x-not-editable .x-tagfield-input-field-nogrow,
.x-tagfield-list.x-tagfield-singleselect .x-tagfield-input-field-nogrow {
    position: absolute;
}

.x-tagfield-stacked .x-tagfield-item {
    display: block;
}

.x-tagfield-item {
    cursor: default;
}

.x-tagfield-item-close {
    cursor: pointer;
    position: absolute;
}

.x-tagfield-arialist {
    list-style-type: none;
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.x-tagfield-arialist-item {
    list-style-type: none;
}

.x-fit-item {
    position: relative;
}

.x-grid-view {
    overflow: hidden;
    position: relative;
}

.x-grid-row-table {
    /* A grid *item* is a dataview item. It is encapsulated by a <table class="x-grid-item">.
 * One item always corresponds to one store record
 * But an item may contain more than one <tr>.
 * ONE child row, <tr class="x-grid-row"> will be the grid-row and will contain record data
 */
    width: 0;
    table-layout: fixed;
    border: 0 none;
    border-collapse: separate;
    border-spacing: 0;
}

.x-grid-item {
    table-layout: fixed;
    outline: none;
    position: relative;
}

.x-ie10 .x-grid-item {
    transform: translateZ(0);
}

.x-grid-row {
    outline: none;
}

.x-grid-td {
    overflow: hidden;
    border-width: 0;
    vertical-align: top;
}

.x-grid-cell-inner {
    overflow: hidden;
    white-space: nowrap;
}

.x-wrap-cell .x-grid-cell-inner {
    white-space: normal;
}

.x-grid-resize-marker {
    position: absolute;
    z-index: 5;
    top: 0;
}

.x-grid-item-container {
    min-height: 1px;
    position: relative;
}

.x-col-move-top,
.x-col-move-bottom {
    position: absolute;
    top: 0;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    z-index: 20000;
    background: no-repeat center top transparent;
}

.x-grid-header-ct {
    cursor: default;
    z-index: 3;
}

.x-column-header {
    position: absolute;
    overflow: hidden;
    background-repeat: repeat-x;
}

.x-column-header-inner {
    /*
 * TODO:
 * When IE8 retires, revisit https://jsbin.com/honawo/quiet for better way to center header text
 */
    white-space: nowrap;
    position: relative;
    overflow: hidden;
}

.x-leaf-column-header {
    height: 100%;
}

.x-leaf-column-header .x-column-header-text-container {
    height: 100%;
}

.x-column-header-text-container {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.x-column-header-text-container.x-column-header-text-container-auto {
    table-layout: auto;
}

.x-column-header-text-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.x-column-header-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.x-column-header-sort-DESC .x-column-header-text,
.x-column-header-sort-ASC .x-column-header-text {
    overflow: visible;
}

.x-column-header-text-inner {
    background-repeat: no-repeat;
}

.x-column-header-inner-empty .x-column-header-text-inner {
    display: none;
}

.x-column-header-inner-empty .x-column-header-text-container {
    line-height: 0;
}

.x-rtl.x-column-header-text {
    display: inline-block;
}

.x-column-header-trigger {
    display: none;
    height: 100%;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
}

.x-ie9m .x-column-header-trigger {
    z-index: 6;
}

.x-rtl.x-column-header-trigger {
    left: 0;
    right: auto;
}

.x-column-header-over .x-column-header-trigger,
.x-column-header-open .x-column-header-trigger {
    display: block;
}

.x-column-header-align-right {
    text-align: right;
}

.x-rtl.x-column-header-align-right {
    text-align: left;
}

.x-column-header-align-left {
    text-align: left;
}

.x-rtl.x-column-header-align-left {
    text-align: right;
}

.x-column-header-align-center {
    text-align: center;
}

.x-grid-cell-inner-action-col {
    line-height: 0;
    font-size: 0;
}

.x-keyboard-mode .x-grid-cell-inner-action-col :focus {
    outline: 1px solid #5fa2dd;
}

.x-action-col-icon {
    display: inline-block;
    line-height: 1;
}

.x-column-header-checkbox .x-column-header-text {
    margin-bottom: 0.4em;
}

.x-column-header-checkbox .x-column-header-inner-empty .x-column-header-text {
    margin-bottom: 0;
}

.x-grid-checkcolumn-cell-inner {
    line-height: 0;
}

.x-keyboard-mode .x-grid-checkcolumn-cell-inner :focus {
    outline: 1px solid #5fa2dd;
}

.x-rtl.x-grid-view .x-grid-advanced-group-title:before {
    right: 0;
}

.x-rtl.x-grid-view .x-grid-advanced-group-hd {
    border-width: 0 1px 0 0;
}

.x-rtl.x-grid-view .x-grid-advanced-group-record {
    border-width: 0 1px 0 0;
}

.x-group-hd-container {
    overflow: hidden;
}

.x-grid-group-hd {
    white-space: nowrap;
    outline: none;
}

.x-grid-group-title {
    position: relative;
}

.x-grid-group-title:before {
    position: absolute;
    left: 0;
}

.x-rtl.x-grid-view .x-grid-group-title:before {
    right: 0;
}

.x-grid-row-body-hidden,
.x-grid-group-collapsed {
    display: none;
}

.x-grid-row-body-hidden {
    display: none;
}

.x-menu {
    outline: none;
}

.x-menu-body {
    position: relative;
}

.x-menu-item {
    white-space: nowrap;
    overflow: hidden;
    border-color: transparent;
    border-style: solid;
}

.x-menu-item-cmp {
    margin: 2px;
}

.x-menu-item-cmp .x-field-label-cell {
    vertical-align: middle;
}

.x-menu-icon-separator {
    position: absolute;
    top: 0px;
    z-index: 0;
    height: 100%;
    overflow: hidden;
}

.x-menu-plain .x-menu-icon-separator {
    display: none;
}

.x-menu-item-link {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    text-decoration: none;
    outline: 0;
    display: block;
}

.x-menu-item-link-href {
    -webkit-touch-callout: default;
}

.x-menu-item-text {
    display: inline-block;
}

.x-menu-item-icon,
.x-menu-item-icon-right,
.x-menu-item-arrow {
    font-size: 0;
    position: absolute;
    text-align: center;
    background-repeat: no-repeat;
}

.x-grid-scroll-container {
    position: absolute;
    z-index: 1;
}

.x-grid-scrollbar-clipper-floated,
.x-grid-scrollbar-floated {
    z-index: 2;
}

.x-grid-scroll-body {
    width: 100%;
    position: relative;
}

.x-grid-scrollbar-clipper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.x-grid-scrollbar-clipper > .x-grid-view,
.x-grid-scrollbar-clipper > .x-tree-view {
    overflow-y: hidden !important;
}

.x-grid-with-col-lines .x-grid-scrollbar-clipper-locked .x-grid-cell-last {
    border-right-width: 0;
}

.x-rtl.x-grid-scrollbar-clipper {
    left: auto;
    right: 0;
}

.x-grid-with-col-lines .x-rtl.x-grid-scrollbar-clipper-locked .x-grid-cell-last {
    border-left-width: 0;
}

.x-grid-scrollbar {
    position: absolute;
    bottom: 0;
    left: 0;
    display: none;
}

.x-rtl.x-grid-scrollbar {
    left: auto;
    right: 0;
}

.x-grid-scrollbar-visible {
    display: block;
}

.x-grid-locking-body > .x-grid {
    z-index: 2;
    pointer-events: none;
}

.x-grid-locking-body > .x-grid .x-docked {
    pointer-events: auto;
}

.x-grid-locking-body .x-grid-body {
    background: transparent;
}

.x-grid-locking-body > .x-splitter {
    z-index: 2;
}

.x-grid-locking-body > .x-splitter-active {
    z-index: 4;
}

.x-grid-locking-body > .x-splitter-focus {
    z-index: 4;
}

.x-grid-inner-locked.x-border-region-slide-in {
    z-index: 3;
}

.x-grid-inner-locked.x-border-region-slide-in > .x-panel-bodyWrap > .x-grid-body > .x-grid-view,
.x-grid-inner-locked.x-border-region-slide-in > .x-panel-bodyWrap > .x-grid-body > .x-tree-view {
    overflow-y: hidden !important;
}

.x-grid-editor .x-form-cb-wrap {
    /*
 * Rules for fields which are rendered to fit inside grid cells.
 * This includes cell and row editor fields and fields in widget columns.
 */
    text-align: center;
}

.x-grid-editor .x-form-cb {
    position: static;
    display: inline-block;
}

.x-grid-editor .x-form-display-field {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
}

.x-grid-editor div.x-form-action-col-field {
    line-height: 0;
}

.x-grid-row-editor-wrap {
    position: absolute;
    overflow: visible;
    z-index: 2;
}

.x-grid-row-editor {
    position: absolute;
    z-index: 0;
}

.x-grid-row-editor-buttons {
    position: absolute;
    white-space: nowrap;
}

.x-grid-row-expander {
    font-size: 0;
    line-height: 0;
    text-align: center;
}

.x-keyboard-mode .x-grid-row-expander:focus {
    outline: 1px solid #5fa2dd;
}

.x-grid-hide-row-expander-spacer .x-grid-row-expander-spacer {
    display: none;
}

.x-grid-row-expander-spacer {
    border: 0 none;
}

.x-ssm-row-numberer-hd {
    cursor: se-resize!important;
}

.x-ssm-row-numberer-cell {
    cursor: e-resize;
}

.x-ssm-column-select .x-column-header {
    cursor: s-resize;
}

.x-ssm-extender-drag-handle {
    position: absolute;
    z-index: 1;
    cursor: crosshair;
}

.x-ssm-extender-mask {
    position: absolute;
    z-index: 1;
    pointer-events: none;
}

.x-abs-layout-ct {
    position: relative;
}

.x-abs-layout-item {
    position: absolute !important;
}

.x-border-layout-ct {
    overflow: hidden;
}

.x-border-layout-ct {
    position: relative;
}

.x-border-region-slide-in {
    z-index: 6;
}

.x-region-collapsed-placeholder {
    z-index: 4;
}

.x-center-layout-item {
    position: absolute;
}

.x-center-target {
    position: relative;
}

.x-form-layout-wrap {
    display: table;
    width: 100%;
    border-collapse: separate;
}

.x-form-layout-colgroup {
    display: table-column-group;
}

.x-form-layout-column {
    display: table-column;
}

.x-form-layout-auto-label > * > .x-form-item-label {
    width: auto !important;
}

.x-form-layout-auto-label > * > .x-form-item-label > .x-form-item-label-inner {
    width: auto !important;
    white-space: nowrap;
}

.x-form-layout-auto-label > * > .x-form-layout-label-column {
    width: 1px;
}

.x-form-layout-sized-label > * > .x-form-item-label {
    width: auto !important;
}

.x-form-form-item {
    display: table-row;
}

.x-form-form-item > .x-form-item-label {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
}

.x-form-form-item > .x-form-item-body {
    max-width: none;
}

.x-form-form-item.x-form-item-no-label:before {
    content: ' ';
    display: table-cell;
    pointer-events: none;
}

.x-resizable-wrapped {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.x-slider {
    outline: none;
    position: relative;
}

.x-slider-inner {
    position: relative;
    left: 0;
    top: 0;
    overflow: visible;
}

.x-slider-vert .x-slider-inner {
    background: repeat-y 0 0;
}

.x-slider-thumb {
    position: absolute;
    background: no-repeat 0 0;
    cursor: default;
    -webkit-user-callout: none;
    display: table;
    table-layout: fixed;
}

.x-slider-horz .x-slider-thumb {
    left: 0;
}

.x-slider-vert .x-slider-thumb {
    bottom: 0;
}

.x-slider-thumb:before {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.x-tab {
    display: block;
    outline: 0;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: top;
    overflow: hidden;
    position: relative;
}

.x-tab > .x-frame {
    height: 100%;
    width: 100%;
}

.x-tab-wrap {
    height: 100%;
    width: 100%;
    display: table;
    border-spacing: 0;
}

.x-tab-button {
    white-space: nowrap;
    line-height: 0;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}

.x-tab-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
}

.x-tab-icon.x-tab-no-text > .x-tab-inner {
    display: none;
}

.x-tab-icon-el {
    display: none;
    vertical-align: middle;
    text-align: center;
    background-position: center center;
    background-repeat: no-repeat;
}

.x-tab-icon > .x-tab-icon-el {
    display: inline-block;
}

.x-tab-icon-top > .x-tab-icon-el,
.x-tab-icon-bottom > .x-tab-icon-el {
    display: block;
}

.x-ie9m .x-tab-button-center {
    text-align: center;
}

.x-tab-button-center.x-tab-icon-top,
.x-tab-button-center.x-tab-icon-bottom {
    text-align: center;
}

.x-tab-button-left {
    text-align: left;
}

.x-rtl.x-tab-button-left {
    text-align: right;
}

.x-tab-button-right {
    text-align: right;
}

.x-tab-tooltip {
    display: none;
    position: absolute;
    pointer-events: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.x-btn-disabled .x-tab-tooltip {
    display: block;
}

.x-rtl.x-tab-button-right {
    text-align: left;
}

.x-tab-mc {
    overflow: visible;
}

.x-tab {
    z-index: 1;
}

.x-tab-active {
    z-index: 3;
}

.x-tab-button {
    position: relative;
}

.x-tab-close-btn {
    display: block;
    position: absolute;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
}

.x-tab-rotate-left {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-tab-rotate-left {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-tab-rotate-left.x-rtl {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-tab-rotate-left.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-rotate-right {
    -webkit-transform: rotate(90deg);
    -webkit-transform-origin: 0 0;
    -moz-transform: rotate(90deg);
    -moz-transform-origin: 0 0;
    -ms-transform: rotate(90deg);
    -ms-transform-origin: 0 0;
    transform: rotate(90deg);
    transform-origin: 0 0;
}

.x-ie8 .x-tab-rotate-right {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
}

.x-tab-rotate-right.x-rtl {
    -webkit-transform: rotate(270deg);
    -webkit-transform-origin: 100% 0;
    -moz-transform: rotate(270deg);
    -moz-transform-origin: 100% 0;
    -ms-transform: rotate(270deg);
    -ms-transform-origin: 100% 0;
    transform: rotate(270deg);
    transform-origin: 100% 0;
}

.x-ie8 .x-tab-rotate-right.x-rtl {
    -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
}

.x-tab-tr,
.x-tab-br,
.x-tab-mr,
.x-tab-tl,
.x-tab-bl,
.x-tab-ml {
    width: 1px;
}

.x-tab-bar {
    z-index: 0;
    position: relative;
}

.x-tab-bar-strip {
    position: absolute;
    line-height: 0;
    font-size: 0;
    z-index: 2;
}

.x-tab-bar-top > .x-tab-bar-strip {
    bottom: 0;
}

.x-tab-bar-bottom > .x-tab-bar-strip {
    top: 0;
}

.x-tab-bar-left > .x-tab-bar-strip {
    right: 0;
}

.x-tab-bar-left > .x-tab-bar-strip.x-rtl {
    right: auto;
    left: 0;
}

.x-tab-bar-right > .x-tab-bar-strip {
    left: 0;
}

.x-tab-bar-right > .x-tab-bar-strip.x-rtl {
    left: auto;
    right: 0;
}

.x-tab-bar-horizontal .x-tab-bar-strip {
    width: 100%;
    left: 0;
}

.x-tab-bar-vertical {
    display: table-cell;
}

.x-tab-bar-vertical .x-tab-bar-strip {
    height: 100%;
    top: 0;
}

.x-tab-bar-plain {
    background: transparent !important;
}

.x-box-scroller-plain {
    background-color: transparent !important;
}

.x-breadcrumb-btn .x-box-target:first-child {
    margin: 0;
}

.x-breadcrumb-btn > .x-btn-split-right > .x-btn-button:after {
    content: none;
}

.x-breadcrumb-btn.x-btn-over > .x-btn-split-right > .x-btn-button:after,
.x-breadcrumb-btn.x-btn-menu-active > .x-btn-split-right > .x-btn-button:after {
    content: '';
}

.x-autowidth-table .x-grid-item-container {
    overflow: visible;
}

.x-autowidth-table .x-grid-item {
    table-layout: auto;
    width: auto !important;
}

.x-tree-view {
    overflow: hidden;
}

.x-tree-elbow-img,
.x-tree-icon {
    display: inline-block;
    text-align: center;
    background-repeat: no-repeat;
    background-position: 0 center;
    vertical-align: top;
}

.x-tree-checkbox {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.x-tree-animator-wrap {
    overflow: hidden;
}

.x-body {
    color: #404040;
    font-size: 13px;
    line-height: 17px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    background: #f6f6f6;
}

.x-animating-size,
.x-collapsed {
    overflow: hidden!important;
}

.x-animating-size {
    z-index: 10000;
}

.x-editor .x-form-item-body {
    padding-bottom: 0;
}

.x-mask {
    background-image: none;
    background-color: rgba(208, 208, 208, 0.5);
    cursor: default;
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
}

.x-ie8 .x-mask {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#80d0d0d0, endColorstr=#80d0d0d0)";
    zoom: 1;
}

body > .x-mask {
    background-image: none;
    background-color: rgba(0, 0, 0, 0.75);
}

.x-ie8 body > .x-mask {
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#bf000000, endColorstr=#bf000000)";
    zoom: 1;
}

.x-keyboard-mode .x-mask.x-focus {
    border-style: solid;
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-mask-msg {
    padding: 10px;
    background: #d0d0d0;
}

.x-mask-msg-inner {
    padding: 0;
    background-color: transparent;
    color: #404040;
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-mask-msg-text {
    padding: 25px 0 0;
    background-image: url(images/loadmask/loading.gif);
    background-repeat: no-repeat;
    background-position: center 0;
}

.x-rtl.x-mask-msg-text {
    padding: 25px 0 0 0;
}

.x-progress-default {
    background-color: #ececec;
    border-width: 0;
    height: 24px;
    border-color: #5fa2dd;
    border-style: solid;
}

.x-progress-default .x-progress-bar-default {
    background-image: none;
    background-color: #7fb5e4;
}

.x-progress-default .x-progress-text {
    color: #fff;
    font-weight: 400;
    font-size: 13px;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-align: center;
    line-height: 24px;
}

.x-progress-default .x-progress-text-back {
    color: #404040;
    line-height: 24px;
}

.x-keyboard-mode .x-progress-default.x-progress-focus:after {
    position: absolute;
    content: ' ';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 0 solid #5fa2dd;
    pointer-events: none;
}

.x-progressbar-default-cell > .x-grid-cell-inner,
.x-progress-default-cell > .x-grid-cell-inner,
.x-progressbarwidget-default-cell > .x-grid-cell-inner {
    padding-top: 4px;
    padding-bottom: 4px;
}

.x-progressbar-default-cell > .x-grid-cell-inner .x-progress-default,
.x-progress-default-cell > .x-grid-cell-inner .x-progress-default,
.x-progressbarwidget-default-cell > .x-grid-cell-inner .x-progress-default {
    height: 24px;
}

.x-btn-default-small {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 7px 7px 7px 7px;
    border-width: 1px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-btn-default-small-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-small-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-7-7-7-7;
}

.x-btn-default-small-tl {
    background-position: 0 -2px;
}

.x-btn-default-small-tr {
    background-position: right -3px;
}

.x-btn-default-small-bl {
    background-position: 0 -4px;
}

.x-btn-default-small-br {
    background-position: right -5px;
}

.x-btn-default-small-ml {
    background-position: 0 top;
}

.x-btn-default-small-mr {
    background-position: right top;
}

.x-btn-default-small-tc {
    background-position: 0 0;
}

.x-btn-default-small-bc {
    background-position: 0 -1px;
}

.x-btn-default-small-tr,
.x-btn-default-small-br,
.x-btn-default-small-mr {
    padding-right: 1px;
}

.x-btn-default-small-tl,
.x-btn-default-small-bl,
.x-btn-default-small-ml {
    padding-left: 1px;
}

.x-btn-default-small-tc {
    height: 1px;
}

.x-btn-default-small-bc {
    height: 1px;
}

.x-btn-default-small-tl,
.x-btn-default-small-bl,
.x-btn-default-small-tr,
.x-btn-default-small-br,
.x-btn-default-small-tc,
.x-btn-default-small-bc,
.x-btn-default-small-ml,
.x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-corners.gif);
}

.x-btn-default-small-ml,
.x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-small-mc {
    padding: 7px 7px 7px 7px;
}

.x-btn-default-small {
    border-color: #5897ce;
}

.x-btn-button-default-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-default-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-default-small {
    font: 400 12px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #f0f0f0;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-default-small {
    max-width: none;
}

.x-btn-icon-el-default-small {
    font-size: 16px;
    height: 16px;
    color: #f0f0f0;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-default-small,
.x-btn-icon-right > .x-btn-icon-el-default-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-default-small,
.x-btn-icon-bottom > .x-btn-icon-el-default-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-default-small.x-btn-glyph {
    color: #f0f0f0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-default-small,
.x-btn-split-bottom > .x-btn-button-default-small {
    padding-bottom: 7px;
}

.x-btn-wrap-default-small.x-btn-arrow-right:after {
    width: 18px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: '\f0d7';
    font: 16px/12px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button {
    padding-right: 7px;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 7px;
}

.x-btn-wrap-default-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #f0f0f0;
}

.x-btn-wrap-default-small.x-btn-split-right:after {
    width: 20px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
    padding-left: 7px;
}

.x-btn-wrap-default-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 7px;
}

.x-btn-wrap-default-small.x-btn-split-bottom {
    margin-bottom: -7px;
}

.x-btn-wrap-default-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #f0f0f0;
}

.x-btn-wrap-default-small.x-btn-split-bottom:after {
    height: 20px;
    content: '\f0d7';
    font: 16px/20px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-small {
    background-image: none;
    background-color: #5fa2dd;
    -webkit-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    -moz-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
}

.x-btn-default-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 27px;
    pointer-events: none;
}

.x-btn-default-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-small.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #d3e0ec;
}

.x-btn-over.x-btn-default-small {
    border-color: #518bbd;
    background-image: none;
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-small {
    -webkit-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    -moz-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-small,
.x-btn.x-btn-pressed.x-btn-default-small {
    border-color: #42719a;
    background-image: none;
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-small {
    -webkit-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    -moz-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-small {
    background-image: none;
    background-color: #5fa2dd;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-small-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-small-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-small-mc {
    background-color: #5fa2dd;
}

.x-btn-over .x-btn-default-small-tl,
.x-btn-over .x-btn-default-small-bl,
.x-btn-over .x-btn-default-small-tr,
.x-btn-over .x-btn-default-small-br,
.x-btn-over .x-btn-default-small-tc,
.x-btn-over .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-over-corners.gif);
}

.x-btn-over .x-btn-default-small-ml,
.x-btn-over .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-over-sides.gif);
}

.x-btn-over .x-btn-default-small-mc {
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-small-mc {
    background-color: #5795cb;
}

.x-btn.x-btn-menu-active .x-btn-default-small-tl,
.x-btn.x-btn-menu-active .x-btn-default-small-bl,
.x-btn.x-btn-menu-active .x-btn-default-small-tr,
.x-btn.x-btn-menu-active .x-btn-default-small-br,
.x-btn.x-btn-menu-active .x-btn-default-small-tc,
.x-btn.x-btn-menu-active .x-btn-default-small-bc,
.x-btn.x-btn-pressed .x-btn-default-small-tl,
.x-btn.x-btn-pressed .x-btn-default-small-bl,
.x-btn.x-btn-pressed .x-btn-default-small-tr,
.x-btn.x-btn-pressed .x-btn-default-small-br,
.x-btn.x-btn-pressed .x-btn-default-small-tc,
.x-btn.x-btn-pressed .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-small-ml,
.x-btn.x-btn-menu-active .x-btn-default-small-mr,
.x-btn.x-btn-pressed .x-btn-default-small-ml,
.x-btn.x-btn-pressed .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-small-mc,
.x-btn.x-btn-pressed .x-btn-default-small-mc {
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-small-mc {
    background-color: #477aa6;
}

.x-btn.x-btn-disabled .x-btn-default-small-tl,
.x-btn.x-btn-disabled .x-btn-default-small-bl,
.x-btn.x-btn-disabled .x-btn-default-small-tr,
.x-btn.x-btn-disabled .x-btn-default-small-br,
.x-btn.x-btn-disabled .x-btn-default-small-tc,
.x-btn.x-btn-disabled .x-btn-default-small-bc {
    background-image: url(images/btn/btn-default-small-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-small-ml,
.x-btn.x-btn-disabled .x-btn-default-small-mr {
    background-image: url(images/btn/btn-default-small-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-small-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-small {
    background-image: none;
}

.x-btn-disabled.x-btn-default-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first .x-btn-default-small-mc {
    padding-right: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-middle .x-btn-default-small-mc {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-last .x-btn-default-small-mc {
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first .x-btn-default-small-mc {
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-middle .x-btn-default-small-mc {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-last .x-btn-default-small-mc {
    padding-top: 7px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-small.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-small.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-small:after {
    border-width: 1px;
    border-color: #d3e0ec;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-small:after {
    border-width: 1px;
    border-color: #d1dee9;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-small:after {
    border-width: 1px;
    border-color: #ced8e1;
}

.x-button-default-small-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-button-default-small-cell > .x-grid-cell-inner >.x-btn-default-small {
    vertical-align: top;
}

.x-btn-default-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 8px 8px 8px;
    border-width: 1px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-btn-default-medium-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-medium-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-8-8-8-8;
}

.x-btn-default-medium-tl {
    background-position: 0 -2px;
}

.x-btn-default-medium-tr {
    background-position: right -3px;
}

.x-btn-default-medium-bl {
    background-position: 0 -4px;
}

.x-btn-default-medium-br {
    background-position: right -5px;
}

.x-btn-default-medium-ml {
    background-position: 0 top;
}

.x-btn-default-medium-mr {
    background-position: right top;
}

.x-btn-default-medium-tc {
    background-position: 0 0;
}

.x-btn-default-medium-bc {
    background-position: 0 -1px;
}

.x-btn-default-medium-tr,
.x-btn-default-medium-br,
.x-btn-default-medium-mr {
    padding-right: 1px;
}

.x-btn-default-medium-tl,
.x-btn-default-medium-bl,
.x-btn-default-medium-ml {
    padding-left: 1px;
}

.x-btn-default-medium-tc {
    height: 1px;
}

.x-btn-default-medium-bc {
    height: 1px;
}

.x-btn-default-medium-tl,
.x-btn-default-medium-bl,
.x-btn-default-medium-tr,
.x-btn-default-medium-br,
.x-btn-default-medium-tc,
.x-btn-default-medium-bc,
.x-btn-default-medium-ml,
.x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-corners.gif);
}

.x-btn-default-medium-ml,
.x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-medium-mc {
    padding: 8px 8px 8px 8px;
}

.x-btn-default-medium {
    border-color: #5897ce;
}

.x-btn-button-default-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-default-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-default-medium {
    font: 400 14px/18px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #f0f0f0;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-default-medium {
    max-width: none;
}

.x-btn-icon-el-default-medium {
    font-size: 20px;
    height: 20px;
    color: #f0f0f0;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-default-medium,
.x-btn-icon-right > .x-btn-icon-el-default-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-default-medium,
.x-btn-icon-bottom > .x-btn-icon-el-default-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-default-medium.x-btn-glyph {
    color: #f0f0f0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-medium {
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-medium.x-rtl {
    margin-right: 0;
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium.x-rtl {
    margin-left: 0;
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-default-medium,
.x-btn-split-bottom > .x-btn-button-default-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-default-medium.x-btn-arrow-right:after {
    width: 20px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: '\f0d7';
    font: 20px/14px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button {
    padding-right: 8px;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-btn-wrap-default-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #f0f0f0;
}

.x-btn-wrap-default-medium.x-btn-split-right:after {
    width: 26px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
    padding-left: 8px;
}

.x-btn-wrap-default-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 8px;
}

.x-btn-wrap-default-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #f0f0f0;
}

.x-btn-wrap-default-medium.x-btn-split-bottom:after {
    height: 24px;
    content: '\f0d7';
    font: 20px/24px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-medium {
    background-image: none;
    background-color: #5fa2dd;
    -webkit-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    -moz-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
}

.x-btn-default-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 34px;
    pointer-events: none;
}

.x-btn-default-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-medium.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #d3e0ec;
}

.x-btn-over.x-btn-default-medium {
    border-color: #518bbd;
    background-image: none;
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-medium {
    -webkit-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    -moz-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-medium,
.x-btn.x-btn-pressed.x-btn-default-medium {
    border-color: #42719a;
    background-image: none;
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-medium {
    -webkit-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    -moz-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-medium {
    background-image: none;
    background-color: #5fa2dd;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-medium-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-medium-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-medium-mc {
    background-color: #5fa2dd;
}

.x-btn-over .x-btn-default-medium-tl,
.x-btn-over .x-btn-default-medium-bl,
.x-btn-over .x-btn-default-medium-tr,
.x-btn-over .x-btn-default-medium-br,
.x-btn-over .x-btn-default-medium-tc,
.x-btn-over .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-over-corners.gif);
}

.x-btn-over .x-btn-default-medium-ml,
.x-btn-over .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-over-sides.gif);
}

.x-btn-over .x-btn-default-medium-mc {
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-medium-mc {
    background-color: #5795cb;
}

.x-btn.x-btn-menu-active .x-btn-default-medium-tl,
.x-btn.x-btn-menu-active .x-btn-default-medium-bl,
.x-btn.x-btn-menu-active .x-btn-default-medium-tr,
.x-btn.x-btn-menu-active .x-btn-default-medium-br,
.x-btn.x-btn-menu-active .x-btn-default-medium-tc,
.x-btn.x-btn-menu-active .x-btn-default-medium-bc,
.x-btn.x-btn-pressed .x-btn-default-medium-tl,
.x-btn.x-btn-pressed .x-btn-default-medium-bl,
.x-btn.x-btn-pressed .x-btn-default-medium-tr,
.x-btn.x-btn-pressed .x-btn-default-medium-br,
.x-btn.x-btn-pressed .x-btn-default-medium-tc,
.x-btn.x-btn-pressed .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-medium-ml,
.x-btn.x-btn-menu-active .x-btn-default-medium-mr,
.x-btn.x-btn-pressed .x-btn-default-medium-ml,
.x-btn.x-btn-pressed .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-medium-mc,
.x-btn.x-btn-pressed .x-btn-default-medium-mc {
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-medium-mc {
    background-color: #477aa6;
}

.x-btn.x-btn-disabled .x-btn-default-medium-tl,
.x-btn.x-btn-disabled .x-btn-default-medium-bl,
.x-btn.x-btn-disabled .x-btn-default-medium-tr,
.x-btn.x-btn-disabled .x-btn-default-medium-br,
.x-btn.x-btn-disabled .x-btn-default-medium-tc,
.x-btn.x-btn-disabled .x-btn-default-medium-bc {
    background-image: url(images/btn/btn-default-medium-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-medium-ml,
.x-btn.x-btn-disabled .x-btn-default-medium-mr {
    background-image: url(images/btn/btn-default-medium-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-medium-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-default-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first .x-btn-default-medium-mc {
    padding-right: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-middle .x-btn-default-medium-mc {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-last .x-btn-default-medium-mc {
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first .x-btn-default-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-middle .x-btn-default-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-last .x-btn-default-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-medium.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-medium.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-medium:after {
    border-width: 1px;
    border-color: #d3e0ec;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-medium:after {
    border-width: 1px;
    border-color: #d1dee9;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-medium:after {
    border-width: 1px;
    border-color: #ced8e1;
}

.x-button-default-medium-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-default-medium-cell > .x-grid-cell-inner >.x-btn-default-medium {
    vertical-align: top;
}

.x-btn-default-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 9px 9px 9px 9px;
    border-width: 1px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-btn-default-large-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-large-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-9-9-9-9;
}

.x-btn-default-large-tl {
    background-position: 0 -2px;
}

.x-btn-default-large-tr {
    background-position: right -3px;
}

.x-btn-default-large-bl {
    background-position: 0 -4px;
}

.x-btn-default-large-br {
    background-position: right -5px;
}

.x-btn-default-large-ml {
    background-position: 0 top;
}

.x-btn-default-large-mr {
    background-position: right top;
}

.x-btn-default-large-tc {
    background-position: 0 0;
}

.x-btn-default-large-bc {
    background-position: 0 -1px;
}

.x-btn-default-large-tr,
.x-btn-default-large-br,
.x-btn-default-large-mr {
    padding-right: 1px;
}

.x-btn-default-large-tl,
.x-btn-default-large-bl,
.x-btn-default-large-ml {
    padding-left: 1px;
}

.x-btn-default-large-tc {
    height: 1px;
}

.x-btn-default-large-bc {
    height: 1px;
}

.x-btn-default-large-tl,
.x-btn-default-large-bl,
.x-btn-default-large-tr,
.x-btn-default-large-br,
.x-btn-default-large-tc,
.x-btn-default-large-bc,
.x-btn-default-large-ml,
.x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-corners.gif);
}

.x-btn-default-large-ml,
.x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-large-mc {
    padding: 9px 9px 9px 9px;
}

.x-btn-default-large {
    border-color: #5897ce;
}

.x-btn-button-default-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-default-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-default-large {
    font: 400 16px/20px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #f0f0f0;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-default-large {
    max-width: none;
}

.x-btn-icon-el-default-large {
    font-size: 24px;
    height: 24px;
    color: #f0f0f0;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-default-large,
.x-btn-icon-right > .x-btn-icon-el-default-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-default-large,
.x-btn-icon-bottom > .x-btn-icon-el-default-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-default-large.x-btn-glyph {
    color: #f0f0f0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-default-large,
.x-btn-split-bottom > .x-btn-button-default-large {
    padding-bottom: 9px;
}

.x-btn-wrap-default-large.x-btn-arrow-right:after {
    width: 22px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: '\f0d7';
    font: 24px/16px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button {
    padding-right: 9px;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 9px;
}

.x-btn-wrap-default-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #f0f0f0;
}

.x-btn-wrap-default-large.x-btn-split-right:after {
    width: 32px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
    padding-left: 9px;
}

.x-btn-wrap-default-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 9px;
}

.x-btn-wrap-default-large.x-btn-split-bottom {
    margin-bottom: -9px;
}

.x-btn-wrap-default-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #f0f0f0;
}

.x-btn-wrap-default-large.x-btn-split-bottom:after {
    height: 28px;
    content: '\f0d7';
    font: 24px/28px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-large {
    background-image: none;
    background-color: #5fa2dd;
    -webkit-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    -moz-box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
    box-shadow: #d3e0ec 0 1px 0px 0 inset, #d3e0ec 0 -1px 0px 0 inset, #d3e0ec -1px 0 0px 0 inset, #d3e0ec 1px 0 0px 0 inset;
}

.x-btn-default-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 41px;
    pointer-events: none;
}

.x-btn-default-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-large.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #d3e0ec;
}

.x-btn-over.x-btn-default-large {
    border-color: #518bbd;
    background-image: none;
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-large {
    -webkit-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    -moz-box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
    box-shadow: #d1dee9 0 1px 0px 0 inset, #d1dee9 0 -1px 0px 0 inset, #d1dee9 -1px 0 0px 0 inset, #d1dee9 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-large,
.x-btn.x-btn-pressed.x-btn-default-large {
    border-color: #42719a;
    background-image: none;
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-large {
    -webkit-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    -moz-box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
    box-shadow: #ced8e1 0 1px 0px 0 inset, #ced8e1 0 -1px 0px 0 inset, #ced8e1 -1px 0 0px 0 inset, #ced8e1 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-large {
    background-image: none;
    background-color: #5fa2dd;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-large-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-large-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-large-mc {
    background-color: #5fa2dd;
}

.x-btn-over .x-btn-default-large-tl,
.x-btn-over .x-btn-default-large-bl,
.x-btn-over .x-btn-default-large-tr,
.x-btn-over .x-btn-default-large-br,
.x-btn-over .x-btn-default-large-tc,
.x-btn-over .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-over-corners.gif);
}

.x-btn-over .x-btn-default-large-ml,
.x-btn-over .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-over-sides.gif);
}

.x-btn-over .x-btn-default-large-mc {
    background-color: #5795cb;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-large-mc {
    background-color: #5795cb;
}

.x-btn.x-btn-menu-active .x-btn-default-large-tl,
.x-btn.x-btn-menu-active .x-btn-default-large-bl,
.x-btn.x-btn-menu-active .x-btn-default-large-tr,
.x-btn.x-btn-menu-active .x-btn-default-large-br,
.x-btn.x-btn-menu-active .x-btn-default-large-tc,
.x-btn.x-btn-menu-active .x-btn-default-large-bc,
.x-btn.x-btn-pressed .x-btn-default-large-tl,
.x-btn.x-btn-pressed .x-btn-default-large-bl,
.x-btn.x-btn-pressed .x-btn-default-large-tr,
.x-btn.x-btn-pressed .x-btn-default-large-br,
.x-btn.x-btn-pressed .x-btn-default-large-tc,
.x-btn.x-btn-pressed .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-large-ml,
.x-btn.x-btn-menu-active .x-btn-default-large-mr,
.x-btn.x-btn-pressed .x-btn-default-large-ml,
.x-btn.x-btn-pressed .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-large-mc,
.x-btn.x-btn-pressed .x-btn-default-large-mc {
    background-color: #477aa6;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-large-mc {
    background-color: #477aa6;
}

.x-btn.x-btn-disabled .x-btn-default-large-tl,
.x-btn.x-btn-disabled .x-btn-default-large-bl,
.x-btn.x-btn-disabled .x-btn-default-large-tr,
.x-btn.x-btn-disabled .x-btn-default-large-br,
.x-btn.x-btn-disabled .x-btn-default-large-tc,
.x-btn.x-btn-disabled .x-btn-default-large-bc {
    background-image: url(images/btn/btn-default-large-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-large-ml,
.x-btn.x-btn-disabled .x-btn-default-large-mr {
    background-image: url(images/btn/btn-default-large-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-large-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-default-large {
    background-image: none;
}

.x-btn-disabled.x-btn-default-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first .x-btn-default-large-mc {
    padding-right: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-middle .x-btn-default-large-mc {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-last .x-btn-default-large-mc {
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first .x-btn-default-large-mc {
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-middle .x-btn-default-large-mc {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-last .x-btn-default-large-mc {
    padding-top: 9px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-large.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-large.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-large:after {
    border-width: 1px;
    border-color: #d3e0ec;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-large:after {
    border-width: 1px;
    border-color: #d1dee9;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-large:after {
    border-width: 1px;
    border-color: #ced8e1;
}

.x-button-default-large-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-default-large-cell > .x-grid-cell-inner >.x-btn-default-large {
    vertical-align: top;
}

.x-btn-default-toolbar-small {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 7px 7px 7px 7px;
    border-width: 1px;
    border-style: solid;
    background-color: #f6f6f6;
}

.x-btn-default-toolbar-small-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-small-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-7-7-7-7;
}

.x-btn-default-toolbar-small-tl {
    background-position: 0 -2px;
}

.x-btn-default-toolbar-small-tr {
    background-position: right -3px;
}

.x-btn-default-toolbar-small-bl {
    background-position: 0 -4px;
}

.x-btn-default-toolbar-small-br {
    background-position: right -5px;
}

.x-btn-default-toolbar-small-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-small-mr {
    background-position: right top;
}

.x-btn-default-toolbar-small-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-small-bc {
    background-position: 0 -1px;
}

.x-btn-default-toolbar-small-tr,
.x-btn-default-toolbar-small-br,
.x-btn-default-toolbar-small-mr {
    padding-right: 1px;
}

.x-btn-default-toolbar-small-tl,
.x-btn-default-toolbar-small-bl,
.x-btn-default-toolbar-small-ml {
    padding-left: 1px;
}

.x-btn-default-toolbar-small-tc {
    height: 1px;
}

.x-btn-default-toolbar-small-bc {
    height: 1px;
}

.x-btn-default-toolbar-small-tl,
.x-btn-default-toolbar-small-bl,
.x-btn-default-toolbar-small-tr,
.x-btn-default-toolbar-small-br,
.x-btn-default-toolbar-small-tc,
.x-btn-default-toolbar-small-bc,
.x-btn-default-toolbar-small-ml,
.x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-corners.gif);
}

.x-btn-default-toolbar-small-ml,
.x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-toolbar-small-mc {
    padding: 7px 7px 7px 7px;
}

.x-btn-default-toolbar-small {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-default-toolbar-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-default-toolbar-small {
    font: 400 12px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-default-toolbar-small {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-small {
    font-size: 16px;
    height: 16px;
    color: #919191;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-small,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-small.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-small,
.x-btn-split-bottom > .x-btn-button-default-toolbar-small {
    padding-bottom: 7px;
}

.x-btn-wrap-default-toolbar-small.x-btn-arrow-right:after {
    width: 18px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-small.x-btn-arrow-bottom:after {
    height: 12px;
    content: '\f0d7';
    font: 16px/12px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button {
    padding-right: 7px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 7px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-right:after {
    width: 20px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 7px;
}

.x-btn-wrap-default-toolbar-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 7px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom {
    margin-bottom: -7px;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-small.x-btn-split-bottom:after {
    height: 20px;
    content: '\f0d7';
    font: 16px/20px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-small {
    background-image: none;
    background-color: #f6f6f6;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-default-toolbar-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 27px;
    pointer-events: none;
}

.x-btn-default-toolbar-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-small.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-default-toolbar-small {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-small {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-small,
.x-btn.x-btn-pressed.x-btn-default-toolbar-small {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-small,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-small {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-small {
    background-image: none;
    background-color: #f6f6f6;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-small-mc {
    background-color: #f6f6f6;
}

.x-btn-over .x-btn-default-toolbar-small-tl,
.x-btn-over .x-btn-default-toolbar-small-bl,
.x-btn-over .x-btn-default-toolbar-small-tr,
.x-btn-over .x-btn-default-toolbar-small-br,
.x-btn-over .x-btn-default-toolbar-small-tc,
.x-btn-over .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-over-corners.gif);
}

.x-btn-over .x-btn-default-toolbar-small-ml,
.x-btn-over .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-over-sides.gif);
}

.x-btn-over .x-btn-default-toolbar-small-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-small-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-tl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-bl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-tr,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-br,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-tc,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-bc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-tl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-bl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-tr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-br,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-tc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-ml,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-mr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-ml,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-small-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-small-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-small-mc {
    background-color: #e2e2e2;
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-small-tl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-bl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-tr,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-br,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-tc,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-bc {
    background-image: url(images/btn/btn-default-toolbar-small-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-small-ml,
.x-btn.x-btn-disabled .x-btn-default-toolbar-small-mr {
    background-image: url(images/btn/btn-default-toolbar-small-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-small-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-small {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first .x-btn-default-toolbar-small-mc {
    padding-right: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-middle .x-btn-default-toolbar-small-mc {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-last .x-btn-default-toolbar-small-mc {
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first .x-btn-default-toolbar-small-mc {
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-middle .x-btn-default-toolbar-small-mc {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-last .x-btn-default-toolbar-small-mc {
    padding-top: 7px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-small.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-small.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-small:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-small:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-default-toolbar-small-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-button-default-toolbar-small-cell > .x-grid-cell-inner >.x-btn-default-toolbar-small {
    vertical-align: top;
}

.x-btn-default-toolbar-medium {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 8px 8px 8px 8px;
    border-width: 1px;
    border-style: solid;
    background-color: #f6f6f6;
}

.x-btn-default-toolbar-medium-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-medium {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-medium-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-8-8-8-8;
}

.x-btn-default-toolbar-medium-tl {
    background-position: 0 -2px;
}

.x-btn-default-toolbar-medium-tr {
    background-position: right -3px;
}

.x-btn-default-toolbar-medium-bl {
    background-position: 0 -4px;
}

.x-btn-default-toolbar-medium-br {
    background-position: right -5px;
}

.x-btn-default-toolbar-medium-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-medium-mr {
    background-position: right top;
}

.x-btn-default-toolbar-medium-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-medium-bc {
    background-position: 0 -1px;
}

.x-btn-default-toolbar-medium-tr,
.x-btn-default-toolbar-medium-br,
.x-btn-default-toolbar-medium-mr {
    padding-right: 1px;
}

.x-btn-default-toolbar-medium-tl,
.x-btn-default-toolbar-medium-bl,
.x-btn-default-toolbar-medium-ml {
    padding-left: 1px;
}

.x-btn-default-toolbar-medium-tc {
    height: 1px;
}

.x-btn-default-toolbar-medium-bc {
    height: 1px;
}

.x-btn-default-toolbar-medium-tl,
.x-btn-default-toolbar-medium-bl,
.x-btn-default-toolbar-medium-tr,
.x-btn-default-toolbar-medium-br,
.x-btn-default-toolbar-medium-tc,
.x-btn-default-toolbar-medium-bc,
.x-btn-default-toolbar-medium-ml,
.x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-corners.gif);
}

.x-btn-default-toolbar-medium-ml,
.x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-toolbar-medium-mc {
    padding: 8px 8px 8px 8px;
}

.x-btn-default-toolbar-medium {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-medium {
    min-height: 20px;
}

.x-ie9m .x-btn-button-default-toolbar-medium {
    min-height: auto;
    height: 20px;
}

.x-btn-inner-default-toolbar-medium {
    font: 400 14px/18px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 8px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-medium,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-medium {
    max-width: calc(100% - 20px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-medium,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-medium {
    max-width: calc(100% - 19px);
}

.x-ie10p .x-btn-inner-default-toolbar-medium {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-medium {
    font-size: 20px;
    height: 20px;
    color: #919191;
    line-height: 20px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    width: 20px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-medium,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-medium {
    min-width: 20px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-medium.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-medium.x-rtl {
    margin-right: 0;
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-left: 0px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium.x-rtl {
    margin-left: 0;
    margin-right: 0px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-medium {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-medium {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-medium {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 8px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-medium,
.x-btn-split-bottom > .x-btn-button-default-toolbar-medium {
    padding-bottom: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-arrow-right:after {
    width: 20px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-medium.x-btn-arrow-bottom:after {
    height: 14px;
    content: '\f0d7';
    font: 20px/14px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button {
    padding-right: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-right:after {
    width: 26px;
    content: '\f0d7';
    font: 20px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom {
    margin-bottom: -8px;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-medium.x-btn-split-bottom:after {
    height: 24px;
    content: '\f0d7';
    font: 20px/24px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-medium {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-medium {
    margin-right: 8px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-medium {
    background-image: none;
    background-color: #f6f6f6;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-default-toolbar-medium .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 34px;
    pointer-events: none;
}

.x-btn-default-toolbar-medium .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-medium.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-default-toolbar-medium {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-medium {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-medium,
.x-btn.x-btn-pressed.x-btn-default-toolbar-medium {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-medium,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-medium {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-medium {
    background-image: none;
    background-color: #f6f6f6;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-medium-mc {
    background-color: #f6f6f6;
}

.x-btn-over .x-btn-default-toolbar-medium-tl,
.x-btn-over .x-btn-default-toolbar-medium-bl,
.x-btn-over .x-btn-default-toolbar-medium-tr,
.x-btn-over .x-btn-default-toolbar-medium-br,
.x-btn-over .x-btn-default-toolbar-medium-tc,
.x-btn-over .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-over-corners.gif);
}

.x-btn-over .x-btn-default-toolbar-medium-ml,
.x-btn-over .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-over-sides.gif);
}

.x-btn-over .x-btn-default-toolbar-medium-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-medium-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-tl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-bl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-tr,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-br,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-tc,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-bc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-tl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-bl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-tr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-br,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-tc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-ml,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-mr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-ml,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-medium-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-medium-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-medium-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-medium-mc {
    background-color: #e2e2e2;
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-tl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-bl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-tr,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-br,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-tc,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-bc {
    background-image: url(images/btn/btn-default-toolbar-medium-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-ml,
.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-mr {
    background-image: url(images/btn/btn-default-toolbar-medium-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-medium-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-medium {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-medium {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first .x-btn-default-toolbar-medium-mc {
    padding-right: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-middle .x-btn-default-toolbar-medium-mc {
    padding-right: 8px !important;
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-last .x-btn-default-toolbar-medium-mc {
    padding-left: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first .x-btn-default-toolbar-medium-mc {
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-middle .x-btn-default-toolbar-medium-mc {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-last .x-btn-default-toolbar-medium-mc {
    padding-top: 8px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-medium:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-medium.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-medium.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-medium:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-medium:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-default-toolbar-medium-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-default-toolbar-medium-cell > .x-grid-cell-inner >.x-btn-default-toolbar-medium {
    vertical-align: top;
}

.x-btn-default-toolbar-large {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 9px 9px 9px 9px;
    border-width: 1px;
    border-style: solid;
    background-color: #f6f6f6;
}

.x-btn-default-toolbar-large-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-large {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-default-toolbar-large-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-9-9-9-9;
}

.x-btn-default-toolbar-large-tl {
    background-position: 0 -2px;
}

.x-btn-default-toolbar-large-tr {
    background-position: right -3px;
}

.x-btn-default-toolbar-large-bl {
    background-position: 0 -4px;
}

.x-btn-default-toolbar-large-br {
    background-position: right -5px;
}

.x-btn-default-toolbar-large-ml {
    background-position: 0 top;
}

.x-btn-default-toolbar-large-mr {
    background-position: right top;
}

.x-btn-default-toolbar-large-tc {
    background-position: 0 0;
}

.x-btn-default-toolbar-large-bc {
    background-position: 0 -1px;
}

.x-btn-default-toolbar-large-tr,
.x-btn-default-toolbar-large-br,
.x-btn-default-toolbar-large-mr {
    padding-right: 1px;
}

.x-btn-default-toolbar-large-tl,
.x-btn-default-toolbar-large-bl,
.x-btn-default-toolbar-large-ml {
    padding-left: 1px;
}

.x-btn-default-toolbar-large-tc {
    height: 1px;
}

.x-btn-default-toolbar-large-bc {
    height: 1px;
}

.x-btn-default-toolbar-large-tl,
.x-btn-default-toolbar-large-bl,
.x-btn-default-toolbar-large-tr,
.x-btn-default-toolbar-large-br,
.x-btn-default-toolbar-large-tc,
.x-btn-default-toolbar-large-bc,
.x-btn-default-toolbar-large-ml,
.x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-corners.gif);
}

.x-btn-default-toolbar-large-ml,
.x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-default-toolbar-large-mc {
    padding: 9px 9px 9px 9px;
}

.x-btn-default-toolbar-large {
    border-color: #e4e4e4;
}

.x-btn-button-default-toolbar-large {
    min-height: 24px;
}

.x-ie9m .x-btn-button-default-toolbar-large {
    min-height: auto;
    height: 24px;
}

.x-btn-inner-default-toolbar-large {
    font: 400 16px/20px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #606060;
    padding: 0 10px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-default-toolbar-large,
.x-ie9 .x-btn-icon-left > .x-btn-inner-default-toolbar-large {
    max-width: calc(100% - 24px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-default-toolbar-large,
.x-safari8m .x-btn-icon-left > .x-btn-inner-default-toolbar-large {
    max-width: calc(100% - 23px);
}

.x-ie10p .x-btn-inner-default-toolbar-large {
    max-width: none;
}

.x-btn-icon-el-default-toolbar-large {
    font-size: 24px;
    height: 24px;
    color: #919191;
    line-height: 24px;
}

.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large,
.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    width: 24px;
}

.x-btn-icon-top > .x-btn-icon-el-default-toolbar-large,
.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-large {
    min-width: 24px;
}

.x-ie8 .x-btn-icon-el-default-toolbar-large.x-btn-glyph {
    color: #919191;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large {
    margin-right: 0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-default-toolbar-large.x-rtl {
    margin-right: 0;
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-left: 0;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large.x-rtl {
    margin-left: 0;
    margin-right: 0;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-default-toolbar-large {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-default-toolbar-large {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-large {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-right: 10px;
}

.x-btn-arrow-bottom > .x-btn-button-default-toolbar-large,
.x-btn-split-bottom > .x-btn-button-default-toolbar-large {
    padding-bottom: 9px;
}

.x-btn-wrap-default-toolbar-large.x-btn-arrow-right:after {
    width: 22px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-large.x-btn-arrow-bottom:after {
    height: 16px;
    content: '\f0d7';
    font: 24px/16px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button {
    padding-right: 9px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 9px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-right:after {
    width: 32px;
    content: '\f0d7';
    font: 24px/1 'Font Awesome 5 Free';
    color: #606060;
    padding-left: 9px;
}

.x-btn-wrap-default-toolbar-large.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 9px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom {
    margin-bottom: -9px;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #e4e4e4;
}

.x-btn-wrap-default-toolbar-large.x-btn-split-bottom:after {
    height: 28px;
    content: '\f0d7';
    font: 24px/28px 'Font Awesome 5 Free';
    color: #606060;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-default-toolbar-large {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-default-toolbar-large {
    margin-right: 10px;
}

.x-keyboard-mode .x-btn-focus.x-btn-default-toolbar-large {
    background-image: none;
    background-color: #f6f6f6;
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn-default-toolbar-large .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 41px;
    pointer-events: none;
}

.x-btn-default-toolbar-large .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-keyboard-mode .x-btn-default-toolbar-large.x-arrow-focus .x-btn-arrow-el {
    border: 1px solid #5fa2dd;
}

.x-btn-over.x-btn-default-toolbar-large {
    border-color: #dbdbdb;
    background-image: none;
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over.x-btn-default-toolbar-large {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-menu-active.x-btn-default-toolbar-large,
.x-btn.x-btn-pressed.x-btn-default-toolbar-large {
    border-color: #d2d2d2;
    background-image: none;
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-large,
.x-keyboard-mode .x-btn-focus.x-btn-pressed.x-btn-default-toolbar-large {
    -webkit-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    -moz-box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
    box-shadow: #5fa2dd 0 1px 0px 0 inset, #5fa2dd 0 -1px 0px 0 inset, #5fa2dd -1px 0 0px 0 inset, #5fa2dd 1px 0 0px 0 inset;
}

.x-btn.x-btn-disabled.x-btn-default-toolbar-large {
    background-image: none;
    background-color: #f6f6f6;
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-br,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-focus-corners.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-focus-sides.gif);
}

.x-keyboard-mode .x-btn-focus .x-btn-default-toolbar-large-mc {
    background-color: #f6f6f6;
}

.x-btn-over .x-btn-default-toolbar-large-tl,
.x-btn-over .x-btn-default-toolbar-large-bl,
.x-btn-over .x-btn-default-toolbar-large-tr,
.x-btn-over .x-btn-default-toolbar-large-br,
.x-btn-over .x-btn-default-toolbar-large-tc,
.x-btn-over .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-over-corners.gif);
}

.x-btn-over .x-btn-default-toolbar-large-ml,
.x-btn-over .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-over-sides.gif);
}

.x-btn-over .x-btn-default-toolbar-large-mc {
    background-color: #ececec;
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-focus-over-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-focus-over-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-default-toolbar-large-mc {
    background-color: #ececec;
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-tl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-bl,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-tr,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-br,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-tc,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-bc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-tl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-bl,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-tr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-br,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-tc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-pressed-corners.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-ml,
.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-mr,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-ml,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-pressed-sides.gif);
}

.x-btn.x-btn-menu-active .x-btn-default-toolbar-large-mc,
.x-btn.x-btn-pressed .x-btn-default-toolbar-large-mc {
    background-color: #e2e2e2;
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-bc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-tl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-bl,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-tr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-br,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-tc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-focus-pressed-corners.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-mr,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-ml,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-focus-pressed-sides.gif);
}

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-default-toolbar-large-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-default-toolbar-large-mc {
    background-color: #e2e2e2;
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-large-tl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-bl,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-tr,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-br,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-tc,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-bc {
    background-image: url(images/btn/btn-default-toolbar-large-disabled-corners.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-large-ml,
.x-btn.x-btn-disabled .x-btn-default-toolbar-large-mr {
    background-image: url(images/btn/btn-default-toolbar-large-disabled-sides.gif);
}

.x-btn.x-btn-disabled .x-btn-default-toolbar-large-mc {
    background-color: #f6f6f6;
}

.x-nbr .x-btn-default-toolbar-large {
    background-image: none;
}

.x-btn-disabled.x-btn-default-toolbar-large {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first .x-btn-default-toolbar-large-mc {
    padding-right: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-middle .x-btn-default-toolbar-large-mc {
    padding-right: 9px !important;
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-last .x-btn-default-toolbar-large-mc {
    padding-left: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first .x-btn-default-toolbar-large-mc {
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-middle .x-btn-default-toolbar-large-mc {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-last .x-btn-default-toolbar-large-mc {
    padding-top: 9px !important;
}

.x-nbr .x-segmented-button-item.x-btn-default-toolbar-large:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-default-toolbar-large.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-default-toolbar-large.x-segmented-button-last:after {
    bottom: 1px;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-over.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-menu-active.x-btn-default-toolbar-large:after,
.x-keyboard-mode .x-nbr .x-segmented-button-item.x-btn-focus.x-btn-pressed.x-btn-default-toolbar-large:after {
    border-width: 1px;
    border-color: #5fa2dd;
}

.x-button-default-toolbar-large-cell > .x-grid-cell-inner {
    padding-top: 0;
    padding-bottom: 0;
}

.x-button-default-toolbar-large-cell > .x-grid-cell-inner >.x-btn-default-toolbar-large {
    vertical-align: top;
}

.x-tool {
    cursor: pointer;
}

.x-tool-tool-el {
    overflow: hidden;
    width: 16px;
    height: 16px;
    margin: 0;
    color: #fff;
    text-align: center;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-tool-over .x-tool-tool-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-tool-pressed .x-tool-tool-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-keyboard-mode .x-tool-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-tool-focus,
.x-edge .x-keyboard-mode .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-tool-img {
    background-image: url(images/tools/tool-sprites.png);
}

.x-tool-placeholder {
    visibility: hidden;
}

.x-tool-close {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-close:before {
    content: '\f00d';
}

.x-tool-minimize {
    font: 16px/1 ExtJS;
    background: none;
}

.x-tool-minimize:before {
    content: "\e608";
}

.x-tool-maximize {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-tool-maximize:before {
    content: '\f065';
}

.x-tool-restore {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-tool-restore:before {
    content: '\f066';
}

.x-tool-toggle {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-toggle:before {
    content: '\f077';
}

.x-panel-collapsed .x-tool-toggle {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-panel-collapsed .x-tool-toggle:before {
    content: '\f078';
}

.x-tool-gear {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-gear:before {
    content: '\f013';
}

.x-tool-prev {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-prev:before {
    content: '\f053';
}

.x-tool-next {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-next:before {
    content: '\f054';
}

.x-tool-pin {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-pin:before {
    content: '\f08d';
}

.x-tool-unpin {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-unpin:before {
    content: "\e611";
}

.x-tool-right {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-right:before {
    content: '\f0da';
}

.x-tool-left {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-left:before {
    content: '\f0d9';
}

.x-tool-down {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-down:before {
    content: '\f0d7';
}

.x-tool-up {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-up:before {
    content: '\f0d8';
}

.x-tool-refresh {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-refresh:before {
    content: '\f021';
}

.x-tool-plus {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-plus:before {
    content: '\f067';
}

.x-tool-minus {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-minus:before {
    content: '\f068';
}

.x-tool-search {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-search:before {
    content: '\f002';
}

.x-tool-save {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-save:before {
    content: '\f00c';
}

.x-tool-help {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-help:before {
    content: '\f128';
}

.x-tool-print {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-print:before {
    content: '\f02f';
}

.x-tool-expand {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-expand:before {
    content: "\e600";
}

.x-tool-collapse {
    font: 16px/1 ExtJS;
    background: none !important;
}

.x-tool-collapse:before {
    content: "\e606";
}

.x-tool-resize {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-resize:before {
    content: '\f337';
}

.x-tool-move {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-move:before {
    content: '\f31e';
}

.x-tool-expand-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-expand-bottom:before {
    content: '\f0d7';
}

.x-tool-collapse-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-collapse-bottom:before {
    content: '\f0d7';
}

.x-tool-expand-top {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-expand-top:before {
    content: '\f0d8';
}

.x-tool-collapse-top {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-collapse-top:before {
    content: '\f0d8';
}

.x-tool-expand-left {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-expand-left:before {
    content: '\f0d9';
}

.x-tool-collapse-left {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-collapse-left:before {
    content: '\f0d9';
}

.x-tool-expand-right {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-expand-right:before {
    content: '\f0da';
}

.x-tool-collapse-right {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-tool-collapse-right:before {
    content: '\f0da';
}

.x-rtl.x-tool-expand-left {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-rtl.x-tool-expand-left:before {
    content: '\f0da';
}

.x-rtl.x-tool-collapse-left {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-rtl.x-tool-collapse-left:before {
    content: '\f0da';
}

.x-rtl.x-tool-expand-right {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-rtl.x-tool-expand-right:before {
    content: '\f0d9';
}

.x-rtl.x-tool-collapse-right {
    font: 16px/1 'Font Awesome 5 Free';
    background: none !important;
}

.x-rtl.x-tool-collapse-right:before {
    content: '\f0d9';
}

.x-header-draggable,
.x-header-ghost {
    cursor: move;
}

.x-header-text {
    white-space: nowrap;
}

.x-collapse-el {
    cursor: pointer;
    color: #919191;
    display: table;
    table-layout: fixed;
    background-color: #e0e0e0;
}

.x-collapse-el:before {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.x-layout-split-left {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-layout-split-left:before {
    content: '\f0d9';
}

.x-layout-split-right {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-layout-split-right:before {
    content: '\f0da';
}

.x-rtl.x-layout-split-left {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-rtl.x-layout-split-left:before {
    content: '\f0da';
}

.x-rtl.x-layout-split-right {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-rtl.x-layout-split-right:before {
    content: '\f0d9';
}

.x-layout-split-top {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 2px 0;
}

.x-layout-split-top:before {
    content: '\f0d8';
}

.x-layout-split-bottom {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-layout-split-bottom:before {
    content: '\f0d7';
}

.x-splitter-collapsed .x-layout-split-left {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-splitter-collapsed .x-layout-split-left:before {
    content: '\f0da';
}

.x-splitter-collapsed .x-layout-split-right {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-layout-split-right:before {
    content: '\f0d9';
}

.x-splitter-collapsed .x-rtl.x-layout-split-left {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-rtl.x-layout-split-left:before {
    content: '\f0d9';
}

.x-splitter-collapsed .x-rtl.x-layout-split-right {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 0 2px;
}

.x-splitter-collapsed .x-rtl.x-layout-split-right:before {
    content: '\f0da';
}

.x-splitter-collapsed .x-layout-split-top {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-splitter-collapsed .x-layout-split-top:before {
    content: '\f0d7';
}

.x-splitter-collapsed .x-layout-split-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    padding: 0 0 2px 0;
}

.x-splitter-collapsed .x-layout-split-bottom:before {
    content: '\f0d8';
}

.x-splitter-active {
    background-color: #b4b4b4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-splitter-active .x-collapse-el {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-layout-split-left,
.x-layout-split-right {
    top: 50%;
    margin-top: -24px;
    width: 100%;
    height: 48px;
}

.x-layout-split-top,
.x-layout-split-bottom {
    left: 50%;
    width: 48px;
    height: 100%;
    margin-left: -24px;
}

.x-layout-split-top:before,
.x-layout-split-bottom:before {
    width: 100%;
    line-height: 10px;
}

.x-keyboard-mode .x-splitter-focus:after {
    position: absolute;
    content: ' ';
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    border: 1px solid #4c82b1;
    pointer-events: none;
}

.x-toolbar-default {
    padding: 6px 0 6px 8px;
    border-style: solid;
    border-color: #d0d0d0;
    border-width: 1px;
    background-image: none;
    background-color: #fff;
}

.x-toolbar-default.x-rtl {
    padding: 6px 8px 6px 0;
}

.x-toolbar-default .x-tool-img {
    color: #919191;
    background-color: #fff;
}

.x-toolbar-default .x-toolbar-item {
    margin: 0 8px 0 0;
}

.x-toolbar-default .x-toolbar-item.x-rtl {
    margin: 0 0 0 8px;
}

.x-toolbar-default .x-toolbar-separator-horizontal {
    margin: 0 8px 0 0;
    height: 14px;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-left-color: #d0d0d0;
    border-right-color: #fff;
}

.x-toolbar-default .x-box-menu-after {
    margin: 0 8px;
}

.x-toolbar-default-vertical {
    padding: 6px 8px 0;
}

.x-toolbar-default-vertical.x-rtl {
    padding: 6px 8px 0;
}

.x-toolbar-default-vertical .x-toolbar-item {
    margin: 0 0 6px 0;
}

.x-toolbar-default-vertical .x-toolbar-item.x-rtl {
    margin: 0 0 6px 0;
}

.x-toolbar-default-vertical .x-toolbar-separator-vertical {
    margin: 0 5px 6px;
    border-style: solid none;
    border-width: 1px 0 0;
    border-top-color: #d0d0d0;
    border-bottom-color: #fff;
}

.x-toolbar-default-vertical .x-box-menu-after {
    margin: 6px 0;
}

.x-toolbar-text-default {
    padding: 0 4px;
    color: #4b4c4c;
    font: 300 13px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-toolbar-spacer-default {
    width: 2px;
}

.x-toolbar-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 24px;
}

.x-toolbar-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 24px;
}

.x-toolbar-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 26px;
}

.x-box-scroller-toolbar-default {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-toolbar-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-toolbar-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-toolbar-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-toolbar-default.x-box-scroller-left,
.x-box-scroller-toolbar-default.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-toolbar-default.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-toolbar-default.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-right:before {
    content: '\f054';
}

.x-box-scroller-toolbar-default.x-box-scroller-top,
.x-box-scroller-toolbar-default.x-box-scroller-bottom {
    height: 24px;
    width: 24px;
    left: 50%;
    margin-left: -12px;
}

.x-box-scroller-toolbar-default.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-toolbar-default.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-toolbar-default {
    background-color: #fff;
}

.x-toolbar-more-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-toolbar-more-icon:before {
    content: '\f0c9';
}

.x-toolbar-footer {
    padding: 6px 0 6px 6px;
    border-style: solid;
    border-color: #d0d0d0;
    border-width: 0;
    background-image: none;
    background-color: #ececec;
}

.x-toolbar-footer.x-rtl {
    padding: 6px 6px 6px 0;
}

.x-toolbar-footer .x-tool-img {
    color: #919191;
    background-color: #ececec;
}

.x-toolbar-footer .x-toolbar-item {
    margin: 0 6px 0 0;
}

.x-toolbar-footer .x-toolbar-item.x-rtl {
    margin: 0 0 0 6px;
}

.x-toolbar-footer .x-toolbar-separator-horizontal {
    margin: 0 8px 0 0;
    height: 14px;
    border-style: solid;
    border-width: 0 0 0 1px;
    border-left-color: #d0d0d0;
    border-right-color: #fff;
}

.x-toolbar-footer .x-box-menu-after {
    margin: 0 6px;
}

.x-toolbar-footer-vertical {
    padding: 6px 6px 0;
}

.x-toolbar-footer-vertical.x-rtl {
    padding: 6px 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-item {
    margin: 0 0 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-item.x-rtl {
    margin: 0 0 6px 0;
}

.x-toolbar-footer-vertical .x-toolbar-separator-vertical {
    margin: 0 5px 6px;
    border-style: solid none;
    border-width: 1px 0 0;
    border-top-color: #d0d0d0;
    border-bottom-color: #fff;
}

.x-toolbar-footer-vertical .x-box-menu-after {
    margin: 6px 0;
}

.x-toolbar-text-footer {
    padding: 0 4px;
    color: #4b4c4c;
    font: 300 13px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-toolbar-spacer-footer {
    width: 2px;
}

.x-toolbar-footer-scroller .x-box-scroller-body-horizontal {
    margin-left: 26px;
}

.x-toolbar-footer-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 26px;
}

.x-toolbar-footer-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 26px;
}

.x-box-scroller-toolbar-footer {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-toolbar-footer.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
}

.x-box-scroller-toolbar-footer.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-box-scroller-toolbar-footer.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-toolbar-footer.x-box-scroller-left,
.x-box-scroller-toolbar-footer.x-box-scroller-right {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.x-box-scroller-toolbar-footer.x-box-scroller-left {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-footer.x-box-scroller-left:before {
    content: '\f053';
}

.x-box-scroller-toolbar-footer.x-box-scroller-right {
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 0;
    font: 16px/24px 'Font Awesome 5 Free';
}

.x-box-scroller-toolbar-footer.x-box-scroller-right:before {
    content: '\f054';
}

.x-ie8 .x-box-scroller-toolbar-footer {
    background-color: #ececec;
}

.x-toolbar-more-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-toolbar-more-icon:before {
    content: '\f0c9';
}

.x-dd-drag-proxy {
    color: #404040;
    font: 300 13px/17px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border: 1px solid #d0d0d0;
    background-color: #fff;
}

.x-dd-drag-ghost,
.x-dd-drop-icon {
    padding: 5px;
}

.x-rtl .x-dd-drag-ghost,
.x-rtl .x-dd-drop-icon {
    padding: 5px 5px 5px 5px;
}

.x-dd-drag-ghost {
    padding-left: 0;
}

.x-rtl .x-dd-drag-ghost {
    padding-left: 5px;
    padding-right: 0;
}

.x-dd-drop-ok .x-dd-drop-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-dd-drop-ok .x-dd-drop-icon:before {
    content: '\f058';
}

.x-dd-drop-ok-add .x-dd-drop-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #a4cf6d;
}

.x-dd-drop-ok-add .x-dd-drop-icon:before {
    content: '\f058';
}

.x-dd-drop-nodrop div.x-dd-drop-icon {
    font: 16px/1 'Font Awesome 5 Free';
    color: #d9705d;
}

.x-dd-drop-nodrop div.x-dd-drop-icon:before {
    content: '\f057';
}

.x-panel-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-panel-default {
    border-color: #5fa2dd;
    padding: 0;
}

.x-panel-default.x-masked {
    border-color: #afd1ee;
}

.x-panel-header-default {
    font-size: 16px;
    border: 1px solid #5fa2dd;
}

.x-panel-header-default .x-tool-tool-el {
    background-color: #5fa2dd;
}

.x-panel-header-default-horizontal {
    padding: 11px 15px 12px;
}

.x-panel-header-default-horizontal .x-panel-header-default-tab-bar {
    margin-top: -11px;
    margin-bottom: -12px;
}

.x-panel-header-default-horizontal.x-header-noborder {
    padding: 12px 16px 12px 16px;
}

.x-panel-header-default-horizontal.x-header-noborder .x-panel-header-default-tab-bar {
    margin-top: -12px;
    margin-bottom: -12px;
}

.x-panel-header-default-vertical {
    padding: 15px 11px 15px 12px;
}

.x-panel-header-default-vertical .x-panel-header-default-tab-bar {
    margin-right: -11px;
    margin-left: -12px;
}

.x-panel-header-default-vertical.x-header-noborder {
    padding: 16px 12px 16px 12px;
}

.x-panel-header-default-vertical.x-header-noborder .x-panel-header-default-tab-bar {
    margin-right: -12px;
    margin-left: -12px;
}

.x-rtl.x-panel-header-default-vertical {
    padding: 15px 12px 15px 11px;
}

.x-rtl.x-panel-header-default-vertical .x-panel-header-default-tab-bar {
    margin-left: -11px;
    margin-right: -12px;
}

.x-rtl.x-panel-header-default-vertical.x-header-noborder {
    padding: 16px 12px 16px 12px;
}

.x-rtl.x-panel-header-default-vertical.x-header-noborder .x-panel-header-default-tab-bar {
    margin-left: -12px;
    margin-right: -12px;
}

.x-panel-header-title-default {
    color: #f0f0f0;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 20px;
}

.x-keyboard-mode .x-panel-header-title-default.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-default.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-default.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-default > .x-title-text-default {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #f0f0f0;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default.x-title-glyph {
    color: #f0f0f0;
}

.x-panel-body-default {
    background: #fff;
    border-color: #d0d0d0;
    color: #404040;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-header-default {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-default-vertical {
    background-image: none;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-default-vertical {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel .x-panel-header-default-collapsed-border-top {
    border-bottom-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-right {
    border-left-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-bottom {
    border-top-width: 1px !important;
}

.x-panel .x-panel-header-default-collapsed-border-left {
    border-right-width: 1px !important;
}

.x-panel-header-default-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-default-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-default .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-default .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-default .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-default .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-default .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-default .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-default .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-default-collapsed-border-right {
    border-right-width: 1px !important;
}

.x-rtl.x-panel-header-default-collapsed-border-left {
    border-left-width: 1px !important;
}

.x-panel-default-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-default-outer-border-l {
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-b {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-bl {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-r {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-default-outer-border-rl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-rb {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-rbl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-t {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
}

.x-panel-default-outer-border-tl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-tb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-tbl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-tr {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-default-outer-border-trl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-outer-border-trb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-outer-border-trbl {
    border-color: #5fa2dd !important;
    border-width: 1px !important;
}

.x-panel-default-framed {
    border-color: #5fa2dd;
    padding: 0;
}

.x-panel-default-framed.x-masked {
    border-color: #afd1ee;
}

.x-panel-header-default-framed {
    font-size: 16px;
    border: 2px solid #5fa2dd;
}

.x-panel-header-default-framed .x-tool-tool-el {
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-horizontal {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-default-framed-horizontal .x-panel-header-default-framed-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-panel-header-default-framed-horizontal.x-header-noborder {
    padding: 12px 16px 10px 16px;
}

.x-panel-header-default-framed-horizontal.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-top: -12px;
    margin-bottom: -10px;
}

.x-panel-header-default-framed-vertical {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-default-framed-vertical .x-panel-header-default-framed-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-panel-header-default-framed-vertical.x-header-noborder {
    padding: 16px 12px 16px 10px;
}

.x-panel-header-default-framed-vertical.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-right: -12px;
    margin-left: -10px;
}

.x-rtl.x-panel-header-default-framed-vertical {
    padding: 14px 10px 14px 10px;
}

.x-rtl.x-panel-header-default-framed-vertical .x-panel-header-default-framed-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-rtl.x-panel-header-default-framed-vertical.x-header-noborder {
    padding: 16px 10px 16px 12px;
}

.x-rtl.x-panel-header-default-framed-vertical.x-header-noborder .x-panel-header-default-framed-tab-bar {
    margin-left: -12px;
    margin-right: -10px;
}

.x-panel-header-title-default-framed {
    color: #f0f0f0;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 20px;
}

.x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus,
.x-edge .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-title-default-framed.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-panel-header-title-default-framed > .x-title-text-default-framed {
    text-transform: none;
    padding: 0;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed > .x-title-icon-default-framed {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #f0f0f0;
    background-position: center center;
}

.x-ie8 .x-panel-header-title-default-framed > .x-title-icon-wrap-default-framed > .x-title-icon-default-framed.x-title-glyph {
    color: #f0f0f0;
}

.x-panel-body-default-framed {
    background: #fff;
    border-color: #d0d0d0;
    color: #404040;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    border-width: 1px;
    border-style: solid;
}

.x-panel-default-framed {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-panel-default-framed-mc {
    background-color: #fff;
}

.x-nbr .x-panel-default-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-default-framed-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-0px-0px-0px-0px;
}

.x-panel-default-framed-tl {
    background-position: 0 -4px;
}

.x-panel-default-framed-tr {
    background-position: right -6px;
}

.x-panel-default-framed-bl {
    background-position: 0 -8px;
}

.x-panel-default-framed-br {
    background-position: right -10px;
}

.x-panel-default-framed-ml {
    background-position: 0 top;
}

.x-panel-default-framed-mr {
    background-position: right top;
}

.x-panel-default-framed-tc {
    background-position: 0 0;
}

.x-panel-default-framed-bc {
    background-position: 0 -2px;
}

.x-panel-default-framed-tr,
.x-panel-default-framed-br,
.x-panel-default-framed-mr {
    padding-right: 2px;
}

.x-panel-default-framed-tl,
.x-panel-default-framed-bl,
.x-panel-default-framed-ml {
    padding-left: 2px;
}

.x-panel-default-framed-tc {
    height: 2px;
}

.x-panel-default-framed-bc {
    height: 2px;
}

.x-panel-default-framed-tl,
.x-panel-default-framed-bl,
.x-panel-default-framed-tr,
.x-panel-default-framed-br,
.x-panel-default-framed-tc,
.x-panel-default-framed-bc,
.x-panel-default-framed-ml,
.x-panel-default-framed-mr {
    background-image: url(images/panel/panel-default-framed-corners.gif);
}

.x-panel-default-framed-ml,
.x-panel-default-framed-mr {
    background-image: url(images/panel/panel-default-framed-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-default-framed-mc {
    padding: 0px 0px 0px 0px;
}

.x-panel-header-default-framed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px 2px 0 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-top-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-top-frameInfo {
    font-family: dh-2-2-0-2-2-2-0-2-10-14-10-14;
}

.x-panel-header-default-framed-top-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-top-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-top-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-top-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-top-ml {
    background-position: 0 top;
}

.x-panel-header-default-framed-top-mr {
    background-position: right top;
}

.x-panel-header-default-framed-top-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-top-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-top-tr,
.x-panel-header-default-framed-top-br,
.x-panel-header-default-framed-top-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-top-tl,
.x-panel-header-default-framed-top-bl,
.x-panel-header-default-framed-top-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-top-tc {
    height: 2px;
}

.x-panel-header-default-framed-top-bc {
    height: 0;
}

.x-panel-header-default-framed-top-tl,
.x-panel-header-default-framed-top-bl,
.x-panel-header-default-framed-top-tr,
.x-panel-header-default-framed-top-br,
.x-panel-header-default-framed-top-tc,
.x-panel-header-default-framed-top-bc,
.x-panel-header-default-framed-top-ml,
.x-panel-header-default-framed-top-mr {
    background-image: url(images/panel-header/panel-header-default-framed-top-corners.gif);
}

.x-panel-header-default-framed-top-ml,
.x-panel-header-default-framed-top-mr {
    background-image: url(images/panel-header/panel-header-default-framed-top-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-default-framed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 2px 2px 0;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-default-framed-right {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-right-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-right-frameInfo {
    font-family: dh-2-2-2-0-2-2-2-0-14-10-14-10;
}

.x-panel-header-default-framed-right-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-right-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-right-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-right-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-right-ml {
    background-position: right 0;
}

.x-panel-header-default-framed-right-mr {
    background-position: right 0;
}

.x-panel-header-default-framed-right-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-right-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-right-tr,
.x-panel-header-default-framed-right-br,
.x-panel-header-default-framed-right-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-right-tl,
.x-panel-header-default-framed-right-bl,
.x-panel-header-default-framed-right-ml {
    padding-left: 0;
}

.x-panel-header-default-framed-right-tc {
    height: 2px;
}

.x-panel-header-default-framed-right-bc {
    height: 2px;
}

.x-panel-header-default-framed-right-tl,
.x-panel-header-default-framed-right-bl,
.x-panel-header-default-framed-right-tr,
.x-panel-header-default-framed-right-br,
.x-panel-header-default-framed-right-tc,
.x-panel-header-default-framed-right-bc,
.x-panel-header-default-framed-right-ml,
.x-panel-header-default-framed-right-mr {
    background-image: url(images/panel-header/panel-header-default-framed-right-corners.gif);
}

.x-rtl.x-panel-header-default-framed-right-tl,
.x-rtl.x-panel-header-default-framed-right-ml,
.x-rtl.x-panel-header-default-framed-right-bl,
.x-rtl.x-panel-header-default-framed-right-tr,
.x-rtl.x-panel-header-default-framed-right-mr,
.x-rtl.x-panel-header-default-framed-right-br {
    background-image: url(images/panel-header/panel-header-default-framed-right-corners-rtl.gif);
}

.x-panel-header-default-framed-right-ml,
.x-panel-header-default-framed-right-mr {
    background-image: url(images/panel-header/panel-header-default-framed-right-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-default-framed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-bottom-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-bottom-frameInfo {
    font-family: dh-0-2-2-2-0-2-2-2-10-14-10-14;
}

.x-panel-header-default-framed-bottom-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-bottom-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-bottom-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-bottom-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-default-framed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-default-framed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-bottom-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-bottom-tr,
.x-panel-header-default-framed-bottom-br,
.x-panel-header-default-framed-bottom-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-bottom-tl,
.x-panel-header-default-framed-bottom-bl,
.x-panel-header-default-framed-bottom-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-bottom-tc {
    height: 0;
}

.x-panel-header-default-framed-bottom-bc {
    height: 2px;
}

.x-panel-header-default-framed-bottom-tl,
.x-panel-header-default-framed-bottom-bl,
.x-panel-header-default-framed-bottom-tr,
.x-panel-header-default-framed-bottom-br,
.x-panel-header-default-framed-bottom-tc,
.x-panel-header-default-framed-bottom-bc,
.x-panel-header-default-framed-bottom-ml,
.x-panel-header-default-framed-bottom-mr {
    background-image: url(images/panel-header/panel-header-default-framed-bottom-corners.gif);
}

.x-panel-header-default-framed-bottom-ml,
.x-panel-header-default-framed-bottom-mr {
    background-image: url(images/panel-header/panel-header-default-framed-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-default-framed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 0 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-default-framed-left {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-left-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-left-frameInfo {
    font-family: dh-2-0-2-2-2-0-2-2-14-10-14-10;
}

.x-panel-header-default-framed-left-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-left-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-left-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-left-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-left-ml {
    background-position: left 0;
}

.x-panel-header-default-framed-left-mr {
    background-position: left 0;
}

.x-panel-header-default-framed-left-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-left-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-left-tr,
.x-panel-header-default-framed-left-br,
.x-panel-header-default-framed-left-mr {
    padding-right: 0;
}

.x-panel-header-default-framed-left-tl,
.x-panel-header-default-framed-left-bl,
.x-panel-header-default-framed-left-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-left-tc {
    height: 2px;
}

.x-panel-header-default-framed-left-bc {
    height: 2px;
}

.x-panel-header-default-framed-left-tl,
.x-panel-header-default-framed-left-bl,
.x-panel-header-default-framed-left-tr,
.x-panel-header-default-framed-left-br,
.x-panel-header-default-framed-left-tc,
.x-panel-header-default-framed-left-bc,
.x-panel-header-default-framed-left-ml,
.x-panel-header-default-framed-left-mr {
    background-image: url(images/panel-header/panel-header-default-framed-left-corners.gif);
}

.x-rtl.x-panel-header-default-framed-left-tl,
.x-rtl.x-panel-header-default-framed-left-ml,
.x-rtl.x-panel-header-default-framed-left-bl,
.x-rtl.x-panel-header-default-framed-left-tr,
.x-rtl.x-panel-header-default-framed-left-mr,
.x-rtl.x-panel-header-default-framed-left-br {
    background-image: url(images/panel-header/panel-header-default-framed-left-corners-rtl.gif);
}

.x-panel-header-default-framed-left-ml,
.x-panel-header-default-framed-left-mr {
    background-image: url(images/panel-header/panel-header-default-framed-left-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-default-framed-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-collapsed-top-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-top-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-panel-header-default-framed-collapsed-top-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-top-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-collapsed-top-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-collapsed-top-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-collapsed-top-ml {
    background-position: 0 top;
}

.x-panel-header-default-framed-collapsed-top-mr {
    background-position: right top;
}

.x-panel-header-default-framed-collapsed-top-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-top-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-top-tr,
.x-panel-header-default-framed-collapsed-top-br,
.x-panel-header-default-framed-collapsed-top-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-collapsed-top-tl,
.x-panel-header-default-framed-collapsed-top-bl,
.x-panel-header-default-framed-collapsed-top-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-collapsed-top-tc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-top-bc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-top-tl,
.x-panel-header-default-framed-collapsed-top-bl,
.x-panel-header-default-framed-collapsed-top-tr,
.x-panel-header-default-framed-collapsed-top-br,
.x-panel-header-default-framed-collapsed-top-tc,
.x-panel-header-default-framed-collapsed-top-bc,
.x-panel-header-default-framed-collapsed-top-ml,
.x-panel-header-default-framed-collapsed-top-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-top-corners.gif);
}

.x-panel-header-default-framed-collapsed-top-ml,
.x-panel-header-default-framed-collapsed-top-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-top-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-default-framed-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-default-framed-collapsed-right {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-collapsed-right-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-right-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-panel-header-default-framed-collapsed-right-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-right-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-collapsed-right-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-collapsed-right-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-collapsed-right-ml {
    background-position: right 0;
}

.x-panel-header-default-framed-collapsed-right-mr {
    background-position: right 0;
}

.x-panel-header-default-framed-collapsed-right-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-right-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-right-tr,
.x-panel-header-default-framed-collapsed-right-br,
.x-panel-header-default-framed-collapsed-right-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-collapsed-right-tl,
.x-panel-header-default-framed-collapsed-right-bl,
.x-panel-header-default-framed-collapsed-right-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-collapsed-right-tc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-right-bc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-right-tl,
.x-panel-header-default-framed-collapsed-right-bl,
.x-panel-header-default-framed-collapsed-right-tr,
.x-panel-header-default-framed-collapsed-right-br,
.x-panel-header-default-framed-collapsed-right-tc,
.x-panel-header-default-framed-collapsed-right-bc,
.x-panel-header-default-framed-collapsed-right-ml,
.x-panel-header-default-framed-collapsed-right-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-right-corners.gif);
}

.x-rtl.x-panel-header-default-framed-collapsed-right-tl,
.x-rtl.x-panel-header-default-framed-collapsed-right-ml,
.x-rtl.x-panel-header-default-framed-collapsed-right-bl,
.x-rtl.x-panel-header-default-framed-collapsed-right-tr,
.x-rtl.x-panel-header-default-framed-collapsed-right-mr,
.x-rtl.x-panel-header-default-framed-collapsed-right-br {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-right-corners-rtl.gif);
}

.x-panel-header-default-framed-collapsed-right-ml,
.x-panel-header-default-framed-collapsed-right-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-right-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel-header-default-framed-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-collapsed-bottom-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-bottom-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-panel-header-default-framed-collapsed-bottom-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-bottom-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-collapsed-bottom-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-collapsed-bottom-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-collapsed-bottom-ml {
    background-position: 0 bottom;
}

.x-panel-header-default-framed-collapsed-bottom-mr {
    background-position: right bottom;
}

.x-panel-header-default-framed-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-bottom-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-bottom-tr,
.x-panel-header-default-framed-collapsed-bottom-br,
.x-panel-header-default-framed-collapsed-bottom-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-collapsed-bottom-tl,
.x-panel-header-default-framed-collapsed-bottom-bl,
.x-panel-header-default-framed-collapsed-bottom-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-collapsed-bottom-tc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-bottom-bc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-bottom-tl,
.x-panel-header-default-framed-collapsed-bottom-bl,
.x-panel-header-default-framed-collapsed-bottom-tr,
.x-panel-header-default-framed-collapsed-bottom-br,
.x-panel-header-default-framed-collapsed-bottom-tc,
.x-panel-header-default-framed-collapsed-bottom-bc,
.x-panel-header-default-framed-collapsed-bottom-ml,
.x-panel-header-default-framed-collapsed-bottom-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-bottom-corners.gif);
}

.x-panel-header-default-framed-collapsed-bottom-ml,
.x-panel-header-default-framed-collapsed-bottom-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-panel-header-default-framed-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-rtl.x-panel-header-default-framed-collapsed-left {
    background-image: none;
    background-color: #5fa2dd;
}

.x-panel-header-default-framed-collapsed-left-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-panel-header-default-framed-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-panel-header-default-framed-collapsed-left-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-panel-header-default-framed-collapsed-left-tl {
    background-position: 0 -4px;
}

.x-panel-header-default-framed-collapsed-left-tr {
    background-position: right -6px;
}

.x-panel-header-default-framed-collapsed-left-bl {
    background-position: 0 -8px;
}

.x-panel-header-default-framed-collapsed-left-br {
    background-position: right -10px;
}

.x-panel-header-default-framed-collapsed-left-ml {
    background-position: left 0;
}

.x-panel-header-default-framed-collapsed-left-mr {
    background-position: left 0;
}

.x-panel-header-default-framed-collapsed-left-tc {
    background-position: 0 0;
}

.x-panel-header-default-framed-collapsed-left-bc {
    background-position: 0 -2px;
}

.x-panel-header-default-framed-collapsed-left-tr,
.x-panel-header-default-framed-collapsed-left-br,
.x-panel-header-default-framed-collapsed-left-mr {
    padding-right: 2px;
}

.x-panel-header-default-framed-collapsed-left-tl,
.x-panel-header-default-framed-collapsed-left-bl,
.x-panel-header-default-framed-collapsed-left-ml {
    padding-left: 2px;
}

.x-panel-header-default-framed-collapsed-left-tc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-left-bc {
    height: 2px;
}

.x-panel-header-default-framed-collapsed-left-tl,
.x-panel-header-default-framed-collapsed-left-bl,
.x-panel-header-default-framed-collapsed-left-tr,
.x-panel-header-default-framed-collapsed-left-br,
.x-panel-header-default-framed-collapsed-left-tc,
.x-panel-header-default-framed-collapsed-left-bc,
.x-panel-header-default-framed-collapsed-left-ml,
.x-panel-header-default-framed-collapsed-left-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-left-corners.gif);
}

.x-rtl.x-panel-header-default-framed-collapsed-left-tl,
.x-rtl.x-panel-header-default-framed-collapsed-left-ml,
.x-rtl.x-panel-header-default-framed-collapsed-left-bl,
.x-rtl.x-panel-header-default-framed-collapsed-left-tr,
.x-rtl.x-panel-header-default-framed-collapsed-left-mr,
.x-rtl.x-panel-header-default-framed-collapsed-left-br {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-left-corners-rtl.gif);
}

.x-panel-header-default-framed-collapsed-left-ml,
.x-panel-header-default-framed-collapsed-left-mr {
    background-image: url(images/panel-header/panel-header-default-framed-collapsed-left-sides.gif);
    background-repeat: repeat-y;
}

.x-panel-header-default-framed-collapsed-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-panel .x-panel-header-default-framed-top {
    border-bottom-width: 2px !important;
}

.x-panel .x-panel-header-default-framed-right {
    border-left-width: 2px !important;
}

.x-panel .x-panel-header-default-framed-bottom {
    border-top-width: 2px !important;
}

.x-panel .x-panel-header-default-framed-left {
    border-right-width: 2px !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-top {
    border-bottom-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-right {
    border-left-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-bottom {
    border-top-width: 0 !important;
}

.x-nbr .x-panel-header-default-framed-collapsed-left {
    border-right-width: 0 !important;
}

.x-panel-header-default-framed-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-framed-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-framed-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-panel-header-default-framed-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-panel-header-default-framed-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-framed-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-panel-header-default-framed-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-panel-header-default-framed-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-panel-header-default-framed .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus,
.x-edge .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-panel-header-default-framed .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-rtl.x-panel-header-default-framed-collapsed-border-right {
    border-right-width: 2px !important;
}

.x-rtl.x-panel-header-default-framed-collapsed-border-left {
    border-left-width: 2px !important;
}

.x-panel-default-framed-resizable .x-panel-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-panel-default-framed-outer-border-l {
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-b {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-bl {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-r {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-default-framed-outer-border-rl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-rb {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-rbl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-t {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
}

.x-panel-default-framed-outer-border-tl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-tb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-tbl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-tr {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-panel-default-framed-outer-border-trl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-panel-default-framed-outer-border-trb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-panel-default-framed-outer-border-trbl {
    border-color: #5fa2dd !important;
    border-width: 1px !important;
}

.x-btn-group-default {
    border-color: #d0d0d0;
    -webkit-box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
    -moz-box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
    box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
}

.x-btn-group-header-default {
    padding: 8px 8px 7px;
    line-height: 16px;
    background: #fff;
    -moz-border-radius-topleft: 0px;
    -webkit-border-top-left-radius: 0px;
    border-top-left-radius: 0px;
    -moz-border-radius-topright: 0px;
    -webkit-border-top-right-radius: 0px;
    border-top-right-radius: 0px;
}

.x-btn-group-header-default .x-tool-img {
    background-image: url(images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-btn-group-header-title-default {
    font: 300 14px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 16px;
    color: #606060;
}

.x-btn-group-body-default {
    padding: 0 1px;
}

.x-btn-group-body-default .x-table-layout {
    border-spacing: 6px;
}

.x-btn-group-default-framed {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-btn-group-default-framed-mc {
    background-color: #fff;
}

.x-nbr .x-btn-group-default-framed {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-group-default-framed-frameInfo {
    font-family: dh-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-btn-group-default-framed-tl {
    background-position: 0 -4px;
}

.x-btn-group-default-framed-tr {
    background-position: right -6px;
}

.x-btn-group-default-framed-bl {
    background-position: 0 -8px;
}

.x-btn-group-default-framed-br {
    background-position: right -10px;
}

.x-btn-group-default-framed-ml {
    background-position: 0 top;
}

.x-btn-group-default-framed-mr {
    background-position: right top;
}

.x-btn-group-default-framed-tc {
    background-position: 0 0;
}

.x-btn-group-default-framed-bc {
    background-position: 0 -2px;
}

.x-btn-group-default-framed-tr,
.x-btn-group-default-framed-br,
.x-btn-group-default-framed-mr {
    padding-right: 2px;
}

.x-btn-group-default-framed-tl,
.x-btn-group-default-framed-bl,
.x-btn-group-default-framed-ml {
    padding-left: 2px;
}

.x-btn-group-default-framed-tc {
    height: 2px;
}

.x-btn-group-default-framed-bc {
    height: 2px;
}

.x-btn-group-default-framed-tl,
.x-btn-group-default-framed-bl,
.x-btn-group-default-framed-tr,
.x-btn-group-default-framed-br,
.x-btn-group-default-framed-tc,
.x-btn-group-default-framed-bc,
.x-btn-group-default-framed-ml,
.x-btn-group-default-framed-mr {
    background-image: url(images/btn-group/btn-group-default-framed-corners.gif);
}

.x-btn-group-default-framed-ml,
.x-btn-group-default-framed-mr {
    background-image: url(images/btn-group/btn-group-default-framed-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-group-default-framed-mc {
    padding: 0px 0px 0px 0px;
}

.x-btn-group-default-framed-notitle {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #fff;
}

.x-btn-group-default-framed-notitle-mc {
    background-color: #fff;
}

.x-nbr .x-btn-group-default-framed-notitle {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-group-default-framed-notitle-frameInfo {
    font-family: dh-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-btn-group-default-framed-notitle-tl {
    background-position: 0 -4px;
}

.x-btn-group-default-framed-notitle-tr {
    background-position: right -6px;
}

.x-btn-group-default-framed-notitle-bl {
    background-position: 0 -8px;
}

.x-btn-group-default-framed-notitle-br {
    background-position: right -10px;
}

.x-btn-group-default-framed-notitle-ml {
    background-position: 0 top;
}

.x-btn-group-default-framed-notitle-mr {
    background-position: right top;
}

.x-btn-group-default-framed-notitle-tc {
    background-position: 0 0;
}

.x-btn-group-default-framed-notitle-bc {
    background-position: 0 -2px;
}

.x-btn-group-default-framed-notitle-tr,
.x-btn-group-default-framed-notitle-br,
.x-btn-group-default-framed-notitle-mr {
    padding-right: 2px;
}

.x-btn-group-default-framed-notitle-tl,
.x-btn-group-default-framed-notitle-bl,
.x-btn-group-default-framed-notitle-ml {
    padding-left: 2px;
}

.x-btn-group-default-framed-notitle-tc {
    height: 2px;
}

.x-btn-group-default-framed-notitle-bc {
    height: 2px;
}

.x-btn-group-default-framed-notitle-tl,
.x-btn-group-default-framed-notitle-bl,
.x-btn-group-default-framed-notitle-tr,
.x-btn-group-default-framed-notitle-br,
.x-btn-group-default-framed-notitle-tc,
.x-btn-group-default-framed-notitle-bc,
.x-btn-group-default-framed-notitle-ml,
.x-btn-group-default-framed-notitle-mr {
    background-image: url(images/btn-group/btn-group-default-framed-notitle-corners.gif);
}

.x-btn-group-default-framed-notitle-ml,
.x-btn-group-default-framed-notitle-mr {
    background-image: url(images/btn-group/btn-group-default-framed-notitle-sides.gif);
    background-repeat: repeat-y;
}

.x-btn-group-default-framed-notitle-mc {
    padding: 0px 0px 0px 0px;
}

.x-btn-group-default-framed {
    border-color: #d0d0d0;
    -webkit-box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
    -moz-box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
    box-shadow: #fff 0 1px 0px 0 inset, #fff 0 -1px 0px 0 inset, #fff -1px 0 0px 0 inset, #fff 1px 0 0px 0 inset;
}

.x-btn-group-header-default-framed {
    padding: 8px 8px 7px;
    line-height: 16px;
    background: #fff;
    -moz-border-radius-topleft: 2px;
    -webkit-border-top-left-radius: 2px;
    border-top-left-radius: 2px;
    -moz-border-radius-topright: 2px;
    -webkit-border-top-right-radius: 2px;
    border-top-right-radius: 2px;
}

.x-btn-group-header-default-framed .x-tool-img {
    background-image: url(images/tools/tool-sprites-dark.png);
    background-color: #fff;
}

.x-btn-group-header-title-default-framed {
    font: 300 14px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 16px;
    color: #606060;
}

.x-btn-group-body-default-framed {
    padding: 0 1px;
}

.x-btn-group-body-default-framed .x-table-layout {
    border-spacing: 6px;
}

.x-dashboard-column {
    padding: 0 0 7px 0;
}

.x-dashboard-panel {
    margin-top: 7px;
}

.x-dashboard-column-first {
    padding-left: 4px;
    clear: left;
}

.x-dashboard-column-last {
    padding-right: 4px;
}

.x-dashboard .x-panel-dd-spacer {
    border: 2px dashed #99bbe8;
    background: #f6f6f6;
    border-radius: 4px;
    -moz-border-radius: 4px;
    margin-top: 7px;
}

.x-panel .x-dashboard {
    padding: 0 3px;
}

.x-dashboard-dd-over {
    overflow: hidden !important;
}

.x-window-ghost {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-window-default {
    border-color: #5fa2dd;
    -webkit-border-radius: 0 0 0 0;
    -moz-border-radius: 0 0 0 0;
    -ms-border-radius: 0 0 0 0;
    -o-border-radius: 0 0 0 0;
    border-radius: 0 0 0 0;
}

.x-window-default {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 0 0 0 0;
    border-width: 2px;
    border-style: solid;
    background-color: #fff;
}

.x-window-default-mc {
    background-color: #fff;
}

.x-nbr .x-window-default {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-default-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-0px-0px-0px-0px;
}

.x-window-default-tl {
    background-position: 0 -4px;
}

.x-window-default-tr {
    background-position: right -6px;
}

.x-window-default-bl {
    background-position: 0 -8px;
}

.x-window-default-br {
    background-position: right -10px;
}

.x-window-default-ml {
    background-position: 0 top;
}

.x-window-default-mr {
    background-position: right top;
}

.x-window-default-tc {
    background-position: 0 0;
}

.x-window-default-bc {
    background-position: 0 -2px;
}

.x-window-default-tr,
.x-window-default-br,
.x-window-default-mr {
    padding-right: 2px;
}

.x-window-default-tl,
.x-window-default-bl,
.x-window-default-ml {
    padding-left: 2px;
}

.x-window-default-tc {
    height: 2px;
}

.x-window-default-bc {
    height: 2px;
}

.x-window-default-tl,
.x-window-default-bl,
.x-window-default-tr,
.x-window-default-br,
.x-window-default-tc,
.x-window-default-bc,
.x-window-default-ml,
.x-window-default-mr {
    background-image: url(images/window/window-default-corners.gif);
}

.x-window-default-ml,
.x-window-default-mr {
    background-image: url(images/window/window-default-sides.gif);
    background-repeat: repeat-y;
}

.x-window-default-mc {
    padding: 0px 0px 0px 0px;
}

.x-window-body-default {
    border-color: #5fa2dd;
    border-width: 1px;
    border-style: solid;
    background: #fff;
    color: #000;
    font-size: 13px;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-window-header-default {
    font-size: 16px;
    border-color: #5fa2dd;
    background-color: #5fa2dd;
}

.x-window-header-default .x-tool-img {
    background-color: #5fa2dd;
}

.x-window-header-default-horizontal .x-window-header-default-tab-bar {
    margin-top: -10px;
    margin-bottom: -10px;
}

.x-window-header-default-vertical .x-window-header-default-tab-bar {
    margin-right: -10px;
    margin-left: -10px;
}

.x-rtl.x-window-header-default-vertical .x-window-header-default-tab-bar {
    margin-left: -10px;
    margin-right: -10px;
}

.x-window-header-title-default {
    color: #f0f0f0;
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 20px;
}

.x-window-header-title-default > .x-title-text-default {
    padding: 0;
    text-transform: none;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-top {
    height: 24px;
    padding-bottom: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-right {
    width: 24px;
    padding-left: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-right.x-rtl {
    padding-left: 0;
    padding-right: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-bottom {
    height: 24px;
    padding-top: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-left {
    width: 24px;
    padding-right: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default.x-title-icon-left.x-rtl {
    padding-right: 0;
    padding-left: 8px;
}

.x-window-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default {
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: #f0f0f0;
    background-position: center center;
}

.x-ie8 .x-window-header-title-default > .x-title-icon-wrap-default > .x-title-icon-default.x-title-glyph {
    color: #f0f0f0;
}

.x-window-header-default-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-top-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-top-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-window-header-default-top-tl {
    background-position: 0 -4px;
}

.x-window-header-default-top-tr {
    background-position: right -6px;
}

.x-window-header-default-top-bl {
    background-position: 0 -8px;
}

.x-window-header-default-top-br {
    background-position: right -10px;
}

.x-window-header-default-top-ml {
    background-position: 0 top;
}

.x-window-header-default-top-mr {
    background-position: right top;
}

.x-window-header-default-top-tc {
    background-position: 0 0;
}

.x-window-header-default-top-bc {
    background-position: 0 -2px;
}

.x-window-header-default-top-tr,
.x-window-header-default-top-br,
.x-window-header-default-top-mr {
    padding-right: 2px;
}

.x-window-header-default-top-tl,
.x-window-header-default-top-bl,
.x-window-header-default-top-ml {
    padding-left: 2px;
}

.x-window-header-default-top-tc {
    height: 2px;
}

.x-window-header-default-top-bc {
    height: 2px;
}

.x-window-header-default-top-tl,
.x-window-header-default-top-bl,
.x-window-header-default-top-tr,
.x-window-header-default-top-br,
.x-window-header-default-top-tc,
.x-window-header-default-top-bc,
.x-window-header-default-top-ml,
.x-window-header-default-top-mr {
    background-image: url(images/window-header/window-header-default-top-corners.gif);
}

.x-window-header-default-top-ml,
.x-window-header-default-top-mr {
    background-image: url(images/window-header/window-header-default-top-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-window-header-default-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-right-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-right-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-window-header-default-right-tl {
    background-position: 0 -4px;
}

.x-window-header-default-right-tr {
    background-position: right -6px;
}

.x-window-header-default-right-bl {
    background-position: 0 -8px;
}

.x-window-header-default-right-br {
    background-position: right -10px;
}

.x-window-header-default-right-ml {
    background-position: 0 top;
}

.x-window-header-default-right-mr {
    background-position: right top;
}

.x-window-header-default-right-tc {
    background-position: 0 0;
}

.x-window-header-default-right-bc {
    background-position: 0 -2px;
}

.x-window-header-default-right-tr,
.x-window-header-default-right-br,
.x-window-header-default-right-mr {
    padding-right: 2px;
}

.x-window-header-default-right-tl,
.x-window-header-default-right-bl,
.x-window-header-default-right-ml {
    padding-left: 2px;
}

.x-window-header-default-right-tc {
    height: 2px;
}

.x-window-header-default-right-bc {
    height: 2px;
}

.x-window-header-default-right-tl,
.x-window-header-default-right-bl,
.x-window-header-default-right-tr,
.x-window-header-default-right-br,
.x-window-header-default-right-tc,
.x-window-header-default-right-bc,
.x-window-header-default-right-ml,
.x-window-header-default-right-mr {
    background-image: url(images/window-header/window-header-default-right-corners.gif);
}

.x-rtl.x-window-header-default-right-tl,
.x-rtl.x-window-header-default-right-ml,
.x-rtl.x-window-header-default-right-bl,
.x-rtl.x-window-header-default-right-tr,
.x-rtl.x-window-header-default-right-mr,
.x-rtl.x-window-header-default-right-br {
    background-image: url(images/window-header/window-header-default-right-corners-rtl.gif);
}

.x-window-header-default-right-ml,
.x-window-header-default-right-mr {
    background-image: url(images/window-header/window-header-default-right-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-window-header-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-bottom-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-bottom-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-window-header-default-bottom-tl {
    background-position: 0 -4px;
}

.x-window-header-default-bottom-tr {
    background-position: right -6px;
}

.x-window-header-default-bottom-bl {
    background-position: 0 -8px;
}

.x-window-header-default-bottom-br {
    background-position: right -10px;
}

.x-window-header-default-bottom-ml {
    background-position: 0 top;
}

.x-window-header-default-bottom-mr {
    background-position: right top;
}

.x-window-header-default-bottom-tc {
    background-position: 0 0;
}

.x-window-header-default-bottom-bc {
    background-position: 0 -2px;
}

.x-window-header-default-bottom-tr,
.x-window-header-default-bottom-br,
.x-window-header-default-bottom-mr {
    padding-right: 2px;
}

.x-window-header-default-bottom-tl,
.x-window-header-default-bottom-bl,
.x-window-header-default-bottom-ml {
    padding-left: 2px;
}

.x-window-header-default-bottom-tc {
    height: 2px;
}

.x-window-header-default-bottom-bc {
    height: 2px;
}

.x-window-header-default-bottom-tl,
.x-window-header-default-bottom-bl,
.x-window-header-default-bottom-tr,
.x-window-header-default-bottom-br,
.x-window-header-default-bottom-tc,
.x-window-header-default-bottom-bc,
.x-window-header-default-bottom-ml,
.x-window-header-default-bottom-mr {
    background-image: url(images/window-header/window-header-default-bottom-corners.gif);
}

.x-window-header-default-bottom-ml,
.x-window-header-default-bottom-mr {
    background-image: url(images/window-header/window-header-default-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-window-header-default-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px 2px 2px 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-left-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-left-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-window-header-default-left-tl {
    background-position: 0 -4px;
}

.x-window-header-default-left-tr {
    background-position: right -6px;
}

.x-window-header-default-left-bl {
    background-position: 0 -8px;
}

.x-window-header-default-left-br {
    background-position: right -10px;
}

.x-window-header-default-left-ml {
    background-position: 0 top;
}

.x-window-header-default-left-mr {
    background-position: right top;
}

.x-window-header-default-left-tc {
    background-position: 0 0;
}

.x-window-header-default-left-bc {
    background-position: 0 -2px;
}

.x-window-header-default-left-tr,
.x-window-header-default-left-br,
.x-window-header-default-left-mr {
    padding-right: 2px;
}

.x-window-header-default-left-tl,
.x-window-header-default-left-bl,
.x-window-header-default-left-ml {
    padding-left: 2px;
}

.x-window-header-default-left-tc {
    height: 2px;
}

.x-window-header-default-left-bc {
    height: 2px;
}

.x-window-header-default-left-tl,
.x-window-header-default-left-bl,
.x-window-header-default-left-tr,
.x-window-header-default-left-br,
.x-window-header-default-left-tc,
.x-window-header-default-left-bc,
.x-window-header-default-left-ml,
.x-window-header-default-left-mr {
    background-image: url(images/window-header/window-header-default-left-corners.gif);
}

.x-rtl.x-window-header-default-left-tl,
.x-rtl.x-window-header-default-left-ml,
.x-rtl.x-window-header-default-left-bl,
.x-rtl.x-window-header-default-left-tr,
.x-rtl.x-window-header-default-left-mr,
.x-rtl.x-window-header-default-left-br {
    background-image: url(images/window-header/window-header-default-left-corners-rtl.gif);
}

.x-window-header-default-left-ml,
.x-window-header-default-left-mr {
    background-image: url(images/window-header/window-header-default-left-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-window-header-default-collapsed-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-collapsed-top-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-collapsed-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-top-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-window-header-default-collapsed-top-tl {
    background-position: 0 -4px;
}

.x-window-header-default-collapsed-top-tr {
    background-position: right -6px;
}

.x-window-header-default-collapsed-top-bl {
    background-position: 0 -8px;
}

.x-window-header-default-collapsed-top-br {
    background-position: right -10px;
}

.x-window-header-default-collapsed-top-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-top-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-top-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-top-bc {
    background-position: 0 -2px;
}

.x-window-header-default-collapsed-top-tr,
.x-window-header-default-collapsed-top-br,
.x-window-header-default-collapsed-top-mr {
    padding-right: 2px;
}

.x-window-header-default-collapsed-top-tl,
.x-window-header-default-collapsed-top-bl,
.x-window-header-default-collapsed-top-ml {
    padding-left: 2px;
}

.x-window-header-default-collapsed-top-tc {
    height: 2px;
}

.x-window-header-default-collapsed-top-bc {
    height: 2px;
}

.x-window-header-default-collapsed-top-tl,
.x-window-header-default-collapsed-top-bl,
.x-window-header-default-collapsed-top-tr,
.x-window-header-default-collapsed-top-br,
.x-window-header-default-collapsed-top-tc,
.x-window-header-default-collapsed-top-bc,
.x-window-header-default-collapsed-top-ml,
.x-window-header-default-collapsed-top-mr {
    background-image: url(images/window-header/window-header-default-collapsed-top-corners.gif);
}

.x-window-header-default-collapsed-top-ml,
.x-window-header-default-collapsed-top-mr {
    background-image: url(images/window-header/window-header-default-collapsed-top-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-collapsed-top-mc {
    padding: 10px 14px 10px 14px;
}

.x-window-header-default-collapsed-right {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-collapsed-right-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-collapsed-right {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-right-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-window-header-default-collapsed-right-tl {
    background-position: 0 -4px;
}

.x-window-header-default-collapsed-right-tr {
    background-position: right -6px;
}

.x-window-header-default-collapsed-right-bl {
    background-position: 0 -8px;
}

.x-window-header-default-collapsed-right-br {
    background-position: right -10px;
}

.x-window-header-default-collapsed-right-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-right-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-right-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-right-bc {
    background-position: 0 -2px;
}

.x-window-header-default-collapsed-right-tr,
.x-window-header-default-collapsed-right-br,
.x-window-header-default-collapsed-right-mr {
    padding-right: 2px;
}

.x-window-header-default-collapsed-right-tl,
.x-window-header-default-collapsed-right-bl,
.x-window-header-default-collapsed-right-ml {
    padding-left: 2px;
}

.x-window-header-default-collapsed-right-tc {
    height: 2px;
}

.x-window-header-default-collapsed-right-bc {
    height: 2px;
}

.x-window-header-default-collapsed-right-tl,
.x-window-header-default-collapsed-right-bl,
.x-window-header-default-collapsed-right-tr,
.x-window-header-default-collapsed-right-br,
.x-window-header-default-collapsed-right-tc,
.x-window-header-default-collapsed-right-bc,
.x-window-header-default-collapsed-right-ml,
.x-window-header-default-collapsed-right-mr {
    background-image: url(images/window-header/window-header-default-collapsed-right-corners.gif);
}

.x-rtl.x-window-header-default-collapsed-right-tl,
.x-rtl.x-window-header-default-collapsed-right-ml,
.x-rtl.x-window-header-default-collapsed-right-bl,
.x-rtl.x-window-header-default-collapsed-right-tr,
.x-rtl.x-window-header-default-collapsed-right-mr,
.x-rtl.x-window-header-default-collapsed-right-br {
    background-image: url(images/window-header/window-header-default-collapsed-right-corners-rtl.gif);
}

.x-window-header-default-collapsed-right-ml,
.x-window-header-default-collapsed-right-mr {
    background-image: url(images/window-header/window-header-default-collapsed-right-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-collapsed-right-mc {
    padding: 14px 10px 14px 10px;
}

.x-window-header-default-collapsed-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px 14px 10px 14px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-collapsed-bottom-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-collapsed-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-bottom-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-10-14-10-14;
}

.x-window-header-default-collapsed-bottom-tl {
    background-position: 0 -4px;
}

.x-window-header-default-collapsed-bottom-tr {
    background-position: right -6px;
}

.x-window-header-default-collapsed-bottom-bl {
    background-position: 0 -8px;
}

.x-window-header-default-collapsed-bottom-br {
    background-position: right -10px;
}

.x-window-header-default-collapsed-bottom-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-bottom-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-bottom-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-bottom-bc {
    background-position: 0 -2px;
}

.x-window-header-default-collapsed-bottom-tr,
.x-window-header-default-collapsed-bottom-br,
.x-window-header-default-collapsed-bottom-mr {
    padding-right: 2px;
}

.x-window-header-default-collapsed-bottom-tl,
.x-window-header-default-collapsed-bottom-bl,
.x-window-header-default-collapsed-bottom-ml {
    padding-left: 2px;
}

.x-window-header-default-collapsed-bottom-tc {
    height: 2px;
}

.x-window-header-default-collapsed-bottom-bc {
    height: 2px;
}

.x-window-header-default-collapsed-bottom-tl,
.x-window-header-default-collapsed-bottom-bl,
.x-window-header-default-collapsed-bottom-tr,
.x-window-header-default-collapsed-bottom-br,
.x-window-header-default-collapsed-bottom-tc,
.x-window-header-default-collapsed-bottom-bc,
.x-window-header-default-collapsed-bottom-ml,
.x-window-header-default-collapsed-bottom-mr {
    background-image: url(images/window-header/window-header-default-collapsed-bottom-corners.gif);
}

.x-window-header-default-collapsed-bottom-ml,
.x-window-header-default-collapsed-bottom-mr {
    background-image: url(images/window-header/window-header-default-collapsed-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-collapsed-bottom-mc {
    padding: 10px 14px 10px 14px;
}

.x-window-header-default-collapsed-left {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 14px 10px 14px 10px;
    border-width: 2px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-window-header-default-collapsed-left-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-window-header-default-collapsed-left {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-window-header-default-collapsed-left-frameInfo {
    font-family: dh-2-2-2-2-2-2-2-2-14-10-14-10;
}

.x-window-header-default-collapsed-left-tl {
    background-position: 0 -4px;
}

.x-window-header-default-collapsed-left-tr {
    background-position: right -6px;
}

.x-window-header-default-collapsed-left-bl {
    background-position: 0 -8px;
}

.x-window-header-default-collapsed-left-br {
    background-position: right -10px;
}

.x-window-header-default-collapsed-left-ml {
    background-position: 0 top;
}

.x-window-header-default-collapsed-left-mr {
    background-position: right top;
}

.x-window-header-default-collapsed-left-tc {
    background-position: 0 0;
}

.x-window-header-default-collapsed-left-bc {
    background-position: 0 -2px;
}

.x-window-header-default-collapsed-left-tr,
.x-window-header-default-collapsed-left-br,
.x-window-header-default-collapsed-left-mr {
    padding-right: 2px;
}

.x-window-header-default-collapsed-left-tl,
.x-window-header-default-collapsed-left-bl,
.x-window-header-default-collapsed-left-ml {
    padding-left: 2px;
}

.x-window-header-default-collapsed-left-tc {
    height: 2px;
}

.x-window-header-default-collapsed-left-bc {
    height: 2px;
}

.x-window-header-default-collapsed-left-tl,
.x-window-header-default-collapsed-left-bl,
.x-window-header-default-collapsed-left-tr,
.x-window-header-default-collapsed-left-br,
.x-window-header-default-collapsed-left-tc,
.x-window-header-default-collapsed-left-bc,
.x-window-header-default-collapsed-left-ml,
.x-window-header-default-collapsed-left-mr {
    background-image: url(images/window-header/window-header-default-collapsed-left-corners.gif);
}

.x-rtl.x-window-header-default-collapsed-left-tl,
.x-rtl.x-window-header-default-collapsed-left-ml,
.x-rtl.x-window-header-default-collapsed-left-bl,
.x-rtl.x-window-header-default-collapsed-left-tr,
.x-rtl.x-window-header-default-collapsed-left-mr,
.x-rtl.x-window-header-default-collapsed-left-br {
    background-image: url(images/window-header/window-header-default-collapsed-left-corners-rtl.gif);
}

.x-window-header-default-collapsed-left-ml,
.x-window-header-default-collapsed-left-mr {
    background-image: url(images/window-header/window-header-default-collapsed-left-sides.gif);
    background-repeat: repeat-y;
}

.x-window-header-default-collapsed-left-mc {
    padding: 14px 10px 14px 10px;
}

.x-window-header-default .x-window-header-icon {
    width: 16px;
    height: 16px;
    color: #f0f0f0;
    font-size: 16px;
    line-height: 16px;
    background-position: center center;
}

.x-window-header-default .x-window-header-glyph {
    color: #f0f0f0;
    font-size: 16px;
    line-height: 16px;
}

.x-ie8 .x-window-header-default .x-window-header-glyph {
    color: #f0f0f0;
}

.x-window-header-default-horizontal .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-window-header-default-horizontal .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-window-header-default-horizontal .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-window-header-default-horizontal .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-window-header-default-vertical .x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-default-vertical .x-rtl.x-tool-after-title {
    margin: 6px 0 0 0;
}

.x-window-header-default-vertical .x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-window-header-default-vertical .x-rtl.x-tool-before-title {
    margin: 0 0 6px 0;
}

.x-keyboard-mode .x-window-header-default .x-tool-focus {
    outline: 1px solid #fff;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-window-header-default .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-window-header-default .x-tool-focus,
.x-edge .x-keyboard-mode .x-window-header-default .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-window-header-default .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-window-header-default .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-window-header-default .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #fff;
    pointer-events: none;
}

.x-window-header-default {
    border-width: 2px !important;
}

.x-nbr .x-window-default-collapsed .x-window-header {
    border-width: 0 !important;
}

.x-window-default-outer-border-l {
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-b {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-bl {
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-r {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-window-default-outer-border-rl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-rb {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-rbl {
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-t {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
}

.x-window-default-outer-border-tl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-tb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-tbl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-tr {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
}

.x-window-default-outer-border-trl {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-left-color: #5fa2dd !important;
    border-left-width: 1px !important;
}

.x-window-default-outer-border-trb {
    border-top-color: #5fa2dd !important;
    border-top-width: 1px !important;
    border-right-color: #5fa2dd !important;
    border-right-width: 1px !important;
    border-bottom-color: #5fa2dd !important;
    border-bottom-width: 1px !important;
}

.x-window-default-outer-border-trbl {
    border-color: #5fa2dd !important;
    border-width: 1px !important;
}

.x-window-body-plain {
    background-color: transparent;
}

.x-form-item-label-default {
    color: #404040;
    font: 300 13px/17px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    min-height: 32px;
    padding-top: 8px;
    padding-right: 5px;
}

.x-form-item-label-default.x-rtl {
    padding-left: 5px;
    padding-right: 0;
}

.x-ie8 .x-form-item-label-default {
    min-height: 24px;
}

.x-form-item-label-default.x-form-item-label-top {
    height: 1px;
}

.x-form-item-label-default.x-form-item-label-top > .x-form-item-label-inner {
    padding-top: 8px;
    padding-bottom: 5px;
}

.x-form-item-label-default.x-form-item-label-top-side-error:after {
    width: 26px;
}

.x-form-item-body-default {
    min-height: 32px;
}

.x-form-invalid-icon-default {
    width: 16px;
    height: 16px;
    margin: 0 5px;
    background: url(images/form/exclamation.png) no-repeat;
}

.x-form-invalid-under-default {
    padding: 2px 2px 2px 20px;
    color: #cf4c35;
    font: 300 13px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    background: no-repeat 0 2px;
    background-image: url(images/form/exclamation.png);
}

.x-form-error-wrap-default.x-form-error-wrap-side {
    width: 26px;
}

.x-form-item-default.x-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-autocontainer-form-item,
.x-anchor-form-item,
.x-vbox-form-item,
.x-table-form-item {
    margin-bottom: 10px;
}

.x-form-text-field-body-default {
    min-width: 170px;
    max-width: 170px;
}

.x-form-trigger-wrap-default {
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-form-trigger-wrap-default.x-form-trigger-wrap-focus {
    border-color: #77b0e2;
}

.x-form-trigger-wrap-default.x-form-trigger-wrap-invalid {
    border-color: #cf4c35;
}

.x-form-text-default {
    color: #404040;
    padding: 5px 10px 4px;
    background-color: #fff;
    font: 300 13px/21px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    min-height: 30px;
}

.x-ie8 .x-form-text-default {
    min-height: 21px;
}

.x-form-text-default.x-form-textarea {
    line-height: 20px;
    min-height: 80px;
}

.x-ie8 .x-form-text-default.x-form-textarea {
    min-height: 71px;
}

.x-form-text-heighted .x-form-text-default.x-form-textarea {
    min-height: auto;
}

.x-form-text-default.x-form-text-file {
    color: grey;
}

.x-form-text-default.x-webkit-border-box-bug {
    height: calc(100% + 9px);
}

.x-placeholder-label-default {
    padding: 5px 10px 4px;
}

.x-form-empty-field-default + .x-placeholder-label-default {
    color: grey;
}

.x-form-text-default:-ms-input-placeholder {
    color: grey;
}

.x-form-invalid-field-default {
    background-color: #fff;
}

.x-form-trigger-default {
    width: 32px;
    font: 16px/30px 'Font Awesome 5 Free';
    background: #fff;
    color: #919191;
}

.x-form-trigger-default:before {
    content: '\f0d7';
}

.x-form-trigger-default.x-form-trigger-over {
    background-position: -32px center;
}

.x-form-trigger-default.x-form-trigger-over.x-form-trigger-focus {
    background-position: -128px center;
}

.x-form-trigger-default.x-form-trigger-focus {
    background-position: -96px center;
}

.x-form-trigger.x-form-trigger-default.x-form-trigger-click {
    background-position: -64px center;
}

.x-textfield-default-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-form-clear-trigger {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
}

.x-form-clear-trigger:before {
    content: '\f00d';
}

.x-form-search-trigger {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
}

.x-form-search-trigger:before {
    content: '\f002';
}

.x-message-box .x-window-body {
    background-color: #fff;
    border-width: 0;
}

.x-message-box-info,
.x-message-box-warning,
.x-message-box-question,
.x-message-box-error {
    background-position: left top;
    background-repeat: no-repeat;
}

.x-rtl.x-message-box-info,
.x-rtl.x-message-box-warning,
.x-rtl.x-message-box-question,
.x-rtl.x-message-box-error {
    background-position: right top;
}

.x-message-box-icon {
    height: 44px;
    width: 44px;
    margin-right: 10px;
}

.x-message-box-info {
    font: 44px/1 'Font Awesome 5 Free';
    color: grey;
}

.x-message-box-info:before {
    content: '\f05a';
}

.x-message-box-warning {
    font: 44px/1 'Font Awesome 5 Free';
    color: #f8d400;
}

.x-message-box-warning:before {
    content: '\f071';
}

.x-message-box-question {
    font: 44px/1 'Font Awesome 5 Free';
    color: grey;
}

.x-message-box-question:before {
    content: '\f059';
}

.x-message-box-error {
    font: 44px/1 'Font Awesome 5 Free';
    color: #ee611f;
}

.x-message-box-error:before {
    content: '\f057';
}

.x-form-cb-wrap-default {
    height: 32px;
    min-width: 18px;
}

.x-form-cb-default {
    margin-top: 7px;
}

.x-form-checkbox-default,
.x-form-radio-default {
    width: 18px;
    height: 18px;
}

.x-form-radio-default {
    font: 18px/1 'Font Awesome 5 Free Regular';
    color: #919191;
}

.x-form-radio-default:before {
    content: '\f111';
}

.x-form-cb-checked .x-form-radio-default {
    font: 18px/1 'Font Awesome 5 Free Regular';
}

.x-form-cb-checked .x-form-radio-default:before {
    content: '\f192';
}

.x-form-checkbox-default {
    font: 18px/1 ExtJS;
    color: #919191;
}

.x-form-checkbox-default:before {
    content: "\e614";
}

.x-form-cb-checked .x-form-checkbox-default {
    font: 18px/1 ExtJS;
}

.x-form-cb-checked .x-form-checkbox-default:before {
    content: "\e613";
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-radio-default {
    color: #77b0e2;
}

.x-keyboard-mode .x-form-checkbox-focus.x-form-checkbox-default {
    color: #77b0e2;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-radio-default {
    color: #77b0e2;
}

.x-keyboard-mode .x-form-cb-checked .x-form-checkbox-focus.x-form-checkbox-default {
    color: #77b0e2;
}

.x-form-cb-label-default {
    margin-top: 8px;
    font: 300 13px/17px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #404040;
}

.x-form-cb-label-default.x-form-cb-label-before {
    padding-right: 22px;
}

.x-form-cb-label-default.x-form-cb-label-before.x-rtl {
    padding-right: 0;
    padding-left: 22px;
}

.x-form-cb-label-default.x-form-cb-label-after {
    padding-left: 22px;
}

.x-form-cb-label-default.x-rtl {
    padding-left: 0;
    padding-right: 22px;
}

.x-checkbox-default-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-form-item-body-default.x-form-checkboxgroup-body {
    padding: 0 4px;
}

.x-form-invalid .x-form-item-body-default.x-form-checkboxgroup-body {
    border-width: 1px;
    border-style: solid;
    border-color: #cf4c35;
}

.x-fieldset-default {
    border: 1px solid #d0d0d0;
    padding: 5px 15px 10px 15px;
    margin: 0 0 10px;
}

.x-ie8 .x-fieldset-default {
    padding-top: 0;
}

.x-ie8 .x-fieldset-body-default {
    padding-top: 5px;
}

.x-fieldset-header-default {
    padding: 10px 5px;
    line-height: 20px;
}

.x-fieldset-header-default > .x-fieldset-header-text {
    font: 400 16px/20px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #919191;
    padding: 1px 0;
}

.x-fieldset-header-checkbox-default {
    margin: 2px 4px 0 0;
    line-height: 20px;
}

.x-fieldset-header-checkbox-default.x-rtl {
    margin: 2px 0 0 4px;
}

.x-fieldset-header-tool-default {
    margin: 2px 4px 0 0;
    padding: 0;
}

.x-fieldset-header-tool-default.x-rtl {
    margin: 2px 0 0 4px;
}

.x-fieldset-header-tool-default > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    opacity: 0.8;
    height: 15px;
    width: 15px;
}

.x-fieldset-header-tool-default.x-tool-over > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    opacity: 0.9;
}

.x-fieldset-header-tool-default.x-tool-pressed > .x-tool-img {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-fieldset-header-tool-default > .x-tool-toggle {
    font: 14px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-fieldset-header-tool-default > .x-tool-toggle:before {
    content: '\f146';
}

.x-keyboard-mode .x-fieldset-header-tool-default.x-focus {
    outline: 1px solid #77b0e2;
}

.x-fieldset-default.x-fieldset-collapsed {
    border-width: 1px 1px 0 1px;
    border-left-color: transparent;
    border-right-color: transparent;
}

.x-fieldset-default.x-fieldset-collapsed .x-tool-toggle {
    font: 14px/1 'Font Awesome 5 Free';
}

.x-fieldset-default.x-fieldset-collapsed .x-tool-toggle:before {
    content: '\f0fe';
}

.x-form-trigger-spinner-default {
    width: 32px;
}

.x-form-trigger-spinner-default:before {
    content: '';
}

.x-form-spinner-default {
    background-color: #fff;
    width: 32px;
    height: 15px;
    color: #919191;
}

.x-form-spinner-up-default {
    font: 16px/1 ExtJS;
}

.x-form-spinner-up-default:before {
    content: "\e61c";
}

.x-form-spinner-down-default {
    font: 16px/1 ExtJS;
}

.x-form-spinner-down-default:before {
    content: "\e61b";
}

.x-form-spinner-up-default {
    background-position: 0 0;
}

.x-form-spinner-up-default.x-form-spinner-over {
    background-position: -32px 0;
}

.x-form-spinner-up-default.x-form-spinner-over.x-form-spinner-focus {
    background-position: -128px 0;
}

.x-form-spinner-up-default.x-form-spinner-focus {
    background-position: -96px 0;
}

.x-form-spinner-up-default.x-form-spinner.x-form-spinner-click {
    background-position: -64px 0;
}

.x-form-spinner-down-default {
    background-position: 0 -15px;
}

.x-form-spinner-down-default.x-form-spinner-over {
    background-position: -32px -15px;
}

.x-form-spinner-down-default.x-form-spinner-over.x-form-spinner-focus {
    background-position: -128px -15px;
}

.x-form-spinner-down-default.x-form-spinner-focus {
    background-position: -96px -15px;
}

.x-form-spinner-down-default.x-form-spinner.x-form-spinner-click {
    background-position: -64px -15px;
}

.x-tbar-page-number {
    width: 30px;
}

.x-btn-icon-el.x-tbar-page-first {
    font: 16px/16px ExtJS;
    color: #919191;
}

.x-btn-icon-el.x-tbar-page-first:before {
    content: "\e617";
}

.x-btn-icon-el.x-tbar-page-prev {
    font: 16px/16px ExtJS;
    color: #919191;
}

.x-btn-icon-el.x-tbar-page-prev:before {
    content: "\e615";
}

.x-btn-icon-el.x-tbar-page-next {
    font: 16px/16px ExtJS;
    color: #919191;
}

.x-btn-icon-el.x-tbar-page-next:before {
    content: "\e616";
}

.x-btn-icon-el.x-tbar-page-last {
    font: 16px/16px ExtJS;
    color: #919191;
}

.x-btn-icon-el.x-tbar-page-last:before {
    content: "\e618";
}

.x-btn-icon-el.x-tbar-loading {
    font: 16px/16px 'Font Awesome 5 Free';
    color: #919191;
}

.x-btn-icon-el.x-tbar-loading:before {
    content: '\f021';
}

.x-rtl.x-tbar-page-first {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-first:before {
    content: "\e618";
}

.x-rtl.x-tbar-page-prev {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-prev:before {
    content: "\e616";
}

.x-rtl.x-tbar-page-next {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-next:before {
    content: "\e615";
}

.x-rtl.x-tbar-page-last {
    font: 16px/16px ExtJS;
}

.x-rtl.x-tbar-page-last:before {
    content: "\e617";
}

.x-boundlist {
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background: #fff;
}

.x-boundlist-item {
    padding: 0 10px;
    font: normal 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 30px;
    cursor: pointer;
    cursor: hand;
    position: relative;
    /*allow hover in IE on empty items*/
    border-width: 1px;
    border-style: dotted;
    border-color: #fff;
    color: #404040;
}

.x-boundlist-selected {
    color: #fff;
    background: #5fa2dd;
    border-color: #5fa2dd;
}

.x-boundlist-item-over {
    color: #fff;
    background: #7fb5e4;
    border-color: #7fb5e4;
}

.x-boundlist-floating {
    border-top-width: 0;
}

.x-boundlist-above {
    border-top-width: 1px;
    border-bottom-width: 1px;
}

.x-datepicker {
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #fff;
    width: 310px;
}

.x-datepicker-header {
    padding: 0;
    text-align: center;
    background-color: #ececec;
}

.x-datepicker-arrow {
    width: 32px;
    height: 44px;
    top: 0px;
    cursor: pointer;
    -webkit-touch-callout: none;
    color: #919191;
    background-color: #ececec;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

div.x-datepicker-arrow:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-datepicker-next {
    right: 0;
    font: 16px/1 'Font Awesome 5 Free';
}

.x-datepicker-next:before {
    content: '\f101';
}

.x-datepicker-prev {
    left: 0;
    font: 16px/1 'Font Awesome 5 Free';
}

.x-datepicker-prev:before {
    content: '\f100';
}

.x-datepicker-month {
    background: transparent;
}

.x-datepicker-month .x-btn,
.x-datepicker-month .x-btn .x-btn-tc,
.x-datepicker-month .x-btn .x-btn-tl,
.x-datepicker-month .x-btn .x-btn-tr,
.x-datepicker-month .x-btn .x-btn-mc,
.x-datepicker-month .x-btn .x-btn-ml,
.x-datepicker-month .x-btn .x-btn-mr,
.x-datepicker-month .x-btn .x-btn-bc,
.x-datepicker-month .x-btn .x-btn-bl,
.x-datepicker-month .x-btn .x-btn-br {
    background: transparent;
    border-width: 0 !important;
}

.x-datepicker-month .x-btn-inner {
    color: #404040;
}

.x-datepicker-month .x-btn-split-right:after,
.x-datepicker-month .x-btn-over .x-btn-split-right:after {
    content: '\f107';
    font: 16px/1 'Font Awesome 5 Free';
    color: #606060;
    background: none;
    padding: 0;
    text-align: right;
    width: 16px;
}

.x-datepicker-month .x-btn {
    padding: 14px;
}

.x-datepicker-month .x-btn-over {
    border-color: transparent;
    background: #dfdfdf;
}

.x-datepicker-month .x-btn.x-btn-pressed {
    border-color: transparent;
    background: #d3d3d3;
}

.x-datepicker-month .x-btn-inner {
    font-size: 15px;
}

.x-datepicker-column-header {
    width: 44px;
    color: #404040;
    font: 400 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-align: right;
    background-color: #ececec;
}

.x-datepicker-column-header-inner {
    line-height: 36px;
    padding: 0 15px 0 0;
}

.x-datepicker-cell {
    text-align: right;
    border: 0;
}

.x-datepicker-date {
    padding: 0 15px 0 0;
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #404040;
    cursor: pointer;
    line-height: 36px;
}

div.x-datepicker-date:hover {
    color: #404040;
    background-color: #ececec;
}

.x-datepicker-selected {
    border-style: solid;
    border-color: #7fb5e4;
}

.x-datepicker-selected div.x-datepicker-date {
    background-color: #5fa2dd;
    color: #fff;
    font-weight: 300;
}

.x-datepicker-today {
    border-color: darkred;
    border-style: solid;
    background-color: #bfdaf1;
}

.x-datepicker-prevday .x-datepicker-date,
.x-datepicker-nextday .x-datepicker-date {
    color: #d0d0d0;
}

.x-datepicker-disabled .x-datepicker-date {
    background-color: #eee;
    cursor: default;
    color: silver;
}

.x-datepicker-disabled div.x-datepicker-date:hover {
    background-color: #eee;
    color: silver;
}

.x-datepicker-footer,
.x-monthpicker-buttons {
    padding: 6px 0;
    background-color: #fff;
    text-align: center;
}

.x-datepicker-footer .x-btn,
.x-monthpicker-buttons .x-btn {
    margin: 0 3px 0 2px;
}

.x-monthpicker {
    width: 310px;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #fff;
}

.x-monthpicker-months {
    border-width: 0 1px 0 0;
    border-color: #d0d0d0;
    border-style: solid;
    width: 154px;
}

.x-monthpicker-months .x-monthpicker-item {
    width: 76px;
}

.x-monthpicker-years {
    width: 154px;
}

.x-monthpicker-years .x-monthpicker-item {
    width: 77px;
}

.x-monthpicker-item {
    margin: 5px 0 5px;
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-align: center;
}

.x-monthpicker-item-inner {
    margin: 0 5px 0 5px;
    color: #404040;
    border: 0;
    line-height: 37px;
    cursor: pointer;
}

a.x-monthpicker-item-inner:hover {
    color: #404040;
    background-color: #ececec;
}

.x-monthpicker-item a.x-monthpicker-selected {
    background-color: #5fa2dd;
    color: #fff;
    border-style: solid;
    border-color: #7fb5e4;
}

.x-monthpicker-yearnav {
    height: 47px;
}

.x-monthpicker-yearnav-button-ct {
    width: 77px;
}

.x-monthpicker-yearnav-button {
    height: 16px;
    width: 16px;
    cursor: pointer;
    margin-top: 15px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
    -webkit-touch-callout: none;
    color: #919191;
    background-color: #fff;
}

a.x-monthpicker-yearnav-button:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-monthpicker-yearnav-next {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-monthpicker-yearnav-next:before {
    content: '\f101';
}

.x-monthpicker-yearnav-prev {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-monthpicker-yearnav-prev:before {
    content: '\f100';
}

.x-monthpicker-small .x-monthpicker-item {
    margin: 2px 0 2px;
}

.x-monthpicker-small .x-monthpicker-item-inner {
    margin: 0 5px 0 5px;
}

.x-monthpicker-small .x-monthpicker-yearnav {
    height: 41px;
}

.x-monthpicker-small .x-monthpicker-yearnav-button {
    margin-top: 12px;
}

.x-form-field-date .x-form-date-trigger {
    font-size: 16px;
    font-family: 'Font Awesome 5 Free';
}

.x-form-field-date .x-form-date-trigger:before {
    content: '\f073';
}

.x-form-display-field-default {
    min-height: 32px;
    font: 300 13px/17px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #404040;
    margin-top: 8px;
}

.x-keyboard-mode .x-form-display-field-default.x-field-form-focus {
    outline: 1px solid #77b0e2;
    outline-offset: -1px;
}

.x-ie .x-keyboard-mode .x-form-display-field-default.x-field-form-focus,
.x-ie10p .x-keyboard-mode .x-form-display-field-default.x-field-form-focus,
.x-edge .x-keyboard-mode .x-form-display-field-default.x-field-form-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after,
.x-ie10p .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after,
.x-edge .x-keyboard-mode .x-form-display-field-default.x-field-form-focus:after {
    position: absolute;
    content: ' ';
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid #77b0e2;
    pointer-events: none;
}

.x-ie8 .x-form-display-field-default.x-field-form-focus {
    position: relative;
}

.x-tip-default {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #747474;
}

.x-tip-default-mc {
    background-color: #747474;
}

.x-nbr .x-tip-default {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tip-default-frameInfo {
    font-family: th-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-tip-default-tl {
    background-position: 0 -4px;
}

.x-tip-default-tr {
    background-position: right -6px;
}

.x-tip-default-bl {
    background-position: 0 -8px;
}

.x-tip-default-br {
    background-position: right -10px;
}

.x-tip-default-ml {
    background-position: 0 top;
}

.x-tip-default-mr {
    background-position: right top;
}

.x-tip-default-tc {
    background-position: 0 0;
}

.x-tip-default-bc {
    background-position: 0 -2px;
}

.x-tip-default-tr,
.x-tip-default-br,
.x-tip-default-mr {
    padding-right: 2px;
}

.x-tip-default-tl,
.x-tip-default-bl,
.x-tip-default-ml {
    padding-left: 2px;
}

.x-tip-default-tc {
    height: 2px;
}

.x-tip-default-bc {
    height: 2px;
}

.x-tip-default-tl,
.x-tip-default-bl,
.x-tip-default-tr,
.x-tip-default-br,
.x-tip-default-tc,
.x-tip-default-bc,
.x-tip-default-ml,
.x-tip-default-mr {
    background-image: url(images/tip/tip-default-corners.gif);
}

.x-tip-default-ml,
.x-tip-default-mr {
    background-image: url(images/tip/tip-default-sides.gif);
    background-repeat: repeat-y;
}

.x-tip-default-mc {
    padding: 0px 0px 0px 0px;
}

.x-tip-default {
    background-color: #747474;
    border-color: #747474;
}

.x-tip-default .x-tool-img {
    background-image: url(images/tools/tool-sprites-dark.png);
    background-color: #747474;
}

.x-tip-header-default .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-tip-header-default .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-tip-header-default .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-tip-header-default .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-tip-header-default {
    padding: 3px 3px 0 3px;
}

.x-tip-header-title-default {
    color: #f0f0f0;
    font-size: 13px;
    font-weight: bold;
}

.x-tip-body-default {
    padding: 6px 8px;
    color: #f0f0f0;
    font-size: 13px;
    font-weight: 300;
}

.x-tip-body-default a {
    color: #f0f0f0;
}

.x-tip-default .x-tip-anchor {
    border: 10px solid transparent;
    _border-color: pink;
    _filter: chroma(color=pink);
}

.x-tip-default .x-tip-anchor-top {
    border-top-width: 0;
    border-bottom: 10px solid #747474;
    top: -10px;
}

.x-tip-default .x-tip-anchor-bottom {
    border-bottom-width: 0;
    border-top: 10px solid #747474;
    bottom: -10px;
}

.x-tip-default .x-tip-anchor-left {
    border-left-width: 0;
    border-right: 10px solid #747474;
    left: -10px;
}

.x-tip-default .x-tip-anchor-right {
    border-right-width: 0;
    border-left: 10px solid #747474;
    right: -10px;
}

.x-tip-form-invalid {
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    border-radius: 2px;
    padding: 1px 1px 1px 1px;
    border-width: 1px;
    border-style: solid;
    background-color: #747474;
}

.x-tip-form-invalid-mc {
    background-color: #747474;
}

.x-nbr .x-tip-form-invalid {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-tip-form-invalid-frameInfo {
    font-family: th-2-2-2-2-1-1-1-1-1-1-1-1;
}

.x-tip-form-invalid-tl {
    background-position: 0 -4px;
}

.x-tip-form-invalid-tr {
    background-position: right -6px;
}

.x-tip-form-invalid-bl {
    background-position: 0 -8px;
}

.x-tip-form-invalid-br {
    background-position: right -10px;
}

.x-tip-form-invalid-ml {
    background-position: 0 top;
}

.x-tip-form-invalid-mr {
    background-position: right top;
}

.x-tip-form-invalid-tc {
    background-position: 0 0;
}

.x-tip-form-invalid-bc {
    background-position: 0 -2px;
}

.x-tip-form-invalid-tr,
.x-tip-form-invalid-br,
.x-tip-form-invalid-mr {
    padding-right: 2px;
}

.x-tip-form-invalid-tl,
.x-tip-form-invalid-bl,
.x-tip-form-invalid-ml {
    padding-left: 2px;
}

.x-tip-form-invalid-tc {
    height: 2px;
}

.x-tip-form-invalid-bc {
    height: 2px;
}

.x-tip-form-invalid-tl,
.x-tip-form-invalid-bl,
.x-tip-form-invalid-tr,
.x-tip-form-invalid-br,
.x-tip-form-invalid-tc,
.x-tip-form-invalid-bc,
.x-tip-form-invalid-ml,
.x-tip-form-invalid-mr {
    background-image: url(images/tip/tip-form-invalid-corners.gif);
}

.x-tip-form-invalid-ml,
.x-tip-form-invalid-mr {
    background-image: url(images/tip/tip-form-invalid-sides.gif);
    background-repeat: repeat-y;
}

.x-tip-form-invalid-mc {
    padding: 0px 0px 0px 0px;
}

.x-tip-form-invalid {
    background-color: #747474;
    border-color: #747474;
}

.x-tip-form-invalid .x-tool-img {
    background-image: url(images/tools/tool-sprites-dark.png);
    background-color: #747474;
}

.x-tip-header-form-invalid .x-tool-after-title {
    margin: 0 0 0 6px;
}

.x-tip-header-form-invalid .x-rtl.x-tool-after-title {
    margin: 0 6px 0 0;
}

.x-tip-header-form-invalid .x-tool-before-title {
    margin: 0 6px 0 0;
}

.x-tip-header-form-invalid .x-rtl.x-tool-before-title {
    margin: 0 0 0 6px;
}

.x-tip-header-form-invalid {
    padding: 3px 3px 0 3px;
}

.x-tip-header-title-form-invalid {
    color: #f0f0f0;
    font-size: 13px;
    font-weight: bold;
}

.x-tip-body-form-invalid {
    padding: 3px 3px 3px 22px;
    color: #f0f0f0;
    font-size: 13px;
    font-weight: 300;
}

.x-tip-body-form-invalid a {
    color: #f0f0f0;
}

.x-tip-form-invalid .x-tip-anchor {
    border: 10px solid transparent;
    _border-color: pink;
    _filter: chroma(color=pink);
}

.x-tip-form-invalid .x-tip-anchor-top {
    border-top-width: 0;
    border-bottom: 10px solid #747474;
    top: -10px;
}

.x-tip-form-invalid .x-tip-anchor-bottom {
    border-bottom-width: 0;
    border-top: 10px solid #747474;
    bottom: -10px;
}

.x-tip-form-invalid .x-tip-anchor-left {
    border-left-width: 0;
    border-right: 10px solid #747474;
    left: -10px;
}

.x-tip-form-invalid .x-tip-anchor-right {
    border-right-width: 0;
    border-left: 10px solid #747474;
    right: -10px;
}

.x-tip-body-form-invalid {
    background: 1px 1px no-repeat;
    background-image: url(images/form/exclamation.png);
}

.x-tip-body-form-invalid li {
    margin-bottom: 4px;
}

.x-tip-body-form-invalid li.last {
    margin-bottom: 0;
}

.x-color-picker {
    width: 192px;
    height: 120px;
    background-color: #fff;
    border-color: #fff;
    border-width: 0;
    border-style: solid;
}

.x-color-picker-item {
    width: 24px;
    height: 24px;
    border-width: 1px;
    border-color: #fff;
    border-style: solid;
    background-color: #fff;
    cursor: pointer;
    padding: 2px;
}

a.x-color-picker-item:hover {
    border-color: #8bb8f3;
    background-color: #d9d9d9;
}

.x-color-picker-selected {
    border-color: #8bb8f3;
    background-color: #d9d9d9;
}

.x-color-picker-item-inner {
    line-height: 16px;
    border-color: #d0d0d0;
    border-width: 1px;
    border-style: solid;
}

.x-html-editor-tb .x-btn-icon-el {
    color: #919191;
}

.x-html-editor-tb .x-edit-bold,
.x-menu-item div.x-edit-bold {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-bold:before,
.x-menu-item div.x-edit-bold:before {
    content: '\f032';
}

.x-html-editor-tb .x-edit-italic,
.x-menu-item div.x-edit-italic {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-italic:before,
.x-menu-item div.x-edit-italic:before {
    content: '\f033';
}

.x-html-editor-tb .x-edit-underline,
.x-menu-item div.x-edit-underline {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-underline:before,
.x-menu-item div.x-edit-underline:before {
    content: '\f0cd';
}

.x-html-editor-tb .x-edit-forecolor,
.x-menu-item div.x-edit-forecolor {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-forecolor:before,
.x-menu-item div.x-edit-forecolor:before {
    content: "\e60e";
}

.x-html-editor-tb .x-edit-backcolor,
.x-menu-item div.x-edit-backcolor {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-backcolor:before,
.x-menu-item div.x-edit-backcolor:before {
    content: "\e60d";
}

.x-html-editor-tb .x-edit-justifyleft,
.x-menu-item div.x-edit-justifyleft {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifyleft:before,
.x-menu-item div.x-edit-justifyleft:before {
    content: '\f036';
}

.x-html-editor-tb .x-edit-justifycenter,
.x-menu-item div.x-edit-justifycenter {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifycenter:before,
.x-menu-item div.x-edit-justifycenter:before {
    content: '\f037';
}

.x-html-editor-tb .x-edit-justifyright,
.x-menu-item div.x-edit-justifyright {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-justifyright:before,
.x-menu-item div.x-edit-justifyright:before {
    content: '\f038';
}

.x-html-editor-tb .x-edit-insertorderedlist,
.x-menu-item div.x-edit-insertorderedlist {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-insertorderedlist:before,
.x-menu-item div.x-edit-insertorderedlist:before {
    content: '\f0cb';
}

.x-html-editor-tb .x-edit-insertunorderedlist,
.x-menu-item div.x-edit-insertunorderedlist {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-insertunorderedlist:before,
.x-menu-item div.x-edit-insertunorderedlist:before {
    content: '\f0ca';
}

.x-html-editor-tb .x-edit-increasefontsize,
.x-menu-item div.x-edit-increasefontsize {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-increasefontsize:before,
.x-menu-item div.x-edit-increasefontsize:before {
    content: "\e610";
}

.x-html-editor-tb .x-edit-decreasefontsize,
.x-menu-item div.x-edit-decreasefontsize {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-decreasefontsize:before,
.x-menu-item div.x-edit-decreasefontsize:before {
    content: "\e60f";
}

.x-html-editor-tb .x-edit-sourceedit,
.x-menu-item div.x-edit-sourceedit {
    font: 16px/1 ExtJS;
    background: none;
}

.x-html-editor-tb .x-edit-sourceedit:before,
.x-menu-item div.x-edit-sourceedit:before {
    content: "\e604";
}

.x-html-editor-tb .x-edit-createlink,
.x-menu-item div.x-edit-createlink {
    font: 16px/1 'Font Awesome 5 Free';
    background: none;
}

.x-html-editor-tb .x-edit-createlink:before,
.x-menu-item div.x-edit-createlink:before {
    content: '\f0c1';
}

.x-html-editor-tip .x-tip-bd .x-tip-bd-inner {
    padding: 5px;
    padding-bottom: 1px;
}

.x-html-editor-tb .x-font-select {
    font-size: 13px;
    font-family: inherit;
}

.x-html-editor-wrap textarea {
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    background-color: #fff;
    resize: none;
}

.x-htmleditor-iframe {
    background-color: #fff;
}

.x-form-text-default.x-tagfield {
    padding: 3px 0 0 4px;
}

.x-form-text-default .x-tagfield-input {
    margin: 0 4px 3px 0;
}

.x-form-text-default .x-tagfield-input-field {
    height: 24px;
    line-height: 24px;
}

.x-form-text-default .x-tagfield-item {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    background-color: #ececec;
    border: 1px solid #ececec;
    padding: 0 21px 0 5px;
    margin: 0 4px 3px 0;
    color: #404040;
    line-height: 22px;
}

.x-form-text-default .x-tagfield-item:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
    color: #404040;
}

.x-form-text-default .x-tagfield-item.x-tagfield-item-selected {
    background-color: #7fb5e4;
    border-color: #7fb5e4;
    color: #fff;
}

.x-form-text-default .x-tagfield-item.x-rtl {
    padding: 0 5px 0 21px;
}

.x-form-text-default .x-tagfield-item-close {
    width: 12px;
    height: 12px;
    top: 5px;
    right: 3px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=85)";
    opacity: 0.85;
    font: 12px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-form-text-default .x-tagfield-item-close:before {
    content: '\f00d';
}

.x-form-text-default .x-tagfield-item-close:hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=93)";
    opacity: 0.93;
    color: #919191;
}

.x-form-text-default .x-tagfield-item-close:active {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-form-text-default .x-tagfield-item-close.x-rtl {
    right: auto;
    left: 3px;
}

.x-form-text-default .x-tagfield-item-selected .x-tagfield-item-close {
    color: #fff;
}

.x-form-text-default .x-tagfield-item-selected .x-tagfield-item-close:hover {
    color: #fff;
}

.x-grid-view,
.x-tree-view {
    z-index: 1;
}

.x-theme-row-height-el {
    height: 32px;
}

.x-grid-body {
    background: #fff;
    border-width: 2px 1px 1px;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-grid-empty {
    padding: 10px;
    color: grey;
    background-color: #fff;
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-item {
    color: #404040;
    font: 300 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    background-color: #fff;
}

.x-grid-item-alt {
    background-color: #f6f6f6;
}

.x-grid-item-over {
    color: #404040;
    background-color: #eaeff4;
}

.x-grid-item-focused {
    outline: 0;
}

.x-grid-item-focused .x-grid-cell-inner {
    z-index: 2;
}

.x-keyboard-mode .x-grid-item-focused {
    color: #404040;
}

.x-keyboard-mode .x-grid-item-focused .x-grid-cell-inner:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    pointer-events: none;
    border: 1px solid #5fa2dd;
}

.x-grid-item-selected {
    color: #404040;
    background-color: #ffefbb;
}

.x-grid-with-row-lines .x-grid-item {
    border-style: solid;
    border-width: 1px 0 0;
    border-color: #e9e9e9;
}

.x-grid-with-row-lines .x-grid-item:first-child {
    border-top-color: #fff;
}

.x-grid-with-row-lines .x-grid-item.x-grid-item-over {
    border-style: solid;
    border-color: #dee3e8;
}

.x-grid-with-row-lines .x-grid-item-over + .x-grid-item {
    border-top-style: solid;
    border-top-color: #dee3e8;
}

.x-grid-with-row-lines .x-grid-item.x-grid-item-selected {
    border-style: solid;
    border-color: #f2e3b2;
}

.x-grid-with-row-lines .x-grid-item-selected + .x-grid-item {
    border-top-style: solid;
    border-top-color: #f2e3b2;
}

.x-grid-with-row-lines .x-grid-item:last-child {
    border-bottom-width: 1px;
}

.x-ie8 .x-grid-with-row-lines .x-grid-item {
    border-width: 1px 0;
    margin-top: -1px;
}

.x-ie8 .x-grid-with-row-lines .x-grid-item:first-child {
    margin-top: 0;
}

.x-grid-cell-inner {
    position: relative;
    z-index: 1;
    text-overflow: ellipsis;
    padding: 7px 10px 6px;
}

.x-grid-cell-special {
    border-color: #e9e9e9;
    border-style: solid;
    border-right-width: 1px;
}

.x-rtl.x-grid-cell-special {
    border-right-width: 0;
    border-left-width: 1px;
}

.x-grid-dirty-cell > .x-grid-cell-inner:after {
    content: "\e602";
    font: 14px/1 ExtJS;
    color: #cf4c35;
    position: absolute;
    top: 0;
    left: 0;
}

.x-rtl.x-grid-dirty-cell > .x-grid-cell-inner:after {
    content: "\e603";
    font: 14px/1 ExtJS;
    right: 0;
}

.x-grid-row .x-grid-cell-selected {
    color: #404040;
    background-color: #ffefbb;
}

.x-grid-with-col-lines .x-grid-cell {
    border-style: solid;
    border-color: #e9e9e9;
    border-width: 0 1px 0 0;
}

.x-grid-with-col-lines .x-grid-item-over .x-grid-cell {
    border-color: #dee3e8;
    border-style: solid;
}

.x-grid-with-col-lines .x-grid-item-selected .x-grid-cell {
    border-color: #f2e3b2;
    border-style: solid;
}

.x-rtl.x-grid-with-col-lines .x-grid-cell {
    border-width: 0 0 0 1px;
}

.x-grid-resize-marker {
    width: 1px;
    background-color: #0f0f0f;
}

.x-grid-drop-indicator {
    position: absolute;
    height: 1px;
    line-height: 0px;
    background-color: #77bc71;
    overflow: visible;
    pointer-events: none;
}

.x-grid-drop-indicator .x-grid-drop-indicator-left {
    position: absolute;
    top: -8px;
    left: -12px;
    background-image: url(images/grid/dd-insert-arrow-right.png);
    height: 16px;
    width: 16px;
}

.x-grid-drop-indicator .x-grid-drop-indicator-right {
    position: absolute;
    top: -8px;
    right: -11px;
    background-image: url(images/grid/dd-insert-arrow-left.png);
    height: 16px;
    width: 16px;
}

.x-col-move-top,
.x-col-move-bottom {
    width: 9px;
    height: 9px;
}

.x-col-move-top {
    background-image: url(images/grid/col-move-top.png);
}

.x-col-move-bottom {
    background-image: url(images/grid/col-move-bottom.png);
}

.x-grid-header-ct {
    border: 1px solid #d0d0d0;
    border-bottom-color: #fff;
    background-color: #fff;
}

.x-accordion-item .x-grid-header-ct {
    border-width: 0 0 1px !important;
}

.x-grid-header-ct-hidden {
    border-top: 0 !important;
    border-bottom: 0 !important;
}

.x-grid-body {
    border-top-color: #d0d0d0;
}

.x-hmenu-sort-asc {
    font: 16px/24px ExtJS;
}

.x-hmenu-sort-asc:before {
    content: "\e61a";
}

.x-hmenu-sort-desc {
    font: 16px/24px ExtJS;
}

.x-hmenu-sort-desc:before {
    content: "\e619";
}

.x-cols-icon {
    font: 16px/24px ExtJS;
}

.x-cols-icon:before {
    content: "\e601";
}

.x-column-header {
    border-right: 1px solid #d0d0d0;
    color: #404040;
    font: 400 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    outline: 0;
    background-color: #fff;
}

.x-rtl.x-column-header {
    border-right: 0 none;
    border-left: 1px solid #d0d0d0;
}

.x-group-sub-header {
    background: transparent;
    border-top: 1px solid #d0d0d0;
}

.x-group-sub-header .x-column-header-inner {
    padding: 6px 10px 6px 10px;
}

.x-column-header-inner {
    padding: 7px 10px 6px;
}

.x-column-header-inner-empty {
    text-overflow: clip;
}

.x-keyboard-mode .x-column-header.x-column-header-focus {
    color: #404040;
}

.x-keyboard-mode .x-column-header.x-column-header-focus .x-column-header-inner:after {
    content: "";
    position: absolute;
    z-index: 5;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 1px solid #7fb5e4;
    pointer-events: none;
}

.x-keyboard-mode .x-column-header.x-column-header-focus.x-group-sub-header .x-column-header-inner:before {
    bottom: 0px;
}

.x-column-header-over {
    background-image: none;
    background-color: #eaeff4;
}

.x-column-header-sort-ASC,
.x-column-header-sort-DESC {
    background-image: none;
    background-color: #fff;
}

.x-column-header-open {
    background-color: #eaeff4;
}

.x-column-header-open .x-column-header-trigger {
    background-color: #dee3e8;
}

.x-column-header-over .x-column-header-trigger,
.x-column-header-open .x-column-header-trigger {
    width: 24px;
    cursor: pointer;
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-column-header-over .x-column-header-trigger:before,
.x-column-header-open .x-column-header-trigger:before {
    content: '\f0d7';
}

.x-column-header-over .x-column-header-trigger:before,
.x-column-header-open .x-column-header-trigger:before {
    top: 50%;
    margin-top: -8px;
    text-align: center;
    position: relative;
    display: block;
}

.x-rtl.x-column-header-trigger {
    background-position: center center;
}

.x-column-header-align-right .x-column-header-text {
    margin-right: 18px;
}

.x-column-header-align-right .x-rtl.x-column-header-text {
    margin-right: 0;
    margin-left: 18px;
}

.x-column-header-sort-ASC .x-column-header-text-inner,
.x-column-header-sort-DESC .x-column-header-text-inner {
    background-position: right center;
}

.x-column-header-sort-ASC .x-column-header-text-inner:after,
.x-column-header-sort-DESC .x-column-header-text-inner:after {
    display: inline-block;
    text-align: center;
    margin-left: 5px;
    width: 14px;
}

.x-column-header-sort-ASC .x-rtl.x-column-header-text-inner,
.x-column-header-sort-DESC .x-rtl.x-column-header-text-inner {
    background-position: 0 center;
}

.x-column-header-sort-ASC .x-rtl.x-column-header-text-inner:after,
.x-column-header-sort-DESC .x-rtl.x-column-header-text-inner:after {
    margin-left: 0;
    margin-right: 5px;
}

.x-column-header-sort-ASC .x-column-header-text-inner:after {
    content: '\f30c';
    font: 14px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-column-header-sort-DESC .x-column-header-text-inner:after {
    content: '\f309';
    font: 14px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-no-header-borders .x-column-header {
    border: 0 none;
}

.x-no-header-borders .x-column-header .x-column-header-inner {
    padding-top: 7px;
}

.x-grid-cell-inner-action-col {
    padding: 7px 4px 7px 4px;
}

.x-action-col-cell .x-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-action-col-icon {
    color: #919191;
    font-size: 18px;
    height: 18px;
    width: 18px;
    cursor: pointer;
}

.x-column-header-checkbox .x-column-header-inner,
.x-grid-checkcolumn-cell-inner {
    padding: 7px 4px 7px 4px;
    text-overflow: clip;
}

.x-column-header-checkbox {
    border-color: #d0d0d0;
}

.x-column-header-checkbox .x-column-header-text {
    overflow: visible;
}

.x-column-header-checkbox .x-column-header-checkbox:after,
.x-grid-checkcolumn:after {
    content: "\e614";
    font: 18px/1 ExtJS;
    color: #919191;
}

.x-item-disabled .x-column-header-checkbox .x-column-header-checkbox,
.x-item-disabled .x-grid-checkcolumn {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    opacity: 0.3;
}

.x-grid-hd-checker-on .x-column-header-checkbox:after,
.x-grid-checkcolumn-checked:after {
    content: "\e613";
    font: 18px/1 ExtJS;
    color: #919191;
}

.x-grid-item-selected .x-selmodel-column .x-grid-checkcolumn:after {
    content: "\e613";
    font: 18px/1 ExtJS;
    color: #919191;
}

.x-selmodel-checkbox .x-grid-checkcolumn,
.x-selmodel-column .x-grid-checkcolumn {
    pointer-events: none;
}

.x-grid-cell-row-numberer {
    background-color: #fff;
}

.x-grid-cell-inner-row-numberer {
    padding: 7px 5px 6px 3px;
}

.x-btn-grid-cell-small {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    padding: 7px 7px 7px 7px;
    border-width: 1px;
    border-style: solid;
    background-color: #5fa2dd;
}

.x-btn-grid-cell-small-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-grid-cell-small {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-btn-grid-cell-small-frameInfo {
    font-family: th-1-1-1-1-1-1-1-1-7-7-7-7;
}

.x-btn-grid-cell-small-tl {
    background-position: 0 -2px;
}

.x-btn-grid-cell-small-tr {
    background-position: right -3px;
}

.x-btn-grid-cell-small-bl {
    background-position: 0 -4px;
}

.x-btn-grid-cell-small-br {
    background-position: right -5px;
}

.x-btn-grid-cell-small-ml {
    background-position: 0 top;
}

.x-btn-grid-cell-small-mr {
    background-position: right top;
}

.x-btn-grid-cell-small-tc {
    background-position: 0 0;
}

.x-btn-grid-cell-small-bc {
    background-position: 0 -1px;
}

.x-btn-grid-cell-small-tr,
.x-btn-grid-cell-small-br,
.x-btn-grid-cell-small-mr {
    padding-right: 1px;
}

.x-btn-grid-cell-small-tl,
.x-btn-grid-cell-small-bl,
.x-btn-grid-cell-small-ml {
    padding-left: 1px;
}

.x-btn-grid-cell-small-tc {
    height: 1px;
}

.x-btn-grid-cell-small-bc {
    height: 1px;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-base/sass/etc/mixins/frame.scss:407 *!*/
/*.x-btn-grid-cell-small-tl,*/
/*.x-btn-grid-cell-small-bl,*/
/*.x-btn-grid-cell-small-tr,*/
/*.x-btn-grid-cell-small-br,*/
/*.x-btn-grid-cell-small-tc,*/
/*.x-btn-grid-cell-small-bc,*/
/*.x-btn-grid-cell-small-ml,*/
/*.x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-base/sass/etc/mixins/frame.scss:453 *!*/
/*.x-btn-grid-cell-small-ml,*/
/*.x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-sides.gif);*/
/*    background-repeat: repeat-y;*/
/*}*/

.x-btn-grid-cell-small-mc {
    padding: 7px 7px 7px 7px;
}

.x-btn-grid-cell-small {
    border-color: #5897ce;
}

.x-btn-button-grid-cell-small {
    min-height: 16px;
}

.x-ie9m .x-btn-button-grid-cell-small {
    min-height: auto;
    height: 16px;
}

.x-btn-inner-grid-cell-small {
    font: 400 12px/16px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    color: #f0f0f0;
    padding: 0 5px;
    max-width: 100%;
}

.x-ie9 .x-btn-icon-right > .x-btn-inner-grid-cell-small,
.x-ie9 .x-btn-icon-left > .x-btn-inner-grid-cell-small {
    max-width: calc(100% - 16px);
}

.x-safari8m .x-btn-icon-right > .x-btn-inner-grid-cell-small,
.x-safari8m .x-btn-icon-left > .x-btn-inner-grid-cell-small {
    max-width: calc(100% - 15px);
}

.x-ie10p .x-btn-inner-grid-cell-small {
    max-width: none;
}

.x-btn-icon-el-grid-cell-small {
    font-size: 16px;
    height: 16px;
    color: #f0f0f0;
    line-height: 16px;
}

.x-btn-icon-left > .x-btn-icon-el-grid-cell-small,
.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    width: 16px;
}

.x-btn-icon-top > .x-btn-icon-el-grid-cell-small,
.x-btn-icon-bottom > .x-btn-icon-el-grid-cell-small {
    min-width: 16px;
}

.x-ie8 .x-btn-icon-el-grid-cell-small.x-btn-glyph {
    color: #f0f0f0;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-grid-cell-small {
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-left > .x-btn-icon-el-grid-cell-small.x-rtl {
    margin-right: 0;
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-left: 3px;
}

.x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small.x-rtl {
    margin-left: 0;
    margin-right: 3px;
}

.x-btn-text.x-btn-icon-top > .x-btn-icon-el-grid-cell-small {
    margin-bottom: 8px;
}

.x-btn-text.x-btn-icon-bottom > .x-btn-icon-el-grid-cell-small {
    margin-top: 8px;
}

.x-btn-arrow-right > .x-btn-icon.x-btn-no-text.x-btn-button-grid-cell-small {
    padding-right: 8px;
}

.x-btn-arrow-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-right: 5px;
}

.x-btn-arrow-bottom > .x-btn-button-grid-cell-small,
.x-btn-split-bottom > .x-btn-button-grid-cell-small {
    padding-bottom: 7px;
}

.x-btn-wrap-grid-cell-small.x-btn-arrow-right:after {
    width: 8px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-grid-cell-small.x-btn-arrow-bottom:after {
    height: 8px;
    content: '\f0d7';
    font: 16px/8px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button {
    padding-right: 7px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button.x-rtl {
    padding-right: 0;
    padding-left: 7px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right > .x-btn-button:after {
    border-right: 1px solid #f0f0f0;
}

.x-btn-wrap-grid-cell-small.x-btn-split-right:after {
    width: 14px;
    content: '\f0d7';
    font: 16px/1 'Font Awesome 5 Free';
    color: #f0f0f0;
    padding-left: 7px;
}

.x-btn-wrap-grid-cell-small.x-rtl.x-btn-split-right:after {
    padding-left: 0;
    padding-right: 7px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom {
    margin-bottom: -7px;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom > .x-btn-button:after {
    border-bottom: 1px solid #f0f0f0;
}

.x-btn-wrap-grid-cell-small.x-btn-split-bottom:after {
    height: 14px;
    content: '\f0d7';
    font: 16px/14px 'Font Awesome 5 Free';
    color: #f0f0f0;
}

.x-btn-split-right > .x-btn-icon.x-btn-no-text.x-btn-button-grid-cell-small {
    padding-right: 8px;
}

.x-btn-split-right > .x-btn-text.x-btn-icon-right > .x-btn-icon-el-grid-cell-small {
    margin-right: 5px;
}

.x-keyboard-mode .x-btn-focus.x-btn-grid-cell-small {
    background-image: none;
    background-color: #5fa2dd;
}

.x-btn-grid-cell-small .x-btn-arrow-el {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 21px;
    pointer-events: none;
}

.x-btn-grid-cell-small .x-btn-arrow-el.x-rtl {
    right: inherit;
    left: 0;
}

.x-btn-over.x-btn-grid-cell-small {
    border-color: #518bbd;
    background-image: none;
    background-color: #5795cb;
}

.x-btn.x-btn-menu-active.x-btn-grid-cell-small,
.x-btn.x-btn-pressed.x-btn-grid-cell-small {
    border-color: #42719a;
    background-image: none;
    background-color: #477aa6;
}

.x-btn.x-btn-disabled.x-btn-grid-cell-small {
    background-image: none;
    background-color: #5fa2dd;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2966 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-tl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-bl,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-tr,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-br,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-tc,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2974 *!*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-ml,*/
/*.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus .x-btn-grid-cell-small-mc {
    background-color: #5fa2dd;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2991 *!*/
/*.x-btn-over .x-btn-grid-cell-small-tl,*/
/*.x-btn-over .x-btn-grid-cell-small-bl,*/
/*.x-btn-over .x-btn-grid-cell-small-tr,*/
/*.x-btn-over .x-btn-grid-cell-small-br,*/
/*.x-btn-over .x-btn-grid-cell-small-tc,*/
/*.x-btn-over .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:2999 *!*/
/*.x-btn-over .x-btn-grid-cell-small-ml,*/
/*.x-btn-over .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-over-sides.gif);*/
/*}*/

.x-btn-over .x-btn-grid-cell-small-mc {
    background-color: #5795cb;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3016 *!*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-tl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-bl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-tr,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-br,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-tc,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-over-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3024 *!*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-ml,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-over-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus.x-btn-over .x-btn-grid-cell-small-mc {
    background-color: #5795cb;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3043 *!*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-tl,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-bl,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-tr,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-br,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-tc,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-bc,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-tl,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-bl,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-tr,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-br,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-tc,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-pressed-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3051 *!*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-ml,*/
/*.x-btn.x-btn-menu-active .x-btn-grid-cell-small-mr,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-ml,*/
/*.x-btn.x-btn-pressed .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-pressed-sides.gif);*/
/*}*/

.x-btn.x-btn-menu-active .x-btn-grid-cell-small-mc,
.x-btn.x-btn-pressed .x-btn-grid-cell-small-mc {
    background-color: #477aa6;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3069 *!*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-tl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-bl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-tr,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-br,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-tc,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-bc,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-tl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-bl,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-tr,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-br,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-tc,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-pressed-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3077 *!*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-ml,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-mr,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-ml,*/
/*.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-focus-pressed-sides.gif);*/
/*}*/

.x-keyboard-mode .x-btn-focus.x-btn-menu-active .x-btn-grid-cell-small-mc,
.x-keyboard-mode .x-btn-focus.x-btn-pressed .x-btn-grid-cell-small-mc {
    background-color: #477aa6;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3095 *!*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-tl,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-bl,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-tr,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-br,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-tc,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-bc {*/
/*    background-image: url(images/btn/btn-grid-cell-small-disabled-corners.gif);*/
/*}*/
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/button/Button.scss:3103 *!*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-ml,*/
/*.x-btn.x-btn-disabled .x-btn-grid-cell-small-mr {*/
/*    background-image: url(images/btn/btn-grid-cell-small-disabled-sides.gif);*/
/*}*/

.x-btn.x-btn-disabled .x-btn-grid-cell-small-mc {
    background-color: #5fa2dd;
}

.x-nbr .x-btn-grid-cell-small {
    background-image: none;
}

.x-btn-disabled.x-btn-grid-cell-small {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first .x-btn-grid-cell-small-mc {
    padding-right: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-middle {
    border-right-width: 1px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-middle .x-btn-grid-cell-small-mc {
    padding-right: 7px !important;
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-last .x-btn-grid-cell-small-mc {
    padding-left: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first .x-btn-grid-cell-small-mc {
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-middle {
    border-bottom-width: 1px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-middle .x-btn-grid-cell-small-mc {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-last .x-btn-grid-cell-small-mc {
    padding-top: 7px !important;
}

.x-nbr .x-segmented-button-item.x-btn-grid-cell-small:after {
    content: ' ';
    border-style: solid;
    border-width: 0;
    position: absolute;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small:after {
    top: 1px;
    right: 0;
    bottom: 1px;
    left: 0;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-first:after {
    left: 1px;
}

.x-nbr .x-segmented-button-item-horizontal.x-btn-grid-cell-small.x-segmented-button-last:after {
    right: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small:after {
    top: 0;
    right: 1px;
    bottom: 0;
    left: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-first:after {
    top: 1px;
}

.x-nbr .x-segmented-button-item-vertical.x-btn-grid-cell-small.x-segmented-button-last:after {
    bottom: 1px;
}

.x-button-grid-cell-small-cell > .x-grid-cell-inner {
    padding-top: 0px;
    padding-bottom: 0px;
}

.x-button-grid-cell-small-cell > .x-grid-cell-inner >.x-btn-grid-cell-small {
    vertical-align: top;
}

.x-grid-widgetcolumn-cell-inner {
    text-overflow: clip;
}

.x-grid-advanced-group-hd {
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #d0d0d0;
    padding: 8px 10px 9px;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
}

.x-grid-advanced-group-record {
    border-width: 0 0 0 1px;
    border-style: solid;
    border-color: #d0d0d0;
    padding: 8px 10px 9px;
}

.x-grid-advanced-group-hd-not-collapsible {
    cursor: default;
}

.x-grid-advanced-group-hd-collapsible .x-grid-advanced-group-title {
    padding: 0 0 0 24px;
}

.x-grid-advanced-group-hd-collapsible .x-grid-advanced-group-title:before {
    content: '\f146';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-rtl.x-grid-view .x-grid-advanced-group-hd-collapsible .x-grid-advanced-group-title {
    background-position: right center;
    padding: 0 24px 0 0;
}

.x-grid-advanced-group-title {
    position: relative;
    font: 400 13px/15px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-advanced-group-title:before {
    position: absolute;
    left: 0;
}

.x-grid-advanced-group-hd-collapsed .x-grid-advanced-group-title:before {
    content: '\f0fe';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-groups-icon {
    font: 16px/24px ExtJS;
}

.x-groups-icon:before {
    content: "\e90f";
}

.x-group-by-icon {
    font: 16px/24px ExtJS;
}

.x-group-by-icon:before {
    content: "\e607";
}

.x-add-group-icon {
    font: 16px/24px ExtJS;
}

.x-add-group-icon:before {
    content: "\e910";
}

.x-remove-group-icon {
    font: 16px/24px ExtJS;
}

.x-remove-group-icon:before {
    content: "\e911";
}

.x-grid-advanced-group-summary .x-grid-cell,
.x-grid-summary .x-grid-cell {
    border-style: solid;
    border-color: #e9e9e9;
    background-color: #fff;
    color: #404040;
    font: 300 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-docked-grid-summary {
    border-width: 1px;
    border-color: #e9e9e9;
    border-style: solid;
    background-color: #fff;
}

.x-docked-grid-summary .x-grid-item {
    border: 0 none;
}

.x-grid-summary .x-grid-cell {
    border-color: #e9e9e9;
    background-color: #fff !important;
    border-top: 1px solid #e9e9e9;
    font: 300 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-group-hd {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #d0d0d0;
    padding: 8px 10px;
    background: #fff;
    cursor: pointer;
}

.x-grid-group-hd-not-collapsible {
    cursor: default;
}

.x-grid-group-hd-collapsible .x-grid-group-title {
    padding: 0 0 0 24px;
}

.x-grid-group-hd-collapsible .x-grid-group-title:before {
    content: '\f146';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-rtl.x-grid-view .x-grid-group-hd-collapsible .x-grid-group-title {
    background-position: right center;
    padding: 0 24px 0 0;
}

.x-grid-group-title {
    color: #606060;
    font: 400 13px/15px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-group-hd-collapsed .x-grid-group-title:before {
    content: '\f0fe';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-group-by-icon {
    font: 16px/24px ExtJS;
}

.x-group-by-icon:before {
    content: "\e607";
}

.x-grid-rowbody {
    font: 300 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    padding: 5px 10px 5px 10px;
}

.x-docked-summary {
    border-width: 1px;
    border-color: #d0d0d0;
    border-style: solid;
    background: #fff!important;
}

.x-docked-summary .x-grid-table {
    border: 0 none;
}

.x-grid-row-summary .x-grid-cell,
.x-grid-row-summary .x-grid-rowwrap,
.x-grid-row-summary .x-grid-cell-rowbody {
    border-color: #e9e9e9;
    background-color: #fff !important;
    border-top: 1px solid #e9e9e9;
    font: 300 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-docked-summary .x-grid-item,
.x-docked-summary .x-grid-row-summary .x-grid-cell {
    border-bottom: 0 none;
    border-top: 0 none;
}

.x-docked-summary > :first-child {
    background-color: #fff;
}

.x-grid-row-summary .x-grid-cell-inner-row-expander {
    display: none;
}

.x-menu-default {
    border-style: solid;
    border-width: 1px;
    border-color: #d0d0d0;
}

.x-menu-body-default {
    background: #fff;
    padding: 0;
}

.x-menu-icon-separator-default {
    left: 32px;
    border-left: solid 1px #d0d0d0;
    background-color: #fff;
    width: 1px;
}

.x-rtl > .x-menu-icon-separator {
    left: auto;
    right: 32px;
}

.x-menu-item-default {
    border-width: 0;
    cursor: pointer;
}

.x-menu-item-default.x-menu-item-focus,
.x-menu-item-default.x-menu-item-active {
    background-image: none;
    background-color: #7fb5e4;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/menu/Menu.scss:862 *!*/
/*.x-nlg .x-menu-item-default.x-menu-item-focus,*/
/*.x-nlg .x-menu-item-default.x-menu-item-active {*/
/*    background: #7fb5e4 repeat-x left top;*/
/*    background-image: url(images/menu/menu-item-default-active-bg.gif);*/
/*}*/

.x-menu-item-default.x-menu-item-disabled {
    cursor: default;
}

.x-menu-item-default.x-menu-item-disabled a {
    cursor: default;
}

.x-menu-item-default.x-menu-item-separator {
    height: 1px;
    border-top: solid 1px #d0d0d0;
    background-color: #fff;
    margin: 2px 0;
    padding: 0;
}

.x-menu-item-default.x-menu-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default.x-menu-item-disabled .x-menu-item-icon-ui {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default.x-menu-item-disabled .x-menu-item-text-default {
    background-color: transparent;
}

.x-menu-item-default .x-form-item-label {
    font-size: 13px;
    color: #000;
}

.x-menu-item-text-default,
.x-menu-item-cmp-default {
    margin: 0 8px 0 8px;
}

.x-menu-item-text-default {
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 31px;
    padding-top: 1px;
    color: #000;
    cursor: pointer;
}

.x-menu-item-focus .x-menu-item-text-default,
.x-menu-item-active .x-menu-item-text-default {
    color: #fff;
}

.x-menu-item-text-default.x-menu-item-indent {
    margin-left: 41px;
}

.x-menu-item-text-default.x-menu-item-indent-no-separator {
    margin-left: 36px;
}

.x-menu-item-text-default.x-menu-item-indent-right-icon {
    margin-right: 40px;
}

.x-menu-item-text-default.x-menu-item-indent-right-arrow {
    margin-right: 29px;
}

.x-menu-item-disabled .x-menu-item-text-default {
    cursor: default;
}

.x-rtl.x-menu-item-text-default,
.x-rtl.x-menu-item-cmp-default {
    margin: 0 8px 0 8px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent {
    margin-right: 41px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-no-separator {
    margin-right: 36px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-right-icon {
    margin-left: 40px;
}

.x-rtl.x-menu-item-text-default.x-menu-item-indent-right-arrow {
    margin-left: 29px;
}

.x-menu-item-indent-default {
    margin-left: 41px;
}

.x-menu-item-indent-default.x-rtl {
    margin-left: 0;
    margin-right: 41px;
}

.x-menu-item-icon-default {
    width: 24px;
    height: 24px;
    top: 4px;
    left: 4px;
    line-height: 24px;
    font-size: 16px;
    color: #919191;
    background-position: center center;
}

.x-menu-item-focus .x-menu-item-icon-default,
.x-menu-item-active .x-menu-item-icon-default {
    color: #fff;
}

.x-ie8 .x-menu-item-focus .x-menu-item-icon-default,
.x-ie8 .x-menu-item-active .x-menu-item-icon-default {
    color: #fff !important;
}

.x-menu-item-icon-default.x-rtl {
    left: auto;
    right: 4px;
}

.x-menu-item-focus .x-menu-item-icon-default.x-rtl,
.x-menu-item-active .x-menu-item-icon-default.x-rtl {
    right: 4px;
}

.x-ie8 .x-menu-item-icon-default.x-menu-item-glyph {
    color: #919191;
}

.x-menu-item-icon-default.x-menu-item-icon-right {
    width: 24px;
    height: 24px;
    top: 4px;
    right: 4px;
    left: auto;
    background-position: center center;
}

.x-menu-item-icon-default.x-menu-item-icon-right.x-rtl {
    right: auto;
    left: 4px;
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-item-icon-right.x-rtl,
.x-menu-item-active .x-menu-item-icon-default.x-menu-item-icon-right.x-rtl {
    left: 4px;
}

.x-menu-item-icon-default.x-menu-item-checkbox {
    color: #919191;
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-item-checkbox,
.x-menu-item-active .x-menu-item-icon-default.x-menu-item-checkbox {
    color: #fff;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-item-checkbox {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-item-checkbox:before {
    content: '\f14a';
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-item-checkbox {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-item-checkbox:before {
    content: '\f0c8';
}

.x-menu-item-focus .x-menu-item-icon-default.x-menu-group-icon,
.x-menu-item-active .x-menu-item-icon-default.x-menu-group-icon {
    color: #fff;
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-group-icon {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default.x-menu-group-icon:before {
    content: '\f00c';
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-group-icon {
    background-image: none;
}

.x-menu-item-unchecked .x-menu-item-icon-default.x-menu-group-icon:before {
    content: '';
}

.x-menu-item-arrow-default {
    width: 16px;
    height: 16px;
    top: 8px;
    right: 0;
    margin: 0 4px 0;
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-menu-item-arrow-default:before {
    content: '\f0da';
}

.x-menu-item-focus .x-menu-item-arrow-default,
.x-menu-item-active .x-menu-item-arrow-default {
    color: #fff;
}

.x-menu-item-focus .x-menu-item-arrow-default,
.x-menu-item-active .x-menu-item-arrow-default {
    top: 8px;
    right: 0;
}

.x-menu-item-arrow-default.x-rtl {
    left: 0;
    right: auto;
    font: 16px/1 'Font Awesome 5 Free';
}

.x-menu-item-arrow-default.x-rtl:before {
    content: '\f0d9';
}

.x-menu-item-focus .x-menu-item-arrow-default.x-rtl,
.x-menu-item-active .x-menu-item-arrow-default.x-rtl {
    left: 0;
}

.x-menu-default-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-menu-default-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-menu-default-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 24px;
}

.x-box-scroller-menu-default {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-menu-default.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-menu-default.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-menu-default.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-menu-default.x-box-scroller-top,
.x-box-scroller-menu-default.x-box-scroller-bottom {
    height: 16px;
    width: 16px;
    left: 50%;
    margin-left: -8px;
}

.x-box-scroller-menu-default.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-menu-default.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-menu-default {
    background-color: #fff;
}

.x-menu-default-menubar {
    border-style: solid;
    border-width: 1px;
    border-color: #d0d0d0;
}

.x-menu-body-default-menubar {
    background: #fff;
    padding: 0;
}

.x-menu-icon-separator-default-menubar {
    left: 32px;
    border-left: solid 1px #d0d0d0;
    background-color: #fff;
    width: 1px;
}

.x-rtl > .x-menu-icon-separator {
    left: auto;
    right: 32px;
}

.x-menu-item-default-menubar {
    border-width: 0;
    cursor: pointer;
}

.x-menu-item-default-menubar.x-menu-item-focus,
.x-menu-item-default-menubar.x-menu-item-active {
    background-image: none;
    background-color: #7fb5e4;
}
/*!* /home/konstantinst/Work/ext7/MyApp/ext/classic/theme-neutral/sass/var/menu/Menu.scss:862 *!*/
/*.x-nlg .x-menu-item-default-menubar.x-menu-item-focus,*/
/*.x-nlg .x-menu-item-default-menubar.x-menu-item-active {*/
/*    background: #7fb5e4 repeat-x left top;*/
/*    background-image: url(images/menu/menu-item-default-menubar-active-bg.gif);*/
/*}*/

.x-menu-item-default-menubar.x-menu-item-disabled {
    cursor: default;
}

.x-menu-item-default-menubar.x-menu-item-disabled a {
    cursor: default;
}

.x-menu-item-default-menubar.x-menu-item-separator {
    height: 1px;
    border-top: solid 1px #d0d0d0;
    background-color: #fff;
    margin: 2px 0;
    padding: 0;
}

.x-menu-item-default-menubar.x-menu-item-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default-menubar.x-menu-item-disabled .x-menu-item-icon-ui {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-ie9m .x-menu-item-default-menubar.x-menu-item-disabled .x-menu-item-text-default-menubar {
    background-color: transparent;
}

.x-menu-item-default-menubar .x-form-item-label {
    font-size: 13px;
    color: #000;
}

.x-menu-item-text-default-menubar,
.x-menu-item-cmp-default-menubar {
    margin: 0 8px 0 8px;
}

.x-menu-item-text-default-menubar {
    font: 300 13px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    line-height: 31px;
    padding-top: 1px;
    color: #000;
    cursor: pointer;
}

.x-menu-item-focus .x-menu-item-text-default-menubar,
.x-menu-item-active .x-menu-item-text-default-menubar {
    color: #fff;
}

.x-menu-item-text-default-menubar.x-menu-item-indent {
    margin-left: 41px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-no-separator {
    margin-left: 36px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-right-icon {
    margin-right: 40px;
}

.x-menu-item-text-default-menubar.x-menu-item-indent-right-arrow {
    margin-right: 27px;
}

.x-menu-item-disabled .x-menu-item-text-default-menubar {
    cursor: default;
}

.x-rtl.x-menu-item-text-default-menubar,
.x-rtl.x-menu-item-cmp-default-menubar {
    margin: 0 8px 0 8px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent {
    margin-right: 41px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-no-separator {
    margin-right: 36px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-right-icon {
    margin-left: 40px;
}

.x-rtl.x-menu-item-text-default-menubar.x-menu-item-indent-right-arrow {
    margin-left: 27px;
}

.x-menu-item-indent-default-menubar {
    margin-left: 41px;
}

.x-menu-item-indent-default-menubar.x-rtl {
    margin-left: 0;
    margin-right: 41px;
}

.x-menu-item-icon-default-menubar {
    width: 24px;
    height: 24px;
    top: 4px;
    left: 4px;
    line-height: 24px;
    font-size: 16px;
    color: #919191;
    background-position: center center;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar,
.x-menu-item-active .x-menu-item-icon-default-menubar {
    color: #fff;
}

.x-ie8 .x-menu-item-focus .x-menu-item-icon-default-menubar,
.x-ie8 .x-menu-item-active .x-menu-item-icon-default-menubar {
    color: #fff !important;
}

.x-menu-item-icon-default-menubar.x-rtl {
    left: auto;
    right: 4px;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-rtl,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-rtl {
    right: 4px;
}

.x-ie8 .x-menu-item-icon-default-menubar.x-menu-item-glyph {
    color: #919191;
}

.x-menu-item-icon-default-menubar.x-menu-item-icon-right {
    width: 24px;
    height: 24px;
    top: 4px;
    right: 4px;
    left: auto;
    background-position: center center;
}

.x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl {
    right: auto;
    left: 4px;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-item-icon-right.x-rtl {
    left: 4px;
}

.x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    color: #919191;
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-item-checkbox,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    color: #fff;
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-item-checkbox:before {
    content: '\f14a';
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-item-checkbox {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-item-checkbox:before {
    content: '\f0c8';
}

.x-menu-item-focus .x-menu-item-icon-default-menubar.x-menu-group-icon,
.x-menu-item-active .x-menu-item-icon-default-menubar.x-menu-group-icon {
    color: #fff;
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-group-icon {
    font: 18px/24px 'Font Awesome 5 Free';
}

.x-menu-item-checked .x-menu-item-icon-default-menubar.x-menu-group-icon:before {
    content: '\f00c';
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-group-icon {
    background-image: none;
}

.x-menu-item-unchecked .x-menu-item-icon-default-menubar.x-menu-group-icon:before {
    content: '';
}

.x-menu-item-arrow-default-menubar {
    width: 9px;
    height: 6px;
    top: 9px;
    right: 5px;
    margin: 0 4px 0;
    font: 16px/1 'Font Awesome 5 Free';
    color: #919191;
}

.x-menu-item-arrow-default-menubar:before {
    content: '\f0d7';
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar,
.x-menu-item-active .x-menu-item-arrow-default-menubar {
    color: #fff;
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar,
.x-menu-item-active .x-menu-item-arrow-default-menubar {
    top: 9px;
    right: 5px;
}

.x-menu-item-arrow-default-menubar.x-rtl {
    left: 5px;
    right: auto;
    font: 16px/1 'Font Awesome 5 Free';
}

.x-menu-item-arrow-default-menubar.x-rtl:before {
    content: '\f0d7';
}

.x-menu-item-focus .x-menu-item-arrow-default-menubar.x-rtl,
.x-menu-item-active .x-menu-item-arrow-default-menubar.x-rtl {
    left: 5px;
}

.x-menu-default-menubar-scroller .x-box-scroller-body-horizontal {
    margin-left: 16px;
}

.x-menu-default-menubar-scroller.x-rtl .x-box-scroller-body-horizontal {
    margin-left: 0;
    margin-right: 16px;
}

.x-menu-default-menubar-vertical-scroller .x-box-scroller-body-vertical {
    margin-top: 24px;
}

.x-box-scroller-menu-default-menubar {
    cursor: pointer;
    color: #919191;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    opacity: 0.5;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-hover {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
    opacity: 0.6;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-pressed {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    opacity: 0.7;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-disabled {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    opacity: 0.25;
    cursor: default;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top,
.x-box-scroller-menu-default-menubar.x-box-scroller-bottom {
    height: 16px;
    width: 16px;
    left: 50%;
    margin-left: -8px;
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-top:before {
    content: '\f077';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-bottom {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 4px;
    font: 16px/16px 'Font Awesome 5 Free';
}

.x-box-scroller-menu-default-menubar.x-box-scroller-bottom:before {
    content: '\f078';
}

.x-ie8 .x-box-scroller-menu-default-menubar {
    background-color: #fff;
}

.x-grid-filters-filtered-column {
    font-style: italic;
    font-weight: 600;
    text-decoration: underline;
}

.x-grid-filters-icon {
    background-repeat: no-repeat;
    background-position: center center;
    color: #919191;
    text-align: center;
}

.x-grid-filters-find .x-form-item-label-inner {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-grid-filters-find .x-form-item-label-inner:before {
    content: '\f002';
}

.x-grid-filters-gt .x-form-item-label-inner {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-grid-filters-gt .x-form-item-label-inner:before {
    content: '\f054';
}

.x-grid-filters-lt .x-form-item-label-inner {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-grid-filters-lt .x-form-item-label-inner:before {
    content: '\f053';
}

.x-grid-filters-eq .x-form-item-label-inner {
    font: 16px/1 ExtJS;
}

.x-grid-filters-eq .x-form-item-label-inner:before {
    content: "\e605";
}

.x-grid-locked .x-grid-inner-locked {
    border-width: 0 2px 0 0;
    border-style: solid;
}

.x-grid-locked .x-rtl.x-grid-inner-locked {
    border-width: 0 0 0 2px;
}

.x-grid-locked-split .x-grid-inner-normal {
    border-width: 0 0 0 2px;
    border-style: solid;
    border-left-color: #d0d0d0;
}

.x-grid-locked-split .x-rtl.x-grid-inner-normal {
    border-width: 0 2px 0 0;
    border-right-color: #d0d0d0;
}

.x-grid-locking-body {
    border-width: 1px;
}

.x-grid-locking-body > .x-splitter {
    background-color: #ececec;
}

.x-grid-locking-body > .x-splitter-active {
    background-color: #b4b4b4;
}

.x-grid-inner-locked {
    border-right-color: #d0d0d0;
}

.x-grid-inner-locked .x-column-header-last,
.x-grid-inner-locked .x-grid-cell-last {
    border-right-width: 0!important;
}

.x-grid-inner-locked .x-rtl.x-column-header-last {
    border-left-width: 0!important;
}

.x-rtl.x-grid-inner-locked {
    border-right-color: #5fa2dd;
    border-left-color: #d0d0d0;
}

.x-rtl.x-grid-inner-locked .x-grid-row .x-column-header-last {
    border-left: 0 none;
}

.x-rtl.x-grid-inner-locked .x-grid-row .x-grid-cell-last {
    border-left: 0 none;
}

.x-hmenu-lock {
    font: 16px/24px ExtJS;
}

.x-hmenu-lock:before {
    content: "\e914";
}

.x-hmenu-unlock {
    font: 16px/24px ExtJS;
}

.x-hmenu-unlock:before {
    content: "\e913";
}

.x-grid-scrollbar-clipper > .x-grid-view,
.x-grid-scrollbar-clipper > .x-tree-view {
    background-color: #fff;
}

.x-grid-scrollbar-clipper-locked,
.x-grid-scrollbar-locked {
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-grid-locked .x-rtl.x-grid-scrollbar-clipper-locked,
.x-grid-locked .x-rtl.x-grid-scrollbar-locked {
    border-width: 0 0 0 2px;
}

.x-grid-scroll-container {
    background-color: #fff;
}

.x-rtl > .x-grid-scroll-container {
    direction: rtl;
}

.x-rtl > .x-grid-scroll-container > .x-grid-scroll-body {
    direction: rtl;
}

.x-grid-editor .x-form-display-field {
    text-overflow: ellipsis;
}

.x-grid-editor .x-form-action-col-field {
    padding: 7px 4px 7px 4px;
}

.x-grid-editor .x-form-text {
    padding-left: 9px;
    padding-right: 9px;
}

.x-tree-cell-editor .x-form-text {
    padding-left: 5px;
    padding-right: 5px;
}

.x-grid-group-column {
    background-image: none;
    background-color: #fff;
    cursor: pointer;
    margin: 2px;
}

.x-grid-group-column .x-grid-group-column-btn {
    width: 16px;
    height: 16px;
    float: left;
}

.x-grid-group-column .x-column-header-sort-ASC,
.x-grid-group-column .x-column-header-sort-DESC {
    background-color: transparent !important;
}

.x-grid-group-column.x-focus .x-grid-group-column-inner {
    border: 1px solid #7fb5e4;
}

.x-grid-group-column-over {
    background-image: none;
    background-color: #eaeff4;
}

.x-grid-group-column-inner {
    padding: 3px;
    border: 1px solid #d0d0d0;
    margin: 1px;
    font: 400 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-group-column-btn-image {
    padding: 0 5px 0 0;
    background-position: right center;
    background-repeat: no-repeat;
}

.x-grid-group-column-btn-customize:before {
    content: '\f013';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-group-column-btn-sort-asc:before {
    content: '\f30c';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-group-column-sort-icon-asc:before {
    content: '\f15d';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-group-column-btn-sort-desc:before {
    content: '\f309';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-group-column-sort-icon-desc:before {
    content: '\f15e';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-group-panel-header {
    padding: 2px;
}

.x-grid-group-panel-header .x-panel-header-title {
    font: 400 13px/19px 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
}

.x-grid-group-panel-header.x-header-noborder {
    padding: 2px;
}

.x-grid-group-panel-body {
    background-color: white;
    border: 0 solid #e9e9e9;
}

.x-grid-group-panel-hint {
    color: darkgray;
    padding: 5px;
}

.x-grid-group-panel-icon:before {
    content: "\e607";
    font-size: 14px;
    font-family: ExtJS;
    color: #919191;
}

.x-grid-group-panel-clear-icon:before {
    content: '\f00d';
    font-size: 14px;
    font-family: 'Font Awesome 5 Free';
    color: #919191;
}

.x-grid-row-editor .x-field {
    margin: 0 3px 0 2px;
}

.x-grid-row-editor .x-form-display-field {
    padding: 7px 8px 6px 8px;
    line-height: 19px;
}

.x-ie9m .x-grid-row-editor .x-form-display-field {
    min-height: 19px;
}

.x-grid-row-editor .x-form-action-col-field {
    padding: 7px 1px 7px 2px;
}

.x-grid-row-editor .x-form-text {
    padding: 5px 6px 4px 7px;
}

.x-gecko .x-grid-row-editor .x-form-text {
    padding-left: 6px;
    padding-right: 5px;
}

.x-grid-row-editor .x-panel-body {
    border-top: 1px solid #d0d0d0 !important;
    border-bottom: 1px solid #d0d0d0 !important;
    padding: 5px 0 5px 0;
    background-color: #e5edf4;
}

.x-grid-with-col-lines .x-grid-row-editor .x-form-cb {
    margin-right: 1px;
}

.x-grid-with-col-lines .x-grid-row-editor .x-rtl.x-form-cb {
    margin-right: 0;
    margin-left: 1px;
}

.x-grid-row-editor-buttons-default-bottom {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
    -moz-border-radius-bottomright: 5px;
    -webkit-border-bottom-right-radius: 5px;
    border-bottom-right-radius: 5px;
    -moz-border-radius-bottomleft: 5px;
    -webkit-border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 5px 5px 5px;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    background-color: #e5edf4;
}

.x-grid-row-editor-buttons-default-bottom-mc {
    background-color: #e5edf4;
}

.x-nbr .x-grid-row-editor-buttons-default-bottom {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-grid-row-editor-buttons-default-bottom-frameInfo {
    font-family: th-0-5-5-5-0-1-1-1-5-5-5-5;
}

.x-grid-row-editor-buttons-default-bottom-tl {
    background-position: 0 -10px;
}

.x-grid-row-editor-buttons-default-bottom-tr {
    background-position: right -15px;
}

.x-grid-row-editor-buttons-default-bottom-bl {
    background-position: 0 -20px;
}

.x-grid-row-editor-buttons-default-bottom-br {
    background-position: right -25px;
}

.x-grid-row-editor-buttons-default-bottom-ml {
    background-position: 0 top;
}

.x-grid-row-editor-buttons-default-bottom-mr {
    background-position: right top;
}

.x-grid-row-editor-buttons-default-bottom-tc {
    background-position: 0 0;
}

.x-grid-row-editor-buttons-default-bottom-bc {
    background-position: 0 -5px;
}

.x-grid-row-editor-buttons-default-bottom-tr,
.x-grid-row-editor-buttons-default-bottom-br,
.x-grid-row-editor-buttons-default-bottom-mr {
    padding-right: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tl,
.x-grid-row-editor-buttons-default-bottom-bl,
.x-grid-row-editor-buttons-default-bottom-ml {
    padding-left: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tc {
    height: 0;
}

.x-grid-row-editor-buttons-default-bottom-bc {
    height: 5px;
}

.x-grid-row-editor-buttons-default-bottom-tl,
.x-grid-row-editor-buttons-default-bottom-bl,
.x-grid-row-editor-buttons-default-bottom-tr,
.x-grid-row-editor-buttons-default-bottom-br,
.x-grid-row-editor-buttons-default-bottom-tc,
.x-grid-row-editor-buttons-default-bottom-bc,
.x-grid-row-editor-buttons-default-bottom-ml,
.x-grid-row-editor-buttons-default-bottom-mr {
    background-image: url(images/grid-row-editor-buttons/grid-row-editor-buttons-default-bottom-corners.gif);
}

.x-grid-row-editor-buttons-default-bottom-ml,
.x-grid-row-editor-buttons-default-bottom-mr {
    background-image: url(images/grid-row-editor-buttons/grid-row-editor-buttons-default-bottom-sides.gif);
    background-repeat: repeat-y;
}

.x-grid-row-editor-buttons-default-bottom-mc {
    padding: 5px 1px 1px 1px;
}

.x-grid-row-editor-buttons-default-top {
    -moz-border-radius-topleft: 5px;
    -webkit-border-top-left-radius: 5px;
    border-top-left-radius: 5px;
    -moz-border-radius-topright: 5px;
    -webkit-border-top-right-radius: 5px;
    border-top-right-radius: 5px;
    -moz-border-radius-bottomright: 0;
    -webkit-border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -webkit-border-bottom-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 5px 5px 5px 5px;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    background-color: #e5edf4;
}

.x-grid-row-editor-buttons-default-top-mc {
    background-color: #e5edf4;
}

.x-nbr .x-grid-row-editor-buttons-default-top {
    padding: 0 !important;
    border-width: 0 !important;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none !important;
}

.x-grid-row-editor-buttons-default-top-frameInfo {
    font-family: th-5-5-0-5-1-1-0-1-5-5-5-5;
}

.x-grid-row-editor-buttons-default-top-tl {
    background-position: 0 -10px;
}

.x-grid-row-editor-buttons-default-top-tr {
    background-position: right -15px;
}

.x-grid-row-editor-buttons-default-top-bl {
    background-position: 0 -20px;
}

.x-grid-row-editor-buttons-default-top-br {
    background-position: right -25px;
}

.x-grid-row-editor-buttons-default-top-ml {
    background-position: 0 top;
}

.x-grid-row-editor-buttons-default-top-mr {
    background-position: right top;
}

.x-grid-row-editor-buttons-default-top-tc {
    background-position: 0 0;
}

.x-grid-row-editor-buttons-default-top-bc {
    background-position: 0 -5px;
}

.x-grid-row-editor-buttons-default-top-tr,
.x-grid-row-editor-buttons-default-top-br,
.x-grid-row-editor-buttons-default-top-mr {
    padding-right: 5px;
}

.x-grid-row-editor-buttons-default-top-tl,
.x-grid-row-editor-buttons-default-top-bl,
.x-grid-row-editor-buttons-default-top-ml {
    padding-left: 5px;
}

.x-grid-row-editor-buttons-default-top-tc {
    height: 5px;
}

.x-grid-row-editor-buttons-default-top-bc {
    height: 0;
}

.x-grid-row-editor-buttons-default-top-tl,
.x-grid-row-editor-buttons-default-top-bl,
.x-grid-row-editor-buttons-default-top-tr,
.x-grid-row-editor-buttons-default-top-br,
.x-grid-row-editor-buttons-default-top-tc,
.x-grid-row-editor-buttons-default-top-bc,
.x-grid-row-editor-buttons-default-top-ml,
.x-grid-row-editor-buttons-default-top-mr {
    background-image: url(images/grid-row-editor-buttons/grid-row-editor-buttons-default-top-corners.gif);
}

.x-grid-row-editor-buttons-default-top-ml,
.x-grid-row-editor-buttons-default-top-mr {
    background-image: url(images/grid-row-editor-buttons/grid-row-editor-buttons-default-top-sides.gif);
    background-repeat: repeat-y;
}

.x-grid-row-editor-buttons-default-top-mc {
    padding: 1px 1px 5px 1px;
}

.x-grid-row-editor-buttons {
    border-color: #d0d0d0;
}

.x-row-editor-update-button {
    margin-right: 3px;
}

.x-row-editor-cancel-button {
    margin-left: 2px;
}

.x-rtl.x-row-editor-update-button {
    margin-left: 3px;
    margin-right: auto;
}

.x-rtl.x-row-editor-cancel-button {
    margin-right: 2px;
    margin-left: auto;
}

.x-grid-row-editor-errors .x-tip-body {
    padding: 5px;
}

.x-grid-row-editor-errors-item {
    list-style: disc;
    margin-left: 15px;
}

.x-rtl.x-grid-row-editor-errors .x-grid-row-editor-errors-item {
    margin-left: 0;
    margin-right: 15px;
}

.x-grid-cell-inner-row-expander {
    padding: 8px 8px 8px 8px;
}

.x-grid-row-expander {
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: #919191;
    font: 14px/1 'Font Awesome 5 Free';
}

.x-grid-row-expander:before {
    content: '\f146';
}

.x-grid-row-collapsed .x-grid-row-expander {
    font: 14px/1 'Font Awesome 5 Free';
}

.x-grid-row-collapsed .x-grid-row-expander:before {
    content: '\f0fe';
}

.x-grid-row-non-expander {
    width: 16px;
    height: 16px;
}

.x-summaries-icon {
    font: 16px/24px ExtJS;
}

.x-summaries-icon:before {
    content: "\e912";
}

.x-operator-button {
    width: 16px !important;
    vertical-align: middle;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;
}

.x-operator-like:before {
    content: "\e900";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-nlike:before {
    content: "\e901";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-eq:before {
    content: "\e902";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-neq:before {
    content: "\e903";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-gt:before {
    content: "\e90b";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-gte:before {
    content: "\e90c";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-lt:before {
    content: "\e90d";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-lte:before {
    content: "\e90e";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-in:before {
    content: "\e906";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-nin:before {
    content: "\e907";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-identical:before {
    content: "\e908";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-nidentical:before {
    content: "\e909";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-empty:before {
    content: "\e904";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-exists:before {
    content: "\e905";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-operator-fn:before {
    content: "\e90a";
    font: 12px/1 ExtJS;
    color: #919191;
}

.x-grid-filter-base .x-form-trigger-wrap-default {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-grid-filter-none {
    background-color: #f6f6f6;
    min-height: 32px;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #d0d0d0;
}

.x-grid-filterbar .x-form-text {
    padding: 3px;
    font-size: 11px;
}

.x-grid-filterbar-filtered-column {
    font-style: italic;
    font-weight: inherit;
    text-decoration: inherit;
}

.x-ssm-row-numberer-hd {
    cursor: se-resize!important;
}

.x-ssm-row-numberer-cell {
    cursor: e-resize;
}

.x-ssm-column-select .x-column-header {
    cursor: s-resize;
}

.x-ssm-extender-drag-handle {
    height: 7px;
    width: 7px;
    background-color: #5fa2dd;
}

.x-ssm-extender-mask {
    border: 1px dotted #5fa2dd;
}

.x-accordion-layout-ct {
    background-color: #fff;
    padding: 5px 5px 0;
}

.x-accordion-hd .x-panel-header-title {
    color: #404040;
    font-weight: 300;
    font-family: 'Roboto', 'Helvetica Neue', helvetica, arial, verdana, sans-serif;
    text-transform: none;
}

.x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus,
.x-edge .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after,
.x-edge .x-keyboard-mode .x-accordion-hd .x-panel-header-title.x-title-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

.x-accordion-item {
    margin: 0 0 5px;
}

.x-accordion-item .x-accordion-hd {
    background: #ececec;
    border-width: 0;
    border-color: #5fa2dd;
    padding: 12px 10px;
}

.x-accordion-item .x-accordion-hd-over {
    background-color: #f6f6f6;
}

.x-accordion-item .x-accordion-hd-over .x-tool-tool-el {
    background-color: #f6f6f6;
}

.x-accordion-item .x-accordion-hd-sibling-expanded {
    border-top-color: #5fa2dd;
    border-top-width: 0;
}

.x-accordion-item .x-accordion-hd-last-collapsed {
    border-bottom-color: #ececec;
}

.x-accordion-item .x-accordion-body {
    border-width: 0;
}

.x-accordion-hd .x-tool-tool-el {
    background-color: #ececec;
    color: #5fa2dd;
}

.x-accordion-hd .x-tool-img {
    background-image: url(images/tools/tool-sprites-dark.png);
    background-image: none;
}

.x-accordion-hd .x-tool-collapse-top,
.x-accordion-hd .x-tool-collapse-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background-image: none;
}

.x-accordion-hd .x-tool-collapse-top:before,
.x-accordion-hd .x-tool-collapse-bottom:before {
    content: '\f068';
}

.x-accordion-hd .x-tool-expand-top,
.x-accordion-hd .x-tool-expand-bottom {
    font: 16px/1 'Font Awesome 5 Free';
    background-image: none;
}

.x-accordion-hd .x-tool-expand-top:before,
.x-accordion-hd .x-tool-expand-bottom:before {
    content: '\f067';
}

.x-keyboard-mode .x-accordion-hd .x-tool-focus {
    outline: 1px solid #5fa2dd;
    outline-offset: 2px;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-tool-focus,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-tool-focus,
.x-edge .x-keyboard-mode .x-accordion-hd .x-tool-focus {
    outline: none;
}

.x-ie .x-keyboard-mode .x-accordion-hd .x-tool-focus:after,
.x-ie10p .x-keyboard-mode .x-accordion-hd .x-tool-focus:after,
.x-edge .x-keyboard-mode .x-accordion-hd .x-tool-focus:after {
    position: absolute;
    content: ' ';
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    border: 1px solid #5fa2dd;
    pointer-events: none;
}

body.x-border-layout-ct,
div.x-border-layout-ct {
    background-color: #ececec;
}

.x-form-layout-wrap {
    border-spacing: 10px;
}

.x-resizable-handle {
    position: absolute;
    z-index: 100;
    font-size: 1px;
    line-height: 10px;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
    background-color: #ececec;
    color: #919191;
    text-align: center;
}

.x-collapsed .x-resizable-handle {
    display: none;
}

.x-resizable-handle-southeast {
    cursor: se-resize;
}

.x-resizable-handle-southeast:before {
    position: absolute;
    bottom: 0;
    right: 0;
}

.x-resizable-handle-northwest {
    cursor: nw-resize;
}

.x-resizable-handle-northwest:before {
    position: absolute;
    top: 0;
    left: 0;
}

.x-resizable-handle-northeast {
    cursor: ne-resize;
}

.x-resizable-handle-northeast:before {
    position: absolute;
    top: 0;
    right: 0;
}

.x-resizable-handle-southwest {
    cursor: sw-resize;
}

.x-resizable-handle-southwest:before {
    position: absolute;
    bottom: 0;
    left: 0;
}

.x-resizable-handle-east:before,
.x-resizable-handle-west:before {
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    width: 100%;
}

.x-resizable-handle-east {
    cursor: e-resize;
    width: 10px;
    right: 0;
    top: 0;
    bottom: 0;
}

.x-resizable-handle-south {
    cursor: s-resize;
    height: 10px;
    left: 0;
    right: 0;
    bottom: 0;
}

.x-resizable-handle-west {
    cursor: w-resize;
    width: 10px;
    left: 0;
    top: 0;
    bottom: 0;
}

.x-resizable-handle-north {
    cursor: n-resize;
    height: 10px;
    left: 0;
    right: 0;
    top: 0;
}

.x-resizable-handle-southeast {
    width: 10px;
    height: 10px;
    right: 0;
    bottom: 0;
    z-index: 101;
}

.x-resizable-handle-northwest {
    width: 10px;
    height: 10px;
    left: 0;
    top: 0;
    z-index: 101;
}

.x-resizable-handle-northeast {
    width: 10px;
    height: 10px;
    right: 0;
    top: 0;
    z-index: 101;
}

.x-resizable-handle-southwest {
    width: 10px;
    height: 10px;
    left: 0;
    bottom: 0;
    z-index: 101;
}

.x-tablet .x-resizable-handle-north,
.x-tablet .x-resizable-handle-south {
    height: 20px;
}

.x-tablet .x-resizable-handle-east,
.x-tablet .x-resizable-handle-west {
    width: 20px;
}

.x-tablet .x-resizable-handle-northwest,
.x-tablet .x-resizable-handle-northeast,
.x-tablet .x-resizable-handle-southwest,
.x-tablet .x-resizable-handle-southeast {
    width: 20px;
    height: 20px;
}

.x-window .x-window-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    opacity: 0;
}

.x-window-collapsed .x-window-handle {
    display: none;
}

.x-resizable-proxy {
    border: 1px dashed #3b5a82;
    position: absolute;
    overflow: hidden;
    z-index: 50000;
}

.x-resizable-handle-over,
.x-resizable-pinned .x-resizable-handle {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    opacity: 1;
}

.x-resizable-handle-east-over,
.x-resizable-handle-west-over,
.x-resizable-pinned > .x-resizable-handle-east,
.x-resizable-pinned > .x-resizable-handle-west {
    font: 16px/1 'Font Awesome 5 Free';
}

.x-resizable-handle-east-over:before,
.x-resizable-handle-west-over:before,
.x-resizable-pinned > .x-resizable-handle-east:before,
.x-resizable-pinned > .x-resizable-handle-west:before {
    content: '\f142';
}