:root {
	--color-dark: #1d252c;
	--color-grey-50: #8e9295;
	--color-grey-40: #a5a8ab;
	--color-grey-30: #bbbdbf;
	--color-grey-20: #d2d3d5;
	--color-grey-10: #e8e9e9;
	--color-grey-5: #f3f4f4;
	--color-grey-3: #f8f8f8;
	--color-white: #fff;
	--color-white-opacity-50: rgba(255, 255, 255, 0.5);
	--color-white-opacity-15: rgba(255, 255, 255, 0.15);
	--color-white-opacity-10: rgba(255, 255, 255, 0.1);
	--color-dark-opacity-20: rgba(29, 37, 44, 0.2);
	--color-dark-opacity-10: rgba(29, 37, 44, 0.1);
	--color-dark-opacity-5: rgba(29, 37, 44, 0.05);
	--color-success: #00ac2d;
	--color-success-opacity-10: rgba(0, 172, 45, 0.1);
	--color-error: #e82000;
	--color-error-opacity-10: rgba(232, 32, 0, 0.1);
	--color-error-opacity-5: rgba(232, 32, 0, 0.05);
	--color-yellow: #ff9400;
	--color-yellow-opacity-10: rgba(255, 148, 0, 0.1);
	--color-background: #f2f4f8;
	// Project palette
	// Coreplat, Factorplat, Ediweb, LinkServer
	--color-blue: #3d7dca;
	--color-blue-90: #508acf;
	--color-blue-80: #6497d5;
	--color-blue-50: #96b6de;
	--color-blue-15: #e2ecf7;
	--color-blue-10: #ecf2fa;
	--color-blue-5: #f5f9fc;
	--color-blue-opacity-15: rgba(61, 125, 202, 0.15);
	--color-blue-opacity-10: rgba(61, 125, 202, 0.1);
	--color-blue-opacity-5: rgba(61, 125, 202, 0.05);
	// Dropcat, Prodcat
	--color-dc-dark: #6a6f74;
	--color-dc-modile: #458edd;
	--color-teal: #00a09b;
	--color-teal-90: #1aa9a5;
	--color-teal-80: #33b3af;
	--color-teal-15: #d9f1f0;
}
