@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

.ui-grid-top-bar {
	padding: 0 2px;
	border: none;
}

.ui-grid-top-bar-other-actions-menu {
	.x-menu-item-separator {
		display: block;
		height: 24px;
		margin: 8px 0;
		padding: 8px 33px 0 16px;
		border-top: 1px solid $color-grey-10;
		@extend .caption_01;
		color: $color-grey-50;
		cursor: default;
	}
}
