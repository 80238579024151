@use '@UIkit/components/fields/Base/Base.scss' as box;
@import "@UIkit/styles/global/Colors/Variables";

.ui-checkboxfield {
	@extend %box;

	@include box.input {
		&::before {
			color: $color-grey-50;
			content: '\e835';
			font-family: 'Material Icons', sans-serif;
			font-size: 24px;
		}
	}
	@include box.checked {
		&::before {
			content: '\e834';
			color: $color-blue;
		}
	}

	&:hover {
		@include box.input {
			&::before {
				color: $color-dark;
			}
		}
		@include box.checked {
			&::before {
				color: $color-blue-80;
			}
		}
	}
}
