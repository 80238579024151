@use '@UIkit/styles/typography/_heading.scss';
@import '@UIkit/styles/global/Colors/Variables.scss';

.ui-sidebar {
	background: $color-white;
	box-shadow: 0 4px 24px $color-dark-opacity-20;

	&-header {
		padding: 8px 8px 8px 24px;
		width: auto !important;
		position: relative !important;

		&-title {
			@extend .heading_02;
		}

		.ui-tool,
		.x-tool {
			display: inline-block;
			width: 40px;
			height: 40px;
			margin-left: 8px;
			border-radius: 100%;
			padding: 0;
			overflow: hidden;

			&-ui-tool-el,
			&-tool-el {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				width: 40px;
				height: 40px;
				font-family: 'Material Icons', sans-serif;
				font-size: 20px;
				line-height: 20px;
				transition: all 200ms ease-out 0s;
				color: $color-grey-50;

				&::before {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					font-family: 'Material Icons', sans-serif;
					font-size: 20px;
					line-height: 20px;
					transition: all 200ms ease-out 0s;
					border-radius: 100%;
					color: $color-grey-50;
				}

				&:hover {
					background: $color-grey-5;
					color: $color-dark;

					&::before {
						background: $color-grey-5;
						color: $color-dark;
					}
				}

				&:active {
					background: $color-grey-10;
					color: $color-dark;

					&::before {
						background: $color-grey-10;
						color: $color-dark;
					}
				}
			}

			&-close {
				&::before {
					content: '\e5cd';
				}
			}
		}
	}

	.x-toolbar-footer.x-toolbar {
		margin: 0;
		padding: 16px 24px 16px 24px;

		.x-toolbar-item {
			margin-right: 16px;
		}
	}

	&-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $color-dark-opacity-20;
	}

	&-floating-close-button {
		position: absolute;
		width: 40px !important;
		height: 40px !important;
		top: 4px !important;
		right: 4px !important;
		left: unset !important;
	}
}
