@use '@UIkit/styles/typography/_text.scss';
@import "@UIkit/styles/global/Colors/Variables.scss";

$this: '.ui-pagingbar';

#{$this} {
	display: inline-flex;
	align-items: center;
	padding: 8px 8px 8px 16px;
	box-shadow: 0px -8px 8px rgb(29 37 44 / 4%);

	#{$this}-total-text {
		margin-right: 16px;
	}

	#{$this}-total-text,
	#{$this}-total-text label {
		@extend .body-short_01;
		color: $color-dark;
	}

	#{$this}-documents-per-page {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: auto !important;
		height: 40px !important;
		padding: 0 8px !important;

		&-active {
			background: $color-grey-10;
			border-radius: 4px;
		}

		&:hover {
			border-radius: 4px;
			background-color: $color-grey-10;
		}

		.x-form-trigger-wrap {
			border: 0 !important;

			.x-form-field {
				display: none;
			}
		}

		label.x-form-item-label {
			position: relative;
			top: 11px;
			height: 40px;
			cursor: pointer !important;
			padding: 0 !important;
			margin: 0 !important;

			.x-form-item-label-inner {
				padding: 0;
				margin: 0;
				background: none;

				.x-form-item-label-text {
					@extend .body-long_01;
				}
			}
		}
	}

	#{$this}-download-button {
		margin-right: 8px;
	}

	#{$this}-refresh-button {
		margin-right: 8px;
	}

	#{$this}-request-time {
		@extend .body-short_01;
		color: $color-grey-50;
		margin-right: 8px;
	}
}

#{$this}-per-page-panel {
	display: flex;
	flex-direction: row;
	width: auto !important;

	.x-boundlist-list-ct {
		padding: 16px;

		.x-list-plain {
			display: flex;
			flex-direction: row;

			#{$this}-per-page-title {
				@extend .body-short_01;
				color: $color-dark;
				margin-bottom: 8px;
			}

			#{$this}-per-page-items-wrapper {
				display: flex;
				flex-direction: row;

				#{$this}-per-page-item {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					border-radius: 4px !important;
					width: 40px;
					height: 40px;
					margin: 0 8px 0 0;
					@extend .body-short_01;

					&:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}
