@mixin typography__heading_01() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.15px;
}

@mixin typography__heading_02() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: 0.15px;
}

@mixin typography__heading_03() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.15px;
}

@mixin typography__heading_04() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 34px;
	line-height: 40px;
	letter-spacing: 0.25px;
}

@mixin typography__body-short_01() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.16px;
}

@mixin typography__body-short_02() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.16px;
}

@mixin typography__body-short_03() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 22px;
	letter-spacing: 0.15px;
}

@mixin typography__body-long_01() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.16px;
}

@mixin typography__body-long_02() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.5px;
}

@mixin typography__caption_01() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 0.32px;
}

@mixin typography__caption_02() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.32px;
}

@mixin typography__overline() {
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 10px;
	letter-spacing: 1.5px;
	text-transform: uppercase;
}
