@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

.ui-notification {
	$this: &;

	--color: $color-teal;

	position: relative;
	padding: 16px 8px 16px 20px;
	box-shadow: 0 4px 24px rgba(29, 37, 44, 0.12);
	border-radius: 4px;
	background: $color-white;

	&::before {
		position: absolute;
		top: 16px;
		left: 8px;
		bottom: 16px;
		content: '';
		display: block;
		width: 4px;
		border-radius: 2px;
		background: var(--color);
	}

	&-glyph {
		position: absolute;
		top: 16px;
		left: 24px;
		bottom: 16px;
		max-width: 64px;
		font-size: 24px;
		color: var(--color);
		cursor: default;

		display: inline-flex;
		justify-content: center;
		align-items: center;
	}
	&-header {
		// padding-left: 64px;

		&-date {
			display: block;
			@extend .caption_01;
			color: $color-grey-50;
			margin: 0 0 8px 0;
		}
		&-title {
			display: block;
			@extend .body-short_03;
			color: $color-dark;
			margin: 0 0 8px 0;
		}
		&-close {
			position: absolute;
			top: 13px;
			right: 13px;

			font-family: 'Material Icons', sans-serif;
			font-size: 20px;
			cursor: pointer;
			color: $color-grey-50;

			&::before {
				content: '\e14c';
			}
		}
	}
	&-body {
		@extend .body-short_01;
		color: $color-dark;
	}
	&-footer {
		margin: 16px 0 0 0;
		// padding-left: 64px;
		display: flex;
		align-items: center;
		column-gap: 16px;
	}

	transition: all 200ms ease-out 0s;

	&-readed {
		--color: $color-grey-30 !important;
		background: $color-grey-3;
	}
	&-destroy {
		transform: scale(0);
	}
}
