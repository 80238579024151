@import '@UIkit/styles/global/Colors/Variables';

.ui-daterange {
	overflow: visible;

	.x-box {
		&-inner {
			overflow: visible;
		}
	}

	&-delimiter {
		line-height: 32px;
		top: 8px !important;
		text-align: center;
		color: $color-grey-50;
	}
}

.edi-form-field-date-range-delimiter {
	line-height: 32px;
	top: 8px !important;
	text-align: center;
	color: $color-grey-50;
}
