@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-display: auto;
	src: url('Regular/roboto-regular.woff2') format('woff2'), url('Regular/roboto-regular.woff') format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-display: auto;
	src: url('Medium/roboto-medium.woff2') format('woff2'), url('Medium/roboto-medium.woff') format('woff');
}
