@mixin invalid {
	$icon-size: 20px;

	& .x-form {
		&-invalid {
			&-icon {
				background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjRTgyMDAwIiBkPSJNMTIgMkM2LjQ4IDIgMiA2LjQ4IDIgMTJzNC40OCAxMCAxMCAxMCAxMC00LjQ4IDEwLTEwUzE3LjUyIDIgMTIgMnptMCAxMWMtLjU1IDAtMS0uNDUtMS0xVjhjMC0uNTUuNDUtMSAxLTFzMSAuNDUgMSAxdjRjMCAuNTUtLjQ1IDEtMSAxem0xIDRoLTJ2LTJoMnYyeiIvPjwvc3ZnPg==);
				background-size: $icon-size auto;
				width: $icon-size;
				height: $icon-size;
				margin: 0px;
				position: absolute;
				top: 25px;
				right: 0px;
				transform: translate(0px, -50%);
			}
			&-under {
				line-height: 16px;
				letter-spacing: .4px;
			}
		}
	}
}