@import "@UIkit/styles/typography/index";
@import "@UIkit/styles/global/Colors/Variables.scss";

BODY.edi-viewport {
    background-color: #ffffff;

    // &.edi-viewport-with-background.edi-login-form-displayed .edi-login-form-window {
    //     border-radius: 10px;
    // }

    // .edi-login-form-window .x-window-body {
    //     border-radius: 10px;
    // }

    .edi-login-form {
        background-color: #ffffff;
        background-image: none!important;
        border-radius: 8px;
        padding: 40px 24px 24px 24px;
    }

    // .edi-login-form .edi-login-form__field .x-form-text {
    //     border-width: 1px;
    //     border-style: solid;
    //     border-color: rgba(0, 0, 0, 0.5);
    //     border-radius: 3px;
    //     box-shadow: none !important;
    //     padding: 12px 10px;
    //     height: unset;
    //     transition: all 0.2s ease-out 0s;
    //     background-color: green;
    // }

    // .edi-login-form .edi-login-form__field:hover .x-form-text {
    //     border-color: rgba(0, 0, 0, 1);
    // }

    // .edi-login-form .edi-login-form__field .x-form-text.x-form-invalid-field {
    //     border-color: rgba(255, 0, 0, 1);
    // }

    // .edi-login-form .x-form-trigger-wrap {
    //     box-shadow: none;
    // }

    // .edi-login-form input:-webkit-autofill,
    // .edi-login-form input:-webkit-autofill:hover,
    // .edi-login-form input:-webkit-autofill:focus,
    // .edi-login-form input:-webkit-autofill:active {
    //     -webkit-box-shadow: 0 0 0 30px white inset !important;
    //     -moz-box-shadow: 0 0 0 30px white inset !important;
    //     box-shadow: 0 0 0 30px white inset !important;
    // }

    // .edi-login-form .edi-login-form__field-label {
    //     color: rgba(0, 0, 0, 0.5);
    //     position: absolute;
    //     top: -10px;
    //     left: 9px;
    //     background-color: white;
    //     padding: 0 3px;
    //     transition: all 0.2s ease-out 0s;
    //     z-index: 10;
    // }

    // .edi-login-form .edi-login-form__field:hover .edi-login-form__field-label {
    //     color: rgba(0, 0, 0, 1);
    // }

    // .edi-login-form .edi-login-form__field .x-trigger-cell {
    //     position: absolute;
    //     top: 12px;
    //     right: 6px;
    //     min-width: unset;
    //     margin-left: unset;
    // }

    // .edi-login-form .edi-login-form__field .x-form-trigger-wrap,
    // .edi-login-form .edi-login-form__field .x-form-trigger-input-cell {
    //     width: 100% !important;
    // }

    .edi-ediweb-login-subtitle {
        display: block;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.16px;
        color: #8E9295;
    }

    .edi-password-recovery-link {
        @include typography__caption_01;

        margin: 16px 0 0 0;
        display: block;
        text-align: right;
        color: #3D7DCA;
        cursor: pointer;
    }

    // .edi-ediweb-password-field {
    //     .x-trigger-cell {
    //         width: 33px !important;
    //         top: 15px !important;
    //     }

    //     .x-trigger-glyph {
    //         font-size: 23px !important;
    //     }
    // }

    // .edi-login-register-button {
    //     font-size: 14px;
    //     line-height: 24px;
    //     font-weight: bold;

    //     text-align: center;
    //     letter-spacing: 0.4px;
    //     text-transform: uppercase;

    //     color: #3D7DCA;
    // }

    .edi-viewport-footer .x-panel-body {
        background-color: transparent !important;
        text-align: center;
    }

    .edi-ediweb-footer {
        @include typography__caption_01;
        color: $color-grey-50;

        &-link {
            color: inherit;
        }
    }

    .edi-login-footer-link {
        width: 142px;
        display: block;
        text-decoration: none;

        &:first-child {
            text-align: end;
        }

        &:last-child {
            text-align: start;
        }
    }

    .edi-ediweb-edisoft-header {
        background: url('images/Ediweb_logo.svg') center no-repeat;
        width: 72px;
        height: 16px;
        margin: 0 80px;
    }

    .edi-ediweb-envelope {
        background-image: url('images/envelope.svg');
        background-position: center 15 !important;
        background-repeat: no-repeat;
        background-size: 96px !important;
        width: 96px !important;
        height: 96px !important;
        margin: 32px auto 0 auto !important;
    }

    .edi-restore-password-error {
        &__img {
            background-image: url('images/Enter_Mail-open.png');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 96px !important;
            width: 107px;
            height: 96px;
            margin: 16px auto 0 auto;
        }

        &__title, &__subtitle {
            font-weight: normal;
            display: block;
            text-align: center;
            font-size: 14px;
            line-height: 18px;
            color: #1D252C;
            letter-spacing: 0.16px;
        }

        &__title {
            margin-top: 32px;

            &:first-line {
                font-weight: bold;
            }
        }

        .edi-login-back-button {
            margin-top: 32px !important;
            margin-bottom: 16px;
        }
    }

    .new-password-not-saved, .edi-restore-password-error__subtitle {
        a {
            color: #3D7DCA;
            text-decoration: none;
        }
    }

    .edi-ediweb-envelope-label {
        font-size: 14px;
        line-height: 18px;
        margin: 32px 0 8px 0;
        display: block;
        align-items: center;
        text-align: center;
        letter-spacing: 0.16px;

        span {
            font-weight: bold;
        }
    }


    .edi-login-error-container div {
        text-align: left;
    }

    .edi-login-info-container {
        text-align: center;

        .edi-login-info-label {
            margin-top: 16px;
            padding: 0 14px;
            font-size: 12px;
            line-height: 16px;
            display: block;
            letter-spacing: 0.32px;
            color: #8E9295;

            &:empty {
                margin: 0;
                padding: 0;
            }
        }
    }

    .success-password-restore {
        .edi-login-info-container {
            .edi-login-info-label {
                min-height: 32px;
                &:empty {
                    margin-top: 16px;
                    padding: 0 14px;
                }
            }
        }
    }

    // .edi-ediweb-fields-block {
    //     .edi-login-form__label {
    //         text-align: right;
    //         margin-top: 16px;

    //         .edi-password-recovery-link {
    //             left: unset;
    //         }
    //     }
    // }

    // .edi-login-error-container {
        // &.edi-login-form__label {
        //     margin-top: 16px;
        // }

        .edi-login-error-label {
            color: $invalid;
            font-size: 12px;
            line-height: 15px;
            display: block;
            margin: 16px 0 0 0;

            &.label-empty {
                margin: 0;
            }
        }

    //     &:has(.label-empty) {
    //         width: 0 !important;
    //         height: 0 !important;
    //         opacity: 0 !important;
    //         margin: 0 !important;
    //         min-height: 0 !important;
    //     }
    // }

    .edi-certificate-login-form, .edi-recovery-password-form {
        .edi-login-error-container {
            &.edi-login-form__label {
                margin-top: 8px;
            }
        }
    }

    .edi-http-login-form, .edi-certificate-login-form {
        margin-top: 8px;
    }

    .edi-password-field-trigger {
        font-family: Material Icons;
        font-size: 24px;

        &:before {
            content: '\e8f4';
            transform: translateY(-50%);
            top: 50%;
            right: 8px;
            position: absolute;
        }

        &.edi-fix-password-icon {
            &:before {
                content: '\e8f5';
            }
        }
    }

    .edi-modal-title {
        display: block;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #1D252C;
        margin-top: 32px;
    }

    // .edi-login-back-button {
    //     font-family: Material Icons;


    //     .x-btn-wrap {
    //         width: unset;
    //     }

    //     .x-btn-inner-default-small {
    //         padding: 0;
    //     }

    //     &:before {
    //         font-size: 24px;
    //         content: '\e5c8';
    //         transform: scale(-1);
    //         font-weight: 500;
    //         margin-right: 8px;
    //     }
    // }

    // .edi-new-password-form {
    //     .edi-ediweb-login-subtitle {
    //         margin-top: 0px;
    //         margin-bottom: 8px;
    //     }

    //     .edi-login-error-container {
    //         margin-top: 24px;

    //         .edi-login-error-label {
    //             display: block;
    //             margin-bottom: 16px;

    //             &:empty {
    //                 margin-bottom: 0;
    //             }
    //         }
    //     }

    //     .requirements-title {
    //         display: block;
    //         color: #1D252C;
    //     }

    //     a.x-btn.requirements-pas:not(:first-child) {
    //         margin-top: 4px;
    //     }
    // }

    // .edi-error-save-password-form {
    //     .edi-ediweb-login-subtitle {
    //         margin-top: 16px;
    //     }

    //     .edi-login-back-button {
    //         margin-bottom: 16px;
    //         margin-top: 0;
    //     }
    // }

    // .edi-success-save-password-form {
    //     .edi-ediweb-login-subtitle {
    //         margin-top: 16px;
    //     }

    //     .edi-login-submit-button {
    //         margin-top: 0 !important;
    //     }
    // }

    .sso-main-panel {
        a.sso-build-toolbar.x-btn.x-btn-over {
            background-color: transparent;

            .x-btn-glyph {
                color: #8e9295;
            }
        }

        // .edi-sso-card-panel {
        //     .edi-login-form {
        //         .edi-login-submit-button {
        //             margin-top: 24px;
        //             background-color: #3d7dca !important;
        //             padding: 13px 10px;
        //             border-radius: 3px;
        //             height: 40px;
        //             box-shadow: 0 1px 2px rgb(0 0 0 / 20%);

        //             &.x-btn-over {
        //                 box-shadow: 0 1px 3px rgb(0 0 0 / 60%);
        //             }

        //             .x-btn-inner {
        //                 font-weight: bold;
        //                 color: white !important;
        //             }
        //         }

        //         .edi-login-register-button {
        //             display: block;
        //             min-height: 40px;
        //             font-size: 14px;
        //             display: flex;
        //             justify-content: center;
        //             align-items: center;
        //             letter-spacing: 0.4px;
        //             margin-top: 16px;
        //             background-color: transparent !important;

        //             .x-btn-inner {
        //                 font-weight: bold;
        //                 color: #3D7DCA !important;
        //             }
        //         }
        //     }
        // }

        .x-mask {
            border-radius: 8px;

            &.x-focus {
                border: none;
            }
        }
    }

    .edi-ediweb-header-logo {
        display: block;
        position: relative;
        padding-top: 32px;
        background-image: url('images/EdiwebID_logo.svg');
        width: 96px!important;
        background-repeat: no-repeat;
        background-size: 96px !important;
        margin: auto;

        &.edi-ediweb-header-logo-left {
            margin: 0;
            padding-bottom: 16px;
        }
        &.edi-ediweb-header-logo-big {
            width: 144px!important;
            background-size: 144px !important;

        }
    }
}