.edi-ediweb-upload-sert-btn {
  border: 2px solid #3D7DCA;
  box-sizing: border-box;
  border-radius: 4px;

  .x-btn-inner {
    font-weight: bold;
    color: #3D7DCA;
    font-size: 14px;
    line-height: 18px;
  }

  .x-btn-glyph {
    color: #3D7DCA;
    left: 11px;
  }
}

.ediweb-file-name-container {
  background: rgba(29, 37, 44, 0.05);
  border-radius: 4px;
  .ediweb-file-name-text-field {
    .x-form-text {
      color: #3D7DCA!important;
    }
  }
}