// @use '@UIkit/components/fields/Base/Base.scss' as base;
@import '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables.scss';

$label: '.x-form-item-label';
$wrap: '.x-form-trigger-wrap';

.ui-field {
	$this: &;

	&-required {
		#{$wrap} {
			border-bottom-color: $color-error !important;
			box-shadow: 0 1px 0 0 $color-error !important;
		}
	}

	&-status {
		&-required,
		&-error {
			display: block;
			margin: 4px 0 0 0;

			@extend .caption_01;
			color: $color-grey-50;
		}
		&-error {
			color: $color-error;
		}
	}
}
