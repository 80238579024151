@import './mixins';

.body-short_01 {
	@include typography__body-short_01();
}

.body-short_02 {
	@include typography__body-short_02();
}

.body-short_03 {
	@include typography__body-short_03();
}

.body-long_01 {
	@include typography__body-long_01();
}

.body-long_02 {
	@include typography__body-long_02();
}

.caption_01 {
	@include typography__caption_01();
}

.caption_02 {
	@include typography__caption_02();
}

.overline {
	@include typography__overline();
}
