@import '@UIkit/components/fields/Base/Base.scss';

.ui-numberfield {
	@extend %field;

	.x-form-trigger-spinner {
		display: flex;
		flex-direction: column;

		&:before {
			display: none;
		}
	}
}
