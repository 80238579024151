@use '@UIkit/styles/typography/heading';
@use '@UIkit/styles/typography/text';
@import '@UIkit/styles/global/Colors/Variables';

$this: '.ui-tabpanel';

#{$this} {
	position: relative;

	&-body {
		position: relative;
		top: 30px;
	}

	.x-tab {
		display: inline-flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0;
		margin: 0;

		&-button {
			display: flex;
			justify-content: start;
			align-items: center;
			column-gap: 8px;
			padding: 0 16px;
			color: $color-dark;
		}

		&-inner {
			color: inherit !important;
		}

		&-glyph {
			line-height: 24px;
			margin: 0 !important;
			color: inherit !important;
		}

		&.x-tab-focus {
			outline: none !important;
		}
	}

	.x-tab-bar {
		padding: 0;
		margin: 0;

		&-body {
			padding: 0;
			margin: 0;
			min-height: auto;
			display: flex;

			&-default {
				padding: 0 !important;
			}
		}

		.x-box {
			&-inner {
				margin: 0;
				position: relative;
			}
			&-scroller {
				width: 24px;
				height: 24px;
				color: $color-grey-50;
				background: none;
				position: relative;
				padding: 0;
				margin: 0;
				opacity: 1;

				&-hover {
					color: $color-dark;
				}
				&-pressed {
					color: $color-dark;
				}
				&-disabled {
					opacity: 0.5;
					color: $color-grey-50;
				}

				&-left {
					margin-right: 2px;
				}
				&-right {
					margin-left: 2px;
				}
			}
		}
	}

	//TAB_PANEL_CLS.simple
	&-simple,
	&-simple-without-padding {
		> .ui-tabpanel-bodyWrap {
			> .x-tab-bar {
				&::before {
					bottom: 0;
					position: absolute;
					display: block;
					width: 100%;
					height: 1px;
					content: '';
					background-color: $color-grey-20;
				}

				.x-tab {
					background-color: transparent;
					margin-right: 8px;

					&::after {
						content: '';
						position: absolute;
						bottom: 0;
						width: 100%;
						height: 2px;
					}

					&:last-child {
						margin: 0;
					}

					&-button {
						height: 28px;
					}

					&-inner {
						@extend .heading_01;
					}

					&.x-tab-over {
						.x-tab-button {
							color: $color-blue-80;
						}

						&.x-tab-active {
							&::after {
								background-color: $color-blue;
							}
						}
					}

					&.x-tab-active {
						.x-tab-button {
							color: $color-blue;
						}

						&::after {
							background-color: $color-blue;
						}
					}
				}
			}
		}
	}

	&-simple {
		margin-top: 28px;
	}

	&-simple-without-padding {
		margin-top: 8px;
	}

	//TAB_PANEL_CLS.module
	&-module {
		> .ui-tabpanel-bodyWrap {
			> .x-tab-bar {
				.x-tab {
					border-top-left-radius: 8px;
					border-top-right-radius: 8px;

					&-bar {
						&-body {
							align-items: center;
						}
					}

					&-button {
						height: 40px;
						color: $color-dark;
					}

					&-inner {
						@extend .body-short_03;
					}

					.x-tab-close-btn {
						position: relative;
						flex-shrink: 0;
						height: 24px;
						width: 24px;
						margin-right: 8px;
						border-radius: 100%;

						&:before {
							content: '\e5cd';
							font-family: 'Material Icons', sans-serif;
							font-size: 24px;
							line-height: 24px;
							width: 24px;
							color: $color-grey-50;
						}

						&:hover {
							background-color: $color-grey-10;

							&:before {
								color: $color-dark;
							}
						}
					}

					&.x-tab-over {
						overflow: visible;
						background-color: $color-white-opacity-50;

						.x-tab-button {
							color: $color-dark;
						}
					}

					&.x-tab-over.x-tab-active {
						background-color: $color-white;

						.x-tab-button {
							color: $color-dark;
						}
					}

					&.x-tab-active {
						overflow: visible;
						background-color: $color-white;

						.x-tab-button {
							color: $color-dark;
						}
					}

					&.x-tab-active:after,
					&.x-tab-active:before,
					&.x-tab-over:after,
					&.x-tab-over:before {
						content: '';
						position: absolute;
						width: 8px;
						height: 8px;
						bottom: 0;
						background-size: cover;
					}

					&-active:after {
						left: 100%;
						background-image: url(./images/tab-active-right-bottom-angle.png);
					}

					&:not(:first-child).x-tab-active:before {
						right: 100%;
						background-image: url(./images/tab-active-left-bottom-angle.png);
					}

					&:not(:first-child):not(.x-tab-active):not(:hover):before,
					&:not(:first-child):not(.x-tab-active):not(:hover):last-child:after {
						position: absolute;
						top: 8px;
						display: inline-block;
						width: 1px;
						height: 24px;
						background-color: $color-grey-20;
						content: '';
					}

					&:not(:first-child):not(.x-tab-active):not(:hover):before {
						left: 0;
					}

					&:not(:first-child):not(.x-tab-active):not(:hover):last-child:after {
						right: 0;
					}

					&:hover + .x-tab:not(.x-tab-active):before,
					&-active + .x-tab:before {
						visibility: hidden;
					}
				}
			}
		}
	}
}
