@use '@UIkit/styles/typography/heading';
@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

$this: '.ui-fieldblock';
#{$this} {
	position: relative !important;
	height: auto !important;

	&-title,
	&-subtitle {
		position: static !important;
		display: block;
		margin: 0;
	}

	&-title {
		&-only {
			margin-bottom: 8px;
		}
	}

	&-subtitle {
		margin-bottom: 8px;
	}

	&-body {
		position: static !important;
		height: auto !important;
	}

	&-hide {
		display: none;
	}

	//классы FIELD_BLOCK_CLS
	&-default {
		#{$this}-title {
			@extend .heading_01;
			color: $color-dark;
		}

		#{$this}-subtitle {
			@extend .caption_01;
			opacity: 0.4;
			color: $color-dark;
		}
	}

	&-small {
		#{$this}-title {
			@extend .body-short_02;
			color: $color-dark;
		}

		#{$this}-subtitle {
			@extend .caption_01;
			opacity: 0.4;
			color: $color-dark;
		}
	}

	&-details-block {
		margin-top: 24px;
	}
}
