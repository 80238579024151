@use '@UIkit/components/fields/Base/Base.scss' as box;

.ui-switchfield {
	@extend %box;

	.x-form-cb-label {
		line-height: 16px;
	}

	@include box.input {
		width: 32px;
		height: 16px;
		background: #8e9295;
		border-radius: 16px;

		&::before {
			position: absolute;
			top: 2px;
			left: 0px;
			content: '';
			margin-left: 2px;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			background: #fff;
			box-shadow: 0px 0px 0px rgba(76, 93, 110, 0.2), 0px 1px 2px rgba(76, 93, 110, 0.2);
			transition: all 200ms ease-out 0s;
		}
	}
	@include box.checked {
		background: #3d7dca;

		&::before {
			left: 16px;
		}
	}

	&:hover {
		@include box.input {
			outline-offset: -1px;
			outline: 1px solid #1d252c;
		}
		@include box.checked {
			outline: none;
			background: #6497d5;
		}
	}
}
