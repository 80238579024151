.#{$prefix}-button {
  @mixin edi-button() {
    border-radius: 4px;
    padding: 0;
    height: 40px;

    @at-root .x-toolbar-footer & {
      padding: 0;
      .x-btn-inner {
        color: inherit;
      }
    }
    @at-root .x-form-file-wrap & {
      padding: 0;
      .x-btn-inner {
        color: inherit;
      }
    }

    @at-root BODY.edi-viewport .x-toolbar-footer &.x-btn .x-btn-glyph {
      color: inherit;
    }

    .x-btn-wrap {
      padding: 10px 16px;

      &::after {
        display: none;
      }
    }

    .x-btn-button {
      height: auto;
    }
  }

	&-link {
		background: none !important;

		& .x-btn-inner {
			color: #3D7DCA !important;
		}

		&:hover {
			& .x-btn-inner {
				text-decoration: underline;
			}
		}
	}

  &-primary,
  &-blue {
    // @deprecated use primary button instead of class name containing color name
    background: #3D7DCA;
    border-radius: 4px;
    color: #fff;
    padding: 0;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    .x-btn-wrap {
      padding: 10px 16px;

      &::after {
        display: none;
      }
    }

    .x-btn-button {
      height: auto;
    }

    &-stroke {
      .x-btn-inner, .x-btn-icon-el {
        color: #3D7DCA;
      }
    }
  }

  &-primary {
    @include edi-button();

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    &:not(.x-disabled, :disabled){
      &:hover {
        background-color: #508ACF;
        color: #fff;
      }

      &:active {
        background-color: #6497D5;
        color: #fff;
      }
    }

    &.x-disabled,
    &:disabled {
      background-color: rgba(61, 125, 202, 0.15);
      color: #c1cde0;
      border-color: #c1cde0;
    }
  }

  &-secondary {
    @include edi-button();
    background-color: transparent;
    color: #3D7DCA;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    &:not(.x-disabled),
    &:not(:disabled) {
      &:hover {
        background-color: rgba(61, 125, 202, 0.1);
      }

      &:active {
        background-color: rgba(61, 125, 202, 0.15);
      }
    }

    &.x-disabled,
    &:disabled {
      color: rgba(61, 125, 202, 0.18);
    }
  }

  &-tool {
    @include edi-button();
    background-color: transparent;
    color: #1D252C;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    &:not(.x-disabled),
    &:not(:disabled) {
      &:hover {
        background-color: #F3F4F4;
      }

      &:active {
        background-color: #E8E9E9;
      }
    }

    &.x-disabled,
    &:disabled {
      color: #989a9a
    }
  }

  &-second-tool {
    @include edi-button();
    background-color: transparent;
    color: #1D252C;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    &:not(.x-disabled),
    &:not(:disabled) {
      &:hover {
        background-color: #F3F4F4;
      }

      &:active {
        background-color: #E8E9E9;
      }
    }

    &.x-disabled,
    &:disabled {
      color: #989a9a
    }
  }

  &-primary-action {
    @include edi-button();
    background-color: transparent;
    border: 1px solid #3D7DCA;
    color: #3D7DCA;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    &:not(.x-disabled),
    &:not(:disabled) {
      &:hover {
        background-color: #508ACF;
        color: #fff;
      }

      &:active {
        background-color: #6497D5;
        color: #fff;
      }
    }

    &.x-disabled,
    &:disabled {
      background-color: #FFFFFF;
      color: #c1cde0;
      border-color: #c1cde0;
    }
  }

  &-second-action {
    @include edi-button();
    background-color: transparent;
    border: 1px solid #1D252C;
    color: #1D252C;

    .x-btn-inner,
    .x-btn-icon-el {
      color: inherit;
    }

    @at-root .x-form-file-wrap &,
    .x-toolbar-footer & {
      background-color: transparent;
    }

    &:not(.x-disabled),
    &:not(:disabled) {
      &:hover {
        background-color: #F3F4F4;
        border-color: #F3F4F4;
      }

      &:active {
        background-color: #E8E9E9;
        border-color: #E8E9E9;
      }
    }

    &.x-disabled,
    &:disabled {
      color: #989a9a;
      border-color: #989a9a;
    }
  }
}

.x-btn-badge {
	position: absolute;
	top: 0;
	left: 50%;
	font-size: 12px;
	line-height: 12px;
	color: #FFFFFF;
	background-color: #E82000;
	border-radius: 8px;
	padding: 2px 5px;
}

@mixin roundHover($color, $hoverColor, $hoverBgColor, $pressedBgColor) {
	color: $color;
	.x-btn-glyph {
		color: $color;
	}

	&.x-btn-over {
		color: $hoverColor;

		.x-btn-glyph {
			color: $hoverColor;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			background-color: $hoverBgColor;
		}
	}

	&.x-btn-pressed {
		&::before {
			background-color: $pressedBgColor;
		}
	}
}