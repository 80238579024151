@font-face{
	font-family: 'Material Icons';
	font-style:  normal;
	font-weight: 400;
	font-display: block;
	src:	url(fonts/material_design_icons/MaterialIcons-Regular.eot);
	src:	url(fonts/material_design_icons/MaterialIcons-Regular.eot?#iefix) format('embedded-opentype'),
			url(fonts/material_design_icons/MaterialIcons-Regular.woff2) format('woff2'),
			url(fonts/material_design_icons/MaterialIcons-Regular.woff) format('woff');
}

@font-face{
	font-family: 'EdiSoftIcons';
	src:	url(fonts/edisoft_icons/EdiSoftIcons.eot);
	src:	url(fonts/edisoft_icons/EdiSoftIcons.eot?#iefix) format('embedded-opentype'),
			url(fonts/edisoft_icons/EdiSoftIcons.woff) format('woff');
	font-weight:  normal;
	font-style:   normal;
	font-display: block;
}