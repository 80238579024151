.ui-plugin-combo-item-disabled {
	opacity: 0.3;
	cursor: default;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 1;
	}
}
