.edi-confirmation-header-label {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.15px;
  font-weight: bold;
}

.edi-confirmation-content-label {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.15px;
  margin: auto;
}

.edi-confirmation-success-image {
  background-image: url('images/Mail-Ok.png')!important;
  background-position: center 15!important;
  background-repeat: no-repeat;
  background-size:160px!important;
  width: 160px !important;
  height: 160px !important;
  margin: auto;
}
.edi-confirmation-failure-image {
  background-image: url('images/Mail-Error.png')!important;
  background-position: center 15!important;
  background-repeat: no-repeat;
  background-size:160px!important;
  width: 160px !important;
  height: 160px !important;
  margin: auto;
}

.edi-main-confirmation-panel {
  text-align: center;
}