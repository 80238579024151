@import './mixins';

.heading_04 {
	@include typography__heading_04();
}

.heading_03 {
	@include typography__heading_03();
}

.heading_02 {
	@include typography__heading_02();
}

.heading_01 {
	@include typography__heading_01();
}
