@use '@UIkit/styles/typography/text';
@import "@UIkit/styles/global/Colors/Variables";

$this: '.ui-detail-label';
#{$this} {
	&-title {
		@extend .body-short_01;
		color: $color-grey-50;
		margin-top: 8px;
	}

	&-content {
		@extend .body-short_01;
		padding-right: 16px;
		color: $color-dark;

		&-empty {
			color: $color-grey-50;
			margin-top: 8px;
		}
	}

	&-textarea {
		width: 800px;
	}
}
